import { CurrencyDollarIcon, LifebuoyIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { classNames } from '../App';

import OpportunityOverviewBar from './OpportunityOverviewBar';
import DealopsQuoteTable from './QuoteTables/DealopsQuoteTable';
import { relatedDealsHardcoded } from './RelatedDeals – experimental/hardcodedData';
import RelatedDeals from './RelatedDeals – experimental/RelatedDeals';
import ShareButton from './ShareButton';
import {
  DealopsPricingFlow,
  PricingFlowCommon,
  PricingFlowType,
} from './types';

export default function Step2(props: {
  pricingFlow: PricingFlowCommon;
  updateFlow: (flow: PricingFlowCommon, showLoading?: boolean) => void;
  setStage: (stage: PricingFlowCommon['stage']) => void;
}) {
  const { pricingFlow, updateFlow } = props;
  const [tab, setTab] = useState<'quote' | 'related-deals'>('quote');

  return (
    <div id="step_2" className="">
      {/* Header */}
      <div className="flex flex-col gap-4 border-b border-gray-200 px-6 py-4 xl:flex-row xl:justify-between">
        <div className="flex flex-row items-center gap-4">
          {/* Currency icon */}
          <div className="h-14 w-14">
            <div className="absolute flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <CurrencyDollarIcon className="h-7 w-7" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <h1 className="text-lg font-semibold">
              Pricing for {pricingFlow.opportunity.sfdcOpportunityName}
            </h1>
            <p className="text-base text-gray-700">
              Adjust the quote for this opportunity based on your company
              guidance.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            type="button"
            className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
          >
            <LifebuoyIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
            Help with pricing
          </button>
          <ShareButton
            textToCopy={window.location.href}
            pricingFlow={pricingFlow}
          />
          <button
            type="button"
            className="inline-flex items-center rounded-lg border border-gray-300 bg-fuchsia-900 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
          >
            {'Create quote in Salesforce'}
          </button>
        </div>
      </div>

      <div className="px-8 py-4">
        {/* Tabs – currently only implemented for Dealops demo */}
        {pricingFlow.type === PricingFlowType.DEALOPS && (
          <div className="font-base flex flex-row gap-2 font-semibold text-gray-700">
            <button
              className={classNames(
                tab === 'quote'
                  ? 'bg-gray-50 text-gray-700 hover:text-gray-500'
                  : 'bg-white text-gray-500 hover:bg-gray-50 hover:text-gray-700',
                'rounded-md px-3 py-2',
              )}
              onClick={() => setTab('quote')}
            >
              Quote
            </button>
            <button
              className={classNames(
                tab === 'related-deals'
                  ? 'bg-gray-50 text-gray-700 hover:text-gray-500'
                  : 'bg-white text-gray-500 hover:bg-gray-50 hover:text-gray-700',
                'rounded-md px-3 py-2',
              )}
              onClick={() => setTab('related-deals')}
            >
              Related deals
            </button>
          </div>
        )}

        {tab === 'quote' && (
          <div className="pt-2">
            <OpportunityOverviewBar
              opportunityData={pricingFlow.opportunity.opportunityData}
              stretchWidth
              type={pricingFlow.type}
            />

            {pricingFlow.type === PricingFlowType.DEALOPS && (
              <DealopsQuoteTable
                pricingFlow={pricingFlow as DealopsPricingFlow}
                updateFlow={updateFlow}
                setStage={props.setStage}
              />
            )}
          </div>
        )}

        {pricingFlow.type === PricingFlowType.DEALOPS &&
          tab === 'related-deals' && (
            <RelatedDeals relatedDeals={relatedDealsHardcoded} />
          )}
        <div className="h-16" />
      </div>
    </div>
  );
}
