export interface PriceDifferentialLine {
  label: string;
  color: string;
  points: {
    xLocation: number;
    value: number;
  }[];
  userPoints: {
    xLocation: number;
    value: number;
    userId: number;
  }[];
}

export interface ProductData {
  name: string;
  productCategoryId: string;
  lines: PriceDifferentialLine[];
}

export const PRICE_DIFFERENTIAL_DATA: ProductData[] = [
  {
    name: 'Authentication',
    productCategoryId: '1',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.12,
          },
          {
            xLocation: 500,
            value: 1.18,
          },
          {
            xLocation: 600,
            value: 1.24,
          },
          {
            xLocation: 700,
            value: 1.32,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.38,
          },
          {
            xLocation: 1000,
            value: 1.53,
          },
          {
            xLocation: 1100,
            value: 1.68,
          },
          {
            xLocation: 1200,
            value: 1.7,
          },
          {
            xLocation: 1300,
            value: 1.77,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.99,
          },
          {
            xLocation: 1600,
            value: 2.07,
          },
          {
            xLocation: 1700,
            value: 2.17,
          },
          {
            xLocation: 1800,
            value: 2.19,
          },
          {
            xLocation: 1900,
            value: 2.3,
          },
          {
            xLocation: 2000,
            value: 2.4,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 307,
            value: 1.01,
            userId: 1,
          },
          {
            xLocation: 307,
            value: 1.01,
            userId: 5,
          },
          {
            xLocation: 344,
            value: 1.05,
            userId: 4,
          },
          {
            xLocation: 353,
            value: 1.06,
            userId: 3,
          },
          {
            xLocation: 389,
            value: 1.1,
            userId: 5,
          },
          {
            xLocation: 395,
            value: 1.11,
            userId: 5,
          },
          {
            xLocation: 423,
            value: 1.14,
            userId: 7,
          },
          {
            xLocation: 697,
            value: 1.32,
            userId: 3,
          },
          {
            xLocation: 711,
            value: 1.33,
            userId: 9,
          },
          {
            xLocation: 722,
            value: 1.34,
            userId: 2,
          },
          {
            xLocation: 800,
            value: 1.42,
            userId: 3,
          },
          {
            xLocation: 806,
            value: 1.42,
            userId: 3,
          },
          {
            xLocation: 873,
            value: 1.39,
            userId: 9,
          },
          {
            xLocation: 880,
            value: 1.38,
            userId: 2,
          },
          {
            xLocation: 902,
            value: 1.38,
            userId: 4,
          },
          {
            xLocation: 973,
            value: 1.49,
            userId: 4,
          },
          {
            xLocation: 1005,
            value: 1.54,
            userId: 8,
          },
          {
            xLocation: 1007,
            value: 1.54,
            userId: 4,
          },
          {
            xLocation: 1015,
            value: 1.56,
            userId: 3,
          },
          {
            xLocation: 1032,
            value: 1.58,
            userId: 5,
          },
          {
            xLocation: 1048,
            value: 1.61,
            userId: 6,
          },
          {
            xLocation: 1065,
            value: 1.63,
            userId: 7,
          },
          {
            xLocation: 1066,
            value: 1.63,
            userId: 7,
          },
          {
            xLocation: 1068,
            value: 1.64,
            userId: 3,
          },
          {
            xLocation: 1101,
            value: 1.68,
            userId: 1,
          },
          {
            xLocation: 1130,
            value: 1.69,
            userId: 4,
          },
          {
            xLocation: 1147,
            value: 1.69,
            userId: 2,
          },
          {
            xLocation: 1166,
            value: 1.69,
            userId: 1,
          },
          {
            xLocation: 1225,
            value: 1.71,
            userId: 6,
          },
          {
            xLocation: 1260,
            value: 1.74,
            userId: 8,
          },
          {
            xLocation: 1283,
            value: 1.76,
            userId: 9,
          },
          {
            xLocation: 1307,
            value: 1.78,
            userId: 7,
          },
          {
            xLocation: 1309,
            value: 1.78,
            userId: 7,
          },
          {
            xLocation: 1340,
            value: 1.82,
            userId: 1,
          },
          {
            xLocation: 1378,
            value: 1.85,
            userId: 8,
          },
          {
            xLocation: 1452,
            value: 1.94,
            userId: 8,
          },
          {
            xLocation: 1461,
            value: 1.94,
            userId: 5,
          },
          {
            xLocation: 1504,
            value: 1.99,
            userId: 8,
          },
          {
            xLocation: 1510,
            value: 2,
            userId: 4,
          },
          {
            xLocation: 1583,
            value: 2.06,
            userId: 9,
          },
          {
            xLocation: 1617,
            value: 2.09,
            userId: 1,
          },
          {
            xLocation: 1620,
            value: 2.09,
            userId: 1,
          },
          {
            xLocation: 1639,
            value: 2.11,
            userId: 5,
          },
          {
            xLocation: 1645,
            value: 2.12,
            userId: 6,
          },
          {
            xLocation: 1714,
            value: 2.17,
            userId: 6,
          },
          {
            xLocation: 1756,
            value: 2.18,
            userId: 9,
          },
          {
            xLocation: 1789,
            value: 2.19,
            userId: 2,
          },
          {
            xLocation: 1790,
            value: 2.19,
            userId: 2,
          },
          {
            xLocation: 1807,
            value: 2.19,
            userId: 6,
          },
          {
            xLocation: 1867,
            value: 2.27,
            userId: 6,
          },
          {
            xLocation: 1964,
            value: 2.37,
            userId: 7,
          },
          {
            xLocation: 1976,
            value: 2.38,
            userId: 9,
          },
          {
            xLocation: 1997,
            value: 2.4,
            userId: 8,
          },
          {
            xLocation: 2064,
            value: 2.46,
            userId: 2,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.81,
          },
          {
            xLocation: 500,
            value: 1.9,
          },
          {
            xLocation: 600,
            value: 2.01,
          },
          {
            xLocation: 700,
            value: 2.08,
          },
          {
            xLocation: 800,
            value: 2.01,
          },
          {
            xLocation: 900,
            value: 2.19,
          },
          {
            xLocation: 1000,
            value: 2.28,
          },
          {
            xLocation: 1100,
            value: 2.31,
          },
          {
            xLocation: 1200,
            value: 2.31,
          },
          {
            xLocation: 1300,
            value: 2.53,
          },
          {
            xLocation: 1400,
            value: 2.55,
          },
          {
            xLocation: 1500,
            value: 2.56,
          },
          {
            xLocation: 1600,
            value: 2.67,
          },
          {
            xLocation: 1700,
            value: 2.67,
          },
          {
            xLocation: 1800,
            value: 2.89,
          },
          {
            xLocation: 1900,
            value: 2.92,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 321,
            value: 1.65,
            userId: 6,
          },
          {
            xLocation: 337,
            value: 1.67,
            userId: 5,
          },
          {
            xLocation: 371,
            value: 1.76,
            userId: 7,
          },
          {
            xLocation: 381,
            value: 1.78,
            userId: 6,
          },
          {
            xLocation: 398,
            value: 1.81,
            userId: 9,
          },
          {
            xLocation: 438,
            value: 1.85,
            userId: 2,
          },
          {
            xLocation: 533,
            value: 1.94,
            userId: 9,
          },
          {
            xLocation: 534,
            value: 1.94,
            userId: 6,
          },
          {
            xLocation: 553,
            value: 1.96,
            userId: 7,
          },
          {
            xLocation: 614,
            value: 2.03,
            userId: 2,
          },
          {
            xLocation: 627,
            value: 2.03,
            userId: 1,
          },
          {
            xLocation: 637,
            value: 2.03,
            userId: 8,
          },
          {
            xLocation: 645,
            value: 2.05,
            userId: 3,
          },
          {
            xLocation: 648,
            value: 2.05,
            userId: 5,
          },
          {
            xLocation: 696,
            value: 2.08,
            userId: 8,
          },
          {
            xLocation: 710,
            value: 2.08,
            userId: 2,
          },
          {
            xLocation: 721,
            value: 2.06,
            userId: 5,
          },
          {
            xLocation: 721,
            value: 2.06,
            userId: 2,
          },
          {
            xLocation: 788,
            value: 2.01,
            userId: 7,
          },
          {
            xLocation: 836,
            value: 2.08,
            userId: 3,
          },
          {
            xLocation: 922,
            value: 2.21,
            userId: 6,
          },
          {
            xLocation: 975,
            value: 2.26,
            userId: 3,
          },
          {
            xLocation: 1012,
            value: 2.28,
            userId: 9,
          },
          {
            xLocation: 1037,
            value: 2.3,
            userId: 9,
          },
          {
            xLocation: 1067,
            value: 2.3,
            userId: 1,
          },
          {
            xLocation: 1084,
            value: 2.31,
            userId: 5,
          },
          {
            xLocation: 1097,
            value: 2.31,
            userId: 8,
          },
          {
            xLocation: 1098,
            value: 2.31,
            userId: 4,
          },
          {
            xLocation: 1166,
            value: 2.31,
            userId: 4,
          },
          {
            xLocation: 1199,
            value: 2.31,
            userId: 5,
          },
          {
            xLocation: 1233,
            value: 2.39,
            userId: 3,
          },
          {
            xLocation: 1247,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 1297,
            value: 2.53,
            userId: 6,
          },
          {
            xLocation: 1314,
            value: 2.53,
            userId: 3,
          },
          {
            xLocation: 1316,
            value: 2.53,
            userId: 4,
          },
          {
            xLocation: 1355,
            value: 2.55,
            userId: 9,
          },
          {
            xLocation: 1461,
            value: 2.56,
            userId: 7,
          },
          {
            xLocation: 1490,
            value: 2.56,
            userId: 6,
          },
          {
            xLocation: 1552,
            value: 2.62,
            userId: 8,
          },
          {
            xLocation: 1596,
            value: 2.67,
            userId: 4,
          },
          {
            xLocation: 1607,
            value: 2.67,
            userId: 1,
          },
          {
            xLocation: 1628,
            value: 2.67,
            userId: 4,
          },
          {
            xLocation: 1633,
            value: 2.67,
            userId: 5,
          },
          {
            xLocation: 1652,
            value: 2.67,
            userId: 3,
          },
          {
            xLocation: 1696,
            value: 2.67,
            userId: 1,
          },
          {
            xLocation: 1751,
            value: 2.78,
            userId: 7,
          },
          {
            xLocation: 1790,
            value: 2.87,
            userId: 2,
          },
          {
            xLocation: 1803,
            value: 2.89,
            userId: 9,
          },
          {
            xLocation: 1810,
            value: 2.89,
            userId: 1,
          },
          {
            xLocation: 1885,
            value: 2.92,
            userId: 8,
          },
          {
            xLocation: 1925,
            value: 2.94,
            userId: 7,
          },
          {
            xLocation: 1936,
            value: 2.94,
            userId: 1,
          },
          {
            xLocation: 2016,
            value: 3.01,
            userId: 4,
          },
          {
            xLocation: 2074,
            value: 3.06,
            userId: 2,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.2,
          },
          {
            xLocation: 400,
            value: 2.33,
          },
          {
            xLocation: 500,
            value: 2.54,
          },
          {
            xLocation: 600,
            value: 2.54,
          },
          {
            xLocation: 700,
            value: 2.51,
          },
          {
            xLocation: 800,
            value: 2.69,
          },
          {
            xLocation: 900,
            value: 2.74,
          },
          {
            xLocation: 1000,
            value: 2.8,
          },
          {
            xLocation: 1100,
            value: 2.92,
          },
          {
            xLocation: 1200,
            value: 2.83,
          },
          {
            xLocation: 1300,
            value: 3.14,
          },
          {
            xLocation: 1400,
            value: 3.14,
          },
          {
            xLocation: 1500,
            value: 3.03,
          },
          {
            xLocation: 1600,
            value: 3.32,
          },
          {
            xLocation: 1700,
            value: 3.3,
          },
          {
            xLocation: 1800,
            value: 3.54,
          },
          {
            xLocation: 1900,
            value: 3.39,
          },
          {
            xLocation: 2000,
            value: 3.57,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 338,
            value: 2.24,
            userId: 8,
          },
          {
            xLocation: 354,
            value: 2.27,
            userId: 3,
          },
          {
            xLocation: 359,
            value: 2.29,
            userId: 7,
          },
          {
            xLocation: 360,
            value: 2.29,
            userId: 1,
          },
          {
            xLocation: 365,
            value: 2.29,
            userId: 4,
          },
          {
            xLocation: 424,
            value: 2.38,
            userId: 1,
          },
          {
            xLocation: 434,
            value: 2.4,
            userId: 9,
          },
          {
            xLocation: 435,
            value: 2.4,
            userId: 8,
          },
          {
            xLocation: 449,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 464,
            value: 2.47,
            userId: 4,
          },
          {
            xLocation: 502,
            value: 2.54,
            userId: 2,
          },
          {
            xLocation: 670,
            value: 2.51,
            userId: 5,
          },
          {
            xLocation: 670,
            value: 2.51,
            userId: 6,
          },
          {
            xLocation: 673,
            value: 2.51,
            userId: 1,
          },
          {
            xLocation: 691,
            value: 2.51,
            userId: 8,
          },
          {
            xLocation: 704,
            value: 2.51,
            userId: 1,
          },
          {
            xLocation: 806,
            value: 2.69,
            userId: 7,
          },
          {
            xLocation: 834,
            value: 2.71,
            userId: 7,
          },
          {
            xLocation: 846,
            value: 2.71,
            userId: 3,
          },
          {
            xLocation: 868,
            value: 2.71,
            userId: 9,
          },
          {
            xLocation: 870,
            value: 2.71,
            userId: 4,
          },
          {
            xLocation: 872,
            value: 2.71,
            userId: 9,
          },
          {
            xLocation: 903,
            value: 2.74,
            userId: 2,
          },
          {
            xLocation: 1019,
            value: 2.83,
            userId: 3,
          },
          {
            xLocation: 1038,
            value: 2.85,
            userId: 1,
          },
          {
            xLocation: 1063,
            value: 2.87,
            userId: 1,
          },
          {
            xLocation: 1085,
            value: 2.89,
            userId: 5,
          },
          {
            xLocation: 1086,
            value: 2.89,
            userId: 4,
          },
          {
            xLocation: 1100,
            value: 2.92,
            userId: 5,
          },
          {
            xLocation: 1213,
            value: 2.87,
            userId: 3,
          },
          {
            xLocation: 1214,
            value: 2.87,
            userId: 2,
          },
          {
            xLocation: 1245,
            value: 2.96,
            userId: 9,
          },
          {
            xLocation: 1250,
            value: 2.98,
            userId: 6,
          },
          {
            xLocation: 1334,
            value: 3.14,
            userId: 2,
          },
          {
            xLocation: 1490,
            value: 3.03,
            userId: 8,
          },
          {
            xLocation: 1505,
            value: 3.05,
            userId: 2,
          },
          {
            xLocation: 1575,
            value: 3.25,
            userId: 5,
          },
          {
            xLocation: 1579,
            value: 3.25,
            userId: 6,
          },
          {
            xLocation: 1607,
            value: 3.32,
            userId: 3,
          },
          {
            xLocation: 1674,
            value: 3.3,
            userId: 3,
          },
          {
            xLocation: 1701,
            value: 3.3,
            userId: 5,
          },
          {
            xLocation: 1724,
            value: 3.36,
            userId: 6,
          },
          {
            xLocation: 1728,
            value: 3.36,
            userId: 4,
          },
          {
            xLocation: 1781,
            value: 3.5,
            userId: 4,
          },
          {
            xLocation: 1795,
            value: 3.52,
            userId: 5,
          },
          {
            xLocation: 1845,
            value: 3.48,
            userId: 7,
          },
          {
            xLocation: 1867,
            value: 3.43,
            userId: 7,
          },
          {
            xLocation: 1887,
            value: 3.41,
            userId: 9,
          },
          {
            xLocation: 1965,
            value: 3.5,
            userId: 7,
          },
          {
            xLocation: 1977,
            value: 3.52,
            userId: 6,
          },
          {
            xLocation: 1982,
            value: 3.54,
            userId: 6,
          },
          {
            xLocation: 1983,
            value: 3.54,
            userId: 8,
          },
          {
            xLocation: 2007,
            value: 3.57,
            userId: 9,
          },
          {
            xLocation: 2041,
            value: 3.61,
            userId: 2,
          },
        ],
      },
    ],
  },
  {
    name: 'Fraud Detection',
    productCategoryId: '1',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.06,
          },
          {
            xLocation: 500,
            value: 1.15,
          },
          {
            xLocation: 600,
            value: 1.19,
          },
          {
            xLocation: 700,
            value: 1.34,
          },
          {
            xLocation: 800,
            value: 1.39,
          },
          {
            xLocation: 900,
            value: 1.42,
          },
          {
            xLocation: 1000,
            value: 1.56,
          },
          {
            xLocation: 1100,
            value: 1.73,
          },
          {
            xLocation: 1200,
            value: 1.72,
          },
          {
            xLocation: 1300,
            value: 1.78,
          },
          {
            xLocation: 1400,
            value: 1.91,
          },
          {
            xLocation: 1500,
            value: 1.97,
          },
          {
            xLocation: 1600,
            value: 2.09,
          },
          {
            xLocation: 1700,
            value: 2.23,
          },
          {
            xLocation: 1800,
            value: 2.22,
          },
          {
            xLocation: 1900,
            value: 2.38,
          },
          {
            xLocation: 2000,
            value: 2.4,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 310,
            value: 1.01,
            userId: 9,
          },
          {
            xLocation: 330,
            value: 1.02,
            userId: 2,
          },
          {
            xLocation: 370,
            value: 1.05,
            userId: 7,
          },
          {
            xLocation: 458,
            value: 1.11,
            userId: 2,
          },
          {
            xLocation: 551,
            value: 1.17,
            userId: 7,
          },
          {
            xLocation: 671,
            value: 1.3,
            userId: 9,
          },
          {
            xLocation: 730,
            value: 1.36,
            userId: 9,
          },
          {
            xLocation: 753,
            value: 1.37,
            userId: 4,
          },
          {
            xLocation: 784,
            value: 1.39,
            userId: 2,
          },
          {
            xLocation: 830,
            value: 1.4,
            userId: 6,
          },
          {
            xLocation: 892,
            value: 1.42,
            userId: 2,
          },
          {
            xLocation: 977,
            value: 1.53,
            userId: 5,
          },
          {
            xLocation: 983,
            value: 1.54,
            userId: 2,
          },
          {
            xLocation: 999,
            value: 1.56,
            userId: 4,
          },
          {
            xLocation: 1017,
            value: 1.6,
            userId: 3,
          },
          {
            xLocation: 1023,
            value: 1.6,
            userId: 8,
          },
          {
            xLocation: 1031,
            value: 1.62,
            userId: 5,
          },
          {
            xLocation: 1049,
            value: 1.65,
            userId: 8,
          },
          {
            xLocation: 1080,
            value: 1.7,
            userId: 6,
          },
          {
            xLocation: 1120,
            value: 1.73,
            userId: 5,
          },
          {
            xLocation: 1157,
            value: 1.73,
            userId: 1,
          },
          {
            xLocation: 1202,
            value: 1.72,
            userId: 5,
          },
          {
            xLocation: 1203,
            value: 1.72,
            userId: 3,
          },
          {
            xLocation: 1238,
            value: 1.74,
            userId: 9,
          },
          {
            xLocation: 1272,
            value: 1.77,
            userId: 2,
          },
          {
            xLocation: 1304,
            value: 1.79,
            userId: 6,
          },
          {
            xLocation: 1306,
            value: 1.79,
            userId: 7,
          },
          {
            xLocation: 1364,
            value: 1.87,
            userId: 1,
          },
          {
            xLocation: 1376,
            value: 1.88,
            userId: 4,
          },
          {
            xLocation: 1435,
            value: 1.93,
            userId: 9,
          },
          {
            xLocation: 1442,
            value: 1.94,
            userId: 4,
          },
          {
            xLocation: 1474,
            value: 1.95,
            userId: 5,
          },
          {
            xLocation: 1488,
            value: 1.96,
            userId: 1,
          },
          {
            xLocation: 1503,
            value: 1.97,
            userId: 1,
          },
          {
            xLocation: 1547,
            value: 2.03,
            userId: 8,
          },
          {
            xLocation: 1552,
            value: 2.03,
            userId: 7,
          },
          {
            xLocation: 1563,
            value: 2.04,
            userId: 3,
          },
          {
            xLocation: 1595,
            value: 2.08,
            userId: 7,
          },
          {
            xLocation: 1605,
            value: 2.1,
            userId: 6,
          },
          {
            xLocation: 1730,
            value: 2.23,
            userId: 5,
          },
          {
            xLocation: 1738,
            value: 2.23,
            userId: 1,
          },
          {
            xLocation: 1750,
            value: 2.23,
            userId: 8,
          },
          {
            xLocation: 1776,
            value: 2.22,
            userId: 6,
          },
          {
            xLocation: 1811,
            value: 2.24,
            userId: 3,
          },
          {
            xLocation: 1829,
            value: 2.27,
            userId: 9,
          },
          {
            xLocation: 1830,
            value: 2.27,
            userId: 3,
          },
          {
            xLocation: 1834,
            value: 2.28,
            userId: 1,
          },
          {
            xLocation: 1910,
            value: 2.38,
            userId: 6,
          },
          {
            xLocation: 1943,
            value: 2.39,
            userId: 4,
          },
          {
            xLocation: 1968,
            value: 2.39,
            userId: 3,
          },
          {
            xLocation: 2012,
            value: 2.41,
            userId: 8,
          },
          {
            xLocation: 2017,
            value: 2.41,
            userId: 8,
          },
          {
            xLocation: 2081,
            value: 2.48,
            userId: 7,
          },
          {
            xLocation: 2088,
            value: 2.48,
            userId: 4,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.62,
          },
          {
            xLocation: 400,
            value: 1.6,
          },
          {
            xLocation: 500,
            value: 1.69,
          },
          {
            xLocation: 600,
            value: 1.89,
          },
          {
            xLocation: 700,
            value: 1.98,
          },
          {
            xLocation: 800,
            value: 1.91,
          },
          {
            xLocation: 900,
            value: 2.04,
          },
          {
            xLocation: 1000,
            value: 2.17,
          },
          {
            xLocation: 1100,
            value: 2.19,
          },
          {
            xLocation: 1200,
            value: 2.33,
          },
          {
            xLocation: 1300,
            value: 2.35,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.59,
          },
          {
            xLocation: 1600,
            value: 2.77,
          },
          {
            xLocation: 1700,
            value: 2.64,
          },
          {
            xLocation: 1800,
            value: 2.84,
          },
          {
            xLocation: 1900,
            value: 2.86,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 302,
            value: 1.62,
            userId: 1,
          },
          {
            xLocation: 310,
            value: 1.62,
            userId: 2,
          },
          {
            xLocation: 400,
            value: 1.6,
            userId: 1,
          },
          {
            xLocation: 408,
            value: 1.6,
            userId: 9,
          },
          {
            xLocation: 409,
            value: 1.6,
            userId: 5,
          },
          {
            xLocation: 424,
            value: 1.62,
            userId: 8,
          },
          {
            xLocation: 500,
            value: 1.69,
            userId: 6,
          },
          {
            xLocation: 545,
            value: 1.78,
            userId: 8,
          },
          {
            xLocation: 577,
            value: 1.84,
            userId: 2,
          },
          {
            xLocation: 581,
            value: 1.86,
            userId: 2,
          },
          {
            xLocation: 597,
            value: 1.89,
            userId: 7,
          },
          {
            xLocation: 618,
            value: 1.91,
            userId: 9,
          },
          {
            xLocation: 657,
            value: 1.95,
            userId: 3,
          },
          {
            xLocation: 695,
            value: 1.98,
            userId: 8,
          },
          {
            xLocation: 698,
            value: 1.98,
            userId: 1,
          },
          {
            xLocation: 713,
            value: 1.97,
            userId: 1,
          },
          {
            xLocation: 716,
            value: 1.97,
            userId: 5,
          },
          {
            xLocation: 788,
            value: 1.91,
            userId: 2,
          },
          {
            xLocation: 814,
            value: 1.93,
            userId: 7,
          },
          {
            xLocation: 843,
            value: 1.97,
            userId: 8,
          },
          {
            xLocation: 852,
            value: 1.98,
            userId: 3,
          },
          {
            xLocation: 896,
            value: 2.04,
            userId: 4,
          },
          {
            xLocation: 974,
            value: 2.13,
            userId: 3,
          },
          {
            xLocation: 981,
            value: 2.15,
            userId: 7,
          },
          {
            xLocation: 1038,
            value: 2.17,
            userId: 9,
          },
          {
            xLocation: 1090,
            value: 2.19,
            userId: 5,
          },
          {
            xLocation: 1091,
            value: 2.19,
            userId: 8,
          },
          {
            xLocation: 1158,
            value: 2.28,
            userId: 8,
          },
          {
            xLocation: 1180,
            value: 2.3,
            userId: 4,
          },
          {
            xLocation: 1264,
            value: 2.35,
            userId: 5,
          },
          {
            xLocation: 1264,
            value: 2.35,
            userId: 5,
          },
          {
            xLocation: 1338,
            value: 2.39,
            userId: 9,
          },
          {
            xLocation: 1393,
            value: 2.44,
            userId: 4,
          },
          {
            xLocation: 1415,
            value: 2.46,
            userId: 3,
          },
          {
            xLocation: 1435,
            value: 2.5,
            userId: 7,
          },
          {
            xLocation: 1487,
            value: 2.57,
            userId: 2,
          },
          {
            xLocation: 1555,
            value: 2.7,
            userId: 3,
          },
          {
            xLocation: 1605,
            value: 2.77,
            userId: 4,
          },
          {
            xLocation: 1651,
            value: 2.7,
            userId: 7,
          },
          {
            xLocation: 1660,
            value: 2.7,
            userId: 6,
          },
          {
            xLocation: 1666,
            value: 2.68,
            userId: 6,
          },
          {
            xLocation: 1673,
            value: 2.68,
            userId: 6,
          },
          {
            xLocation: 1682,
            value: 2.66,
            userId: 9,
          },
          {
            xLocation: 1720,
            value: 2.68,
            userId: 6,
          },
          {
            xLocation: 1827,
            value: 2.84,
            userId: 3,
          },
          {
            xLocation: 1836,
            value: 2.84,
            userId: 4,
          },
          {
            xLocation: 1842,
            value: 2.84,
            userId: 4,
          },
          {
            xLocation: 1867,
            value: 2.86,
            userId: 5,
          },
          {
            xLocation: 1879,
            value: 2.86,
            userId: 9,
          },
          {
            xLocation: 1896,
            value: 2.86,
            userId: 2,
          },
          {
            xLocation: 2017,
            value: 3.01,
            userId: 1,
          },
          {
            xLocation: 2032,
            value: 3.03,
            userId: 1,
          },
          {
            xLocation: 2044,
            value: 3.05,
            userId: 6,
          },
          {
            xLocation: 2062,
            value: 3.06,
            userId: 7,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.2,
          },
          {
            xLocation: 400,
            value: 2.32,
          },
          {
            xLocation: 500,
            value: 2.27,
          },
          {
            xLocation: 600,
            value: 2.29,
          },
          {
            xLocation: 700,
            value: 2.55,
          },
          {
            xLocation: 800,
            value: 2.59,
          },
          {
            xLocation: 900,
            value: 2.48,
          },
          {
            xLocation: 1000,
            value: 2.78,
          },
          {
            xLocation: 1100,
            value: 2.71,
          },
          {
            xLocation: 1200,
            value: 2.66,
          },
          {
            xLocation: 1300,
            value: 2.85,
          },
          {
            xLocation: 1400,
            value: 3.15,
          },
          {
            xLocation: 1500,
            value: 3.05,
          },
          {
            xLocation: 1600,
            value: 3.08,
          },
          {
            xLocation: 1700,
            value: 3.22,
          },
          {
            xLocation: 1800,
            value: 3.38,
          },
          {
            xLocation: 1900,
            value: 3.28,
          },
          {
            xLocation: 2000,
            value: 3.42,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 301,
            value: 2.2,
            userId: 4,
          },
          {
            xLocation: 323,
            value: 2.22,
            userId: 6,
          },
          {
            xLocation: 370,
            value: 2.27,
            userId: 4,
          },
          {
            xLocation: 373,
            value: 2.29,
            userId: 7,
          },
          {
            xLocation: 458,
            value: 2.29,
            userId: 9,
          },
          {
            xLocation: 470,
            value: 2.29,
            userId: 8,
          },
          {
            xLocation: 477,
            value: 2.27,
            userId: 3,
          },
          {
            xLocation: 495,
            value: 2.27,
            userId: 6,
          },
          {
            xLocation: 503,
            value: 2.27,
            userId: 8,
          },
          {
            xLocation: 532,
            value: 2.27,
            userId: 1,
          },
          {
            xLocation: 544,
            value: 2.27,
            userId: 9,
          },
          {
            xLocation: 559,
            value: 2.29,
            userId: 5,
          },
          {
            xLocation: 694,
            value: 2.52,
            userId: 2,
          },
          {
            xLocation: 761,
            value: 2.57,
            userId: 9,
          },
          {
            xLocation: 790,
            value: 2.59,
            userId: 2,
          },
          {
            xLocation: 803,
            value: 2.59,
            userId: 2,
          },
          {
            xLocation: 810,
            value: 2.59,
            userId: 4,
          },
          {
            xLocation: 816,
            value: 2.57,
            userId: 4,
          },
          {
            xLocation: 819,
            value: 2.57,
            userId: 9,
          },
          {
            xLocation: 842,
            value: 2.55,
            userId: 1,
          },
          {
            xLocation: 896,
            value: 2.48,
            userId: 5,
          },
          {
            xLocation: 932,
            value: 2.57,
            userId: 2,
          },
          {
            xLocation: 941,
            value: 2.59,
            userId: 1,
          },
          {
            xLocation: 967,
            value: 2.68,
            userId: 9,
          },
          {
            xLocation: 981,
            value: 2.73,
            userId: 3,
          },
          {
            xLocation: 998,
            value: 2.78,
            userId: 6,
          },
          {
            xLocation: 1116,
            value: 2.71,
            userId: 6,
          },
          {
            xLocation: 1167,
            value: 2.68,
            userId: 8,
          },
          {
            xLocation: 1232,
            value: 2.73,
            userId: 1,
          },
          {
            xLocation: 1314,
            value: 2.89,
            userId: 5,
          },
          {
            xLocation: 1319,
            value: 2.89,
            userId: 6,
          },
          {
            xLocation: 1368,
            value: 3.05,
            userId: 7,
          },
          {
            xLocation: 1374,
            value: 3.08,
            userId: 7,
          },
          {
            xLocation: 1382,
            value: 3.1,
            userId: 8,
          },
          {
            xLocation: 1412,
            value: 3.15,
            userId: 5,
          },
          {
            xLocation: 1528,
            value: 3.05,
            userId: 9,
          },
          {
            xLocation: 1528,
            value: 3.05,
            userId: 5,
          },
          {
            xLocation: 1549,
            value: 3.05,
            userId: 7,
          },
          {
            xLocation: 1552,
            value: 3.08,
            userId: 7,
          },
          {
            xLocation: 1597,
            value: 3.08,
            userId: 3,
          },
          {
            xLocation: 1601,
            value: 3.08,
            userId: 3,
          },
          {
            xLocation: 1619,
            value: 3.1,
            userId: 1,
          },
          {
            xLocation: 1622,
            value: 3.1,
            userId: 3,
          },
          {
            xLocation: 1668,
            value: 3.17,
            userId: 8,
          },
          {
            xLocation: 1672,
            value: 3.17,
            userId: 8,
          },
          {
            xLocation: 1780,
            value: 3.35,
            userId: 7,
          },
          {
            xLocation: 1804,
            value: 3.38,
            userId: 2,
          },
          {
            xLocation: 1936,
            value: 3.33,
            userId: 4,
          },
          {
            xLocation: 1974,
            value: 3.38,
            userId: 3,
          },
          {
            xLocation: 1985,
            value: 3.4,
            userId: 4,
          },
          {
            xLocation: 2024,
            value: 3.49,
            userId: 5,
          },
          {
            xLocation: 2052,
            value: 3.56,
            userId: 1,
          },
          {
            xLocation: 2085,
            value: 3.65,
            userId: 2,
          },
          {
            xLocation: 2089,
            value: 3.68,
            userId: 6,
          },
        ],
      },
    ],
  },
  {
    name: 'Payment Processing',
    productCategoryId: '2',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.04,
          },
          {
            xLocation: 500,
            value: 1.2,
          },
          {
            xLocation: 600,
            value: 1.22,
          },
          {
            xLocation: 700,
            value: 1.4,
          },
          {
            xLocation: 800,
            value: 1.44,
          },
          {
            xLocation: 900,
            value: 1.39,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.7,
          },
          {
            xLocation: 1200,
            value: 1.7,
          },
          {
            xLocation: 1300,
            value: 1.8,
          },
          {
            xLocation: 1400,
            value: 1.87,
          },
          {
            xLocation: 1500,
            value: 1.97,
          },
          {
            xLocation: 1600,
            value: 2.13,
          },
          {
            xLocation: 1700,
            value: 2.23,
          },
          {
            xLocation: 1800,
            value: 2.27,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.46,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 362,
            value: 1.02,
            userId: 1,
          },
          {
            xLocation: 402,
            value: 1.04,
            userId: 2,
          },
          {
            xLocation: 417,
            value: 1.07,
            userId: 2,
          },
          {
            xLocation: 434,
            value: 1.09,
            userId: 9,
          },
          {
            xLocation: 440,
            value: 1.1,
            userId: 3,
          },
          {
            xLocation: 472,
            value: 1.15,
            userId: 4,
          },
          {
            xLocation: 491,
            value: 1.18,
            userId: 8,
          },
          {
            xLocation: 504,
            value: 1.2,
            userId: 4,
          },
          {
            xLocation: 504,
            value: 1.2,
            userId: 9,
          },
          {
            xLocation: 534,
            value: 1.21,
            userId: 9,
          },
          {
            xLocation: 551,
            value: 1.21,
            userId: 1,
          },
          {
            xLocation: 553,
            value: 1.21,
            userId: 2,
          },
          {
            xLocation: 585,
            value: 1.22,
            userId: 6,
          },
          {
            xLocation: 668,
            value: 1.34,
            userId: 5,
          },
          {
            xLocation: 705,
            value: 1.4,
            userId: 4,
          },
          {
            xLocation: 767,
            value: 1.42,
            userId: 9,
          },
          {
            xLocation: 905,
            value: 1.4,
            userId: 4,
          },
          {
            xLocation: 908,
            value: 1.41,
            userId: 3,
          },
          {
            xLocation: 1051,
            value: 1.64,
            userId: 8,
          },
          {
            xLocation: 1092,
            value: 1.7,
            userId: 2,
          },
          {
            xLocation: 1100,
            value: 1.7,
            userId: 7,
          },
          {
            xLocation: 1129,
            value: 1.7,
            userId: 5,
          },
          {
            xLocation: 1199,
            value: 1.7,
            userId: 8,
          },
          {
            xLocation: 1199,
            value: 1.7,
            userId: 6,
          },
          {
            xLocation: 1210,
            value: 1.7,
            userId: 4,
          },
          {
            xLocation: 1224,
            value: 1.72,
            userId: 6,
          },
          {
            xLocation: 1246,
            value: 1.74,
            userId: 3,
          },
          {
            xLocation: 1255,
            value: 1.76,
            userId: 8,
          },
          {
            xLocation: 1316,
            value: 1.81,
            userId: 9,
          },
          {
            xLocation: 1346,
            value: 1.83,
            userId: 7,
          },
          {
            xLocation: 1439,
            value: 1.91,
            userId: 3,
          },
          {
            xLocation: 1460,
            value: 1.93,
            userId: 5,
          },
          {
            xLocation: 1471,
            value: 1.94,
            userId: 5,
          },
          {
            xLocation: 1527,
            value: 2.01,
            userId: 7,
          },
          {
            xLocation: 1565,
            value: 2.07,
            userId: 6,
          },
          {
            xLocation: 1581,
            value: 2.09,
            userId: 6,
          },
          {
            xLocation: 1618,
            value: 2.15,
            userId: 7,
          },
          {
            xLocation: 1621,
            value: 2.15,
            userId: 1,
          },
          {
            xLocation: 1630,
            value: 2.16,
            userId: 5,
          },
          {
            xLocation: 1660,
            value: 2.19,
            userId: 2,
          },
          {
            xLocation: 1752,
            value: 2.26,
            userId: 8,
          },
          {
            xLocation: 1764,
            value: 2.26,
            userId: 8,
          },
          {
            xLocation: 1810,
            value: 2.28,
            userId: 3,
          },
          {
            xLocation: 1862,
            value: 2.3,
            userId: 7,
          },
          {
            xLocation: 1865,
            value: 2.31,
            userId: 2,
          },
          {
            xLocation: 1909,
            value: 2.34,
            userId: 9,
          },
          {
            xLocation: 1957,
            value: 2.4,
            userId: 3,
          },
          {
            xLocation: 1964,
            value: 2.42,
            userId: 7,
          },
          {
            xLocation: 1973,
            value: 2.42,
            userId: 4,
          },
          {
            xLocation: 1979,
            value: 2.43,
            userId: 5,
          },
          {
            xLocation: 2036,
            value: 2.48,
            userId: 1,
          },
          {
            xLocation: 2063,
            value: 2.48,
            userId: 6,
          },
          {
            xLocation: 2069,
            value: 2.48,
            userId: 1,
          },
          {
            xLocation: 2083,
            value: 2.49,
            userId: 1,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.66,
          },
          {
            xLocation: 500,
            value: 1.66,
          },
          {
            xLocation: 600,
            value: 1.76,
          },
          {
            xLocation: 700,
            value: 2.04,
          },
          {
            xLocation: 800,
            value: 1.94,
          },
          {
            xLocation: 900,
            value: 2.04,
          },
          {
            xLocation: 1000,
            value: 2.18,
          },
          {
            xLocation: 1100,
            value: 2.2,
          },
          {
            xLocation: 1200,
            value: 2.26,
          },
          {
            xLocation: 1300,
            value: 2.38,
          },
          {
            xLocation: 1400,
            value: 2.52,
          },
          {
            xLocation: 1500,
            value: 2.66,
          },
          {
            xLocation: 1600,
            value: 2.64,
          },
          {
            xLocation: 1700,
            value: 2.74,
          },
          {
            xLocation: 1800,
            value: 2.92,
          },
          {
            xLocation: 1900,
            value: 2.9,
          },
          {
            xLocation: 2000,
            value: 2.96,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 383,
            value: 1.64,
            userId: 3,
          },
          {
            xLocation: 398,
            value: 1.66,
            userId: 6,
          },
          {
            xLocation: 458,
            value: 1.66,
            userId: 2,
          },
          {
            xLocation: 470,
            value: 1.66,
            userId: 9,
          },
          {
            xLocation: 533,
            value: 1.7,
            userId: 8,
          },
          {
            xLocation: 539,
            value: 1.7,
            userId: 3,
          },
          {
            xLocation: 562,
            value: 1.72,
            userId: 1,
          },
          {
            xLocation: 581,
            value: 1.74,
            userId: 9,
          },
          {
            xLocation: 583,
            value: 1.74,
            userId: 9,
          },
          {
            xLocation: 619,
            value: 1.82,
            userId: 7,
          },
          {
            xLocation: 714,
            value: 2.02,
            userId: 7,
          },
          {
            xLocation: 725,
            value: 2.02,
            userId: 1,
          },
          {
            xLocation: 740,
            value: 2,
            userId: 4,
          },
          {
            xLocation: 741,
            value: 2,
            userId: 3,
          },
          {
            xLocation: 757,
            value: 1.98,
            userId: 7,
          },
          {
            xLocation: 759,
            value: 1.98,
            userId: 3,
          },
          {
            xLocation: 770,
            value: 1.96,
            userId: 5,
          },
          {
            xLocation: 781,
            value: 1.96,
            userId: 8,
          },
          {
            xLocation: 803,
            value: 1.94,
            userId: 8,
          },
          {
            xLocation: 955,
            value: 2.12,
            userId: 4,
          },
          {
            xLocation: 1051,
            value: 2.2,
            userId: 4,
          },
          {
            xLocation: 1054,
            value: 2.2,
            userId: 5,
          },
          {
            xLocation: 1092,
            value: 2.2,
            userId: 1,
          },
          {
            xLocation: 1096,
            value: 2.2,
            userId: 9,
          },
          {
            xLocation: 1109,
            value: 2.2,
            userId: 9,
          },
          {
            xLocation: 1114,
            value: 2.2,
            userId: 2,
          },
          {
            xLocation: 1146,
            value: 2.22,
            userId: 1,
          },
          {
            xLocation: 1344,
            value: 2.44,
            userId: 3,
          },
          {
            xLocation: 1345,
            value: 2.44,
            userId: 2,
          },
          {
            xLocation: 1353,
            value: 2.46,
            userId: 5,
          },
          {
            xLocation: 1400,
            value: 2.52,
            userId: 7,
          },
          {
            xLocation: 1433,
            value: 2.56,
            userId: 6,
          },
          {
            xLocation: 1440,
            value: 2.58,
            userId: 3,
          },
          {
            xLocation: 1456,
            value: 2.6,
            userId: 6,
          },
          {
            xLocation: 1459,
            value: 2.6,
            userId: 1,
          },
          {
            xLocation: 1461,
            value: 2.6,
            userId: 4,
          },
          {
            xLocation: 1506,
            value: 2.66,
            userId: 7,
          },
          {
            xLocation: 1533,
            value: 2.66,
            userId: 6,
          },
          {
            xLocation: 1553,
            value: 2.64,
            userId: 9,
          },
          {
            xLocation: 1575,
            value: 2.64,
            userId: 7,
          },
          {
            xLocation: 1687,
            value: 2.72,
            userId: 5,
          },
          {
            xLocation: 1709,
            value: 2.76,
            userId: 2,
          },
          {
            xLocation: 1753,
            value: 2.84,
            userId: 2,
          },
          {
            xLocation: 1787,
            value: 2.9,
            userId: 2,
          },
          {
            xLocation: 1821,
            value: 2.92,
            userId: 8,
          },
          {
            xLocation: 1879,
            value: 2.9,
            userId: 8,
          },
          {
            xLocation: 1900,
            value: 2.9,
            userId: 1,
          },
          {
            xLocation: 1918,
            value: 2.92,
            userId: 5,
          },
          {
            xLocation: 1965,
            value: 2.94,
            userId: 5,
          },
          {
            xLocation: 1988,
            value: 2.96,
            userId: 4,
          },
          {
            xLocation: 2027,
            value: 3,
            userId: 6,
          },
          {
            xLocation: 2050,
            value: 3.02,
            userId: 8,
          },
          {
            xLocation: 2062,
            value: 3.04,
            userId: 6,
          },
          {
            xLocation: 2080,
            value: 3.08,
            userId: 4,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.2,
          },
          {
            xLocation: 400,
            value: 2.42,
          },
          {
            xLocation: 500,
            value: 2.53,
          },
          {
            xLocation: 600,
            value: 2.33,
          },
          {
            xLocation: 700,
            value: 2.51,
          },
          {
            xLocation: 800,
            value: 2.69,
          },
          {
            xLocation: 900,
            value: 2.71,
          },
          {
            xLocation: 1000,
            value: 2.84,
          },
          {
            xLocation: 1100,
            value: 2.93,
          },
          {
            xLocation: 1200,
            value: 2.91,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 2.99,
          },
          {
            xLocation: 1500,
            value: 3.02,
          },
          {
            xLocation: 1600,
            value: 3.15,
          },
          {
            xLocation: 1700,
            value: 3.44,
          },
          {
            xLocation: 1800,
            value: 3.48,
          },
          {
            xLocation: 1900,
            value: 3.3,
          },
          {
            xLocation: 2000,
            value: 3.55,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 301,
            value: 2.2,
            userId: 8,
          },
          {
            xLocation: 378,
            value: 2.38,
            userId: 7,
          },
          {
            xLocation: 384,
            value: 2.38,
            userId: 9,
          },
          {
            xLocation: 447,
            value: 2.46,
            userId: 1,
          },
          {
            xLocation: 459,
            value: 2.49,
            userId: 6,
          },
          {
            xLocation: 469,
            value: 2.49,
            userId: 9,
          },
          {
            xLocation: 495,
            value: 2.53,
            userId: 2,
          },
          {
            xLocation: 644,
            value: 2.4,
            userId: 8,
          },
          {
            xLocation: 750,
            value: 2.6,
            userId: 5,
          },
          {
            xLocation: 781,
            value: 2.64,
            userId: 4,
          },
          {
            xLocation: 798,
            value: 2.69,
            userId: 4,
          },
          {
            xLocation: 820,
            value: 2.69,
            userId: 4,
          },
          {
            xLocation: 833,
            value: 2.69,
            userId: 3,
          },
          {
            xLocation: 836,
            value: 2.69,
            userId: 4,
          },
          {
            xLocation: 938,
            value: 2.75,
            userId: 5,
          },
          {
            xLocation: 956,
            value: 2.77,
            userId: 8,
          },
          {
            xLocation: 1009,
            value: 2.84,
            userId: 7,
          },
          {
            xLocation: 1037,
            value: 2.86,
            userId: 8,
          },
          {
            xLocation: 1044,
            value: 2.88,
            userId: 1,
          },
          {
            xLocation: 1075,
            value: 2.91,
            userId: 1,
          },
          {
            xLocation: 1085,
            value: 2.91,
            userId: 7,
          },
          {
            xLocation: 1270,
            value: 3.04,
            userId: 1,
          },
          {
            xLocation: 1312,
            value: 3.08,
            userId: 5,
          },
          {
            xLocation: 1328,
            value: 3.08,
            userId: 4,
          },
          {
            xLocation: 1331,
            value: 3.06,
            userId: 6,
          },
          {
            xLocation: 1361,
            value: 3.04,
            userId: 2,
          },
          {
            xLocation: 1370,
            value: 3.04,
            userId: 5,
          },
          {
            xLocation: 1416,
            value: 2.99,
            userId: 9,
          },
          {
            xLocation: 1436,
            value: 2.99,
            userId: 3,
          },
          {
            xLocation: 1459,
            value: 3.02,
            userId: 6,
          },
          {
            xLocation: 1481,
            value: 3.02,
            userId: 8,
          },
          {
            xLocation: 1492,
            value: 3.02,
            userId: 6,
          },
          {
            xLocation: 1496,
            value: 3.02,
            userId: 2,
          },
          {
            xLocation: 1523,
            value: 3.04,
            userId: 1,
          },
          {
            xLocation: 1532,
            value: 3.06,
            userId: 7,
          },
          {
            xLocation: 1546,
            value: 3.08,
            userId: 2,
          },
          {
            xLocation: 1582,
            value: 3.13,
            userId: 1,
          },
          {
            xLocation: 1594,
            value: 3.15,
            userId: 5,
          },
          {
            xLocation: 1649,
            value: 3.28,
            userId: 3,
          },
          {
            xLocation: 1652,
            value: 3.3,
            userId: 6,
          },
          {
            xLocation: 1652,
            value: 3.3,
            userId: 7,
          },
          {
            xLocation: 1658,
            value: 3.33,
            userId: 3,
          },
          {
            xLocation: 1680,
            value: 3.37,
            userId: 7,
          },
          {
            xLocation: 1717,
            value: 3.44,
            userId: 9,
          },
          {
            xLocation: 1742,
            value: 3.46,
            userId: 2,
          },
          {
            xLocation: 1810,
            value: 3.46,
            userId: 3,
          },
          {
            xLocation: 1814,
            value: 3.46,
            userId: 5,
          },
          {
            xLocation: 1919,
            value: 3.35,
            userId: 9,
          },
          {
            xLocation: 1932,
            value: 3.39,
            userId: 8,
          },
          {
            xLocation: 1969,
            value: 3.48,
            userId: 6,
          },
          {
            xLocation: 1972,
            value: 3.48,
            userId: 3,
          },
          {
            xLocation: 2004,
            value: 3.55,
            userId: 4,
          },
          {
            xLocation: 2007,
            value: 3.57,
            userId: 9,
          },
          {
            xLocation: 2022,
            value: 3.59,
            userId: 2,
          },
        ],
      },
    ],
  },
  {
    name: 'Transaction Monitoring',
    productCategoryId: '2',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 334,
            value: 1.02,
            userId: 7,
          },
          {
            xLocation: 362,
            value: 1.04,
            userId: 8,
          },
          {
            xLocation: 418,
            value: 1.09,
            userId: 8,
          },
          {
            xLocation: 422,
            value: 1.1,
            userId: 1,
          },
          {
            xLocation: 433,
            value: 1.11,
            userId: 1,
          },
          {
            xLocation: 472,
            value: 1.17,
            userId: 8,
          },
          {
            xLocation: 479,
            value: 1.18,
            userId: 5,
          },
          {
            xLocation: 486,
            value: 1.18,
            userId: 6,
          },
          {
            xLocation: 506,
            value: 1.21,
            userId: 3,
          },
          {
            xLocation: 587,
            value: 1.21,
            userId: 4,
          },
          {
            xLocation: 680,
            value: 1.32,
            userId: 2,
          },
          {
            xLocation: 723,
            value: 1.37,
            userId: 4,
          },
          {
            xLocation: 747,
            value: 1.38,
            userId: 6,
          },
          {
            xLocation: 799,
            value: 1.42,
            userId: 5,
          },
          {
            xLocation: 847,
            value: 1.41,
            userId: 7,
          },
          {
            xLocation: 892,
            value: 1.4,
            userId: 3,
          },
          {
            xLocation: 910,
            value: 1.42,
            userId: 9,
          },
          {
            xLocation: 920,
            value: 1.43,
            userId: 9,
          },
          {
            xLocation: 947,
            value: 1.48,
            userId: 7,
          },
          {
            xLocation: 970,
            value: 1.51,
            userId: 3,
          },
          {
            xLocation: 1034,
            value: 1.62,
            userId: 4,
          },
          {
            xLocation: 1053,
            value: 1.64,
            userId: 6,
          },
          {
            xLocation: 1055,
            value: 1.64,
            userId: 7,
          },
          {
            xLocation: 1074,
            value: 1.67,
            userId: 2,
          },
          {
            xLocation: 1077,
            value: 1.68,
            userId: 1,
          },
          {
            xLocation: 1081,
            value: 1.68,
            userId: 3,
          },
          {
            xLocation: 1099,
            value: 1.71,
            userId: 3,
          },
          {
            xLocation: 1125,
            value: 1.69,
            userId: 9,
          },
          {
            xLocation: 1231,
            value: 1.67,
            userId: 4,
          },
          {
            xLocation: 1249,
            value: 1.68,
            userId: 4,
          },
          {
            xLocation: 1293,
            value: 1.73,
            userId: 5,
          },
          {
            xLocation: 1374,
            value: 1.85,
            userId: 8,
          },
          {
            xLocation: 1475,
            value: 1.93,
            userId: 2,
          },
          {
            xLocation: 1510,
            value: 1.96,
            userId: 6,
          },
          {
            xLocation: 1518,
            value: 1.97,
            userId: 3,
          },
          {
            xLocation: 1519,
            value: 1.97,
            userId: 2,
          },
          {
            xLocation: 1591,
            value: 2.04,
            userId: 6,
          },
          {
            xLocation: 1659,
            value: 2.1,
            userId: 1,
          },
          {
            xLocation: 1666,
            value: 2.11,
            userId: 6,
          },
          {
            xLocation: 1671,
            value: 2.12,
            userId: 7,
          },
          {
            xLocation: 1721,
            value: 2.15,
            userId: 5,
          },
          {
            xLocation: 1723,
            value: 2.15,
            userId: 8,
          },
          {
            xLocation: 1731,
            value: 2.15,
            userId: 8,
          },
          {
            xLocation: 1784,
            value: 2.16,
            userId: 5,
          },
          {
            xLocation: 1784,
            value: 2.16,
            userId: 4,
          },
          {
            xLocation: 1841,
            value: 2.24,
            userId: 1,
          },
          {
            xLocation: 1845,
            value: 2.24,
            userId: 9,
          },
          {
            xLocation: 1854,
            value: 2.25,
            userId: 1,
          },
          {
            xLocation: 1886,
            value: 2.3,
            userId: 9,
          },
          {
            xLocation: 1908,
            value: 2.32,
            userId: 7,
          },
          {
            xLocation: 1945,
            value: 2.33,
            userId: 2,
          },
          {
            xLocation: 1951,
            value: 2.34,
            userId: 5,
          },
          {
            xLocation: 1966,
            value: 2.34,
            userId: 2,
          },
          {
            xLocation: 2059,
            value: 2.43,
            userId: 9,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 312,
            value: 1.62,
            userId: 6,
          },
          {
            xLocation: 330,
            value: 1.62,
            userId: 6,
          },
          {
            xLocation: 352,
            value: 1.65,
            userId: 5,
          },
          {
            xLocation: 383,
            value: 1.67,
            userId: 4,
          },
          {
            xLocation: 390,
            value: 1.67,
            userId: 8,
          },
          {
            xLocation: 427,
            value: 1.73,
            userId: 9,
          },
          {
            xLocation: 428,
            value: 1.73,
            userId: 1,
          },
          {
            xLocation: 470,
            value: 1.76,
            userId: 8,
          },
          {
            xLocation: 524,
            value: 1.84,
            userId: 8,
          },
          {
            xLocation: 618,
            value: 1.93,
            userId: 3,
          },
          {
            xLocation: 627,
            value: 1.93,
            userId: 7,
          },
          {
            xLocation: 641,
            value: 1.93,
            userId: 1,
          },
          {
            xLocation: 704,
            value: 1.89,
            userId: 2,
          },
          {
            xLocation: 706,
            value: 1.89,
            userId: 9,
          },
          {
            xLocation: 733,
            value: 1.87,
            userId: 8,
          },
          {
            xLocation: 750,
            value: 1.86,
            userId: 9,
          },
          {
            xLocation: 768,
            value: 1.84,
            userId: 3,
          },
          {
            xLocation: 806,
            value: 1.84,
            userId: 5,
          },
          {
            xLocation: 821,
            value: 1.86,
            userId: 7,
          },
          {
            xLocation: 823,
            value: 1.86,
            userId: 6,
          },
          {
            xLocation: 841,
            value: 1.87,
            userId: 9,
          },
          {
            xLocation: 994,
            value: 2.17,
            userId: 5,
          },
          {
            xLocation: 1041,
            value: 2.2,
            userId: 7,
          },
          {
            xLocation: 1090,
            value: 2.24,
            userId: 1,
          },
          {
            xLocation: 1091,
            value: 2.24,
            userId: 1,
          },
          {
            xLocation: 1091,
            value: 2.24,
            userId: 4,
          },
          {
            xLocation: 1096,
            value: 2.24,
            userId: 6,
          },
          {
            xLocation: 1099,
            value: 2.24,
            userId: 9,
          },
          {
            xLocation: 1114,
            value: 2.26,
            userId: 1,
          },
          {
            xLocation: 1147,
            value: 2.3,
            userId: 5,
          },
          {
            xLocation: 1151,
            value: 2.3,
            userId: 3,
          },
          {
            xLocation: 1154,
            value: 2.3,
            userId: 4,
          },
          {
            xLocation: 1230,
            value: 2.37,
            userId: 2,
          },
          {
            xLocation: 1233,
            value: 2.37,
            userId: 5,
          },
          {
            xLocation: 1296,
            value: 2.39,
            userId: 4,
          },
          {
            xLocation: 1332,
            value: 2.4,
            userId: 6,
          },
          {
            xLocation: 1445,
            value: 2.53,
            userId: 9,
          },
          {
            xLocation: 1445,
            value: 2.51,
            userId: 7,
          },
          {
            xLocation: 1519,
            value: 2.64,
            userId: 2,
          },
          {
            xLocation: 1526,
            value: 2.66,
            userId: 3,
          },
          {
            xLocation: 1554,
            value: 2.68,
            userId: 4,
          },
          {
            xLocation: 1593,
            value: 2.73,
            userId: 1,
          },
          {
            xLocation: 1615,
            value: 2.73,
            userId: 4,
          },
          {
            xLocation: 1630,
            value: 2.73,
            userId: 2,
          },
          {
            xLocation: 1634,
            value: 2.73,
            userId: 8,
          },
          {
            xLocation: 1697,
            value: 2.73,
            userId: 8,
          },
          {
            xLocation: 1749,
            value: 2.72,
            userId: 2,
          },
          {
            xLocation: 1760,
            value: 2.72,
            userId: 3,
          },
          {
            xLocation: 1807,
            value: 2.72,
            userId: 7,
          },
          {
            xLocation: 1813,
            value: 2.73,
            userId: 6,
          },
          {
            xLocation: 1882,
            value: 2.9,
            userId: 2,
          },
          {
            xLocation: 1897,
            value: 2.94,
            userId: 7,
          },
          {
            xLocation: 2000,
            value: 2.99,
            userId: 5,
          },
          {
            xLocation: 2046,
            value: 3.05,
            userId: 3,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 309,
            value: 2.25,
            userId: 8,
          },
          {
            xLocation: 345,
            value: 2.22,
            userId: 5,
          },
          {
            xLocation: 346,
            value: 2.22,
            userId: 7,
          },
          {
            xLocation: 414,
            value: 2.22,
            userId: 1,
          },
          {
            xLocation: 420,
            value: 2.25,
            userId: 3,
          },
          {
            xLocation: 431,
            value: 2.27,
            userId: 8,
          },
          {
            xLocation: 465,
            value: 2.37,
            userId: 2,
          },
          {
            xLocation: 536,
            value: 2.42,
            userId: 5,
          },
          {
            xLocation: 537,
            value: 2.42,
            userId: 7,
          },
          {
            xLocation: 620,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 628,
            value: 2.42,
            userId: 4,
          },
          {
            xLocation: 637,
            value: 2.42,
            userId: 5,
          },
          {
            xLocation: 641,
            value: 2.42,
            userId: 2,
          },
          {
            xLocation: 681,
            value: 2.44,
            userId: 4,
          },
          {
            xLocation: 681,
            value: 2.44,
            userId: 4,
          },
          {
            xLocation: 717,
            value: 2.49,
            userId: 7,
          },
          {
            xLocation: 732,
            value: 2.49,
            userId: 2,
          },
          {
            xLocation: 734,
            value: 2.49,
            userId: 8,
          },
          {
            xLocation: 737,
            value: 2.49,
            userId: 6,
          },
          {
            xLocation: 740,
            value: 2.51,
            userId: 2,
          },
          {
            xLocation: 802,
            value: 2.56,
            userId: 1,
          },
          {
            xLocation: 816,
            value: 2.59,
            userId: 6,
          },
          {
            xLocation: 856,
            value: 2.61,
            userId: 5,
          },
          {
            xLocation: 1050,
            value: 2.78,
            userId: 9,
          },
          {
            xLocation: 1082,
            value: 2.8,
            userId: 6,
          },
          {
            xLocation: 1099,
            value: 2.8,
            userId: 3,
          },
          {
            xLocation: 1177,
            value: 2.76,
            userId: 3,
          },
          {
            xLocation: 1184,
            value: 2.73,
            userId: 3,
          },
          {
            xLocation: 1186,
            value: 2.73,
            userId: 7,
          },
          {
            xLocation: 1219,
            value: 2.8,
            userId: 2,
          },
          {
            xLocation: 1229,
            value: 2.83,
            userId: 8,
          },
          {
            xLocation: 1258,
            value: 2.95,
            userId: 6,
          },
          {
            xLocation: 1293,
            value: 3.07,
            userId: 5,
          },
          {
            xLocation: 1349,
            value: 3.14,
            userId: 9,
          },
          {
            xLocation: 1385,
            value: 3.19,
            userId: 1,
          },
          {
            xLocation: 1412,
            value: 3.22,
            userId: 9,
          },
          {
            xLocation: 1481,
            value: 3.17,
            userId: 6,
          },
          {
            xLocation: 1563,
            value: 3.17,
            userId: 4,
          },
          {
            xLocation: 1602,
            value: 3.17,
            userId: 3,
          },
          {
            xLocation: 1610,
            value: 3.19,
            userId: 4,
          },
          {
            xLocation: 1634,
            value: 3.24,
            userId: 9,
          },
          {
            xLocation: 1699,
            value: 3.36,
            userId: 7,
          },
          {
            xLocation: 1723,
            value: 3.39,
            userId: 1,
          },
          {
            xLocation: 1771,
            value: 3.43,
            userId: 7,
          },
          {
            xLocation: 1780,
            value: 3.43,
            userId: 5,
          },
          {
            xLocation: 1832,
            value: 3.46,
            userId: 1,
          },
          {
            xLocation: 1840,
            value: 3.46,
            userId: 8,
          },
          {
            xLocation: 1952,
            value: 3.53,
            userId: 9,
          },
          {
            xLocation: 1962,
            value: 3.53,
            userId: 2,
          },
          {
            xLocation: 2001,
            value: 3.58,
            userId: 3,
          },
          {
            xLocation: 2016,
            value: 3.6,
            userId: 6,
          },
          {
            xLocation: 2033,
            value: 3.63,
            userId: 1,
          },
          {
            xLocation: 2050,
            value: 3.65,
            userId: 9,
          },
          {
            xLocation: 2072,
            value: 3.68,
            userId: 4,
          },
        ],
      },
    ],
  },
  {
    name: 'Custom AI Models (Basic)',
    productCategoryId: '3',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 315,
            value: 1.01,
            userId: 6,
          },
          {
            xLocation: 384,
            value: 1.06,
            userId: 4,
          },
          {
            xLocation: 396,
            value: 1.07,
            userId: 4,
          },
          {
            xLocation: 398,
            value: 1.07,
            userId: 8,
          },
          {
            xLocation: 479,
            value: 1.18,
            userId: 8,
          },
          {
            xLocation: 529,
            value: 1.21,
            userId: 6,
          },
          {
            xLocation: 545,
            value: 1.21,
            userId: 4,
          },
          {
            xLocation: 591,
            value: 1.21,
            userId: 5,
          },
          {
            xLocation: 611,
            value: 1.23,
            userId: 3,
          },
          {
            xLocation: 645,
            value: 1.28,
            userId: 9,
          },
          {
            xLocation: 677,
            value: 1.32,
            userId: 5,
          },
          {
            xLocation: 696,
            value: 1.35,
            userId: 2,
          },
          {
            xLocation: 696,
            value: 1.35,
            userId: 9,
          },
          {
            xLocation: 704,
            value: 1.35,
            userId: 4,
          },
          {
            xLocation: 772,
            value: 1.4,
            userId: 7,
          },
          {
            xLocation: 773,
            value: 1.4,
            userId: 7,
          },
          {
            xLocation: 812,
            value: 1.42,
            userId: 1,
          },
          {
            xLocation: 920,
            value: 1.43,
            userId: 6,
          },
          {
            xLocation: 970,
            value: 1.51,
            userId: 3,
          },
          {
            xLocation: 1019,
            value: 1.6,
            userId: 7,
          },
          {
            xLocation: 1026,
            value: 1.6,
            userId: 7,
          },
          {
            xLocation: 1057,
            value: 1.65,
            userId: 8,
          },
          {
            xLocation: 1112,
            value: 1.7,
            userId: 9,
          },
          {
            xLocation: 1134,
            value: 1.68,
            userId: 5,
          },
          {
            xLocation: 1183,
            value: 1.65,
            userId: 5,
          },
          {
            xLocation: 1193,
            value: 1.65,
            userId: 1,
          },
          {
            xLocation: 1199,
            value: 1.64,
            userId: 9,
          },
          {
            xLocation: 1234,
            value: 1.67,
            userId: 5,
          },
          {
            xLocation: 1259,
            value: 1.7,
            userId: 3,
          },
          {
            xLocation: 1260,
            value: 1.7,
            userId: 3,
          },
          {
            xLocation: 1390,
            value: 1.87,
            userId: 3,
          },
          {
            xLocation: 1496,
            value: 1.95,
            userId: 6,
          },
          {
            xLocation: 1505,
            value: 1.96,
            userId: 8,
          },
          {
            xLocation: 1544,
            value: 1.99,
            userId: 2,
          },
          {
            xLocation: 1590,
            value: 2.04,
            userId: 8,
          },
          {
            xLocation: 1607,
            value: 2.06,
            userId: 1,
          },
          {
            xLocation: 1624,
            value: 2.07,
            userId: 2,
          },
          {
            xLocation: 1671,
            value: 2.11,
            userId: 4,
          },
          {
            xLocation: 1672,
            value: 2.12,
            userId: 2,
          },
          {
            xLocation: 1691,
            value: 2.13,
            userId: 9,
          },
          {
            xLocation: 1691,
            value: 2.13,
            userId: 8,
          },
          {
            xLocation: 1700,
            value: 2.14,
            userId: 6,
          },
          {
            xLocation: 1722,
            value: 2.15,
            userId: 9,
          },
          {
            xLocation: 1744,
            value: 2.15,
            userId: 5,
          },
          {
            xLocation: 1767,
            value: 2.16,
            userId: 7,
          },
          {
            xLocation: 1801,
            value: 2.17,
            userId: 1,
          },
          {
            xLocation: 1810,
            value: 2.18,
            userId: 3,
          },
          {
            xLocation: 1826,
            value: 2.21,
            userId: 2,
          },
          {
            xLocation: 1837,
            value: 2.23,
            userId: 4,
          },
          {
            xLocation: 1854,
            value: 2.25,
            userId: 7,
          },
          {
            xLocation: 1856,
            value: 2.26,
            userId: 1,
          },
          {
            xLocation: 1892,
            value: 2.31,
            userId: 2,
          },
          {
            xLocation: 1993,
            value: 2.35,
            userId: 1,
          },
          {
            xLocation: 2029,
            value: 2.39,
            userId: 6,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 366,
            value: 1.65,
            userId: 9,
          },
          {
            xLocation: 428,
            value: 1.73,
            userId: 5,
          },
          {
            xLocation: 588,
            value: 1.93,
            userId: 9,
          },
          {
            xLocation: 628,
            value: 1.93,
            userId: 8,
          },
          {
            xLocation: 656,
            value: 1.91,
            userId: 1,
          },
          {
            xLocation: 726,
            value: 1.87,
            userId: 4,
          },
          {
            xLocation: 739,
            value: 1.86,
            userId: 4,
          },
          {
            xLocation: 741,
            value: 1.86,
            userId: 9,
          },
          {
            xLocation: 747,
            value: 1.86,
            userId: 9,
          },
          {
            xLocation: 756,
            value: 1.86,
            userId: 2,
          },
          {
            xLocation: 859,
            value: 1.91,
            userId: 7,
          },
          {
            xLocation: 869,
            value: 1.93,
            userId: 2,
          },
          {
            xLocation: 874,
            value: 1.93,
            userId: 4,
          },
          {
            xLocation: 934,
            value: 2.04,
            userId: 9,
          },
          {
            xLocation: 963,
            value: 2.11,
            userId: 2,
          },
          {
            xLocation: 973,
            value: 2.13,
            userId: 7,
          },
          {
            xLocation: 1021,
            value: 2.2,
            userId: 6,
          },
          {
            xLocation: 1043,
            value: 2.2,
            userId: 3,
          },
          {
            xLocation: 1049,
            value: 2.2,
            userId: 4,
          },
          {
            xLocation: 1060,
            value: 2.22,
            userId: 2,
          },
          {
            xLocation: 1068,
            value: 2.22,
            userId: 8,
          },
          {
            xLocation: 1112,
            value: 2.26,
            userId: 7,
          },
          {
            xLocation: 1153,
            value: 2.3,
            userId: 8,
          },
          {
            xLocation: 1231,
            value: 2.37,
            userId: 7,
          },
          {
            xLocation: 1272,
            value: 2.37,
            userId: 5,
          },
          {
            xLocation: 1303,
            value: 2.39,
            userId: 1,
          },
          {
            xLocation: 1356,
            value: 2.42,
            userId: 6,
          },
          {
            xLocation: 1356,
            value: 2.42,
            userId: 6,
          },
          {
            xLocation: 1370,
            value: 2.42,
            userId: 7,
          },
          {
            xLocation: 1371,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 1391,
            value: 2.44,
            userId: 3,
          },
          {
            xLocation: 1418,
            value: 2.48,
            userId: 3,
          },
          {
            xLocation: 1418,
            value: 2.48,
            userId: 5,
          },
          {
            xLocation: 1423,
            value: 2.48,
            userId: 3,
          },
          {
            xLocation: 1452,
            value: 2.53,
            userId: 1,
          },
          {
            xLocation: 1475,
            value: 2.59,
            userId: 6,
          },
          {
            xLocation: 1563,
            value: 2.7,
            userId: 3,
          },
          {
            xLocation: 1593,
            value: 2.73,
            userId: 8,
          },
          {
            xLocation: 1625,
            value: 2.73,
            userId: 3,
          },
          {
            xLocation: 1636,
            value: 2.73,
            userId: 9,
          },
          {
            xLocation: 1702,
            value: 2.73,
            userId: 5,
          },
          {
            xLocation: 1768,
            value: 2.72,
            userId: 8,
          },
          {
            xLocation: 1772,
            value: 2.72,
            userId: 2,
          },
          {
            xLocation: 1781,
            value: 2.7,
            userId: 5,
          },
          {
            xLocation: 1793,
            value: 2.7,
            userId: 5,
          },
          {
            xLocation: 1803,
            value: 2.7,
            userId: 1,
          },
          {
            xLocation: 1925,
            value: 2.95,
            userId: 2,
          },
          {
            xLocation: 1965,
            value: 2.97,
            userId: 4,
          },
          {
            xLocation: 1968,
            value: 2.97,
            userId: 1,
          },
          {
            xLocation: 1977,
            value: 2.97,
            userId: 6,
          },
          {
            xLocation: 1980,
            value: 2.97,
            userId: 7,
          },
          {
            xLocation: 2017,
            value: 3.01,
            userId: 6,
          },
          {
            xLocation: 2050,
            value: 3.05,
            userId: 1,
          },
          {
            xLocation: 2062,
            value: 3.06,
            userId: 4,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 308,
            value: 2.25,
            userId: 6,
          },
          {
            xLocation: 350,
            value: 2.22,
            userId: 4,
          },
          {
            xLocation: 374,
            value: 2.22,
            userId: 4,
          },
          {
            xLocation: 375,
            value: 2.22,
            userId: 6,
          },
          {
            xLocation: 384,
            value: 2.2,
            userId: 8,
          },
          {
            xLocation: 407,
            value: 2.22,
            userId: 2,
          },
          {
            xLocation: 410,
            value: 2.22,
            userId: 8,
          },
          {
            xLocation: 414,
            value: 2.22,
            userId: 5,
          },
          {
            xLocation: 418,
            value: 2.25,
            userId: 2,
          },
          {
            xLocation: 423,
            value: 2.25,
            userId: 8,
          },
          {
            xLocation: 431,
            value: 2.27,
            userId: 9,
          },
          {
            xLocation: 436,
            value: 2.3,
            userId: 1,
          },
          {
            xLocation: 439,
            value: 2.3,
            userId: 9,
          },
          {
            xLocation: 441,
            value: 2.3,
            userId: 6,
          },
          {
            xLocation: 449,
            value: 2.32,
            userId: 4,
          },
          {
            xLocation: 517,
            value: 2.44,
            userId: 5,
          },
          {
            xLocation: 522,
            value: 2.44,
            userId: 3,
          },
          {
            xLocation: 590,
            value: 2.39,
            userId: 7,
          },
          {
            xLocation: 600,
            value: 2.39,
            userId: 7,
          },
          {
            xLocation: 704,
            value: 2.47,
            userId: 4,
          },
          {
            xLocation: 707,
            value: 2.47,
            userId: 5,
          },
          {
            xLocation: 723,
            value: 2.49,
            userId: 8,
          },
          {
            xLocation: 759,
            value: 2.51,
            userId: 9,
          },
          {
            xLocation: 781,
            value: 2.54,
            userId: 1,
          },
          {
            xLocation: 829,
            value: 2.59,
            userId: 7,
          },
          {
            xLocation: 879,
            value: 2.64,
            userId: 6,
          },
          {
            xLocation: 885,
            value: 2.64,
            userId: 9,
          },
          {
            xLocation: 894,
            value: 2.66,
            userId: 3,
          },
          {
            xLocation: 913,
            value: 2.68,
            userId: 1,
          },
          {
            xLocation: 1077,
            value: 2.8,
            userId: 7,
          },
          {
            xLocation: 1122,
            value: 2.78,
            userId: 4,
          },
          {
            xLocation: 1123,
            value: 2.78,
            userId: 9,
          },
          {
            xLocation: 1128,
            value: 2.78,
            userId: 5,
          },
          {
            xLocation: 1170,
            value: 2.76,
            userId: 6,
          },
          {
            xLocation: 1173,
            value: 2.76,
            userId: 2,
          },
          {
            xLocation: 1202,
            value: 2.73,
            userId: 7,
          },
          {
            xLocation: 1236,
            value: 2.85,
            userId: 7,
          },
          {
            xLocation: 1237,
            value: 2.85,
            userId: 1,
          },
          {
            xLocation: 1245,
            value: 2.9,
            userId: 3,
          },
          {
            xLocation: 1248,
            value: 2.9,
            userId: 1,
          },
          {
            xLocation: 1268,
            value: 2.97,
            userId: 2,
          },
          {
            xLocation: 1359,
            value: 3.17,
            userId: 5,
          },
          {
            xLocation: 1417,
            value: 3.22,
            userId: 3,
          },
          {
            xLocation: 1486,
            value: 3.17,
            userId: 2,
          },
          {
            xLocation: 1515,
            value: 3.17,
            userId: 2,
          },
          {
            xLocation: 1603,
            value: 3.17,
            userId: 8,
          },
          {
            xLocation: 1665,
            value: 3.29,
            userId: 4,
          },
          {
            xLocation: 1803,
            value: 3.46,
            userId: 6,
          },
          {
            xLocation: 1822,
            value: 3.46,
            userId: 8,
          },
          {
            xLocation: 1870,
            value: 3.46,
            userId: 1,
          },
          {
            xLocation: 1924,
            value: 3.48,
            userId: 5,
          },
          {
            xLocation: 2028,
            value: 3.6,
            userId: 3,
          },
          {
            xLocation: 2053,
            value: 3.65,
            userId: 3,
          },
          {
            xLocation: 2091,
            value: 3.7,
            userId: 9,
          },
        ],
      },
    ],
  },
  {
    name: 'Custom AI Models (Advanced)',
    productCategoryId: '3',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 304,
            value: 1,
            userId: 7,
          },
          {
            xLocation: 354,
            value: 1.04,
            userId: 8,
          },
          {
            xLocation: 406,
            value: 1.07,
            userId: 7,
          },
          {
            xLocation: 428,
            value: 1.1,
            userId: 1,
          },
          {
            xLocation: 497,
            value: 1.2,
            userId: 7,
          },
          {
            xLocation: 719,
            value: 1.37,
            userId: 4,
          },
          {
            xLocation: 744,
            value: 1.38,
            userId: 8,
          },
          {
            xLocation: 746,
            value: 1.38,
            userId: 9,
          },
          {
            xLocation: 754,
            value: 1.39,
            userId: 1,
          },
          {
            xLocation: 772,
            value: 1.4,
            userId: 5,
          },
          {
            xLocation: 798,
            value: 1.42,
            userId: 3,
          },
          {
            xLocation: 937,
            value: 1.46,
            userId: 4,
          },
          {
            xLocation: 937,
            value: 1.46,
            userId: 1,
          },
          {
            xLocation: 941,
            value: 1.47,
            userId: 1,
          },
          {
            xLocation: 944,
            value: 1.48,
            userId: 3,
          },
          {
            xLocation: 946,
            value: 1.48,
            userId: 6,
          },
          {
            xLocation: 1026,
            value: 1.6,
            userId: 6,
          },
          {
            xLocation: 1042,
            value: 1.63,
            userId: 4,
          },
          {
            xLocation: 1090,
            value: 1.69,
            userId: 3,
          },
          {
            xLocation: 1098,
            value: 1.71,
            userId: 5,
          },
          {
            xLocation: 1121,
            value: 1.69,
            userId: 7,
          },
          {
            xLocation: 1123,
            value: 1.69,
            userId: 3,
          },
          {
            xLocation: 1140,
            value: 1.68,
            userId: 3,
          },
          {
            xLocation: 1143,
            value: 1.68,
            userId: 1,
          },
          {
            xLocation: 1148,
            value: 1.68,
            userId: 8,
          },
          {
            xLocation: 1156,
            value: 1.67,
            userId: 8,
          },
          {
            xLocation: 1172,
            value: 1.66,
            userId: 7,
          },
          {
            xLocation: 1191,
            value: 1.65,
            userId: 2,
          },
          {
            xLocation: 1214,
            value: 1.65,
            userId: 2,
          },
          {
            xLocation: 1218,
            value: 1.65,
            userId: 2,
          },
          {
            xLocation: 1348,
            value: 1.81,
            userId: 2,
          },
          {
            xLocation: 1354,
            value: 1.82,
            userId: 4,
          },
          {
            xLocation: 1363,
            value: 1.83,
            userId: 2,
          },
          {
            xLocation: 1399,
            value: 1.88,
            userId: 9,
          },
          {
            xLocation: 1418,
            value: 1.9,
            userId: 9,
          },
          {
            xLocation: 1419,
            value: 1.9,
            userId: 6,
          },
          {
            xLocation: 1449,
            value: 1.91,
            userId: 6,
          },
          {
            xLocation: 1538,
            value: 1.99,
            userId: 5,
          },
          {
            xLocation: 1539,
            value: 1.99,
            userId: 5,
          },
          {
            xLocation: 1565,
            value: 2.01,
            userId: 4,
          },
          {
            xLocation: 1598,
            value: 2.05,
            userId: 3,
          },
          {
            xLocation: 1616,
            value: 2.07,
            userId: 8,
          },
          {
            xLocation: 1638,
            value: 2.09,
            userId: 9,
          },
          {
            xLocation: 1643,
            value: 2.09,
            userId: 6,
          },
          {
            xLocation: 1668,
            value: 2.11,
            userId: 5,
          },
          {
            xLocation: 1684,
            value: 2.13,
            userId: 2,
          },
          {
            xLocation: 1833,
            value: 2.22,
            userId: 9,
          },
          {
            xLocation: 1871,
            value: 2.28,
            userId: 4,
          },
          {
            xLocation: 1905,
            value: 2.32,
            userId: 8,
          },
          {
            xLocation: 1934,
            value: 2.33,
            userId: 1,
          },
          {
            xLocation: 1941,
            value: 2.33,
            userId: 6,
          },
          {
            xLocation: 1944,
            value: 2.33,
            userId: 9,
          },
          {
            xLocation: 1989,
            value: 2.35,
            userId: 7,
          },
          {
            xLocation: 2045,
            value: 2.41,
            userId: 5,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 379,
            value: 1.67,
            userId: 5,
          },
          {
            xLocation: 458,
            value: 1.75,
            userId: 4,
          },
          {
            xLocation: 468,
            value: 1.76,
            userId: 9,
          },
          {
            xLocation: 484,
            value: 1.78,
            userId: 2,
          },
          {
            xLocation: 493,
            value: 1.8,
            userId: 9,
          },
          {
            xLocation: 499,
            value: 1.8,
            userId: 3,
          },
          {
            xLocation: 520,
            value: 1.84,
            userId: 7,
          },
          {
            xLocation: 533,
            value: 1.86,
            userId: 4,
          },
          {
            xLocation: 537,
            value: 1.86,
            userId: 1,
          },
          {
            xLocation: 542,
            value: 1.86,
            userId: 2,
          },
          {
            xLocation: 604,
            value: 1.95,
            userId: 3,
          },
          {
            xLocation: 707,
            value: 1.89,
            userId: 5,
          },
          {
            xLocation: 707,
            value: 1.89,
            userId: 1,
          },
          {
            xLocation: 798,
            value: 1.82,
            userId: 2,
          },
          {
            xLocation: 843,
            value: 1.87,
            userId: 8,
          },
          {
            xLocation: 861,
            value: 1.91,
            userId: 6,
          },
          {
            xLocation: 938,
            value: 2.06,
            userId: 2,
          },
          {
            xLocation: 954,
            value: 2.08,
            userId: 5,
          },
          {
            xLocation: 985,
            value: 2.15,
            userId: 6,
          },
          {
            xLocation: 1006,
            value: 2.19,
            userId: 3,
          },
          {
            xLocation: 1010,
            value: 2.19,
            userId: 9,
          },
          {
            xLocation: 1021,
            value: 2.2,
            userId: 3,
          },
          {
            xLocation: 1023,
            value: 2.2,
            userId: 7,
          },
          {
            xLocation: 1023,
            value: 2.2,
            userId: 5,
          },
          {
            xLocation: 1068,
            value: 2.22,
            userId: 9,
          },
          {
            xLocation: 1170,
            value: 2.31,
            userId: 8,
          },
          {
            xLocation: 1236,
            value: 2.37,
            userId: 7,
          },
          {
            xLocation: 1288,
            value: 2.39,
            userId: 8,
          },
          {
            xLocation: 1320,
            value: 2.4,
            userId: 3,
          },
          {
            xLocation: 1321,
            value: 2.4,
            userId: 8,
          },
          {
            xLocation: 1321,
            value: 2.4,
            userId: 9,
          },
          {
            xLocation: 1321,
            value: 2.4,
            userId: 7,
          },
          {
            xLocation: 1328,
            value: 2.4,
            userId: 4,
          },
          {
            xLocation: 1347,
            value: 2.4,
            userId: 5,
          },
          {
            xLocation: 1408,
            value: 2.46,
            userId: 8,
          },
          {
            xLocation: 1485,
            value: 2.59,
            userId: 4,
          },
          {
            xLocation: 1511,
            value: 2.64,
            userId: 7,
          },
          {
            xLocation: 1533,
            value: 2.66,
            userId: 6,
          },
          {
            xLocation: 1553,
            value: 2.68,
            userId: 6,
          },
          {
            xLocation: 1569,
            value: 2.7,
            userId: 1,
          },
          {
            xLocation: 1648,
            value: 2.73,
            userId: 4,
          },
          {
            xLocation: 1675,
            value: 2.73,
            userId: 1,
          },
          {
            xLocation: 1738,
            value: 2.72,
            userId: 6,
          },
          {
            xLocation: 1755,
            value: 2.72,
            userId: 7,
          },
          {
            xLocation: 1757,
            value: 2.72,
            userId: 6,
          },
          {
            xLocation: 1762,
            value: 2.72,
            userId: 2,
          },
          {
            xLocation: 1797,
            value: 2.7,
            userId: 3,
          },
          {
            xLocation: 1810,
            value: 2.72,
            userId: 4,
          },
          {
            xLocation: 1913,
            value: 2.94,
            userId: 1,
          },
          {
            xLocation: 1951,
            value: 2.97,
            userId: 1,
          },
          {
            xLocation: 1958,
            value: 2.97,
            userId: 9,
          },
          {
            xLocation: 2008,
            value: 2.99,
            userId: 8,
          },
          {
            xLocation: 2014,
            value: 3.01,
            userId: 2,
          },
          {
            xLocation: 2052,
            value: 3.05,
            userId: 5,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 301,
            value: 2.25,
            userId: 7,
          },
          {
            xLocation: 316,
            value: 2.25,
            userId: 4,
          },
          {
            xLocation: 330,
            value: 2.22,
            userId: 5,
          },
          {
            xLocation: 395,
            value: 2.2,
            userId: 2,
          },
          {
            xLocation: 419,
            value: 2.25,
            userId: 5,
          },
          {
            xLocation: 491,
            value: 2.42,
            userId: 3,
          },
          {
            xLocation: 563,
            value: 2.42,
            userId: 5,
          },
          {
            xLocation: 582,
            value: 2.39,
            userId: 7,
          },
          {
            xLocation: 588,
            value: 2.39,
            userId: 4,
          },
          {
            xLocation: 598,
            value: 2.39,
            userId: 1,
          },
          {
            xLocation: 678,
            value: 2.44,
            userId: 4,
          },
          {
            xLocation: 725,
            value: 2.49,
            userId: 1,
          },
          {
            xLocation: 739,
            value: 2.51,
            userId: 9,
          },
          {
            xLocation: 747,
            value: 2.51,
            userId: 1,
          },
          {
            xLocation: 754,
            value: 2.51,
            userId: 9,
          },
          {
            xLocation: 823,
            value: 2.59,
            userId: 5,
          },
          {
            xLocation: 848,
            value: 2.61,
            userId: 6,
          },
          {
            xLocation: 856,
            value: 2.61,
            userId: 4,
          },
          {
            xLocation: 939,
            value: 2.71,
            userId: 6,
          },
          {
            xLocation: 946,
            value: 2.71,
            userId: 8,
          },
          {
            xLocation: 998,
            value: 2.76,
            userId: 1,
          },
          {
            xLocation: 1002,
            value: 2.76,
            userId: 2,
          },
          {
            xLocation: 1006,
            value: 2.76,
            userId: 3,
          },
          {
            xLocation: 1016,
            value: 2.76,
            userId: 3,
          },
          {
            xLocation: 1035,
            value: 2.78,
            userId: 8,
          },
          {
            xLocation: 1151,
            value: 2.76,
            userId: 3,
          },
          {
            xLocation: 1167,
            value: 2.76,
            userId: 7,
          },
          {
            xLocation: 1174,
            value: 2.76,
            userId: 2,
          },
          {
            xLocation: 1214,
            value: 2.78,
            userId: 9,
          },
          {
            xLocation: 1308,
            value: 3.1,
            userId: 3,
          },
          {
            xLocation: 1326,
            value: 3.12,
            userId: 5,
          },
          {
            xLocation: 1338,
            value: 3.14,
            userId: 1,
          },
          {
            xLocation: 1342,
            value: 3.14,
            userId: 7,
          },
          {
            xLocation: 1362,
            value: 3.17,
            userId: 2,
          },
          {
            xLocation: 1384,
            value: 3.19,
            userId: 4,
          },
          {
            xLocation: 1406,
            value: 3.22,
            userId: 6,
          },
          {
            xLocation: 1420,
            value: 3.22,
            userId: 2,
          },
          {
            xLocation: 1458,
            value: 3.19,
            userId: 8,
          },
          {
            xLocation: 1480,
            value: 3.17,
            userId: 4,
          },
          {
            xLocation: 1481,
            value: 3.17,
            userId: 2,
          },
          {
            xLocation: 1523,
            value: 3.17,
            userId: 8,
          },
          {
            xLocation: 1531,
            value: 3.17,
            userId: 6,
          },
          {
            xLocation: 1538,
            value: 3.17,
            userId: 9,
          },
          {
            xLocation: 1603,
            value: 3.17,
            userId: 8,
          },
          {
            xLocation: 1611,
            value: 3.19,
            userId: 1,
          },
          {
            xLocation: 1623,
            value: 3.22,
            userId: 7,
          },
          {
            xLocation: 1631,
            value: 3.24,
            userId: 5,
          },
          {
            xLocation: 1634,
            value: 3.24,
            userId: 9,
          },
          {
            xLocation: 1669,
            value: 3.29,
            userId: 3,
          },
          {
            xLocation: 1706,
            value: 3.36,
            userId: 9,
          },
          {
            xLocation: 2039,
            value: 3.63,
            userId: 6,
          },
          {
            xLocation: 2055,
            value: 3.65,
            userId: 6,
          },
          {
            xLocation: 2080,
            value: 3.68,
            userId: 7,
          },
          {
            xLocation: 2087,
            value: 3.68,
            userId: 8,
          },
        ],
      },
    ],
  },
  {
    name: 'Developer Support (Basic)',
    productCategoryId: '4',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 315,
            value: 1.01,
            userId: 7,
          },
          {
            xLocation: 322,
            value: 1.01,
            userId: 1,
          },
          {
            xLocation: 331,
            value: 1.02,
            userId: 3,
          },
          {
            xLocation: 410,
            value: 1.08,
            userId: 7,
          },
          {
            xLocation: 472,
            value: 1.17,
            userId: 9,
          },
          {
            xLocation: 602,
            value: 1.21,
            userId: 1,
          },
          {
            xLocation: 650,
            value: 1.28,
            userId: 8,
          },
          {
            xLocation: 680,
            value: 1.32,
            userId: 1,
          },
          {
            xLocation: 686,
            value: 1.33,
            userId: 3,
          },
          {
            xLocation: 706,
            value: 1.36,
            userId: 2,
          },
          {
            xLocation: 779,
            value: 1.4,
            userId: 6,
          },
          {
            xLocation: 813,
            value: 1.42,
            userId: 5,
          },
          {
            xLocation: 821,
            value: 1.42,
            userId: 5,
          },
          {
            xLocation: 823,
            value: 1.42,
            userId: 3,
          },
          {
            xLocation: 836,
            value: 1.41,
            userId: 4,
          },
          {
            xLocation: 875,
            value: 1.41,
            userId: 8,
          },
          {
            xLocation: 888,
            value: 1.4,
            userId: 7,
          },
          {
            xLocation: 890,
            value: 1.4,
            userId: 1,
          },
          {
            xLocation: 895,
            value: 1.4,
            userId: 9,
          },
          {
            xLocation: 905,
            value: 1.41,
            userId: 6,
          },
          {
            xLocation: 961,
            value: 1.5,
            userId: 8,
          },
          {
            xLocation: 988,
            value: 1.54,
            userId: 6,
          },
          {
            xLocation: 1075,
            value: 1.67,
            userId: 1,
          },
          {
            xLocation: 1094,
            value: 1.7,
            userId: 7,
          },
          {
            xLocation: 1132,
            value: 1.68,
            userId: 6,
          },
          {
            xLocation: 1197,
            value: 1.64,
            userId: 2,
          },
          {
            xLocation: 1200,
            value: 1.64,
            userId: 4,
          },
          {
            xLocation: 1287,
            value: 1.72,
            userId: 9,
          },
          {
            xLocation: 1295,
            value: 1.73,
            userId: 3,
          },
          {
            xLocation: 1300,
            value: 1.74,
            userId: 2,
          },
          {
            xLocation: 1301,
            value: 1.74,
            userId: 5,
          },
          {
            xLocation: 1322,
            value: 1.76,
            userId: 3,
          },
          {
            xLocation: 1340,
            value: 1.79,
            userId: 8,
          },
          {
            xLocation: 1365,
            value: 1.83,
            userId: 7,
          },
          {
            xLocation: 1497,
            value: 1.95,
            userId: 4,
          },
          {
            xLocation: 1547,
            value: 2,
            userId: 6,
          },
          {
            xLocation: 1556,
            value: 2.01,
            userId: 4,
          },
          {
            xLocation: 1563,
            value: 2.01,
            userId: 9,
          },
          {
            xLocation: 1608,
            value: 2.06,
            userId: 6,
          },
          {
            xLocation: 1628,
            value: 2.07,
            userId: 8,
          },
          {
            xLocation: 1653,
            value: 2.1,
            userId: 4,
          },
          {
            xLocation: 1712,
            value: 2.14,
            userId: 2,
          },
          {
            xLocation: 1728,
            value: 2.15,
            userId: 7,
          },
          {
            xLocation: 1743,
            value: 2.15,
            userId: 3,
          },
          {
            xLocation: 1760,
            value: 2.15,
            userId: 1,
          },
          {
            xLocation: 1856,
            value: 2.26,
            userId: 4,
          },
          {
            xLocation: 1857,
            value: 2.26,
            userId: 9,
          },
          {
            xLocation: 1870,
            value: 2.28,
            userId: 8,
          },
          {
            xLocation: 1872,
            value: 2.28,
            userId: 2,
          },
          {
            xLocation: 1892,
            value: 2.31,
            userId: 5,
          },
          {
            xLocation: 1904,
            value: 2.32,
            userId: 2,
          },
          {
            xLocation: 1936,
            value: 2.33,
            userId: 9,
          },
          {
            xLocation: 1960,
            value: 2.34,
            userId: 5,
          },
          {
            xLocation: 2088,
            value: 2.49,
            userId: 5,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 357,
            value: 1.65,
            userId: 3,
          },
          {
            xLocation: 383,
            value: 1.67,
            userId: 2,
          },
          {
            xLocation: 399,
            value: 1.69,
            userId: 5,
          },
          {
            xLocation: 432,
            value: 1.73,
            userId: 8,
          },
          {
            xLocation: 482,
            value: 1.78,
            userId: 8,
          },
          {
            xLocation: 538,
            value: 1.86,
            userId: 2,
          },
          {
            xLocation: 599,
            value: 1.95,
            userId: 4,
          },
          {
            xLocation: 708,
            value: 1.89,
            userId: 2,
          },
          {
            xLocation: 748,
            value: 1.86,
            userId: 4,
          },
          {
            xLocation: 765,
            value: 1.84,
            userId: 1,
          },
          {
            xLocation: 818,
            value: 1.84,
            userId: 9,
          },
          {
            xLocation: 831,
            value: 1.86,
            userId: 7,
          },
          {
            xLocation: 861,
            value: 1.91,
            userId: 8,
          },
          {
            xLocation: 867,
            value: 1.91,
            userId: 1,
          },
          {
            xLocation: 880,
            value: 1.93,
            userId: 9,
          },
          {
            xLocation: 929,
            value: 2.04,
            userId: 5,
          },
          {
            xLocation: 941,
            value: 2.06,
            userId: 5,
          },
          {
            xLocation: 972,
            value: 2.13,
            userId: 6,
          },
          {
            xLocation: 1052,
            value: 2.22,
            userId: 4,
          },
          {
            xLocation: 1060,
            value: 2.22,
            userId: 3,
          },
          {
            xLocation: 1084,
            value: 2.24,
            userId: 7,
          },
          {
            xLocation: 1107,
            value: 2.24,
            userId: 4,
          },
          {
            xLocation: 1177,
            value: 2.33,
            userId: 6,
          },
          {
            xLocation: 1185,
            value: 2.33,
            userId: 1,
          },
          {
            xLocation: 1216,
            value: 2.35,
            userId: 5,
          },
          {
            xLocation: 1263,
            value: 2.37,
            userId: 7,
          },
          {
            xLocation: 1276,
            value: 2.39,
            userId: 8,
          },
          {
            xLocation: 1302,
            value: 2.39,
            userId: 2,
          },
          {
            xLocation: 1311,
            value: 2.39,
            userId: 6,
          },
          {
            xLocation: 1318,
            value: 2.4,
            userId: 7,
          },
          {
            xLocation: 1346,
            value: 2.4,
            userId: 1,
          },
          {
            xLocation: 1358,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 1366,
            value: 2.42,
            userId: 6,
          },
          {
            xLocation: 1371,
            value: 2.42,
            userId: 6,
          },
          {
            xLocation: 1384,
            value: 2.44,
            userId: 8,
          },
          {
            xLocation: 1385,
            value: 2.44,
            userId: 3,
          },
          {
            xLocation: 1404,
            value: 2.44,
            userId: 5,
          },
          {
            xLocation: 1447,
            value: 2.53,
            userId: 3,
          },
          {
            xLocation: 1468,
            value: 2.57,
            userId: 2,
          },
          {
            xLocation: 1660,
            value: 2.73,
            userId: 6,
          },
          {
            xLocation: 1674,
            value: 2.73,
            userId: 4,
          },
          {
            xLocation: 1687,
            value: 2.73,
            userId: 2,
          },
          {
            xLocation: 1737,
            value: 2.72,
            userId: 9,
          },
          {
            xLocation: 1754,
            value: 2.72,
            userId: 9,
          },
          {
            xLocation: 1806,
            value: 2.72,
            userId: 1,
          },
          {
            xLocation: 1814,
            value: 2.73,
            userId: 3,
          },
          {
            xLocation: 1826,
            value: 2.75,
            userId: 3,
          },
          {
            xLocation: 1849,
            value: 2.81,
            userId: 7,
          },
          {
            xLocation: 1881,
            value: 2.88,
            userId: 9,
          },
          {
            xLocation: 1895,
            value: 2.92,
            userId: 9,
          },
          {
            xLocation: 1959,
            value: 2.97,
            userId: 7,
          },
          {
            xLocation: 1966,
            value: 2.97,
            userId: 4,
          },
          {
            xLocation: 2015,
            value: 3.01,
            userId: 1,
          },
          {
            xLocation: 2064,
            value: 3.06,
            userId: 5,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 332,
            value: 2.22,
            userId: 7,
          },
          {
            xLocation: 338,
            value: 2.22,
            userId: 8,
          },
          {
            xLocation: 369,
            value: 2.22,
            userId: 3,
          },
          {
            xLocation: 380,
            value: 2.2,
            userId: 2,
          },
          {
            xLocation: 387,
            value: 2.2,
            userId: 5,
          },
          {
            xLocation: 586,
            value: 2.39,
            userId: 1,
          },
          {
            xLocation: 590,
            value: 2.39,
            userId: 4,
          },
          {
            xLocation: 686,
            value: 2.47,
            userId: 2,
          },
          {
            xLocation: 689,
            value: 2.47,
            userId: 5,
          },
          {
            xLocation: 744,
            value: 2.51,
            userId: 5,
          },
          {
            xLocation: 749,
            value: 2.51,
            userId: 9,
          },
          {
            xLocation: 756,
            value: 2.51,
            userId: 3,
          },
          {
            xLocation: 775,
            value: 2.54,
            userId: 1,
          },
          {
            xLocation: 818,
            value: 2.59,
            userId: 5,
          },
          {
            xLocation: 858,
            value: 2.61,
            userId: 4,
          },
          {
            xLocation: 871,
            value: 2.64,
            userId: 6,
          },
          {
            xLocation: 933,
            value: 2.68,
            userId: 3,
          },
          {
            xLocation: 967,
            value: 2.73,
            userId: 6,
          },
          {
            xLocation: 1003,
            value: 2.76,
            userId: 8,
          },
          {
            xLocation: 1089,
            value: 2.8,
            userId: 1,
          },
          {
            xLocation: 1098,
            value: 2.8,
            userId: 3,
          },
          {
            xLocation: 1110,
            value: 2.8,
            userId: 5,
          },
          {
            xLocation: 1130,
            value: 2.78,
            userId: 8,
          },
          {
            xLocation: 1236,
            value: 2.85,
            userId: 8,
          },
          {
            xLocation: 1237,
            value: 2.88,
            userId: 6,
          },
          {
            xLocation: 1258,
            value: 2.95,
            userId: 7,
          },
          {
            xLocation: 1296,
            value: 3.07,
            userId: 2,
          },
          {
            xLocation: 1303,
            value: 3.1,
            userId: 7,
          },
          {
            xLocation: 1305,
            value: 3.1,
            userId: 2,
          },
          {
            xLocation: 1334,
            value: 3.14,
            userId: 8,
          },
          {
            xLocation: 1362,
            value: 3.17,
            userId: 4,
          },
          {
            xLocation: 1387,
            value: 3.19,
            userId: 9,
          },
          {
            xLocation: 1392,
            value: 3.22,
            userId: 4,
          },
          {
            xLocation: 1554,
            value: 3.17,
            userId: 6,
          },
          {
            xLocation: 1563,
            value: 3.17,
            userId: 1,
          },
          {
            xLocation: 1639,
            value: 3.24,
            userId: 9,
          },
          {
            xLocation: 1695,
            value: 3.36,
            userId: 9,
          },
          {
            xLocation: 1700,
            value: 3.36,
            userId: 8,
          },
          {
            xLocation: 1705,
            value: 3.36,
            userId: 4,
          },
          {
            xLocation: 1731,
            value: 3.39,
            userId: 7,
          },
          {
            xLocation: 1735,
            value: 3.39,
            userId: 9,
          },
          {
            xLocation: 1743,
            value: 3.41,
            userId: 5,
          },
          {
            xLocation: 1748,
            value: 3.41,
            userId: 6,
          },
          {
            xLocation: 1783,
            value: 3.43,
            userId: 2,
          },
          {
            xLocation: 1796,
            value: 3.46,
            userId: 4,
          },
          {
            xLocation: 1828,
            value: 3.46,
            userId: 3,
          },
          {
            xLocation: 1842,
            value: 3.46,
            userId: 7,
          },
          {
            xLocation: 1857,
            value: 3.46,
            userId: 1,
          },
          {
            xLocation: 1865,
            value: 3.46,
            userId: 9,
          },
          {
            xLocation: 1939,
            value: 3.51,
            userId: 6,
          },
          {
            xLocation: 1949,
            value: 3.51,
            userId: 7,
          },
          {
            xLocation: 1971,
            value: 3.55,
            userId: 3,
          },
          {
            xLocation: 1979,
            value: 3.55,
            userId: 1,
          },
          {
            xLocation: 2049,
            value: 3.63,
            userId: 2,
          },
        ],
      },
    ],
  },
  {
    name: 'Developer Support (Advanced)',
    productCategoryId: '4',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 320,
            value: 1.01,
            userId: 9,
          },
          {
            xLocation: 326,
            value: 1.01,
            userId: 5,
          },
          {
            xLocation: 377,
            value: 1.05,
            userId: 4,
          },
          {
            xLocation: 399,
            value: 1.07,
            userId: 8,
          },
          {
            xLocation: 418,
            value: 1.09,
            userId: 4,
          },
          {
            xLocation: 435,
            value: 1.12,
            userId: 2,
          },
          {
            xLocation: 494,
            value: 1.2,
            userId: 6,
          },
          {
            xLocation: 562,
            value: 1.21,
            userId: 2,
          },
          {
            xLocation: 622,
            value: 1.24,
            userId: 6,
          },
          {
            xLocation: 632,
            value: 1.26,
            userId: 5,
          },
          {
            xLocation: 663,
            value: 1.3,
            userId: 4,
          },
          {
            xLocation: 693,
            value: 1.35,
            userId: 3,
          },
          {
            xLocation: 736,
            value: 1.38,
            userId: 3,
          },
          {
            xLocation: 826,
            value: 1.41,
            userId: 4,
          },
          {
            xLocation: 832,
            value: 1.41,
            userId: 9,
          },
          {
            xLocation: 862,
            value: 1.41,
            userId: 1,
          },
          {
            xLocation: 863,
            value: 1.41,
            userId: 6,
          },
          {
            xLocation: 871,
            value: 1.41,
            userId: 7,
          },
          {
            xLocation: 879,
            value: 1.4,
            userId: 2,
          },
          {
            xLocation: 895,
            value: 1.4,
            userId: 8,
          },
          {
            xLocation: 935,
            value: 1.46,
            userId: 1,
          },
          {
            xLocation: 969,
            value: 1.51,
            userId: 1,
          },
          {
            xLocation: 984,
            value: 1.54,
            userId: 9,
          },
          {
            xLocation: 1028,
            value: 1.6,
            userId: 7,
          },
          {
            xLocation: 1042,
            value: 1.63,
            userId: 3,
          },
          {
            xLocation: 1057,
            value: 1.65,
            userId: 3,
          },
          {
            xLocation: 1061,
            value: 1.65,
            userId: 5,
          },
          {
            xLocation: 1067,
            value: 1.66,
            userId: 3,
          },
          {
            xLocation: 1147,
            value: 1.68,
            userId: 3,
          },
          {
            xLocation: 1192,
            value: 1.65,
            userId: 4,
          },
          {
            xLocation: 1231,
            value: 1.67,
            userId: 8,
          },
          {
            xLocation: 1294,
            value: 1.73,
            userId: 7,
          },
          {
            xLocation: 1357,
            value: 1.82,
            userId: 4,
          },
          {
            xLocation: 1385,
            value: 1.86,
            userId: 5,
          },
          {
            xLocation: 1388,
            value: 1.87,
            userId: 8,
          },
          {
            xLocation: 1405,
            value: 1.88,
            userId: 7,
          },
          {
            xLocation: 1421,
            value: 1.9,
            userId: 9,
          },
          {
            xLocation: 1487,
            value: 1.94,
            userId: 6,
          },
          {
            xLocation: 1487,
            value: 1.94,
            userId: 6,
          },
          {
            xLocation: 1498,
            value: 1.95,
            userId: 8,
          },
          {
            xLocation: 1515,
            value: 1.96,
            userId: 1,
          },
          {
            xLocation: 1527,
            value: 1.98,
            userId: 7,
          },
          {
            xLocation: 1553,
            value: 2,
            userId: 7,
          },
          {
            xLocation: 1554,
            value: 2.01,
            userId: 1,
          },
          {
            xLocation: 1599,
            value: 2.05,
            userId: 2,
          },
          {
            xLocation: 1617,
            value: 2.07,
            userId: 5,
          },
          {
            xLocation: 1630,
            value: 2.08,
            userId: 5,
          },
          {
            xLocation: 1635,
            value: 2.08,
            userId: 1,
          },
          {
            xLocation: 1662,
            value: 2.1,
            userId: 2,
          },
          {
            xLocation: 1837,
            value: 2.23,
            userId: 8,
          },
          {
            xLocation: 1916,
            value: 2.32,
            userId: 2,
          },
          {
            xLocation: 2070,
            value: 2.46,
            userId: 9,
          },
          {
            xLocation: 2074,
            value: 2.46,
            userId: 6,
          },
          {
            xLocation: 2084,
            value: 2.48,
            userId: 9,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 392,
            value: 1.69,
            userId: 8,
          },
          {
            xLocation: 468,
            value: 1.76,
            userId: 7,
          },
          {
            xLocation: 484,
            value: 1.78,
            userId: 7,
          },
          {
            xLocation: 488,
            value: 1.78,
            userId: 2,
          },
          {
            xLocation: 560,
            value: 1.89,
            userId: 3,
          },
          {
            xLocation: 581,
            value: 1.91,
            userId: 3,
          },
          {
            xLocation: 582,
            value: 1.93,
            userId: 1,
          },
          {
            xLocation: 595,
            value: 1.95,
            userId: 3,
          },
          {
            xLocation: 612,
            value: 1.95,
            userId: 9,
          },
          {
            xLocation: 624,
            value: 1.93,
            userId: 5,
          },
          {
            xLocation: 665,
            value: 1.91,
            userId: 4,
          },
          {
            xLocation: 672,
            value: 1.91,
            userId: 1,
          },
          {
            xLocation: 679,
            value: 1.91,
            userId: 2,
          },
          {
            xLocation: 738,
            value: 1.86,
            userId: 1,
          },
          {
            xLocation: 754,
            value: 1.86,
            userId: 6,
          },
          {
            xLocation: 768,
            value: 1.84,
            userId: 9,
          },
          {
            xLocation: 913,
            value: 2,
            userId: 4,
          },
          {
            xLocation: 949,
            value: 2.08,
            userId: 9,
          },
          {
            xLocation: 966,
            value: 2.11,
            userId: 6,
          },
          {
            xLocation: 970,
            value: 2.11,
            userId: 7,
          },
          {
            xLocation: 987,
            value: 2.15,
            userId: 8,
          },
          {
            xLocation: 1002,
            value: 2.19,
            userId: 4,
          },
          {
            xLocation: 1163,
            value: 2.31,
            userId: 9,
          },
          {
            xLocation: 1167,
            value: 2.31,
            userId: 8,
          },
          {
            xLocation: 1204,
            value: 2.35,
            userId: 6,
          },
          {
            xLocation: 1253,
            value: 2.37,
            userId: 8,
          },
          {
            xLocation: 1255,
            value: 2.37,
            userId: 6,
          },
          {
            xLocation: 1259,
            value: 2.37,
            userId: 7,
          },
          {
            xLocation: 1260,
            value: 2.37,
            userId: 7,
          },
          {
            xLocation: 1266,
            value: 2.37,
            userId: 3,
          },
          {
            xLocation: 1308,
            value: 2.39,
            userId: 6,
          },
          {
            xLocation: 1329,
            value: 2.4,
            userId: 4,
          },
          {
            xLocation: 1401,
            value: 2.44,
            userId: 3,
          },
          {
            xLocation: 1438,
            value: 2.51,
            userId: 2,
          },
          {
            xLocation: 1484,
            value: 2.59,
            userId: 9,
          },
          {
            xLocation: 1505,
            value: 2.62,
            userId: 5,
          },
          {
            xLocation: 1524,
            value: 2.64,
            userId: 2,
          },
          {
            xLocation: 1527,
            value: 2.66,
            userId: 6,
          },
          {
            xLocation: 1610,
            value: 2.73,
            userId: 5,
          },
          {
            xLocation: 1618,
            value: 2.73,
            userId: 1,
          },
          {
            xLocation: 1699,
            value: 2.73,
            userId: 7,
          },
          {
            xLocation: 1741,
            value: 2.72,
            userId: 5,
          },
          {
            xLocation: 1770,
            value: 2.72,
            userId: 2,
          },
          {
            xLocation: 1780,
            value: 2.7,
            userId: 4,
          },
          {
            xLocation: 1803,
            value: 2.7,
            userId: 3,
          },
          {
            xLocation: 1951,
            value: 2.97,
            userId: 4,
          },
          {
            xLocation: 1996,
            value: 2.99,
            userId: 8,
          },
          {
            xLocation: 2042,
            value: 3.03,
            userId: 1,
          },
          {
            xLocation: 2048,
            value: 3.05,
            userId: 5,
          },
          {
            xLocation: 2073,
            value: 3.06,
            userId: 9,
          },
          {
            xLocation: 2074,
            value: 3.06,
            userId: 1,
          },
          {
            xLocation: 2075,
            value: 3.08,
            userId: 5,
          },
          {
            xLocation: 2076,
            value: 3.08,
            userId: 2,
          },
          {
            xLocation: 2091,
            value: 3.08,
            userId: 8,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 340,
            value: 2.22,
            userId: 7,
          },
          {
            xLocation: 378,
            value: 2.2,
            userId: 4,
          },
          {
            xLocation: 447,
            value: 2.32,
            userId: 6,
          },
          {
            xLocation: 450,
            value: 2.32,
            userId: 4,
          },
          {
            xLocation: 477,
            value: 2.39,
            userId: 2,
          },
          {
            xLocation: 511,
            value: 2.44,
            userId: 6,
          },
          {
            xLocation: 521,
            value: 2.44,
            userId: 7,
          },
          {
            xLocation: 590,
            value: 2.39,
            userId: 5,
          },
          {
            xLocation: 594,
            value: 2.39,
            userId: 9,
          },
          {
            xLocation: 595,
            value: 2.39,
            userId: 8,
          },
          {
            xLocation: 618,
            value: 2.42,
            userId: 2,
          },
          {
            xLocation: 632,
            value: 2.42,
            userId: 5,
          },
          {
            xLocation: 668,
            value: 2.44,
            userId: 9,
          },
          {
            xLocation: 705,
            value: 2.47,
            userId: 1,
          },
          {
            xLocation: 717,
            value: 2.49,
            userId: 2,
          },
          {
            xLocation: 735,
            value: 2.49,
            userId: 4,
          },
          {
            xLocation: 739,
            value: 2.51,
            userId: 3,
          },
          {
            xLocation: 770,
            value: 2.54,
            userId: 6,
          },
          {
            xLocation: 770,
            value: 2.54,
            userId: 6,
          },
          {
            xLocation: 836,
            value: 2.59,
            userId: 9,
          },
          {
            xLocation: 848,
            value: 2.61,
            userId: 6,
          },
          {
            xLocation: 854,
            value: 2.61,
            userId: 3,
          },
          {
            xLocation: 902,
            value: 2.66,
            userId: 8,
          },
          {
            xLocation: 1039,
            value: 2.78,
            userId: 4,
          },
          {
            xLocation: 1096,
            value: 2.8,
            userId: 5,
          },
          {
            xLocation: 1110,
            value: 2.8,
            userId: 8,
          },
          {
            xLocation: 1118,
            value: 2.78,
            userId: 8,
          },
          {
            xLocation: 1132,
            value: 2.78,
            userId: 5,
          },
          {
            xLocation: 1154,
            value: 2.76,
            userId: 1,
          },
          {
            xLocation: 1259,
            value: 2.95,
            userId: 3,
          },
          {
            xLocation: 1290,
            value: 3.05,
            userId: 7,
          },
          {
            xLocation: 1299,
            value: 3.1,
            userId: 9,
          },
          {
            xLocation: 1307,
            value: 3.1,
            userId: 7,
          },
          {
            xLocation: 1327,
            value: 3.12,
            userId: 1,
          },
          {
            xLocation: 1327,
            value: 3.12,
            userId: 1,
          },
          {
            xLocation: 1331,
            value: 3.14,
            userId: 2,
          },
          {
            xLocation: 1340,
            value: 3.14,
            userId: 2,
          },
          {
            xLocation: 1365,
            value: 3.17,
            userId: 8,
          },
          {
            xLocation: 1373,
            value: 3.19,
            userId: 2,
          },
          {
            xLocation: 1383,
            value: 3.19,
            userId: 1,
          },
          {
            xLocation: 1521,
            value: 3.17,
            userId: 1,
          },
          {
            xLocation: 1584,
            value: 3.17,
            userId: 3,
          },
          {
            xLocation: 1598,
            value: 3.17,
            userId: 6,
          },
          {
            xLocation: 1665,
            value: 3.29,
            userId: 9,
          },
          {
            xLocation: 1766,
            value: 3.43,
            userId: 4,
          },
          {
            xLocation: 1793,
            value: 3.46,
            userId: 5,
          },
          {
            xLocation: 1814,
            value: 3.46,
            userId: 4,
          },
          {
            xLocation: 1842,
            value: 3.46,
            userId: 3,
          },
          {
            xLocation: 1913,
            value: 3.48,
            userId: 7,
          },
          {
            xLocation: 2009,
            value: 3.58,
            userId: 9,
          },
          {
            xLocation: 2019,
            value: 3.6,
            userId: 8,
          },
          {
            xLocation: 2021,
            value: 3.6,
            userId: 7,
          },
          {
            xLocation: 2037,
            value: 3.63,
            userId: 3,
          },
          {
            xLocation: 2068,
            value: 3.65,
            userId: 5,
          },
        ],
      },
    ],
  },
  {
    name: 'Real-time Analytics (Basic)',
    productCategoryId: '5',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 385,
            value: 1.06,
            userId: 1,
          },
          {
            xLocation: 394,
            value: 1.06,
            userId: 7,
          },
          {
            xLocation: 415,
            value: 1.09,
            userId: 8,
          },
          {
            xLocation: 443,
            value: 1.13,
            userId: 5,
          },
          {
            xLocation: 512,
            value: 1.21,
            userId: 1,
          },
          {
            xLocation: 547,
            value: 1.21,
            userId: 7,
          },
          {
            xLocation: 566,
            value: 1.21,
            userId: 4,
          },
          {
            xLocation: 573,
            value: 1.21,
            userId: 6,
          },
          {
            xLocation: 731,
            value: 1.38,
            userId: 8,
          },
          {
            xLocation: 735,
            value: 1.38,
            userId: 9,
          },
          {
            xLocation: 747,
            value: 1.38,
            userId: 3,
          },
          {
            xLocation: 791,
            value: 1.41,
            userId: 3,
          },
          {
            xLocation: 853,
            value: 1.41,
            userId: 8,
          },
          {
            xLocation: 866,
            value: 1.41,
            userId: 2,
          },
          {
            xLocation: 871,
            value: 1.41,
            userId: 7,
          },
          {
            xLocation: 895,
            value: 1.4,
            userId: 4,
          },
          {
            xLocation: 911,
            value: 1.42,
            userId: 1,
          },
          {
            xLocation: 929,
            value: 1.45,
            userId: 4,
          },
          {
            xLocation: 1008,
            value: 1.57,
            userId: 5,
          },
          {
            xLocation: 1037,
            value: 1.62,
            userId: 8,
          },
          {
            xLocation: 1048,
            value: 1.63,
            userId: 1,
          },
          {
            xLocation: 1081,
            value: 1.68,
            userId: 9,
          },
          {
            xLocation: 1095,
            value: 1.7,
            userId: 5,
          },
          {
            xLocation: 1097,
            value: 1.7,
            userId: 6,
          },
          {
            xLocation: 1144,
            value: 1.68,
            userId: 6,
          },
          {
            xLocation: 1151,
            value: 1.67,
            userId: 4,
          },
          {
            xLocation: 1175,
            value: 1.65,
            userId: 7,
          },
          {
            xLocation: 1190,
            value: 1.65,
            userId: 3,
          },
          {
            xLocation: 1237,
            value: 1.68,
            userId: 7,
          },
          {
            xLocation: 1248,
            value: 1.68,
            userId: 4,
          },
          {
            xLocation: 1289,
            value: 1.73,
            userId: 4,
          },
          {
            xLocation: 1303,
            value: 1.74,
            userId: 6,
          },
          {
            xLocation: 1352,
            value: 1.81,
            userId: 6,
          },
          {
            xLocation: 1405,
            value: 1.88,
            userId: 9,
          },
          {
            xLocation: 1410,
            value: 1.89,
            userId: 5,
          },
          {
            xLocation: 1421,
            value: 1.9,
            userId: 1,
          },
          {
            xLocation: 1436,
            value: 1.9,
            userId: 9,
          },
          {
            xLocation: 1487,
            value: 1.94,
            userId: 6,
          },
          {
            xLocation: 1546,
            value: 1.99,
            userId: 2,
          },
          {
            xLocation: 1591,
            value: 2.04,
            userId: 3,
          },
          {
            xLocation: 1671,
            value: 2.12,
            userId: 3,
          },
          {
            xLocation: 1671,
            value: 2.12,
            userId: 9,
          },
          {
            xLocation: 1703,
            value: 2.14,
            userId: 9,
          },
          {
            xLocation: 1753,
            value: 2.15,
            userId: 2,
          },
          {
            xLocation: 1850,
            value: 2.25,
            userId: 5,
          },
          {
            xLocation: 1851,
            value: 2.25,
            userId: 3,
          },
          {
            xLocation: 1856,
            value: 2.26,
            userId: 8,
          },
          {
            xLocation: 1862,
            value: 2.26,
            userId: 2,
          },
          {
            xLocation: 1890,
            value: 2.31,
            userId: 7,
          },
          {
            xLocation: 1960,
            value: 2.34,
            userId: 8,
          },
          {
            xLocation: 1977,
            value: 2.34,
            userId: 2,
          },
          {
            xLocation: 1977,
            value: 2.34,
            userId: 1,
          },
          {
            xLocation: 2033,
            value: 2.4,
            userId: 2,
          },
          {
            xLocation: 2055,
            value: 2.43,
            userId: 5,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 331,
            value: 1.64,
            userId: 3,
          },
          {
            xLocation: 352,
            value: 1.65,
            userId: 6,
          },
          {
            xLocation: 368,
            value: 1.65,
            userId: 4,
          },
          {
            xLocation: 369,
            value: 1.65,
            userId: 3,
          },
          {
            xLocation: 385,
            value: 1.67,
            userId: 1,
          },
          {
            xLocation: 406,
            value: 1.69,
            userId: 8,
          },
          {
            xLocation: 407,
            value: 1.69,
            userId: 5,
          },
          {
            xLocation: 431,
            value: 1.73,
            userId: 4,
          },
          {
            xLocation: 475,
            value: 1.78,
            userId: 8,
          },
          {
            xLocation: 505,
            value: 1.8,
            userId: 9,
          },
          {
            xLocation: 537,
            value: 1.86,
            userId: 1,
          },
          {
            xLocation: 554,
            value: 1.87,
            userId: 7,
          },
          {
            xLocation: 577,
            value: 1.91,
            userId: 6,
          },
          {
            xLocation: 637,
            value: 1.93,
            userId: 2,
          },
          {
            xLocation: 810,
            value: 1.84,
            userId: 8,
          },
          {
            xLocation: 862,
            value: 1.91,
            userId: 8,
          },
          {
            xLocation: 899,
            value: 1.97,
            userId: 1,
          },
          {
            xLocation: 938,
            value: 2.06,
            userId: 9,
          },
          {
            xLocation: 949,
            value: 2.08,
            userId: 5,
          },
          {
            xLocation: 980,
            value: 2.15,
            userId: 5,
          },
          {
            xLocation: 1021,
            value: 2.2,
            userId: 4,
          },
          {
            xLocation: 1035,
            value: 2.2,
            userId: 2,
          },
          {
            xLocation: 1051,
            value: 2.22,
            userId: 9,
          },
          {
            xLocation: 1085,
            value: 2.24,
            userId: 2,
          },
          {
            xLocation: 1127,
            value: 2.28,
            userId: 2,
          },
          {
            xLocation: 1155,
            value: 2.3,
            userId: 7,
          },
          {
            xLocation: 1275,
            value: 2.39,
            userId: 5,
          },
          {
            xLocation: 1301,
            value: 2.39,
            userId: 3,
          },
          {
            xLocation: 1317,
            value: 2.4,
            userId: 8,
          },
          {
            xLocation: 1359,
            value: 2.42,
            userId: 6,
          },
          {
            xLocation: 1415,
            value: 2.48,
            userId: 9,
          },
          {
            xLocation: 1418,
            value: 2.48,
            userId: 8,
          },
          {
            xLocation: 1429,
            value: 2.5,
            userId: 9,
          },
          {
            xLocation: 1431,
            value: 2.5,
            userId: 3,
          },
          {
            xLocation: 1438,
            value: 2.51,
            userId: 7,
          },
          {
            xLocation: 1455,
            value: 2.53,
            userId: 7,
          },
          {
            xLocation: 1476,
            value: 2.59,
            userId: 4,
          },
          {
            xLocation: 1482,
            value: 2.59,
            userId: 9,
          },
          {
            xLocation: 1511,
            value: 2.64,
            userId: 6,
          },
          {
            xLocation: 1526,
            value: 2.66,
            userId: 7,
          },
          {
            xLocation: 1575,
            value: 2.7,
            userId: 1,
          },
          {
            xLocation: 1583,
            value: 2.72,
            userId: 3,
          },
          {
            xLocation: 1617,
            value: 2.73,
            userId: 6,
          },
          {
            xLocation: 1709,
            value: 2.73,
            userId: 5,
          },
          {
            xLocation: 1714,
            value: 2.73,
            userId: 2,
          },
          {
            xLocation: 1726,
            value: 2.72,
            userId: 3,
          },
          {
            xLocation: 1753,
            value: 2.72,
            userId: 1,
          },
          {
            xLocation: 1890,
            value: 2.92,
            userId: 7,
          },
          {
            xLocation: 1895,
            value: 2.92,
            userId: 6,
          },
          {
            xLocation: 1944,
            value: 2.95,
            userId: 5,
          },
          {
            xLocation: 1948,
            value: 2.95,
            userId: 4,
          },
          {
            xLocation: 1976,
            value: 2.97,
            userId: 4,
          },
          {
            xLocation: 1996,
            value: 2.99,
            userId: 1,
          },
          {
            xLocation: 2060,
            value: 3.06,
            userId: 2,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 306,
            value: 2.25,
            userId: 2,
          },
          {
            xLocation: 312,
            value: 2.25,
            userId: 3,
          },
          {
            xLocation: 350,
            value: 2.22,
            userId: 2,
          },
          {
            xLocation: 469,
            value: 2.37,
            userId: 4,
          },
          {
            xLocation: 477,
            value: 2.39,
            userId: 9,
          },
          {
            xLocation: 477,
            value: 2.39,
            userId: 1,
          },
          {
            xLocation: 495,
            value: 2.42,
            userId: 6,
          },
          {
            xLocation: 525,
            value: 2.42,
            userId: 4,
          },
          {
            xLocation: 562,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 605,
            value: 2.39,
            userId: 6,
          },
          {
            xLocation: 629,
            value: 2.42,
            userId: 8,
          },
          {
            xLocation: 660,
            value: 2.44,
            userId: 7,
          },
          {
            xLocation: 678,
            value: 2.44,
            userId: 1,
          },
          {
            xLocation: 821,
            value: 2.59,
            userId: 1,
          },
          {
            xLocation: 899,
            value: 2.66,
            userId: 4,
          },
          {
            xLocation: 926,
            value: 2.68,
            userId: 2,
          },
          {
            xLocation: 954,
            value: 2.71,
            userId: 3,
          },
          {
            xLocation: 979,
            value: 2.73,
            userId: 5,
          },
          {
            xLocation: 1126,
            value: 2.78,
            userId: 3,
          },
          {
            xLocation: 1131,
            value: 2.78,
            userId: 7,
          },
          {
            xLocation: 1135,
            value: 2.78,
            userId: 1,
          },
          {
            xLocation: 1142,
            value: 2.78,
            userId: 6,
          },
          {
            xLocation: 1164,
            value: 2.76,
            userId: 5,
          },
          {
            xLocation: 1223,
            value: 2.8,
            userId: 6,
          },
          {
            xLocation: 1246,
            value: 2.9,
            userId: 4,
          },
          {
            xLocation: 1263,
            value: 2.95,
            userId: 1,
          },
          {
            xLocation: 1271,
            value: 3,
            userId: 8,
          },
          {
            xLocation: 1298,
            value: 3.1,
            userId: 5,
          },
          {
            xLocation: 1304,
            value: 3.1,
            userId: 8,
          },
          {
            xLocation: 1314,
            value: 3.12,
            userId: 3,
          },
          {
            xLocation: 1331,
            value: 3.14,
            userId: 8,
          },
          {
            xLocation: 1380,
            value: 3.19,
            userId: 4,
          },
          {
            xLocation: 1405,
            value: 3.22,
            userId: 5,
          },
          {
            xLocation: 1435,
            value: 3.19,
            userId: 7,
          },
          {
            xLocation: 1527,
            value: 3.17,
            userId: 3,
          },
          {
            xLocation: 1545,
            value: 3.17,
            userId: 5,
          },
          {
            xLocation: 1625,
            value: 3.22,
            userId: 9,
          },
          {
            xLocation: 1630,
            value: 3.22,
            userId: 2,
          },
          {
            xLocation: 1637,
            value: 3.24,
            userId: 9,
          },
          {
            xLocation: 1640,
            value: 3.24,
            userId: 1,
          },
          {
            xLocation: 1664,
            value: 3.29,
            userId: 8,
          },
          {
            xLocation: 1674,
            value: 3.31,
            userId: 3,
          },
          {
            xLocation: 1705,
            value: 3.36,
            userId: 5,
          },
          {
            xLocation: 1793,
            value: 3.46,
            userId: 7,
          },
          {
            xLocation: 1819,
            value: 3.46,
            userId: 6,
          },
          {
            xLocation: 1836,
            value: 3.46,
            userId: 2,
          },
          {
            xLocation: 1847,
            value: 3.46,
            userId: 9,
          },
          {
            xLocation: 1858,
            value: 3.46,
            userId: 9,
          },
          {
            xLocation: 1859,
            value: 3.46,
            userId: 4,
          },
          {
            xLocation: 1903,
            value: 3.46,
            userId: 6,
          },
          {
            xLocation: 1925,
            value: 3.48,
            userId: 7,
          },
          {
            xLocation: 2003,
            value: 3.58,
            userId: 7,
          },
          {
            xLocation: 2089,
            value: 3.68,
            userId: 2,
          },
          {
            xLocation: 2097,
            value: 3.7,
            userId: 9,
          },
        ],
      },
    ],
  },
  {
    name: 'Real-time Analytics (Advanced)',
    productCategoryId: '5',
    lines: [
      {
        label: 'Suggested',
        color: '#0BA5EC',
        points: [
          {
            xLocation: 300,
            value: 1,
          },
          {
            xLocation: 400,
            value: 1.07,
          },
          {
            xLocation: 500,
            value: 1.21,
          },
          {
            xLocation: 600,
            value: 1.21,
          },
          {
            xLocation: 700,
            value: 1.35,
          },
          {
            xLocation: 800,
            value: 1.42,
          },
          {
            xLocation: 900,
            value: 1.4,
          },
          {
            xLocation: 1000,
            value: 1.57,
          },
          {
            xLocation: 1100,
            value: 1.71,
          },
          {
            xLocation: 1200,
            value: 1.64,
          },
          {
            xLocation: 1300,
            value: 1.74,
          },
          {
            xLocation: 1400,
            value: 1.88,
          },
          {
            xLocation: 1500,
            value: 1.95,
          },
          {
            xLocation: 1600,
            value: 2.05,
          },
          {
            xLocation: 1700,
            value: 2.14,
          },
          {
            xLocation: 1800,
            value: 2.17,
          },
          {
            xLocation: 1900,
            value: 2.32,
          },
          {
            xLocation: 2000,
            value: 2.35,
          },
          {
            xLocation: 2100,
            value: 2.5,
          },
        ],
        userPoints: [
          {
            xLocation: 328,
            value: 1.02,
            userId: 7,
          },
          {
            xLocation: 334,
            value: 1.02,
            userId: 2,
          },
          {
            xLocation: 356,
            value: 1.04,
            userId: 6,
          },
          {
            xLocation: 358,
            value: 1.04,
            userId: 5,
          },
          {
            xLocation: 536,
            value: 1.21,
            userId: 3,
          },
          {
            xLocation: 554,
            value: 1.21,
            userId: 5,
          },
          {
            xLocation: 565,
            value: 1.21,
            userId: 9,
          },
          {
            xLocation: 570,
            value: 1.21,
            userId: 1,
          },
          {
            xLocation: 666,
            value: 1.31,
            userId: 8,
          },
          {
            xLocation: 683,
            value: 1.33,
            userId: 2,
          },
          {
            xLocation: 684,
            value: 1.33,
            userId: 4,
          },
          {
            xLocation: 692,
            value: 1.34,
            userId: 8,
          },
          {
            xLocation: 705,
            value: 1.35,
            userId: 3,
          },
          {
            xLocation: 729,
            value: 1.38,
            userId: 8,
          },
          {
            xLocation: 755,
            value: 1.39,
            userId: 1,
          },
          {
            xLocation: 764,
            value: 1.4,
            userId: 3,
          },
          {
            xLocation: 776,
            value: 1.4,
            userId: 5,
          },
          {
            xLocation: 786,
            value: 1.41,
            userId: 5,
          },
          {
            xLocation: 791,
            value: 1.41,
            userId: 7,
          },
          {
            xLocation: 815,
            value: 1.42,
            userId: 7,
          },
          {
            xLocation: 830,
            value: 1.41,
            userId: 2,
          },
          {
            xLocation: 889,
            value: 1.4,
            userId: 9,
          },
          {
            xLocation: 913,
            value: 1.43,
            userId: 6,
          },
          {
            xLocation: 913,
            value: 1.43,
            userId: 4,
          },
          {
            xLocation: 934,
            value: 1.46,
            userId: 6,
          },
          {
            xLocation: 938,
            value: 1.46,
            userId: 9,
          },
          {
            xLocation: 990,
            value: 1.55,
            userId: 9,
          },
          {
            xLocation: 1057,
            value: 1.65,
            userId: 1,
          },
          {
            xLocation: 1099,
            value: 1.71,
            userId: 7,
          },
          {
            xLocation: 1116,
            value: 1.7,
            userId: 6,
          },
          {
            xLocation: 1151,
            value: 1.67,
            userId: 9,
          },
          {
            xLocation: 1197,
            value: 1.64,
            userId: 1,
          },
          {
            xLocation: 1309,
            value: 1.75,
            userId: 4,
          },
          {
            xLocation: 1366,
            value: 1.83,
            userId: 3,
          },
          {
            xLocation: 1368,
            value: 1.84,
            userId: 1,
          },
          {
            xLocation: 1402,
            value: 1.88,
            userId: 5,
          },
          {
            xLocation: 1424,
            value: 1.9,
            userId: 6,
          },
          {
            xLocation: 1465,
            value: 1.93,
            userId: 5,
          },
          {
            xLocation: 1475,
            value: 1.93,
            userId: 4,
          },
          {
            xLocation: 1508,
            value: 1.96,
            userId: 2,
          },
          {
            xLocation: 1547,
            value: 2,
            userId: 2,
          },
          {
            xLocation: 1643,
            value: 2.09,
            userId: 9,
          },
          {
            xLocation: 1663,
            value: 2.11,
            userId: 7,
          },
          {
            xLocation: 1704,
            value: 2.14,
            userId: 8,
          },
          {
            xLocation: 1736,
            value: 2.15,
            userId: 1,
          },
          {
            xLocation: 1770,
            value: 2.16,
            userId: 7,
          },
          {
            xLocation: 1790,
            value: 2.17,
            userId: 8,
          },
          {
            xLocation: 1869,
            value: 2.27,
            userId: 4,
          },
          {
            xLocation: 2000,
            value: 2.35,
            userId: 2,
          },
          {
            xLocation: 2022,
            value: 2.38,
            userId: 8,
          },
          {
            xLocation: 2041,
            value: 2.41,
            userId: 4,
          },
          {
            xLocation: 2051,
            value: 2.43,
            userId: 3,
          },
          {
            xLocation: 2061,
            value: 2.44,
            userId: 3,
          },
          {
            xLocation: 2076,
            value: 2.46,
            userId: 6,
          },
        ],
      },
      {
        label: 'Avg Quoted Price',
        color: '#16B364',
        points: [
          {
            xLocation: 300,
            value: 1.6,
          },
          {
            xLocation: 400,
            value: 1.69,
          },
          {
            xLocation: 500,
            value: 1.8,
          },
          {
            xLocation: 600,
            value: 1.95,
          },
          {
            xLocation: 700,
            value: 1.89,
          },
          {
            xLocation: 800,
            value: 1.82,
          },
          {
            xLocation: 900,
            value: 1.97,
          },
          {
            xLocation: 1000,
            value: 2.19,
          },
          {
            xLocation: 1100,
            value: 2.24,
          },
          {
            xLocation: 1200,
            value: 2.35,
          },
          {
            xLocation: 1300,
            value: 2.39,
          },
          {
            xLocation: 1400,
            value: 2.44,
          },
          {
            xLocation: 1500,
            value: 2.62,
          },
          {
            xLocation: 1600,
            value: 2.73,
          },
          {
            xLocation: 1700,
            value: 2.73,
          },
          {
            xLocation: 1800,
            value: 2.7,
          },
          {
            xLocation: 1900,
            value: 2.94,
          },
          {
            xLocation: 2000,
            value: 2.99,
          },
          {
            xLocation: 2100,
            value: 3.1,
          },
        ],
        userPoints: [
          {
            xLocation: 316,
            value: 1.62,
            userId: 7,
          },
          {
            xLocation: 323,
            value: 1.62,
            userId: 5,
          },
          {
            xLocation: 330,
            value: 1.64,
            userId: 7,
          },
          {
            xLocation: 336,
            value: 1.64,
            userId: 5,
          },
          {
            xLocation: 404,
            value: 1.69,
            userId: 6,
          },
          {
            xLocation: 430,
            value: 1.73,
            userId: 6,
          },
          {
            xLocation: 461,
            value: 1.76,
            userId: 5,
          },
          {
            xLocation: 524,
            value: 1.84,
            userId: 8,
          },
          {
            xLocation: 529,
            value: 1.84,
            userId: 6,
          },
          {
            xLocation: 567,
            value: 1.89,
            userId: 9,
          },
          {
            xLocation: 601,
            value: 1.95,
            userId: 3,
          },
          {
            xLocation: 606,
            value: 1.95,
            userId: 7,
          },
          {
            xLocation: 642,
            value: 1.93,
            userId: 8,
          },
          {
            xLocation: 666,
            value: 1.91,
            userId: 1,
          },
          {
            xLocation: 685,
            value: 1.89,
            userId: 9,
          },
          {
            xLocation: 692,
            value: 1.89,
            userId: 1,
          },
          {
            xLocation: 713,
            value: 1.87,
            userId: 1,
          },
          {
            xLocation: 724,
            value: 1.87,
            userId: 3,
          },
          {
            xLocation: 729,
            value: 1.87,
            userId: 2,
          },
          {
            xLocation: 805,
            value: 1.82,
            userId: 7,
          },
          {
            xLocation: 822,
            value: 1.86,
            userId: 4,
          },
          {
            xLocation: 836,
            value: 1.87,
            userId: 4,
          },
          {
            xLocation: 859,
            value: 1.91,
            userId: 4,
          },
          {
            xLocation: 962,
            value: 2.09,
            userId: 2,
          },
          {
            xLocation: 1013,
            value: 2.19,
            userId: 3,
          },
          {
            xLocation: 1014,
            value: 2.19,
            userId: 4,
          },
          {
            xLocation: 1032,
            value: 2.2,
            userId: 9,
          },
          {
            xLocation: 1117,
            value: 2.26,
            userId: 5,
          },
          {
            xLocation: 1118,
            value: 2.26,
            userId: 2,
          },
          {
            xLocation: 1119,
            value: 2.26,
            userId: 6,
          },
          {
            xLocation: 1162,
            value: 2.31,
            userId: 6,
          },
          {
            xLocation: 1264,
            value: 2.37,
            userId: 9,
          },
          {
            xLocation: 1377,
            value: 2.42,
            userId: 3,
          },
          {
            xLocation: 1400,
            value: 2.44,
            userId: 2,
          },
          {
            xLocation: 1424,
            value: 2.48,
            userId: 8,
          },
          {
            xLocation: 1476,
            value: 2.59,
            userId: 9,
          },
          {
            xLocation: 1584,
            value: 2.72,
            userId: 1,
          },
          {
            xLocation: 1609,
            value: 2.73,
            userId: 3,
          },
          {
            xLocation: 1621,
            value: 2.73,
            userId: 1,
          },
          {
            xLocation: 1625,
            value: 2.73,
            userId: 9,
          },
          {
            xLocation: 1775,
            value: 2.72,
            userId: 2,
          },
          {
            xLocation: 1775,
            value: 2.7,
            userId: 7,
          },
          {
            xLocation: 1826,
            value: 2.75,
            userId: 5,
          },
          {
            xLocation: 1855,
            value: 2.83,
            userId: 3,
          },
          {
            xLocation: 1928,
            value: 2.95,
            userId: 2,
          },
          {
            xLocation: 1950,
            value: 2.95,
            userId: 8,
          },
          {
            xLocation: 1977,
            value: 2.97,
            userId: 6,
          },
          {
            xLocation: 1982,
            value: 2.97,
            userId: 4,
          },
          {
            xLocation: 1991,
            value: 2.99,
            userId: 7,
          },
          {
            xLocation: 2002,
            value: 2.99,
            userId: 4,
          },
          {
            xLocation: 2016,
            value: 3.01,
            userId: 5,
          },
          {
            xLocation: 2026,
            value: 3.03,
            userId: 8,
          },
          {
            xLocation: 2077,
            value: 3.08,
            userId: 8,
          },
          {
            xLocation: 2085,
            value: 3.08,
            userId: 1,
          },
        ],
      },
      {
        label: 'L1',
        color: '#FF4405',
        points: [
          {
            xLocation: 300,
            value: 2.25,
          },
          {
            xLocation: 400,
            value: 2.2,
          },
          {
            xLocation: 500,
            value: 2.44,
          },
          {
            xLocation: 600,
            value: 2.39,
          },
          {
            xLocation: 700,
            value: 2.47,
          },
          {
            xLocation: 800,
            value: 2.56,
          },
          {
            xLocation: 900,
            value: 2.66,
          },
          {
            xLocation: 1000,
            value: 2.76,
          },
          {
            xLocation: 1100,
            value: 2.8,
          },
          {
            xLocation: 1200,
            value: 2.73,
          },
          {
            xLocation: 1300,
            value: 3.1,
          },
          {
            xLocation: 1400,
            value: 3.22,
          },
          {
            xLocation: 1500,
            value: 3.17,
          },
          {
            xLocation: 1600,
            value: 3.17,
          },
          {
            xLocation: 1700,
            value: 3.36,
          },
          {
            xLocation: 1800,
            value: 3.46,
          },
          {
            xLocation: 1900,
            value: 3.46,
          },
          {
            xLocation: 2000,
            value: 3.58,
          },
          {
            xLocation: 2100,
            value: 3.7,
          },
        ],
        userPoints: [
          {
            xLocation: 333,
            value: 2.22,
            userId: 9,
          },
          {
            xLocation: 337,
            value: 2.22,
            userId: 8,
          },
          {
            xLocation: 362,
            value: 2.22,
            userId: 5,
          },
          {
            xLocation: 370,
            value: 2.22,
            userId: 8,
          },
          {
            xLocation: 372,
            value: 2.22,
            userId: 8,
          },
          {
            xLocation: 394,
            value: 2.2,
            userId: 1,
          },
          {
            xLocation: 416,
            value: 2.25,
            userId: 5,
          },
          {
            xLocation: 448,
            value: 2.32,
            userId: 2,
          },
          {
            xLocation: 484,
            value: 2.39,
            userId: 3,
          },
          {
            xLocation: 525,
            value: 2.44,
            userId: 2,
          },
          {
            xLocation: 611,
            value: 2.39,
            userId: 2,
          },
          {
            xLocation: 637,
            value: 2.42,
            userId: 5,
          },
          {
            xLocation: 684,
            value: 2.47,
            userId: 1,
          },
          {
            xLocation: 684,
            value: 2.47,
            userId: 9,
          },
          {
            xLocation: 743,
            value: 2.51,
            userId: 3,
          },
          {
            xLocation: 749,
            value: 2.51,
            userId: 3,
          },
          {
            xLocation: 844,
            value: 2.61,
            userId: 7,
          },
          {
            xLocation: 852,
            value: 2.61,
            userId: 6,
          },
          {
            xLocation: 877,
            value: 2.64,
            userId: 7,
          },
          {
            xLocation: 882,
            value: 2.64,
            userId: 3,
          },
          {
            xLocation: 888,
            value: 2.66,
            userId: 2,
          },
          {
            xLocation: 906,
            value: 2.66,
            userId: 1,
          },
          {
            xLocation: 911,
            value: 2.66,
            userId: 4,
          },
          {
            xLocation: 933,
            value: 2.68,
            userId: 1,
          },
          {
            xLocation: 1146,
            value: 2.78,
            userId: 6,
          },
          {
            xLocation: 1150,
            value: 2.78,
            userId: 8,
          },
          {
            xLocation: 1221,
            value: 2.8,
            userId: 6,
          },
          {
            xLocation: 1285,
            value: 3.05,
            userId: 8,
          },
          {
            xLocation: 1301,
            value: 3.1,
            userId: 4,
          },
          {
            xLocation: 1302,
            value: 3.1,
            userId: 1,
          },
          {
            xLocation: 1358,
            value: 3.17,
            userId: 2,
          },
          {
            xLocation: 1392,
            value: 3.22,
            userId: 4,
          },
          {
            xLocation: 1438,
            value: 3.19,
            userId: 5,
          },
          {
            xLocation: 1459,
            value: 3.19,
            userId: 6,
          },
          {
            xLocation: 1494,
            value: 3.17,
            userId: 7,
          },
          {
            xLocation: 1511,
            value: 3.17,
            userId: 9,
          },
          {
            xLocation: 1565,
            value: 3.17,
            userId: 2,
          },
          {
            xLocation: 1574,
            value: 3.17,
            userId: 7,
          },
          {
            xLocation: 1586,
            value: 3.17,
            userId: 6,
          },
          {
            xLocation: 1607,
            value: 3.19,
            userId: 3,
          },
          {
            xLocation: 1712,
            value: 3.36,
            userId: 4,
          },
          {
            xLocation: 1718,
            value: 3.39,
            userId: 3,
          },
          {
            xLocation: 1764,
            value: 3.43,
            userId: 7,
          },
          {
            xLocation: 1829,
            value: 3.46,
            userId: 4,
          },
          {
            xLocation: 1864,
            value: 3.46,
            userId: 7,
          },
          {
            xLocation: 1869,
            value: 3.46,
            userId: 4,
          },
          {
            xLocation: 1875,
            value: 3.46,
            userId: 9,
          },
          {
            xLocation: 1882,
            value: 3.46,
            userId: 8,
          },
          {
            xLocation: 1930,
            value: 3.48,
            userId: 5,
          },
          {
            xLocation: 1942,
            value: 3.51,
            userId: 5,
          },
          {
            xLocation: 1985,
            value: 3.55,
            userId: 6,
          },
          {
            xLocation: 2005,
            value: 3.58,
            userId: 9,
          },
          {
            xLocation: 2011,
            value: 3.6,
            userId: 9,
          },
          {
            xLocation: 2021,
            value: 3.6,
            userId: 1,
          },
        ],
      },
    ],
  },
];

/**
 * This is the original data used to generate each of the above...
 */

// let targetLines = [
//   {
//     label: 'Suggested',
//     color: '#0BA5EC',
//     points: [
//       { xLocation: 300, value: 603 + Math.random() * 10 - 5 },
//       { xLocation: 400, value: 609 + Math.random() * 10 - 5 },
//       { xLocation: 500, value: 623 + Math.random() * 10 - 5 },
//       { xLocation: 600, value: 629 + Math.random() * 10 - 5 },
//       { xLocation: 700, value: 646 + Math.random() * 10 - 5 },
//       { xLocation: 800, value: 655 + Math.random() * 10 - 5 },
//       { xLocation: 900, value: 651 + Math.random() * 10 - 5 },
//       { xLocation: 1000, value: 674 + Math.random() * 10 - 5 },
//       { xLocation: 1100, value: 692 + Math.random() * 10 - 5 },
//       { xLocation: 1200, value: 690 + Math.random() * 10 - 5 },
//       { xLocation: 1300, value: 702 + Math.random() * 10 - 5 },
//       { xLocation: 1400, value: 718 + Math.random() * 10 - 5 },
//       { xLocation: 1500, value: 730 + Math.random() * 10 - 5 },
//       { xLocation: 1600, value: 742 + Math.random() * 10 - 5 },
//       { xLocation: 1700, value: 757 + Math.random() * 10 - 5 },
//       { xLocation: 1800, value: 761 + Math.random() * 10 - 5 },
//       { xLocation: 1900, value: 776 + Math.random() * 10 - 5 },
//       { xLocation: 2000, value: 785 + Math.random() * 10 - 5 },
//       { xLocation: 2100, value: 799 + Math.random() * 10 - 5 },
//     ],
//   },
//   {
//     label: 'Avg Quoted Price',
//     color: '#16B364',
//     points: [
//       { xLocation: 300, value: 400 + Math.random() * 10 - 5 },
//       { xLocation: 400, value: 405 + Math.random() * 10 - 5 },
//       { xLocation: 500, value: 410 + Math.random() * 10 - 5 },
//       { xLocation: 600, value: 415 + Math.random() * 10 - 5 },
//       { xLocation: 700, value: 420 + Math.random() * 10 - 5 },
//       { xLocation: 800, value: 418 + Math.random() * 10 - 5 },
//       { xLocation: 900, value: 425 + Math.random() * 10 - 5 },
//       { xLocation: 1000, value: 430 + Math.random() * 10 - 5 },
//       { xLocation: 1100, value: 435 + Math.random() * 10 - 5 },
//       { xLocation: 1200, value: 440 + Math.random() * 10 - 5 },
//       { xLocation: 1300, value: 445 + Math.random() * 10 - 5 },
//       { xLocation: 1400, value: 450 + Math.random() * 10 - 5 },
//       { xLocation: 1500, value: 455 + Math.random() * 10 - 5 },
//       { xLocation: 1600, value: 460 + Math.random() * 10 - 5 },
//       { xLocation: 1700, value: 458 + Math.random() * 10 - 5 },
//       { xLocation: 1800, value: 465 + Math.random() * 10 - 5 },
//       { xLocation: 1900, value: 470 + Math.random() * 10 - 5 },
//       { xLocation: 2000, value: 475 + Math.random() * 10 - 5 },
//       { xLocation: 2100, value: 480 + Math.random() * 10 - 5 },
//     ],
//   },
//   {
//     label: 'L1',
//     color: '#FF4405',
//     points: [
//       { xLocation: 300, value: 300 + Math.random() * 10 - 5 },
//       { xLocation: 400, value: 305 + Math.random() * 10 - 5 },
//       { xLocation: 500, value: 310 + Math.random() * 10 - 5 },
//       { xLocation: 600, value: 308 + Math.random() * 10 - 5 },
//       { xLocation: 700, value: 315 + Math.random() * 10 - 5 },
//       { xLocation: 800, value: 320 + Math.random() * 10 - 5 },
//       { xLocation: 900, value: 318 + Math.random() * 10 - 5 },
//       { xLocation: 1000, value: 325 + Math.random() * 10 - 5 },
//       { xLocation: 1100, value: 330 + Math.random() * 10 - 5 },
//       { xLocation: 1200, value: 328 + Math.random() * 10 - 5 },
//       { xLocation: 1300, value: 335 + Math.random() * 10 - 5 },
//       { xLocation: 1400, value: 340 + Math.random() * 10 - 5 },
//       { xLocation: 1500, value: 338 + Math.random() * 10 - 5 },
//       { xLocation: 1600, value: 345 + Math.random() * 10 - 5 },
//       { xLocation: 1700, value: 350 + Math.random() * 10 - 5 },
//       { xLocation: 1800, value: 355 + Math.random() * 10 - 5 },
//       { xLocation: 1900, value: 353 + Math.random() * 10 - 5 },
//       { xLocation: 2000, value: 360 + Math.random() * 10 - 5 },
//       { xLocation: 2100, value: 365 + Math.random() * 10 - 5 },
//     ],
//   },
// ];
