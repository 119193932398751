import { UserIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { User } from 'src/types';
import { classNames, Navigation, NavigationPage } from '../dashboard/App';
import dealopsLogo from '../marketing-site/assets/Logo_mobile.svg';
import OverflowMenu from './OverflowMenu';
import Tooltip from './Tooltip';

type SidebarDesktopProps = {
  tab: NavigationPage;
  navigation: Navigation;
  user?: User | null;
  onLogout?: () => void;
};

export default function SidebarDesktop(props: SidebarDesktopProps) {
  const { tab, navigation, user, onLogout } = props;

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-x-visible border-r border-gray-200 bg-white px-2">
        <div className="flex h-16 shrink-0 items-center">
          <img className="mx-auto h-8 w-auto" src={dealopsLogo} alt="Dealops" />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li className="flex-1">
              <ul className="space-y-1">
                {Object.keys(navigation).map((name) => {
                  const item = navigation[name];
                  if (item.hidden) return null;
                  return (
                    <Fragment key={item.name}>
                      <Tooltip
                        as="li"
                        key={item.name}
                        className="block"
                        location="RIGHT"
                        text={item.name}
                      >
                        <Link
                          to={item.to}
                          className={classNames(
                            tab.name === item.name
                              ? 'bg-gray-50 text-fuchsia-900'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-fuchsia-900',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          {item.icon ? (
                            <>
                              <item.icon
                                className={classNames(
                                  tab.name === item.name
                                    ? 'text-fuchsia-900'
                                    : 'text-gray-400 group-hover:text-fuchsia-900',
                                  'h-6 w-6 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                              <span className="sr-only">{item.name}</span>
                            </>
                          ) : (
                            <span>
                              {item.shortName ? item.shortName : item.name}
                            </span>
                          )}
                        </Link>
                      </Tooltip>
                    </Fragment>
                  );
                })}
              </ul>
            </li>

            {user && onLogout && (
              <OverflowMenu
                menuItems={[
                  {
                    name: 'Logout',
                    onClick: onLogout,
                  },
                ]}
                placement="right-start"
              >
                <div className="flex items-center p-2 my-4 rounded-md text-sm font-semibold text-gray-900 hover:bg-gray-50">
                  <UserIcon
                    className={classNames('text-gray-400', 'h-6 w-6 shrink-0')}
                    aria-hidden="true"
                  />
                  <span className="sr-only">Your profile</span>
                </div>
              </OverflowMenu>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}
