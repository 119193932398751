import { createContext, useContext } from 'react';
import { AnalyticsEvent } from 'src/analytics_event';

const AnalyticsContext = createContext<
  ((event: AnalyticsEvent) => void) | null
>(null);

export function AnalyticsContextProvider({
  children,
  externalReferrer,
  sessionId,
}: {
  children: React.ReactNode;
  externalReferrer: string | null;
  sessionId: string;
}) {
  async function createAnalyticsEvent(event: AnalyticsEvent) {
    const fullEventData = {
      external_referrer: externalReferrer,
      session_id: sessionId,
      ...event.eventData,
    };
    console.log(`AnalyticsEvent: `, fullEventData);
    navigator.sendBeacon(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/analytics_event`,
      new Blob(
        [
          JSON.stringify({
            name: event.name,
            eventData: fullEventData,
          }),
        ],
        { type: 'application/json' },
      ),
    );
  }
  return (
    <AnalyticsContext.Provider value={createAnalyticsEvent}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within a AnalyticsContextProvider',
    );
  }
  return context;
}
