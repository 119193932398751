import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import { PricingFlowType } from '../../types';
import { PenguinCustomStageType, PenguinPricingFlow } from '../penguin_types';

function icon(
  customSteps: PenguinCustomStageType[],
  currentStep: PenguinCustomStageType,
  stepToDisplay: PenguinCustomStageType,
) {
  const currentStepIndex = customSteps.indexOf(currentStep);
  const stepToDisplayIndex = customSteps.indexOf(stepToDisplay);
  if (currentStepIndex < stepToDisplayIndex) {
    return (
      <div
        className={
          'flex h-6 w-6 items-center justify-center rounded-full border border-gray-400 text-sm'
        }
      >
        {stepToDisplayIndex + 1}
      </div>
    );
  } else if (currentStepIndex === stepToDisplayIndex) {
    return (
      <div
        className={
          'flex h-6 w-6 items-center justify-center rounded-full border border-none border-gray-400 bg-fuchsia-50 text-sm text-fuchsia-900'
        }
      >
        {stepToDisplayIndex + 1}
      </div>
    );
  } else if (currentStepIndex > stepToDisplayIndex) {
    return (
      <CheckCircleIcon
        className="-m-1 h-7 w-7 text-green-500"
        aria-hidden="true"
      />
    );
  }
}

export default function FlowProgressBar(props: {
  stage: PenguinCustomStageType;
  setStage: (stage: PenguinCustomStageType) => void;
  customSteps: PenguinCustomStageType[] | null;
  align?: 'left' | 'center';
}) {
  const { stage, setStage, customSteps, align } = props;

  return (
    <div
      className={classNames(
        align === 'left'
          ? 'items-left justify-left pl-8 pr-4'
          : 'items-center justify-center px-4 ',
        'flex flex-row gap-3 border-b bg-white py-6 text-gray-600',
      )}
    >
      <CustomSteps
        customSteps={customSteps ?? []}
        setStage={setStage}
        currentStep={stage}
      />
    </div>
  );
}

function CustomSteps(props: {
  customSteps: PenguinCustomStageType[];
  setStage: (stage: PenguinCustomStageType) => void;
  currentStep: PenguinCustomStageType;
}) {
  const { customSteps, setStage, currentStep } = props;
  const { pricingFlow } = usePricingFlowContext<PenguinPricingFlow>();

  let stepsToRender = customSteps;

  // TODO: let's redo this, and have each step have its own rendering – this is too hacky
  // If there are no categories, show a tooltip on the Use Case Selection step
  const hasCategories =
    Object.keys(pricingFlow?.pricingSheetData?.categories ?? {}).length > 0;

  return (
    <div className="flex flex-row items-center justify-center gap-3 bg-white text-gray-600">
      {stepsToRender.map((step, index) => {
        const stepDisplayName =
          step === PenguinCustomStageType.MODEL_SELECTION &&
          pricingFlow.type === PricingFlowType.PENGUIN
            ? 'Use Case Selection'
            : step;
        return (
          <div
            key={index}
            className="flex flex-row items-center justify-center gap-3"
          >
            {index > 0 && (
              <ChevronRightIcon
                key={`chevron-${index}`}
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
            )}

            <Tooltip
              text={'No use cases available'}
              disableTooltip={
                hasCategories || step !== PenguinCustomStageType.MODEL_SELECTION
              }
              as="Fragment"
              location={'BOTTOM'}
            >
              <button
                key={step}
                className="flex flex-row items-center gap-2 hover:text-fuchsia-900"
                onClick={() => setStage(step)}
              >
                {icon(customSteps, currentStep, step)}
                {/* bold if step == stage */}
                {step == currentStep ? (
                  <span className="font-semibold">{stepDisplayName}</span>
                ) : (
                  <span>{stepDisplayName}</span>
                )}
              </button>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
