import { User } from '@stytch/vanilla-js';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import api from 'src/api';
import { Organization } from 'src/types';
import { formatTimestamp } from '../utils';
import { PricingFlowStage } from './PricingFlow/types';

interface InternalAnalyticsPageProps {
  user: User;
  organization: Organization;
}

export default function InternalAnalyticsPage(
  props: InternalAnalyticsPageProps,
) {
  // Only supports ampersand connections at the moment
  const salesforceUrl: string | undefined = _.find(
    props.organization.connections,
    (c) => c.type === 'AMPERSAND',
  )?.ampersandConnectionConfig?.instanceURL;
  return (
    <div className="flex flex-col gap-4 px-4 py-4">
      {/* Header */}
      <div className="flex flex-row justify-between">
        <div className="">
          <div className="text-xl">Analytics</div>
          <div className="text-sm">
            This dashboard only includes Dealops generated quotes
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 pt-4">
        {/* Main Content */}
        {props.organization.pricingFlowType === 'PENGUIN' && (
          <PenguinQuotesFromCPQ salesforceUrl={salesforceUrl} />
        )}
      </div>
    </div>
  );
}

type SalesforceQuote = {
  CreatedDate: string;
  Owner: {
    Name: string;
  };
  OwnerId: string;
  CreatedBy: {
    Name: string;
  };
  CreatedById: string;
  Name: string;
  SBQQ__Opportunity2__c: string;
  SBQQ__Opportunity2__r: {
    Name: string;
    attributes: {
      url: string;
    };
  };
  SBQQ__Primary__c: boolean;
  SBCF_MRR_NEW__c: number;
  SBQQ__Status__c: string;
  SBQQ__LineItemCount__c: number;
  Id: string;
  creationDurationMsV2?: number;
};

// #DealopsQuoteTableDataSync
interface DealopsQuoteTableData {
  createdAt: string;
  ownerRep: string;
  createdBy: string | undefined;
  status: string | undefined;
  hasLineItems: boolean | undefined;
  quoteId: string;
  oppName: string;
  primary: boolean | undefined;
  mrr: number | undefined;
  time: number;
  pricingFlowId: string;
  opportunityId: string;
}

function PenguinQuotesFromCPQ({ salesforceUrl }: { salesforceUrl?: string }) {
  return (
    <>
      <SalesforceQuotesTable
        salesforceUrl={salesforceUrl}
        tableName={'Quotes created in the CPQ'}
      />
      <DealopsQuotesTable
        salesforceUrl={salesforceUrl}
        tableName={'Quotes created in Dealops'}
      />
      <DealopsDraftsTable tableName="Draft Quotes created in Dealops" />
    </>
  );
}

function DealopsQuotesTable({
  tableName,
  salesforceUrl,
}: {
  tableName: string;
  salesforceUrl?: string;
}) {
  const [startDate, setStartDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );

  const [endDate, setEndDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [quotes, setQuotes] = useState<DealopsQuoteTableData[]>([]);

  useEffect(() => {
    if (isSubmitting) {
      const fetchSalesforceQuotes = async () => {
        const response = await api.post('analytics-prototype/dealops-quotes', {
          startDate,
          endDate,
        });
        setQuotes(response.data);
        setIsSubmitting(false);
      };
      fetchSalesforceQuotes();
    }
  }, [isSubmitting]);

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [sortedQuotes, setSortedQuotes] =
    useState<DealopsQuoteTableData[]>(quotes);
  useEffect(() => {
    setSortedQuotes(quotes);
  }, [quotes]);
  function copyTable() {
    if (tableRef.current !== null) {
      var table = tableRef.current;
      var range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      alert('quotes copied');
    }
  }
  return (
    <>
      <DatePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setIsSubmitting={setIsSubmitting}
      />
      <div className="flex flex-col gap-4 rounded-lg border px-4 pt-4">
        <button
          onClick={copyTable}
          className="text-md border-b border-gray-200 pb-2 text-sm font-semibold text-gray-600"
        >
          {tableName}
          <div className="text-xs italic text-gray-500">
            {quotes.some(
              (quote) =>
                quote.ownerRep === 'Unknown' &&
                quote.createdBy === 'Unknown' &&
                quote.status === 'Unknown',
            ) && (
              <p>
                Note: If data fields are mostly unknown, it's likely the user
                deleted this quote from Salesforce.
              </p>
            )}
          </div>
        </button>
        <div className="h-56 w-full overflow-y-auto rounded-xl bg-white">
          <table
            ref={tableRef}
            className="w-full border-separate border-spacing-0"
          >
            <thead>
              <tr>
                <th>
                  Created At (UTC)
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'createdAt');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  PricingFlow Id
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'pricingFlowId');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Opportunity Id
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'opportunityId');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Opportunity Name
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'oppName');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Quote Id
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'quoteId');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Owner
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'ownerRep');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Created by
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'createdBy');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Status
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'status');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Has quote line items
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(
                        quotes,
                        (q) => q.hasLineItems,
                      );
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Primary?
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'primary');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  MRR
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'mrr');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Creation Time (minutes)
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'time');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedQuotes &&
                sortedQuotes.map((quote) => {
                  const quoteLink =
                    salesforceUrl !== undefined
                      ? salesforceUrl +
                        `/lightning/r/SBQQ__Quote__c/${quote.quoteId}/view`
                      : null;
                  return (
                    <tr className="border border-gray-200">
                      <td>{formatTimestamp({ timestamp: quote.createdAt })}</td>
                      <td>{quote.pricingFlowId}</td>
                      <td>{quote.opportunityId}</td>
                      <td>{quote.oppName}</td>
                      <td>
                        {quoteLink ? (
                          <a href={quoteLink} target="_blank" rel="noreferrer">
                            {quote.quoteId}
                          </a>
                        ) : (
                          <>{quote.quoteId}</>
                        )}
                      </td>
                      <td>{quote.ownerRep}</td>
                      <td>{quote.createdBy ?? 'Unknown'}</td>
                      <td>{quote.status ?? 'Unknown'}</td>
                      <td>
                        <span
                          className={`rounded-full px-2 py-1 text-xs font-medium ${
                            quote.hasLineItems === undefined
                              ? 'text-gray-800 bg-gray-100'
                              : quote.hasLineItems
                                ? 'bg-green-100 text-green-800'
                                : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {quote.hasLineItems === undefined
                            ? 'Unknown'
                            : quote.hasLineItems
                              ? 'True'
                              : 'False'}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`rounded-full px-2 py-1 text-xs font-medium ${
                            quote.primary === undefined
                              ? 'text-gray-800 bg-gray-100'
                              : quote.primary
                                ? 'text-green-800 bg-green-100'
                                : 'text-gray-800 bg-gray-100'
                          }`}
                        >
                          {quote.primary === undefined
                            ? 'Unknown'
                            : quote.primary
                              ? 'Primary'
                              : 'Non-primary'}
                        </span>
                      </td>
                      <td className="text-right">{quote.mrr ?? 'Unknown'}</td>
                      <td className="text-right">
                        {`${(quote.time / 1000 / 60).toFixed(2)}`}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

// #DraftTableDataSync
interface DraftTableData {
  createdAt: string;
  user: string | null;
  status: PricingFlowStage;
  oppName: string | null;
  time: number;
  pricingFlowId: string;
  opportunityId: string;
}

function DealopsDraftsTable({ tableName }: { tableName: string }) {
  const [startDate, setStartDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );
  const [endDate, setEndDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [drafts, setDrafts] = useState<DraftTableData[]>([]);

  useEffect(() => {
    if (isSubmitting) {
      const fetchDealopsDrafts = async () => {
        const response = await api.post(
          'analytics-prototype/dealops-draft-quotes',
          {
            startDate,
            endDate,
          },
        );
        setDrafts(response.data);
        setIsSubmitting(false);
      };
      fetchDealopsDrafts();
    }
  }, [isSubmitting]);

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [sortedDrafts, setSortedDrafts] = useState<DraftTableData[]>(drafts);
  useEffect(() => {
    setSortedDrafts(drafts);
  }, [drafts]);
  function copyTable() {
    if (tableRef.current !== null) {
      var table = tableRef.current;
      var range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      alert('quotes copied');
    }
  }
  return (
    <>
      <DatePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setIsSubmitting={setIsSubmitting}
      />
      <div className="flex flex-col gap-4 rounded-lg border px-4 pt-4">
        <button
          onClick={copyTable}
          className="text-md border-b border-gray-200 pb-2 text-sm font-semibold text-gray-600"
        >
          {tableName}
        </button>
        <div className="h-56 w-full overflow-y-auto rounded-xl bg-white">
          <table
            ref={tableRef}
            className="w-full border-separate border-spacing-0"
          >
            <thead>
              <tr>
                <th>
                  Created At (UTC)
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedDrafts = _.sortBy(drafts, 'createdAt');
                      setSortedDrafts(sortedDrafts);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  PricingFlow Id
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedDrafts = _.sortBy(drafts, 'pricingFlowId');
                      setSortedDrafts(sortedDrafts);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Opportunity Id
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedDrafts = _.sortBy(drafts, 'opportunityId');
                      setSortedDrafts(sortedDrafts);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Opportunity Name
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(drafts, 'oppName');
                      setSortedDrafts(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Owner
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(drafts, 'user');
                      setSortedDrafts(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Status
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(drafts, 'status');
                      setSortedDrafts(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Creation Time (minutes)
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(drafts, 'time');
                      setSortedDrafts(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedDrafts &&
                sortedDrafts.map((draft) => {
                  return (
                    <tr className="border border-gray-200">
                      <td>{formatTimestamp({ timestamp: draft.createdAt })}</td>
                      <td>{draft.pricingFlowId}</td>
                      <td>{draft.opportunityId}</td>
                      <td>{draft.oppName ?? 'Opp Name Not Found'}</td>
                      <td>{draft.user ?? 'User Not Found'}</td>
                      <td>{draft.status.toString()}</td>
                      <td className="text-right">
                        {`${(draft.time / 1000 / 60).toFixed(1)}`}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const DatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setIsSubmitting,
}: {
  startDate: string;
  setStartDate: (d: string) => void;
  endDate: string;
  setEndDate: (d: string) => void;
  setIsSubmitting: (b: boolean) => void;
}) => (
  <div className="flex flex-row gap-4">
    <div className="flex flex-col gap-2">
      Start date
      <input
        type="date"
        className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
        id="startDate"
        value={startDate}
        onChange={(e) =>
          setStartDate(new Date(e.target.value).toISOString().slice(0, 10))
        }
      />
    </div>
    <div className="flex flex-col gap-2">
      End date
      <input
        type="date"
        className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
        id="endDate"
        value={endDate}
        onChange={(e) =>
          setEndDate(new Date(e.target.value).toISOString().slice(0, 10))
        }
      />
    </div>
    <div className="flex flex-col justify-end">
      <button
        onClick={() => setIsSubmitting(true)}
        className="bg-fuchsia-900 hover:bg-fuchsia-950 text-white font-bold py-2 px-4 rounded max-h-10"
      >
        Submit
      </button>
    </div>
  </div>
);

function SalesforceQuotesTable({
  tableName,
  salesforceUrl,
}: {
  tableName: string;
  salesforceUrl?: string;
}) {
  const [startDate, setStartDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );

  const [endDate, setEndDate] = useState<string>(
    new Date().toISOString().slice(0, 10),
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [quotes, setQuotes] = useState<SalesforceQuote[]>([]);

  useEffect(() => {
    if (isSubmitting) {
      const fetchSalesforceQuotes = async () => {
        const response = await api.post(
          'analytics-prototype/penguin-cpq-usage',
          {
            startDate,
            endDate,
          },
        );
        setQuotes(response.data);
        setIsSubmitting(false);
      };
      fetchSalesforceQuotes();
    }
  }, [isSubmitting]);

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [sortedQuotes, setSortedQuotes] = useState<SalesforceQuote[]>(quotes);
  useEffect(() => {
    setSortedQuotes(quotes);
  }, [quotes]);
  function copyTable() {
    if (tableRef.current !== null) {
      var table = tableRef.current;
      var range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      alert('quotes copied');
    }
  }
  return (
    <>
      <DatePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setIsSubmitting={setIsSubmitting}
      />
      <div className="flex flex-col gap-4 rounded-lg border px-4 pt-4">
        <button
          onClick={copyTable}
          className="text-md border-b border-gray-200 pb-2 text-sm font-semibold text-gray-600"
        >
          {tableName}
        </button>
        <div className="h-56 w-full overflow-y-auto rounded-xl bg-white">
          <table
            ref={tableRef}
            className="w-full border-separate border-spacing-0"
          >
            <thead>
              <tr>
                <th>
                  Created At (UTC)
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'CreatedDate');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Opportunity Id
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(
                        quotes,
                        'SBQQ__Opportunity2__c',
                      );
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Opportunity Name
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(
                        quotes,
                        'SBQQ__Opportunity2__r.Name',
                      );
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Quote ID
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'Name');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Owner
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'Owner.Name');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Created by
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'CreatedBy.Name');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Status
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'SBQQ__Status__c');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Has quote line items
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(
                        quotes,
                        (q) => q.SBQQ__LineItemCount__c === 0,
                      );
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Primary?
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'SBQQ__Primary__c');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  MRR
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(quotes, 'SBCF_MRR_NEW__c');
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
                <th>
                  Creation Time (minutes)
                  <button
                    className="text-xs font-semibold text-gray-500"
                    onClick={() => {
                      const sortedQuotes = _.sortBy(
                        quotes,
                        'creationDurationMsV2',
                      );
                      setSortedQuotes(sortedQuotes);
                    }}
                  >
                    ▲
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedQuotes &&
                sortedQuotes.map((quote) => {
                  const quoteLink =
                    salesforceUrl !== undefined
                      ? salesforceUrl +
                        `/lightning/r/SBQQ__Quote__c/${quote.Id}/view`
                      : null;
                  return (
                    <tr className="border border-gray-200">
                      <td>
                        {formatTimestamp({ timestamp: quote.CreatedDate })}
                      </td>
                      <td>{quote.SBQQ__Opportunity2__c}</td>
                      <td>{quote.SBQQ__Opportunity2__r.Name}</td>
                      <td>
                        {quoteLink ? (
                          <a href={quoteLink} target="_blank" rel="noreferrer">
                            {quote.Id}
                          </a>
                        ) : (
                          <>{quote.Id}</>
                        )}
                      </td>
                      <td>{quote.Owner?.Name}</td>
                      <td>{quote.CreatedBy?.Name}</td>
                      <td>{quote.SBQQ__Status__c}</td>
                      {/* Green badge for primary, gray for non-primary */}
                      <td>
                        <span
                          className={`rounded-full px-2 py-1 text-xs font-medium ${
                            quote.SBQQ__LineItemCount__c
                              ? 'bg-green-100 text-green-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {quote.SBQQ__LineItemCount__c === 0
                            ? 'False'
                            : 'True'}
                        </span>
                      </td>
                      {/* Green badge for primary, gray for non-primary */}
                      <td>
                        <span
                          className={`rounded-full px-2 py-1 text-xs font-medium ${
                            quote.SBQQ__Primary__c
                              ? 'bg-green-100 text-green-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {quote.SBQQ__Primary__c ? 'Primary' : 'Non-primary'}
                        </span>
                      </td>
                      <td className="text-right">
                        {quote.SBCF_MRR_NEW__c != null &&
                          quote.SBCF_MRR_NEW__c.toFixed(3)}
                      </td>
                      <td className="text-right">
                        {quote.creationDurationMsV2
                          ? `${(quote.creationDurationMsV2 / 1000 / 60).toFixed(2)}`
                          : 'N/A'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
