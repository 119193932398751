import React, { useEffect } from 'react';

type AlpacaWarningPopupProps = {
  message: string;
  show: boolean;
  duration?: number;
  className?: string;
  onHide: () => void;
};

const AlpacaWarningPopup: React.FC<AlpacaWarningPopupProps> = ({
  message,
  show,
  duration = 3000,
  onHide,
  className = '',
}) => {
  // todo(seb): between uses of this component, make each timer be independent of the others.
  // current behavior is the timeout makes them all disappear simultaneously
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [show, duration, onHide]);

  if (!show) {
    return null;
  }

  return <div className={className}>{message}</div>;
};

export default AlpacaWarningPopup;
