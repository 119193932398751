import { UserPlusIcon } from '@heroicons/react/24/outline';
import * as d3 from 'd3';
import { ReactNode, useEffect, useRef } from 'react';
import { useTooltip } from '../MouseTooltip';
import GraphContainer, { RenderChildWithSize } from './GraphContainer';
import { Legend } from './helpers';

export type Colors = {
  [key: string]: string;
};

type Bar = {
  value: number;
};

export type Group = {
  label: string;
  bars: {
    [key: string]: Bar;
  };
};

const MARGIN = { top: 10, right: 0, bottom: 20, left: 25 };
const Y_TICKS_RATIO = 40;

export function StackedColumnChart<GroupType extends Group>({
  height,
  width,
  yLimit,
  groups,
  colors,
  formatTooltip,
}: {
  height: number;
  width: number;
  yLimit: number;
  groups: GroupType[];
  colors: Colors;
  formatTooltip?: (arg0: GroupType['bars'][string]) => ReactNode;
}) {
  const { setTooltipText } = useTooltip();
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);
    // Clear existing chart
    svg.selectAll('*').remove();

    svg.attr('width', width).attr('height', height).append('g');

    // X axis
    const x = d3
      .scaleBand()
      .domain(groups.map((g) => g.label))
      .range([0, width - MARGIN.right - MARGIN.left])
      .padding(0.15);

    svg
      .append('g')
      .attr('transform', `translate(${MARGIN.left}, ${height - MARGIN.bottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(2))
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove());

    // Y axi
    const y = d3
      .scaleLinear()
      .domain([0, yLimit])
      .range([height - MARGIN.top - MARGIN.bottom, 0]);
    svg
      .append('g')
      .attr('transform', `translate(${MARGIN.left}, ${MARGIN.top})`)
      .call(d3.axisLeft(y).ticks(height / Y_TICKS_RATIO))
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove());

    // convert the nested groups into lists of bar locations
    const stackedData = d3
      .stack<GroupType>()
      .keys(Object.keys(groups[0].bars))
      .value((d, key) => d.bars[key].value)(groups);

    // Show the bars
    svg
      .append('g')
      .selectAll('g')
      .data(stackedData)
      .join('g')
      .attr('fill', (d) => colors[d.key])
      .selectAll('rect')
      .data((d) => {
        return d.map((x) => ({ ...x, key: d.key, index: d.index }));
      })
      .join('rect')
      .attr('stroke-width', 3)
      .attr('rx', 3)
      .attr('x', (d) => {
        const x_val = x(d.data.label);
        if (x_val) {
          return x_val + MARGIN.left;
        }
        return null;
      })
      .attr('y', (d) => y(d[1]) + MARGIN.top)
      .attr('height', (d) => y(d[0]) - y(d[1] - 1.0))
      .attr('width', x.bandwidth())
      .on('mouseover', function (event, d) {
        if (formatTooltip !== undefined) {
          const group = d.data as GroupType;
          d3.select(this).attr('stroke', colors[d.key]);
          setTooltipText(
            formatTooltip(group.bars[d.key] as GroupType['bars'][string]),
          );
        }
      })
      .on('mouseout', function (event, d) {
        d3.select(this).attr('stroke', null);
        setTooltipText(null);
      });
  }, [width, height]);

  return (
    <svg
      className="text-gray-700"
      ref={svgRef}
      width={width}
      height={height}
    ></svg>
  );
}

export const STACKED_COLUMN_CHART_EXAMPLE_ARGS = {
  groups: [
    {
      label: 'SMB',
      bars: {
        ['Baseline']: { value: 45, user: 'tyler' },
        ['Variation 1']: { value: 20, user: 'sage' },
        ['Variation 2']: { value: 0, user: 'tyler' },
      },
    },
    {
      label: 'Platform',
      bars: {
        ['Baseline']: { value: 20, user: 'tyler' },
        ['Variation 1']: { value: 60, user: 'sage' },
        ['Variation 2']: { value: 0, user: 'tyler' },
      },
    },
    {
      label: 'Enterprise',
      bars: {
        ['Baseline']: { value: 40, user: 'sage' },
        ['Variation 1']: { value: 30, user: 'tyler' },
        ['Variation 2']: { value: 10, user: 'sage' },
      },
    },
  ],
  colors: {
    ['Baseline']: '#35B9E9',
    ['Variation 1']: '#981781',
    ['Variation 2']: '#FF4405',
  },
};

type GroupWithUser = Group & {
  bars: { [key: string]: { user: string } };
};

export function StackedColumnChartExample({
  groups,
  colors,
}: {
  groups: GroupWithUser[];
  colors: Colors;
}) {
  return (
    <GraphContainer
      className="h-96"
      header={
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center text-gray-900">
            <div className="mr-1 w-6 stroke-gray-600">
              <UserPlusIcon className="text-gray-800" />
            </div>{' '}
            <div className="font-semibold text-gray-900">Approval Level</div>
          </div>
          <Legend
            mappings={Object.entries(colors).map(([label, color]) => ({
              label,
              color,
            }))}
            className="justify-self-end"
          />
        </div>
      }
    >
      <RenderChildWithSize
        className="h-full w-full overflow-hidden"
        childFunction={(width, height) => {
          return (
            <StackedColumnChart
              width={width}
              height={height}
              yLimit={90}
              groups={groups}
              colors={colors}
              formatTooltip={(bar) => {
                return (
                  <div>
                    <p>{bar.value}</p>
                    <p>{bar.user}</p>
                  </div>
                );
              }}
            />
          );
        }}
      />
    </GraphContainer>
  );
}
