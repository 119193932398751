import { isNil } from 'lodash';
import Tooltip, { Location } from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { Count, CurrencyValue, DerivedValue } from '../../types_common/price';
import {
  convertCurrencyValueToNumber,
  formatCurrencyValue,
} from '../alpaca_utils';

type DerivedCurrencyValueProps = {
  value: DerivedValue<CurrencyValue | Count> | null;
  tooltipLocation?: Location;
  className?: string;
  tooltipTextOverride?: string;
  colorConfig?: {
    useColorWhenZero?: boolean;
    type: 'COST' | 'REVENUE';
  };
};

export default function DerivedCurrencyValue(props: DerivedCurrencyValueProps) {
  const { value, tooltipLocation, tooltipTextOverride, colorConfig } = props;

  const tooltipText = (() => {
    if (!isNil(tooltipTextOverride) && tooltipTextOverride.length > 0) {
      return tooltipTextOverride;
    }
    // the numbers behind the calculation, e.g. 33/100
    const provenance = value?.provenance.trim() ?? '';
    // notes about the calculation, e.g. "gross rev / gross volume" or "only
    // considers apple pay"
    const concept = value?.concept?.trim() ?? '';
    if (provenance.length === 0 && concept.length === 0) {
      return 'N/A';
    }
    return ((concept.length > 0 ? concept + '\n' : '') + provenance).trim();
  })();

  const className = classNames(
    props.className ?? '',
    (() => {
      if (colorConfig) {
        //todo: move color config to formatCurrency instead
        let numericValue = convertCurrencyValueToNumber(value);
        const GRAY = ' text-gray-400';
        const RED = ' text-red-700';
        const GREEN = ' text-green-700';

        if (!colorConfig?.useColorWhenZero && numericValue === 0) {
          return GRAY;
        } else {
          return numericValue > 0
            ? ` ${colorConfig.type == 'REVENUE' ? GREEN : RED}`
            : numericValue < 0
              ? ` ${colorConfig.type == 'REVENUE' ? RED : GREEN}`
              : '';
        }
      }
      return '';
    })(),
  );

  return (
    <Tooltip
      as="span"
      scope="col"
      className={className}
      location={tooltipLocation ?? 'LEFT'}
      text={tooltipText}
      disableTooltip={isNil(value)}
    >
      {formatCurrencyValue(value)}
    </Tooltip>
  );
}
