import React from 'react';
import { ProductSelectField, SelectField } from 'src/components/Fields';
import { useModal } from 'src/components/Modal';
import BottomBar from '../../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../../PricingFlow';
import { CurrencyValueType, QuotePrice } from '../../types_common/price';
import {
  ALL_ALPACA_PRODUCT_CATEGORIES,
  AlpacaAdditionalData,
  AlpacaCollectionsProduct,
  AlpacaGlobalAccountsProduct,
  AlpacaPricingFlow,
  AlpacaProductCategory,
  AlpacaSupportedCurrency,
  MonthlySubscriptionFee,
} from '../alpaca_types';
import {
  getAllCollectionsProductInfos,
  getAllGlobalAccountsProductInfos,
} from '../Steps/Collections';
import AlpacaHeader from './AlpacaHeader';
import { QuotePriceEditable } from './AlpacaQuotePriceEditable';

type UpdateAdditionalData = (newAdditionalData: AlpacaAdditionalData) => void;

function deduplicateArray<T>(arr: T[]) {
  const seen = new Set();
  return arr.filter((item) => {
    if (seen.has(item)) {
      return false;
    } else {
      seen.add(item);
      return true;
    }
  });
}

const ProductGrid = ({
  selectedCategories,
}: {
  selectedCategories: AlpacaProductCategory[];
}) => {
  const superCategories = deduplicateArray(
    ALL_ALPACA_PRODUCT_CATEGORIES.map((pc) => pc.superCategory),
  );
  const maxCategoryCount = Math.max(
    ...superCategories.map(
      (sc) =>
        ALL_ALPACA_PRODUCT_CATEGORIES.filter((pc) => pc.superCategory === sc)
          .length,
    ),
  );

  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<AlpacaPricingFlow>();

  function selectCategory(selectedCategory: AlpacaProductCategory) {
    const products = pricingFlow.products ?? [];
    if (selectedCategory.category === 'Global Accounts') {
      // Add all the global accounts products and all the Collections products
      const allGlobalAccountsProductInfos =
        getAllGlobalAccountsProductInfos(pricingFlow);
      const addedGlobalAccountsProductsToAdd: AlpacaGlobalAccountsProduct[] =
        allGlobalAccountsProductInfos.map((productInfo) => {
          return {
            categoryName: 'Global Accounts',
            quotePrice: null,
            volume: 0,
            name: productInfo.name,
            id: productInfo.id,
          };
        });
      products.push(...addedGlobalAccountsProductsToAdd);
      const allCollectionsProductInfos =
        getAllCollectionsProductInfos(pricingFlow);
      const addedCollectionsProductsToAdd: AlpacaCollectionsProduct[] =
        allCollectionsProductInfos.map((productInfo) => {
          return {
            categoryName: 'Collections',
            quotePrice: null,
            volume: 0,
            name: productInfo.name,
            id: productInfo.id,
          };
        });
      products.push(...addedCollectionsProductsToAdd);
    }
    updateFlow(
      {
        ...pricingFlow,
        products,
        additionalData: {
          ...pricingFlow.additionalData,
          productCategories: selectedCategories.concat([selectedCategory]),
        },
      },
      false,
    );
  }

  /**
   *
   * @param unselectedCategory
   * Technically we don't have to remove all these products because the product category will be disabled
   */
  function unselectCategory(unselectedCategory: AlpacaProductCategory) {
    let products = pricingFlow.products ?? [];
    if (unselectedCategory.category === 'Global Accounts') {
      // Remove all the global accounts products and all the Collections products
      products = products.filter(
        (product) =>
          product.categoryName !== 'Global Accounts' &&
          product.categoryName !== 'Collections',
      );
    }
    updateFlow(
      {
        ...pricingFlow,
        products,
        additionalData: {
          ...pricingFlow.additionalData,
          productCategories: selectedCategories.filter(
            (category) => category.category !== unselectedCategory.category,
          ),
        },
      },
      false,
    );
  }

  return (
    <div
      className="grid gap-2"
      style={{
        gridTemplateRows: `auto repeat(${maxCategoryCount}, auto)`,
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
      }}
    >
      {superCategories.map((superCategory) => {
        const productCategories = ALL_ALPACA_PRODUCT_CATEGORIES.filter(
          (pc) => pc.superCategory === superCategory,
        );
        return (
          <React.Fragment key={superCategory}>
            <div className="bg-gray-100 px-6 py-2 text-xs font-medium text-gray-500 rounded-lg uppercase">
              {superCategory}
            </div>
            {/* {superCategory === 'Accept payments / Fund accounts' && (
              <ProductSelectField
                className="h-full"
                name={'Acquiring'}
                id="Acquiring"
                disabled={true}
                onChange={function (): void {
                  throw new Error('Function not implemented.');
                }}
              />
            )} */}
            {productCategories.map((productCategory) => {
              const isSelected =
                selectedCategories.find(
                  (selectedCategory) =>
                    selectedCategory.category === productCategory.category,
                ) !== undefined;
              const name = productCategory.category;
              return (
                <ProductSelectField
                  key={productCategory.category}
                  className="h-full"
                  name={
                    productCategory.disabled ? (
                      <div className="flex flex-row gap-2">
                        {name}
                        <span className="inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                          Coming soon
                        </span>
                      </div>
                    ) : (
                      name
                    )
                  }
                  disabled={(productCategory.disabled ?? false) || !editMode}
                  id={productCategory.category}
                  onChange={() => {
                    if (isSelected) {
                      unselectCategory(productCategory);
                    } else {
                      selectCategory(productCategory);
                    }
                  }}
                  checked={isSelected}
                />
              );
            })}
            {/* Add empty divs to fill the column */}
            {[...Array(maxCategoryCount - productCategories.length)].map(
              (_, index) => (
                <div
                  key={`empty-${superCategory}-${index}`}
                  className="h-full"
                />
              ),
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

type ProductCategoriesProps = {
  additionalData: AlpacaAdditionalData;
};
function ProductCategories(props: ProductCategoriesProps) {
  const { additionalData } = props;
  const selectedProductCategories = additionalData.productCategories ?? [];
  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-sm font-medium text-slate-700">
        Applicable product categories
      </h1>
      <ProductGrid selectedCategories={selectedProductCategories} />
    </div>
  );
}
function SubscriptionFee(props: {
  updateSubscriptionFee: (subscriptionFee: QuotePrice | null) => void;
  subscriptionFee: QuotePrice | null;
  quoteCurrency: AlpacaSupportedCurrency;
}) {
  const { subscriptionFee, updateSubscriptionFee, quoteCurrency } = props;
  const { editMode } = usePricingFlowContext<AlpacaPricingFlow>();

  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="flex flex-col gap-2">
        <h1 className="text-sm font-medium text-slate-700">Subscription fee</h1>
        <ProductSelectField
          name={'Monthly subscription fee'}
          id={'Monthly_subscription_fee'}
          description="Add a monthly subscription fee to this quote"
          onChange={() => {
            if (subscriptionFee) {
              updateSubscriptionFee(null);
            } else {
              updateSubscriptionFee({
                type: CurrencyValueType.FLAT,
                value: 0,
                currency: quoteCurrency,
              });
            }
          }}
          checked={subscriptionFee != null}
          disabled={!editMode}
        >
          {subscriptionFee && (
            <div className="mx-2 border-none border-gray-200">
              {/* // @TODO(fay) let's remove capability for Greater Than Volume for monthly subscription fee */}
              <QuotePriceEditable
                quotePrice={subscriptionFee}
                updateQuotePrice={(quotePrice: QuotePrice | null) => {
                  updateSubscriptionFee(quotePrice);
                }}
                validPriceTypes={[CurrencyValueType.FLAT]}
                quoteCurrency={quoteCurrency}
                // @TODO(fay) we want this hardcoded to something else!
                stickerPrice={{
                  type: CurrencyValueType.FLAT,
                  value: 10000,
                  currency: quoteCurrency,
                }}
                cost={{
                  type: CurrencyValueType.FLAT,
                  value: 0,
                  currency: quoteCurrency,
                }}
                productName={'Monthly subscription fee'}
                validTierMinimumTypes={[]}
                tierConfig={{
                  tierName: 'Month',
                  showMinimum: false,
                  showCost: false,
                }}
              />
            </div>
          )}
        </ProductSelectField>
      </div>
    </div>
  );
}
type QuoteCurrencyProps = {
  additionalData: AlpacaAdditionalData;
  updateAdditionalData: UpdateAdditionalData;
};
function QuoteCurrency(props: QuoteCurrencyProps) {
  const { additionalData, updateAdditionalData } = props;
  const { showModal, hideModal } = useModal();
  const { editMode } = usePricingFlowContext<AlpacaPricingFlow>();

  function openConfirmationModal(currency: AlpacaSupportedCurrency) {
    showModal({
      title: 'Confirm quote currency change',
      children: (
        <div className="p-0 text-sm text-slate-900">
          <p>
            Changing the quote currency will convert the monthly subscription
            fee and all products' prices using current currency conversion
            rates.
          </p>
          <div className="h-4" />

          <div className="flex flex-row gap-2 justify-end">
            <button
              type="button"
              className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </button>

            <button
              type="button"
              className="rounded bg-fuchsia-900 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
              disabled={!editMode}
              onClick={() => {
                updateAdditionalData({
                  ...(additionalData ?? {}),
                  quoteCurrency: currency,
                  shouldCurrencyValuesBeConsistent: false,
                  issuingConfig: {
                    ...additionalData.issuingConfig,
                    entities: additionalData.issuingConfig.entities.map((e) => {
                      if (
                        additionalData.issuingConfig.isShowLocalCurrencySelected
                      )
                        return e;
                      return {
                        ...e,
                        issuingEntityCurrency: currency,
                      };
                    }),
                  },
                });
                hideModal();
              }}
            >
              Confirm change
            </button>
          </div>
        </div>
      ),
    });
  }

  return (
    <SelectField
      label="Quote currency"
      name="currency"
      value={additionalData.quoteCurrency}
      onChange={(currency) => {
        // #AlpacaForexEffects
        // Heads up, this is going to have downstream effects, see other
        // instances of this # comment
        openConfirmationModal(currency.target.value as AlpacaSupportedCurrency);
      }}
      className="max-w-[200px]"
      disabled={!editMode}
    >
      <option value="USD">USD</option>
      <option value="EUR">EUR</option>
      <option value="GBP">GBP</option>
      <option value="AUD">AUD</option>
      <option value="SGD">SGD</option>
      <option value="HKD">HKD</option>
    </SelectField>
  );
}
type AlpacaQuoteInputsProps = {
  nextStep: () => void;
};

export default function AlpacaQuoteInputs(props: AlpacaQuoteInputsProps) {
  const { nextStep } = props;
  const { updateFlow, pricingFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();

  const updateAdditionalData = (newAdditionalData: AlpacaAdditionalData) => {
    updateFlow({ ...pricingFlow, additionalData: newAdditionalData }, false);
  };
  return (
    <div className="h-full w-full">
      <AlpacaHeader
        title="Quote Inputs"
        description="Let's start by filling some details about this deal"
        rightChildren={[]}
      ></AlpacaHeader>
      <div className="p-6 flex flex-col gap-12">
        <QuoteCurrency
          additionalData={pricingFlow.additionalData}
          updateAdditionalData={updateAdditionalData}
        />
        <SubscriptionFee
          subscriptionFee={pricingFlow.additionalData.monthlySubscriptionFee}
          updateSubscriptionFee={(subscriptionFee: QuotePrice | null) => {
            updateAdditionalData({
              ...pricingFlow.additionalData,
              monthlySubscriptionFee: subscriptionFee as MonthlySubscriptionFee,
            });
          }}
          quoteCurrency={pricingFlow.additionalData.quoteCurrency}
        />
        <ProductCategories additionalData={pricingFlow.additionalData} />
        <div className="h-8" />
        <BottomBar
          primaryButtonProps={{
            label: 'Next',
            onClick: async () => nextStep(),
          }}
        />
      </div>
    </div>
  );
}
