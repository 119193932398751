import { UserPlusIcon } from '@heroicons/react/24/outline';
import * as d3 from 'd3';
import _ from 'lodash';
import { ReactNode, useEffect, useRef } from 'react';
import { useTooltip } from '../MouseTooltip';
import GraphContainer, { RenderChildWithSize } from './GraphContainer';

type Bar = {
  label: string;
  value: number;
  color: string;
};

const MARGIN = { top: 10, right: 10, bottom: 40, left: 80 };

// these indicate how many labeled ticks there are on the x and y axes per height / width
const X_TICKS_RATIO = 80;

export function HorizontalBarGraph<BarType extends Bar>({
  height,
  width,
  xLimit,
  bars,
  formatTooltip,
}: {
  height: number;
  width: number;
  xLimit: number;
  bars: BarType[];
  formatTooltip?: (arg0: BarType) => ReactNode;
}) {
  const { setTooltipText } = useTooltip();
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    svg.attr('width', width).attr('height', height).append('g');

    // X axis
    const x = d3
      .scaleLinear()
      .domain([0, xLimit])
      .range([0, width - MARGIN.left - MARGIN.right]);
    svg
      .append('g')
      .attr('transform', `translate(${MARGIN.left}, ${height - MARGIN.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / X_TICKS_RATIO)
          .tickSizeOuter(0),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .attr('y2', -(height - MARGIN.top - MARGIN.bottom))
          .attr('stroke-opacity', 0.14)
          .attr('stroke-dasharray', '5,7'),
      )
      .selectAll('text');

    // Y axis
    const y = d3
      .scaleBand()
      .range([0, height - MARGIN.top - MARGIN.bottom])
      .domain(bars.map((d) => d.label))
      .padding(0.3);
    svg
      .append('g')
      .call(d3.axisLeft(y))
      .attr('transform', `translate(${MARGIN.left}, ${MARGIN.top})`)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove());

    // add Bars
    svg
      .selectAll('myRect')
      .data(bars)
      .enter()
      .append('rect')
      .attr('y', (d) => y(d.label) ?? null)
      .attr('x', x(0))
      .attr('width', (d) => x(d.value))
      .attr('height', y.bandwidth())
      .attr('fill', (d) => d.color)
      .attr('stroke-width', (d) => 5)
      .attr('transform', `translate(${MARGIN.left}, ${MARGIN.top})`)
      .on('mouseover', function (event, bar) {
        if (formatTooltip !== undefined) {
          d3.select(this).attr('stroke', bar.color);

          setTooltipText(formatTooltip(bar));
        }
      })
      .on('mouseleave', function (d) {
        d3.select(this).attr('stroke', null);
        setTooltipText(null);
      });
  }, [width, height]);

  return (
    <svg
      className="text-gray-700"
      ref={svgRef}
      width={width}
      height={height}
    ></svg>
  );
}

export const HORIZONTAL_BAR_CHART_EXAMPLE_1_ARGS = {
  bars: [
    { label: 'Manager', value: 40, color: '#73E2A3' },
    { label: 'Head of sales', value: 30, color: '#8098F9' },
    { label: 'Deal desk', value: 20, color: '#FF9C66' },
    { label: 'Finance', value: 10, color: '#FDA29B' },
  ],
};

export function HorizontalBarChartExample1({ bars }: { bars: Bar[] }) {
  return (
    <GraphContainer
      header={
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center text-gray-900">
            <div className="mr-1 w-6 stroke-gray-600">
              <UserPlusIcon className="text-gray-800" />
            </div>{' '}
            <div className="font-semibold text-gray-900">Approval Level</div>
          </div>
        </div>
      }
    >
      <RenderChildWithSize
        className="h-full w-full overflow-hidden"
        childFunction={(width, height) => {
          return (
            <HorizontalBarGraph
              width={width}
              height={height}
              xLimit={Math.round(
                (_.max(bars.map((b) => b.value)) as number) * 1.1,
              )}
              bars={bars}
              formatTooltip={(bar: Bar) => {
                return `${bar.value}`;
              }}
            />
          );
        }}
      />
    </GraphContainer>
  );
}

// Example 2 is designed to show how you can pass extra information and display it in the tooltip
export type UserInfo = {
  name: string;
  occupation: string;
};
export const HORIZONTAL_BAR_CHART_EXAMPLE_2_ARGS = {
  bars: [
    {
      label: 'Variation 1',
      value: 22,
      color: '#73E2A3',
      info: {
        name: 'Tyler',
        occupation: 'Student',
      },
    },
    {
      label: 'Variation 2',
      value: 33,
      color: '#8098F9',
      info: {
        name: 'Taylor',
        occupation: 'Developer',
      },
    },
  ],
};

export function HorizontalBarChartExample2({
  bars,
}: {
  bars: (Bar & { info: UserInfo })[];
}) {
  return (
    <GraphContainer
      header={
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center text-gray-900">
            <div className="mr-1 w-6 stroke-gray-600">
              <UserPlusIcon className="text-gray-800" />
            </div>{' '}
            <div className="font-semibold text-gray-900">Approval Level</div>
          </div>
        </div>
      }
    >
      <RenderChildWithSize
        className="h-full w-full overflow-hidden"
        childFunction={(width, height) => {
          return (
            <HorizontalBarGraph
              width={width}
              height={width / 2}
              xLimit={Math.round(
                (_.max(bars.map((b) => b.value)) as number) * 1.1,
              )}
              bars={bars}
              formatTooltip={(bar) => {
                return (
                  <div>
                    <p>{`name: ${bar.info.name}`}</p>
                    <p>{`occupation: ${bar.info.occupation}`}</p>
                  </div>
                );
              }}
            />
          );
        }}
      />
    </GraphContainer>
  );
}
