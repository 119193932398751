import { RefObject } from 'react';

const retrieveParamFromURL = (param: string): string => {
  const value = new URLSearchParams(window.location.search).get(param);

  if (!value) {
    throw new Error(`param ${param} not found in URL.`);
  }

  return value;
};

/**
 * E.g.
 * https://dealops-client-test.onrender.com -> .dealops-client-test.onrender.com
 * https://www.dealops.com -> .dealops.com
 * https://dealops.com -> .dealops.com
 * @returns domain from process.env.REACT_APP_CLIENT_BASE_URL without the protocol and with a "." in front
 */
function getDomainWithPeriodPrefix(url: string | undefined) {
  if (url == null) throw new Error('Missing env var REACT_APP_CLIENT_BASE_URL');
  const parsedUrl = new URL(url);
  const hostname = parsedUrl.hostname;
  const domain = hostname.startsWith('www.') ? hostname.substring(4) : hostname;
  return '.' + domain;
}

export const safeParseFloat = (inputString: string) => {
  const cleanInputString = inputString.replace(/[^\d.-]/g, ''); // Remove everything except digits, decimal points, and minus sign
  return parseFloat(cleanInputString);
};

export const formatTimestamp = ({
  timestamp,
  date: dateArg,
}: {
  timestamp?: string;
  date?: Date;
}): string => {
  if (timestamp === undefined && dateArg === undefined) {
    throw new Error('Please provide either timestamp or date');
  }

  if (timestamp !== undefined && dateArg !== undefined) {
    throw new Error('Please provide either timestamp or date, but not both');
  }

  // Create a new Date object from the timestamp
  const date = timestamp ? new Date(timestamp) : dateArg;

  // Define options for date and time formatting
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  };

  // Format the date using Intl.DateTimeFormat
  return new Intl.DateTimeFormat('en-US', options)
    .format(date)
    .replace(',', '');
};

export const doesSearchQueryMatch = (
  searchQuery: string,
  text: string,
): boolean => {
  // Lower case and remove any non-alphanumeric characters
  const searchQueryCleaned = searchQuery
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '');
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '')
    .includes(searchQueryCleaned);
};

const utils = {
  retrieveParamFromURL,
  getDomainWithPeriodPrefix,
  formatTimestamp,
};

// use the returned handleKeyDown function to make "enter" send you to the next
// row of a table
export function getHandleKeyDownForEnterNextRowHandling(
  ref: RefObject<HTMLInputElement>,
  dataTag: string,
) {
  return (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      const currentRow = ref.current?.closest('tr');
      if (currentRow) {
        let nextRow = currentRow.nextElementSibling as HTMLElement;
        while (nextRow) {
          const nextInput = nextRow.querySelector<HTMLInputElement>(
            `input[${dataTag}]`,
          );
          if (nextInput) {
            nextInput.focus();
            return;
          }
          nextRow = nextRow.nextElementSibling as HTMLElement;
        }
        ref.current?.blur();
      }
    }
  };
}

export default utils;
