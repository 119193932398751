import { datadogRum } from '@datadog/browser-rum';
import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { produce, setAutoFreeze } from 'immer';
import _, { isNil } from 'lodash';
import { Fragment, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import api from 'src/api';
import Badge from 'src/components/Badge';
import { FormattedNumberField } from 'src/components/Fields';
import { Spinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import {
  Count,
  Currency,
  Tier,
} from 'src/dashboard/PricingFlow/types_common/price';
import list from 'src/list';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { formatCurrency } from 'src/utils/formatters';
import { ALPACA_CURRENCY_SYMBOLS } from '../../Alpaca/alpaca_types';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  PenguinCV,
  PenguinEffectiveContractualMin,
  PenguinPricingFlow,
  PenguinPricingFlowWithProductVolumes,
  PenguinProduct,
  PenguinProductPrices,
  PenguinProductWithVolume,
  PenguinSupportedCurrency,
  PenguinTier,
  TieredApprovalLevels,
} from '../penguin_types';
import { computeEffectiveMinimumContractualSpend } from '../penguin_utils';
import EditableIndicator from './EditableIndicator';
import {
  approvalBadgeColors,
  approvalLevelsHumanReadable,
  getL1AndSuggestedPriceForProduct,
} from './PenguinQuoteTable';

type Approval = { isLoading: true } | { isLoading: false; level: number };
type TierWithApprovals = Tier<PenguinCV, Count> & {
  approval: Approval;
  key: string;
};

export function Sidebar({
  isOpen,
  onClose,
  children,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}) {
  return createPortal(
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-min">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-xl font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    </div>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body,
  );
}

const HEADINGS = [
  'Tier',
  'Volume',
  'Sticker',
  'L1 price',
  'Suggested price',
  'Quote price',
  'Approval level',
  undefined,
];

function Header() {
  return (
    <thead>
      <tr>
        {HEADINGS.map((heading) => (
          <th
            key={heading}
            scope="col"
            className="sticky top-0 z-10 whitespace-nowrap rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-sm font-medium text-gray-700 backdrop-blur backdrop-filter"
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function BodyDataCell({ children }: { children: React.ReactNode }) {
  return (
    <td className="has-tooltip whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium">
      {children}
    </td>
  );
}

function format(amount: number) {
  return formatCurrency({
    amount,
    currency: 'USD',
    rounding: true,
    minimumFractionDigits: 3,
  });
}

function BodyRow({
  index,
  tier,
  onDelete,
  setTier,
  stickerPrice,
  l1Price,
  suggestedPrice,
  isVolumeEditable,
}: {
  stickerPrice: PenguinCV | null;
  l1Price: PenguinCV | null;
  suggestedPrice: PenguinCV | null;
  index: number;
  tier: TierWithApprovals;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
  isVolumeEditable: boolean;
  setTier: (tier: TierWithApprovals) => void;
}) {
  const volumeInputRef = useRef<HTMLInputElement>(null);
  const quotePriceInputRef = useRef<HTMLInputElement>(null);
  const { pricingFlow, editMode } = usePricingFlowContext<PenguinPricingFlow>();

  return (
    <tr>
      <BodyDataCell>Tier {index}</BodyDataCell>
      <td
        className="borer-gray-200 border-b p-0 text-sm font-medium"
        onClick={() => {
          if (volumeInputRef.current !== null) {
            volumeInputRef.current.focus();
          }
        }}
      >
        {isVolumeEditable ? (
          <EditableIndicator className="h-full w-full px-6 py-4">
            <FormattedNumberField
              ref={volumeInputRef}
              data-tiered-volume-editable
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                volumeInputRef,
                'data-tiered-volume-editable',
              )}
              type="text"
              value={tier.minimum.value}
              required={true}
              className="h-full max-w-[100px] cursor-pointer border-none bg-transparent text-sm outline-none focus:border-none focus:ring-0 focus:ring-transparent"
              updateValue={(value: number) => {
                setTier({ ...tier, minimum: { type: 'count', value } });
              }}
              numberDecimals={0}
              disabled={!editMode}
            />
          </EditableIndicator>
        ) : (
          <div className="flex h-full w-full items-center px-6 py-4">
            <span className="max-w-[100px] pl-4">{tier.minimum.value}</span>
          </div>
        )}
      </td>
      <BodyDataCell>
        <button
          onClick={() => {
            if (!isNil(stickerPrice)) {
              setTier({
                ...tier,
                currencyValue: {
                  ...tier.currencyValue,
                  value: stickerPrice.value,
                },
              });
            }
          }}
          disabled={
            isNil(stickerPrice) || !_.isFinite(stickerPrice.value) || !editMode
          }
        >
          <Badge color="purple">
            {!isNil(stickerPrice) && _.isFinite(stickerPrice.value)
              ? format(stickerPrice.value)
              : 'N/A'}
          </Badge>
        </button>
      </BodyDataCell>
      <BodyDataCell>
        <button
          onClick={() => {
            if (!isNil(l1Price)) {
              setTier({
                ...tier,
                currencyValue: { ...tier.currencyValue, value: l1Price.value },
              });
            }
          }}
          disabled={isNil(l1Price) || !_.isFinite(l1Price.value) || !editMode}
        >
          <Badge color="orange">
            {!isNil(l1Price) && _.isFinite(l1Price.value)
              ? format(l1Price.value)
              : 'N/A'}
          </Badge>
        </button>
      </BodyDataCell>
      <BodyDataCell>
        <button
          onClick={() => {
            if (!isNil(suggestedPrice) && _.isFinite(suggestedPrice.value)) {
              setTier({
                ...tier,
                currencyValue: {
                  ...tier.currencyValue,
                  value: suggestedPrice.value,
                },
              });
            }
          }}
          disabled={
            isNil(suggestedPrice) ||
            !_.isFinite(suggestedPrice.value) ||
            !editMode
          }
        >
          <Badge color="green">
            {!isNil(suggestedPrice) && _.isFinite(suggestedPrice.value)
              ? format(suggestedPrice.value)
              : 'N/A'}
          </Badge>
        </button>
      </BodyDataCell>
      <td
        className="borer-gray-200 border-b p-0 text-sm font-medium"
        onClick={() => {
          if (quotePriceInputRef.current !== null) {
            quotePriceInputRef.current.focus();
          }
        }}
      >
        <EditableIndicator className="h-full w-full px-6 py-4">
          <FormattedNumberField
            ref={quotePriceInputRef}
            data-tiered-quote-price-editable
            onKeyDown={getHandleKeyDownForEnterNextRowHandling(
              quotePriceInputRef,
              'data-tiered-quote-price-editable',
            )}
            type="text"
            value={tier.currencyValue.value}
            required={true}
            className="-ml-3 mr-0 max-w-[100px] cursor-pointer border-none bg-transparent py-4 pr-6 text-sm outline-none focus:border-none focus:ring-0 focus:ring-transparent"
            updateValue={(value: number) => {
              setTier({
                ...tier,
                currencyValue: { ...tier.currencyValue, value },
              });
            }}
            numberDecimals={3}
            prefix={
              ALPACA_CURRENCY_SYMBOLS[
                (pricingFlow.additionalData?.quoteCurrency ?? 'USD') as Currency
              ]
            }
            disabled={!editMode}
          />
        </EditableIndicator>
      </td>
      <BodyDataCell>
        <ApprovalBadge approval={tier.approval} />
      </BodyDataCell>
      <BodyDataCell>
        {index > 2 ? (
          <button
            type="button"
            tabIndex={-1}
            className={classNames(
              'flex items-center text-fuchsia-900 hover:text-fuchsia-950',
              !editMode && 'opacity-0',
            )}
            onClick={onDelete}
            disabled={!editMode}
          >
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        ) : null}
      </BodyDataCell>
    </tr>
  );
}

function ApprovalBadge({ approval }: { approval: Approval }) {
  if (approval.isLoading) {
    return <Spinner height={18} width={18} />;
  }

  const approvalBadgeColor = approvalBadgeColors[approval.level];
  const approvalHumanReadable = approvalLevelsHumanReadable[approval.level];
  return <Badge color={approvalBadgeColor}>{approvalHumanReadable}</Badge>;
}

function Body({
  tiers,
  removeTierWithKey,
  setTierWithKey,
  stickerPrice,
  l1Price,
  suggestedPrice,
}: {
  stickerPrice: PenguinCV | null;
  l1Price: PenguinCV | null;
  suggestedPrice: PenguinCV | null;
  tiers: TierWithApprovals[];
  removeTierWithKey: (key: string) => void;
  setTierWithKey: (key: string, tier: TierWithApprovals) => void;
}) {
  return (
    <tbody>
      {tiers.map((tier, index) => (
        <BodyRow
          stickerPrice={stickerPrice}
          l1Price={l1Price}
          suggestedPrice={suggestedPrice}
          key={tier.key}
          index={index + 1}
          tier={tier}
          onDelete={() => removeTierWithKey(tier.key)}
          setTier={(tier: TierWithApprovals) => setTierWithKey(tier.key, tier)}
          isVolumeEditable={index !== 0}
        />
      ))}
    </tbody>
  );
}

function Footer({
  onAddTier,
  disableAddNewTier,
}: {
  onAddTier: React.MouseEventHandler<HTMLButtonElement>;
  disableAddNewTier: boolean;
}) {
  const { editMode } = usePricingFlowContext();

  return (
    <tfoot>
      <tr>
        <td colSpan={HEADINGS.length} className="bg-gray-50 px-6 py-2">
          <div className="flex justify-start">
            <button
              className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
              onClick={onAddTier}
              disabled={disableAddNewTier || !editMode}
            >
              Add new tier
            </button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}

function TieredDetails(props: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  productPrices: PenguinProductPrices;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  productIdToEdit: string | undefined;
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
}) {
  const { productIdToEdit, isOpen, onCancel, onSave, pricingFlow } = props;

  const product = pricingFlow.products.find((p) => p.id === productIdToEdit) as
    | PenguinProductWithVolume
    | undefined;

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onCancel}
      title={`Volume pricing tiers for ${product?.name ?? ''}`}
    >
      {productIdToEdit !== undefined ? (
        <Editor
          {...props}
          productId={productIdToEdit}
          initialApprovalLevels={
            pricingFlow.approvalLevels[productIdToEdit] as TieredApprovalLevels
          }
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}
    </Sidebar>
  );
}

async function fetchApprovalLevel({
  product,
  monthlyMinimum,
  price,
  currency,
  pricingFlow,
}: {
  // TODO: Fix ProductCommon and the other product types so they can be used
  // here
  product: PenguinProduct;
  monthlyMinimum: PenguinEffectiveContractualMin;
  price: PenguinCV;
  currency: PenguinSupportedCurrency;
  pricingFlow: PenguinPricingFlow;
}): Promise<number> {
  const resp = await api.post('calculate_tier_approval_level', {
    product,
    price,
    monthlyMinimum,
    currency,
    pricingFlowId: pricingFlow.id,
  });
  return resp.data.level;
}

function Editor({
  pricingFlow,
  productPrices,
  updateFlow,
  productId,
  onCancel,
  onSave,
  initialApprovalLevels,
}: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  productPrices: PenguinProductPrices;
  updateFlow: (
    flow: PenguinPricingFlowWithProductVolumes,
    showLoading?: boolean,
  ) => void;
  productId: string;
  onCancel: () => void;
  onSave: () => void;
  initialApprovalLevels: TieredApprovalLevels;
}) {
  const { showToast } = useToast();
  const { manualQuote } = pricingFlow;
  const quote = manualQuote.products?.[productId];
  const product = pricingFlow.products.find((p) => p.id === productId) as
    | PenguinProductWithVolume
    | undefined;

  if (product === undefined) {
    throw new Error(
      `Attempting to edit tiers for missing product ${productId}`,
    );
  }
  const pricingInfo =
    productPrices[product.id]?.currentPricingCurve.pricingInformation;

  const stickerPrice = pricingInfo?.selfServe;

  const { L1Price, suggestedPrice } = getL1AndSuggestedPriceForProduct({
    product: product,
    pricingFlow: pricingFlow,
  });

  if (isNil(quote) || quote.type !== 'tiered') {
    throw new Error(
      `Attempting to edit tiers of non tiered product quote ${quote} for ${product.name}`,
    );
  }
  const initialTiers: TierWithApprovals[] = quote.tiers
    ? quote.tiers.map((tier: PenguinTier, idx) => {
        const initialLevel = initialApprovalLevels?.levels?.[idx];
        return {
          ...tier,
          approval:
            typeof initialLevel === 'number'
              ? { level: initialLevel, isLoading: false }
              : // This shouldn't happen in reality, but if something goes wrong
                // with initialApprovalLevels this will prevent us from crashing
                { isLoading: true },
          key: _.uniqueId(),
        };
      })
    : [];

  const [tiers, setTiers] = useState<TierWithApprovals[]>(initialTiers);

  const duplicateLastTierPlus = () => {
    const stepSize = productPrices[productId]?.pricingTierStepSize ?? 1000;
    const lastTier = tiers[tiers.length - 1];
    const newKey = _.uniqueId();
    const newTier: TierWithApprovals = {
      ...lastTier,
      key: newKey,
      minimum: { type: 'count', value: lastTier.minimum.value + stepSize },
    };
    setTiers(tiers.concat(newTier));
    if (lastTier.approval.isLoading) {
      void updateApproval(newKey, newTier);
    }
  };

  const removeTierWithKey = (key: string) => {
    if (tiers.length > 1) {
      const newTiers = [...tiers].filter((tier) => tier.key !== key);
      setTiers(newTiers);
    }
  };
  // Ask the server for a new approval level
  const updateApproval = async (key: string, newTier: TierWithApprovals) => {
    const level = await fetchApprovalLevel({
      product,
      price: newTier.currencyValue,
      monthlyMinimum: computeEffectiveMinimumContractualSpend(pricingFlow),
      currency: pricingFlow.additionalData.quoteCurrency,
      pricingFlow,
    });
    setTierWithKey(key, {
      ...newTier,
      approval: { level, isLoading: false },
    });
  };
  const setTierWithKey = (key: string, newTier: TierWithApprovals) => {
    setTiers((tiers) => {
      const index = list.findIndexOrNull(tiers, (tier) => tier.key === key);
      if (isNil(index)) {
        datadogRum.addError(`Found no tier with key ${key}. Nothing to update`);
        return tiers;
      }
      const oldTier = tiers[index];

      const needsNewApproval =
        newTier.currencyValue.value !== oldTier.currencyValue.value;

      const isLoading =
        newTier.approval.isLoading ?? oldTier.approval.isLoading;

      const newTiers = [...tiers];
      if (needsNewApproval && !isLoading) {
        newTiers[index] = { ...newTier, approval: { isLoading: true } };
        void updateApproval(key, newTier);
      } else {
        newTiers[index] = newTier;
      }

      return newTiers;
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const validateTiers = (): boolean => {
    const productPrice = pricingFlow.pricingSheetData.productInfo;
    const stepSize = productPrice[productId]?.pricingTierStepSize ?? 1000;
    if (tiers.slice(1).some((tier) => tier.minimum.value % stepSize !== 0)) {
      showToast({
        title: `All tier volumes must be divisible by ${stepSize} (e.g. ${stepSize}, ${stepSize * 2}...)`,
        subtitle: '',
        type: 'error',
      });
      return false;
    }
    for (let i = 0; i < tiers.length; i++) {
      if (i !== 0 && tiers[i - 1].minimum.value >= tiers[i].minimum.value) {
        showToast({
          title: 'The tier volumes must be increasing',
          subtitle: '',
          type: 'error',
        });
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (!validateTiers()) {
      return;
    }

    // Our local state contains approval and loading state. The server doesn't
    // need this.
    const tiersWithoutApprovals = tiers.map((tier) => {
      const { approval, ...rest } = tier;
      return rest;
    });

    setAutoFreeze(false);
    const newFlow: PenguinPricingFlowWithProductVolumes = produce(
      pricingFlow,
      (draftFlow) => {
        if (!isNil(draftFlow.manualQuote.products)) {
          draftFlow.manualQuote.products[productId] = {
            type: 'tiered',
            minimumType: 'count',
            tiers: tiersWithoutApprovals,
          };
        }
      },
    );
    updateFlow(newFlow, false);
    onSave();
  };

  return (
    <div className="px-6 py-4">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full">
          <Header />
          <Body
            stickerPrice={stickerPrice ?? null}
            l1Price={L1Price}
            suggestedPrice={suggestedPrice}
            tiers={tiers}
            removeTierWithKey={removeTierWithKey}
            setTierWithKey={setTierWithKey}
          />
          <Footer
            onAddTier={duplicateLastTierPlus}
            disableAddNewTier={tiers.length >= 10}
          />
        </table>
      </div>
      <Savebar onCancel={handleCancel} onSave={handleSave} />
    </div>
  );
}

function Savebar({
  onCancel,
  onSave,
}: {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { editMode } = usePricingFlowContext();
  return (
    <div className="mt-8 flex flex-row items-center justify-end gap-2">
      <button
        type="button"
        className="col-span-full justify-center rounded-lg border border-gray-200 bg-white px-5 py-2 font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
        onClick={onCancel}
      >
        Cancel
      </button>

      <button
        type="submit"
        className={classNames(
          'col-span-full justify-center rounded-lg border border-fuchsia-900 bg-fuchsia-900 px-5 py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900',
          !editMode ? 'cursor-not-allowed opacity-50' : '',
        )}
        onClick={onSave}
        disabled={!editMode}
      >
        Save
      </button>
    </div>
  );
}

export default TieredDetails;
