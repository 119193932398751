import { ChartBarIcon } from '@heroicons/react/24/outline';
import * as d3 from 'd3';
import _ from 'lodash';
import { ReactNode, useEffect, useRef } from 'react';
import { useTooltip } from '../MouseTooltip';
import GraphContainer, { RenderChildWithSize } from './GraphContainer';
import { Legend } from './helpers';

type Point = {
  xLocation: number;
  value: number;
};

type GetElementType<T extends any[]> = T extends (infer U)[] ? U : never;

export type Line = {
  color: string;
  points: Point[];
};

let MARGIN = {
  top: 0,
  right: 0,
  bottom: 30,
  left: 40,
};

// A buffer on the top and the bottom of a graph looks nice
const Y_SCALE_BUFFER = 0.1;

// these indicate how many labeld ticks there are on the x and y axes per height / width
const X_TICKS_RATIO = 90;
const Y_TICKS_RATIO = 50;

export default function LinePlot<LineType extends Line>({
  width,
  height,
  start,
  end,
  lines,
  formatTooltip,
  leftMargin = MARGIN.left,
  xAxisLabel,
  yAxisLabel,
  formatXAxis = (x) => x.toString(),
  formatYAxis = (y) => y.toString(),
}: {
  width: number;
  height: number;
  start: number;
  end: number;
  lines: LineType[];
  leftMargin?: number;
  xAxisLabel?: string;
  yAxisLabel?: string;
  formatXAxis?: (arg0: number) => string;
  formatYAxis?: (arg0: number) => string;
  formatTooltip?: (
    arg0: {
      line: LineType;
      point: GetElementType<LineType['points']>;
    }[],
  ) => ReactNode;
}) {
  const { setTooltipText } = useTooltip();

  if (xAxisLabel) {
    MARGIN = { ...MARGIN, bottom: 50 };
  }

  if (yAxisLabel) {
    MARGIN = { ...MARGIN, left: 55 };
  }

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);
    // Clear existing chart
    svg.selectAll('*').remove();

    const x = d3.scaleLinear([start, end], [leftMargin, width - MARGIN.right]);

    const allPointValues = _.concat(
      ...lines.map((l: LineType) => l.points),
    ).map((dp) => dp.value);

    const y = d3.scaleLinear(
      [
        (Math.min(...allPointValues) as number) * (1 - Y_SCALE_BUFFER),
        (Math.max(...allPointValues) as number) * (1 + Y_SCALE_BUFFER),
      ],
      [height - MARGIN.bottom, MARGIN.top],
    );

    // Add each line
    for (const lineData of lines) {
      const sortedPoints = Array.from(lineData.points);
      sortedPoints.sort((a: Point, b: Point) => a.xLocation - b.xLocation);
      const line = d3
        .line<Point>()
        .x((d) => x(d.xLocation))
        .y((d) => y(d.value));
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', lineData.color)
        .attr('stroke-width', 2)
        .attr('d', line(sortedPoints));
    }

    // Add the x-axis.
    const xAxis = d3
      .axisBottom<number>(x)
      .tickFormat((d) => formatXAxis(d))
      .ticks(width / X_TICKS_RATIO)
      .tickSizeOuter(0);

    svg
      .append('g')
      .attr('transform', `translate(0,${height - MARGIN.bottom})`)
      .call(xAxis)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove());

    if (xAxisLabel) {
      svg
        .append('text') // Adding X axis label
        .attr('text-anchor', 'start')
        .attr('x', width / 2 - (xAxisLabel.length * 4) / 2) // Shifted left by half the size of the label, assuming an average width of 4 pixels per character
        .attr('y', height - MARGIN.bottom / 4 + 8)
        .text(xAxisLabel);
    }

    // Add the y-axis
    svg
      .append('g')
      .attr('transform', `translate(${leftMargin},0)`)
      .call(
        d3
          .axisLeft<number>(y)
          .tickFormat((d) => formatYAxis(d))
          .ticks(height / Y_TICKS_RATIO),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .attr('x2', width - leftMargin - MARGIN.right)
          .attr('stroke-opacity', 0.14)
          .attr('stroke-dasharray', '5,7'),
      );

    if (yAxisLabel) {
      svg
        .append('text') // Adding Y axis label
        .attr('text-anchor', 'start')
        .attr('transform', `rotate(-90)`)
        .attr('x', -height / 2 - (yAxisLabel.length * 4) / 2) // Shifted down by half the size of the label, assuming an average width of 4 pixels per character
        .attr('y', leftMargin / 4)
        .text(yAxisLabel);
    }

    const bisect = d3.bisector((d: Point) => d.xLocation).center;

    // Create a rect on top of the svg area: this rectangle recovers mouse position and shows
    // tooltips
    svg
      .append('rect')
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .attr('width', width - MARGIN.left - MARGIN.right)
      .attr('height', height)
      .attr('x', MARGIN.left)
      .attr('y', MARGIN.top)
      .on('mousemove', function (event) {
        let linesWithPoints = lines.filter((line) => line.points.length > 0);
        if (formatTooltip !== undefined && linesWithPoints.length > 0) {
          const mouseX = d3.pointer(event)[0];

          // find closest point for each line
          const closestPoints = lines
            .filter((line) => line.points.length > 0) // line may have 0 points
            .map((line) => {
              const closestIndex = bisect(line.points, x.invert(mouseX));
              return line.points[closestIndex];
            });

          // find each of the distances
          const distances = closestPoints.map((dp) => {
            const pointX = x(dp.xLocation);
            return Math.abs(pointX - mouseX);
          });

          // choose the line with the closest point
          const closestLine = lines[distances.indexOf(Math.min(...distances))];

          const closestPointIndex = bisect(
            closestLine.points,
            x.invert(mouseX),
          );

          const closestPoint = closestLine.points[closestPointIndex];

          const linesAndPoints = lines
            .map((line) => {
              const overlappingPoint = line.points.find(
                (dp) => dp.xLocation === closestPoint.xLocation,
              );
              if (overlappingPoint !== undefined) {
                return {
                  line: line,
                  point: overlappingPoint,
                };
              }
            })
            .filter((x) => x !== undefined) as {
            line: LineType;
            point: GetElementType<LineType['points']>;
          }[];
          svg.selectAll('.focus').remove();

          svg
            .append('line')
            .classed('focus', true)
            .attr('x1', x(closestPoint.xLocation))
            .attr('x2', x(closestPoint.xLocation))
            .attr('y1', MARGIN.top)
            .attr('y2', height - MARGIN.bottom)
            .attr('stroke', 'black')
            .attr('stroke-opacity', 0.24)
            .style('stroke-width', 1);

          for (const { line, point } of linesAndPoints) {
            svg
              .append('g')
              .append('circle')
              .style('fill', 'none')
              .classed('focus', true)
              .attr('stroke', '#FFFFFF')
              .attr('stroke-width', 2)
              .style('fill', line.color)
              .attr('cx', x(point.xLocation))
              .attr('cy', y(point.value))
              .attr('r', 6);
          }

          setTooltipText(formatTooltip(linesAndPoints));
        }
      })
      .on('mouseout', function (event) {
        svg.selectAll('.focus').remove();
        setTooltipText(null);
      });
  }, [width, height, lines]);

  return (
    <div className="h-full w-full">
      <svg
        className="text-gray-700"
        ref={svgRef}
        width={width}
        height={height}
      ></svg>
    </div>
  );
}
export const LINE_PLOT_EXAMPLE_1_ARGS = {
  lines: [
    {
      label: 'Fintech',
      color: '#0BA5EC',
      points: [
        { xLocation: new Date('2007-04-23').getTime(), value: 110 },
        { xLocation: new Date('2007-04-24').getTime(), value: 115 },
        { xLocation: new Date('2007-04-25').getTime(), value: 120 },
        { xLocation: new Date('2007-04-26').getTime(), value: 122 },
        { xLocation: new Date('2007-04-29').getTime(), value: 124 },
        { xLocation: new Date('2007-05-01').getTime(), value: 128 },
        { xLocation: new Date('2007-05-02').getTime(), value: 132 },
        { xLocation: new Date('2007-05-03').getTime(), value: 136 },
        { xLocation: new Date('2007-05-04').getTime(), value: 140 },
        { xLocation: new Date('2007-05-08').getTime(), value: 138 },
        { xLocation: new Date('2007-05-09').getTime(), value: 135 },
        { xLocation: new Date('2007-05-09').getTime(), value: 132 },
        { xLocation: new Date('2007-05-10').getTime(), value: 129 },
        { xLocation: new Date('2007-05-13').getTime(), value: 126 },
        { xLocation: new Date('2007-05-14').getTime(), value: 123 },
        { xLocation: new Date('2007-05-15').getTime(), value: 120 },
        { xLocation: new Date('2007-05-16').getTime(), value: 117 },
        { xLocation: new Date('2007-05-17').getTime(), value: 114 },
        { xLocation: new Date('2007-05-20').getTime(), value: 111 },
      ],
    },
    {
      label: 'Enterprise',
      color: '#16B364',
      points: [
        { xLocation: new Date('2007-04-23').getTime(), value: 93.24 },
        { xLocation: new Date('2007-04-24').getTime(), value: 95.35 },
        { xLocation: new Date('2007-04-25').getTime(), value: 98.84 },
        { xLocation: new Date('2007-04-26').getTime(), value: 99.92 },
        { xLocation: new Date('2007-04-29').getTime(), value: 99.8 },
        { xLocation: new Date('2007-05-01').getTime(), value: 99.47 },
        { xLocation: new Date('2007-05-02').getTime(), value: 100.39 },
        { xLocation: new Date('2007-05-03').getTime(), value: 100.4 },
        { xLocation: new Date('2007-05-04').getTime(), value: 100.81 },
        { xLocation: new Date('2007-05-07').getTime(), value: 103.92 },
        { xLocation: new Date('2007-05-08').getTime(), value: 105.06 },
        { xLocation: new Date('2007-05-09').getTime(), value: 106.88 },
        { xLocation: new Date('2007-05-09').getTime(), value: 107.34 },
        { xLocation: new Date('2007-05-10').getTime(), value: 108.74 },
        { xLocation: new Date('2007-05-13').getTime(), value: 109.36 },
        { xLocation: new Date('2007-05-14').getTime(), value: 107.52 },
        { xLocation: new Date('2007-05-15').getTime(), value: 107.34 },
        { xLocation: new Date('2007-05-16').getTime(), value: 116.44 },
        { xLocation: new Date('2007-05-17').getTime(), value: 118.02 },
        { xLocation: new Date('2007-05-20').getTime(), value: 124.98 },
      ],
    },

    {
      label: 'Midmarket',
      color: '#FF4405',
      points: [
        { xLocation: new Date('2007-04-23').getTime(), value: 141 },
        { xLocation: new Date('2007-04-24').getTime(), value: 143 },
        { xLocation: new Date('2007-04-25').getTime(), value: 143 },
        { xLocation: new Date('2007-04-26').getTime(), value: 142 },
        { xLocation: new Date('2007-04-29').getTime(), value: 145 },
        { xLocation: new Date('2007-05-01').getTime(), value: 141 },
        { xLocation: new Date('2007-05-02').getTime(), value: 137 },
        { xLocation: new Date('2007-05-03').getTime(), value: 133 },
        { xLocation: new Date('2007-05-04').getTime(), value: 129 },
        { xLocation: new Date('2007-05-07').getTime(), value: 125 },
        { xLocation: new Date('2007-05-08').getTime(), value: 121 },
        { xLocation: new Date('2007-05-09').getTime(), value: 117 },
        { xLocation: new Date('2007-05-09').getTime(), value: 113 },
        { xLocation: new Date('2007-05-10').getTime(), value: 109 },
        { xLocation: new Date('2007-05-13').getTime(), value: 105 },
        { xLocation: new Date('2007-05-14').getTime(), value: 101 },
        { xLocation: new Date('2007-05-15').getTime(), value: 97 },
        { xLocation: new Date('2007-05-16').getTime(), value: 97 },
        { xLocation: new Date('2007-05-17').getTime(), value: 94 },
        { xLocation: new Date('2007-05-20').getTime(), value: 92 },
      ],
    },
  ],
};
export function LinePlotExample1({
  lines,
}: {
  lines: (Line & { label: string })[];
}) {
  return (
    <GraphContainer
      className="h-full w-full"
      header={
        <div className="flex w-full items-center text-gray-900">
          <div className="mr-1 w-6 stroke-gray-600">
            <ChartBarIcon className="text-gray-800" />
          </div>{' '}
          <div className="font-semibold text-gray-900">Quotes created</div>
        </div>
      }
    >
      <div className="flex h-full w-full flex-col">
        <Legend className="self-end" mappings={lines} />
        <RenderChildWithSize
          className="h-full w-full overflow-hidden"
          childFunction={(width, height) => {
            return (
              <LinePlot
                width={width}
                height={height}
                start={new Date('2007-04-22').getTime()}
                end={new Date('2007-05-20').getTime()}
                lines={lines}
                formatXAxis={(x: number) => {
                  const date = new Date(x);
                  const month = date.getMonth() + 1;
                  const day = date.getDate();
                  return `${day}/${month}`;
                }}
                formatTooltip={(linesAndPoints) => {
                  return (
                    <div>
                      {linesAndPoints.map(({ line, point }) => {
                        return (
                          <div>
                            <p style={{ color: line.color }}>{point.value}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            );
          }}
        />
      </div>
    </GraphContainer>
  );
}

// this example is designed to show how you can show extra information in a tooltip about the line
// and point
export const LINE_PLOT_EXAMPLE_2_ARGS = {
  line: {
    color: '#981781',
    points: [
      {
        xLocation: new Date('2007-04-23').getTime(),
        value: 0.55,
        info: { user: 'tyler' },
      },
      {
        xLocation: new Date('2007-04-25').getTime(),
        value: 0.55,
        info: { user: 'taylor' },
      },
      {
        xLocation: new Date('2007-04-26').getTime(),
        value: 0.75,
        info: { user: 'sage' },
      },
      {
        xLocation: new Date('2007-04-29').getTime(),
        value: 0.75,
        info: { user: 'taylor' },
      },
      {
        xLocation: new Date('2007-05-01').getTime(),
        value: 0.35,
        info: { user: 'tyler' },
      },
      {
        xLocation: new Date('2007-05-03').getTime(),
        value: 0.32,
        info: { user: 'sage' },
      },
      {
        xLocation: new Date('2007-05-04').getTime(),
        value: 0.32,
        info: { user: 'tyler' },
      },
      {
        xLocation: new Date('2007-05-08').getTime(),
        value: 0.25,
        info: { user: 'taylor' },
      },
      {
        xLocation: new Date('2007-05-02').getTime(),
        value: 0.35,
        info: { user: 'sage' },
      },
      {
        xLocation: new Date('2007-05-09').getTime(),
        value: 0.25,
        info: { user: 'taylor' },
      },
      {
        xLocation: new Date('2007-05-10').getTime(),
        value: 0.4,
        info: { user: 'tyler' },
      },
      {
        xLocation: new Date('2007-05-11').getTime(),
        value: 0.4,
        info: { user: 'sage' },
      },
      {
        xLocation: new Date('2007-05-12').getTime(),
        value: 0.57,
        info: { user: 'tyler' },
      },
      {
        xLocation: new Date('2007-05-13').getTime(),
        value: 0.57,
        info: { user: 'sage' },
      },
      {
        xLocation: new Date('2007-05-14').getTime(),
        value: 0.45,
        info: { user: 'tyler' },
      },
      {
        xLocation: new Date('2007-05-15').getTime(),
        value: 0.45,
        info: { user: 'taylor' },
      },
    ],
    info: {
      total: 1000,
    },
  },
};

type LineWithUser = Line & { points: (Point & { info: { user: string } })[] };

export function LinePlotExample2({ line }: { line: LineWithUser }) {
  return (
    <GraphContainer
      className="h-full w-full"
      header={
        <div className="flex w-full items-center text-gray-900">
          <div className="mr-1 w-6 stroke-gray-600">
            <ChartBarIcon className="text-gray-800" />
          </div>{' '}
          <div className="font-semibold text-gray-900">Quotes created</div>
        </div>
      }
    >
      <div className="flex h-full w-full flex-col">
        <RenderChildWithSize
          className="h-full w-full overflow-hidden"
          childFunction={(width, height) => {
            return (
              <LinePlot
                width={width}
                height={height}
                start={new Date('2007-04-22').getTime()}
                end={new Date('2007-05-15').getTime()}
                lines={[line]}
                formatYAxis={(y) => `${(y * 100).toFixed(0)}%`}
                formatXAxis={(x: number) => {
                  const date = new Date(x);
                  const month = date.getMonth() + 1;
                  const day = date.getDate();
                  return `${day}/${month}`;
                }}
                formatTooltip={(linesAndPoints) => {
                  return (
                    <div>
                      {linesAndPoints.map(({ line, point }) => {
                        return (
                          <div>
                            <p style={{ color: line.color }}>
                              {point.value} {point.info.user}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            );
          }}
        />
      </div>
    </GraphContainer>
  );
}
