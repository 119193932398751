import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import CollapsibleSection from 'src/components/CollapsibleSection';

export type PricingFlowForNavigation = { id: string; createdAt: string };
type QuoteOptionsSectionProps = {
  currentPricingFlowId: string;
  sfdcOpportunityId: string;
  pricingFlows: PricingFlowForNavigation[];
};
export default function QuoteOptionsSection(props: QuoteOptionsSectionProps) {
  const { pricingFlows, sfdcOpportunityId, currentPricingFlowId } = props;
  return (
    <CollapsibleSection title="QUOTE OPTIONS">
      <div className="flex flex-wrap items-center divide-y">
        {pricingFlows.map((pricingFlow, i: number) => {
          const isOptionCurrentPricingFlow =
            pricingFlow.id === currentPricingFlowId;
          return isOptionCurrentPricingFlow ? (
            <div
              key={pricingFlow.id}
              className={
                'px-3 flex w-full items-center justify-between py-3 pr-4 bg-gray-50'
              }
            >
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-900 font-medium">
                  Option {i + 1} (current)
                </span>
                <span className="text-xs text-gray-500">
                  Created {dayjs(pricingFlow.createdAt).fromNow()}
                </span>
              </div>
            </div>
          ) : (
            <Link
              key={pricingFlow.id}
              className={
                'px-3 flex w-full items-center justify-between py-3 pr-4'
              }
              to={`/app/opportunity/${sfdcOpportunityId}/pricingflow/${pricingFlow.id}`}
            >
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-900 font-medium">
                  Option {i + 1}
                </span>
                <span className="text-xs text-gray-500">
                  Created {dayjs(pricingFlow.createdAt).fromNow()}
                </span>
              </div>
              <ArrowTopRightOnSquareIcon className="h-6 w-6 mr-0" />
            </Link>
          );
        })}
      </div>
    </CollapsibleSection>
  );
}
