import { PencilIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/dashboard/App';
import PencilBackgroundSvg from 'src/images/pencil-background.svg';

type BackgroundColor = 'white' | 'gray-100';

// these comments are needed because programatically constructed tailwind classes do not work
// due to tree shaking. see https://v2.tailwindcss.com/docs/optimizing-for-production for more info
// bg-white bg-gray-100
// focus-within:bg-white focus-within:bg-gray-100
// group-hover:bg-white group-hover:bg-gray-100

export default function EditableIndicator(props: {
  backgroundColor?: BackgroundColor;
  className?: string;
  children: React.ReactNode;
}) {
  const { children, className } = props;
  const backgroundColor: BackgroundColor = props.backgroundColor ?? 'white';

  return (
    <div
      className={`group relative h-full w-full cursor-pointer transition-all duration-200 focus-within:bg-${backgroundColor} hover:bg-fuchsia-50`}
    >
      <div className="flex h-full w-full items-center rounded-md border-2 border-transparent ring-2 ring-transparent transition-all duration-200 focus-within:border-fuchsia-800 focus-within:ring-fuchsia-200 ">
        <div className="pointer-events-none absolute right-0 top-0 transition-all duration-200 group-focus-within:opacity-0">
          <img
            className="pointer-events-none"
            src={PencilBackgroundSvg}
            alt=""
          />
          <PencilIcon
            className={`pointer-events-none absolute top-0 right-0 box-content w-3 stroke-gray-600 py-1 pr-2 transition-all duration-200 group-hover:stroke-[#83196E]`}
          />
        </div>
        <div
          className={classNames(
            className ?? '',
            'flex h-full w-full items-center',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
