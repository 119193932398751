import { datadogRum } from '@datadog/browser-rum';
import {
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  GlobeAltIcon,
  PlusIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { produce } from 'immer';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import api from 'src/api';
import Badge, { BadgeColor } from 'src/components/Badge';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FullscreenSpinner, InlineSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { unreachable } from 'src/typeUtils';
import { safeParseFloat } from 'src/utils';
import { Organization, User } from '../../types';
import { classNames } from '../App';
import { AlpacaOpportunityData } from '../PricingFlow/Alpaca/alpaca_types';
import { formatCurrencyValue } from '../PricingFlow/Alpaca/alpaca_utils';
import { getOpportunityOverviewFields } from '../PricingFlow/Alpaca/Components/AlpacaOpportunitySidebar';
import { HamsterOpportunityData } from '../PricingFlow/Hamster/hamster_types';
import { getOpportunityOverviewBarFields } from '../PricingFlow/Penguin/Components/OpportunityOverviewBar';
import { PenguinOpportunityData } from '../PricingFlow/Penguin/penguin_types';
import { PricingFlowCommon, PricingFlowType } from '../PricingFlow/types';
import {
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueType,
} from '../PricingFlow/types_common/price';

dayjs.extend(relativeTime);

interface OpportunityDetailPageProps {
  user: User;
  organization: Organization;
}

type OpportunityDetailPageConfig = {
  isOpportunityEditable: boolean;
  hasApprovals: boolean;
  canCloneFromOppDetailsPage: boolean;
  shouldAutoRedirectToPricingFlow: boolean;
};

type Opportunity = {
  pricingFlows: PricingFlowCommon[] | null;
  sfdcOpportunityName: string;
  opportunityData:
    | AlpacaOpportunityData
    | PenguinOpportunityData
    | HamsterOpportunityData;
  sfdcOpportunityId: string;
  type: PricingFlowType;
};

function configForOrg(
  type: PricingFlowType | undefined,
): OpportunityDetailPageConfig {
  switch (type) {
    case PricingFlowType.HAMSTER:
      return {
        isOpportunityEditable: true,
        hasApprovals: true,
        canCloneFromOppDetailsPage: true,
        shouldAutoRedirectToPricingFlow: false,
      };
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
    case PricingFlowType.ALPACA:
    default:
      return {
        isOpportunityEditable: false,
        hasApprovals: false,
        // ##CloneFromOppDetailPage
        // do NOT turn this to true for a pricing flow without implementing the
        // cloning behavior on the server!
        canCloneFromOppDetailsPage: false,
        shouldAutoRedirectToPricingFlow: true,
      };
  }
}

export default function OpportunityDetailPage(
  props: OpportunityDetailPageProps,
) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const params = useParams();
  const { sfdcOppId } = params;
  const { pathname } = useLocation();
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);
  const pageConfig = configForOrg(props.organization.pricingFlowType);
  useEffect(() => {
    let isMounted = true;
    if (pathname.startsWith('/app/opportunity'))
      (async () => {
        try {
          const opportunityData = await (async () => {
            // try to get existing opportunity
            const existingOpportunityRes = await api.get('opportunity', {
              sfdcOpportunityId: sfdcOppId,
            });
            const { doesOpportunityExistInDealops, opportunityData } =
              existingOpportunityRes.data;
            if (doesOpportunityExistInDealops) {
              return opportunityData;
            } else {
              // otherwise, create an opportunity
              const newOpportunityRes = await api.post('opportunity', {
                sfdcOpportunityId: sfdcOppId,
              });
              const pricingFlows = pageConfig.shouldAutoRedirectToPricingFlow
                ? [
                    (
                      await api.post('pricingflow', {
                        sfdcOpportunityId: sfdcOppId,
                      })
                    ).data,
                  ]
                : [];
              return {
                ...newOpportunityRes.data.opportunityData,
                pricingFlows,
              };
            }
          })();
          if (isMounted) {
            setOpportunity(opportunityData);
          }
        } catch (error) {
          datadogRum.addError(error);
          if (
            axios.isAxiosError(error) &&
            (error as AxiosError).response?.status === 404
          ) {
            showToast({
              title: 'No Opportunity was Found',
              subtitle: 'Opportunity ID: ' + sfdcOppId,
              type: 'error',
              autoDismiss: false,
            });
            navigate('/app/opportunity');
          } else {
            console.error('unknown error on POST PricingFlow:', error);
          }
        }
      })();
    return () => {
      isMounted = false;
    };
  }, [sfdcOppId]);

  if (pageConfig.shouldAutoRedirectToPricingFlow) {
    const firstPricingFlow = _.first(opportunity?.pricingFlows);
    return opportunity && firstPricingFlow ? (
      <Navigate to={`pricingflow/${firstPricingFlow.id}`} relative="path" />
    ) : (
      <FullscreenSpinner />
    );
  } else {
    return opportunity ? (
      <>
        {/* breadcrumbs */}
        <HeaderBreadcrumbs
          steps={[
            {
              label: 'Opportunities',
              onClick: () => {
                navigate('/app/opportunity');
              },
            },
            {
              label: `Opp-${opportunity.sfdcOpportunityId}`,
              onClick: () => {},
            },
          ]}
        />
        {/* title */}
        <div className="px-4 sm:px-6 lg:px-8 mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {opportunity.sfdcOpportunityName}
            </h2>
          </div>
        </div>
        {/* content */}
        <div className="min-h-screen p-8">
          <div className="mx-auto pb-10 flex flex-col gap-6 items-start md:flex-row">
            <OpportunityInfo
              opportunity={opportunity}
              pageConfig={pageConfig}
            />
            <Quotes opportunity={opportunity} pageConfig={pageConfig} />
          </div>
        </div>
      </>
    ) : (
      <FullscreenSpinner />
    );
  }
}

export function OpportunityFieldValue(props: {
  displayValue: OpportunityFieldDisplayValue;
}) {
  const { displayValue } = props;
  switch (displayValue.type) {
    case 'badge':
      return <Badge color={displayValue.color}>{displayValue.value}</Badge>;
    case 'currency':
      return formatCurrencyValue(displayValue.value);
    case 'text':
      return displayValue.value;
    default:
      unreachable(displayValue);
  }
}
function getOpportunityFieldValueTextOnly(
  displayValue: OpportunityFieldDisplayValue,
) {
  switch (displayValue.type) {
    case 'badge':
    case 'text':
      return displayValue.value;
    case 'currency':
      return formatCurrencyValue(displayValue.value);
    default:
      unreachable(displayValue);
  }
}

function getHamsterOpportunityDetails(
  oppData: HamsterOpportunityData,
): OpportunityDisplayField[] {
  return [
    {
      name: 'Owner',
      editable: false,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.Owner ?? '-',
      },
    },
    {
      name: 'Segment',
      editable: false,
      icon: TagIcon,
      displayValue: oppData.Segment
        ? {
            type: 'badge',
            value: oppData.Segment,
            color: 'blue',
          }
        : {
            type: 'text',
            value: '-',
          },
    },
    {
      name: 'Country',
      editable: false,
      icon: GlobeAltIcon,
      displayValue: {
        type: 'text',
        value: oppData.Country ?? '-',
      },
    },
    {
      name: 'Number of lawyers',
      editable: true,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.Number_of_lawyers.toLocaleString() ?? '-',
      },
    },
    {
      name: 'Estimated revenue',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: {
        type: 'currency',
        value: {
          type: CurrencyValueType.FLAT,
          value: oppData.Estimated_revenue,
          currency: 'USD',
        },
      },
    },
  ];
}

type OpportunityFieldDisplayValue =
  | {
      type: 'badge';
      color: BadgeColor;
      value: string;
    }
  | {
      type: 'currency';
      value: CurrencyValueFlat | CurrencyValuePercent;
    }
  | { type: 'text'; value: string };
export interface OpportunityDisplayField {
  name: string;
  displayValue: OpportunityFieldDisplayValue;
  icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  editable: boolean;
}
function getOpportunityDetails(
  opportunity: Opportunity,
): OpportunityDisplayField[] {
  switch (opportunity.type) {
    case PricingFlowType.PENGUIN: {
      return getOpportunityOverviewBarFields(
        opportunity.opportunityData as PenguinOpportunityData,
      );
    }
    case PricingFlowType.ALPACA: {
      return getOpportunityOverviewFields(
        opportunity.opportunityData as AlpacaOpportunityData,
      );
    }
    case PricingFlowType.HAMSTER:
      const oppData = opportunity.opportunityData as HamsterOpportunityData;
      return getHamsterOpportunityDetails(oppData);
    case PricingFlowType.DEALOPS:
    case PricingFlowType.COMPLEX_DEMO:
    default:
      return [];
  }
}

type OpportunityInfoProps = {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
};
function OpportunityInfo(props: OpportunityInfoProps) {
  const fields = getOpportunityDetails(props.opportunity);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // intermediate values of fields while in editing state
  const [editedFields, setEditedFields] = useState(fields);
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white border border-slate-200 w-full md:w-80 md:min-w-80">
      {/* header */}
      <div className="px-4 py-5 flex justify-between items-center font-medium">
        <p className="text-xs text-gray-500">INFO</p>
        {props.pageConfig.isOpportunityEditable && (
          <button
            className="text-sm text-fuchsia-900 hover:text-fuchsia-700 "
            onClick={() => {
              setIsEditing((prevIsEditing) => {
                if (prevIsEditing) {
                  // switching from editing to not editing, also write changes to the pricing flow
                  // TODO(georgehamster)
                }
                return !prevIsEditing;
              });
            }}
          >
            {isEditing ? 'Save' : 'Edit'}
          </button>
        )}
      </div>
      {/* body */}
      <div className="px-4 py-5 space-y-4">
        {fields.map((field: OpportunityDisplayField, i: number) => {
          const isFieldEditable = isEditing && field.editable;
          return (
            <div className="flex justify-between items-center" key={field.name}>
              <p className="text-sm text-gray-500">{field.name}</p>
              <input
                className={classNames(
                  'text-sm text-right rounded-lg ml-8 border w-44',
                  isFieldEditable && 'border-gray-300',
                  !isFieldEditable && 'border-white',
                )}
                value={
                  editedFields[i]
                    ? getOpportunityFieldValueTextOnly(
                        editedFields[i].displayValue,
                      )
                    : getOpportunityFieldValueTextOnly(field.displayValue)
                }
                onChange={(e) => {
                  setEditedFields(
                    produce((draftFields) => {
                      const field = draftFields[i];
                      switch (field.displayValue.type) {
                        case 'badge':
                        case 'text':
                          field.displayValue.value = e.target.value;
                          break;
                        case 'currency':
                          const cv = field.displayValue.value;
                          switch (cv.type) {
                            case CurrencyValueType.FLAT:
                            case CurrencyValueType.PERCENT:
                              cv.value = safeParseFloat(e.target.value);
                              break;
                            default:
                              unreachable(cv);
                          }
                          break;
                        default:
                          unreachable(field.displayValue);
                      }
                    }),
                  );
                }}
                disabled={!isEditing}
              />
            </div>
          );
        })}
      </div>
      {/* footer */}
      <div className="px-4 py-4 flex justify-between items-center">
        <p className="text-xs text-gray-500 italic">Opportunity ID</p>
        <p className="text-xs text-gray-500 italic">
          {props.opportunity.sfdcOpportunityId}
        </p>
      </div>
    </div>
  );
}
interface QuotesProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
}
function Quotes(props: QuotesProps) {
  const { opportunity, pageConfig } = props;
  const { pricingFlows } = opportunity;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevPricingFlows, setPrevPricingFlows] = useState<
    PricingFlowCommon[] | null
  >(null);
  useEffect(() => {
    if (isNil(prevPricingFlows)) {
      api.get('pricingFlows', { matchingPricingFlowId: null }).then((res) => {
        setPrevPricingFlows(res.data);
      });
    }
  }, []);
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white border border-slate-200 w-full md:flex-grow ">
      {isNil(pricingFlows) || pricingFlows.length === 0 ? (
        <>
          {/* header */}
          <div className="px-4 py-5 text-xs text-slate-500 font-medium">
            <span>QUOTE OPTIONS</span>
          </div>
          {/* body */}
          <QuotesEmptyState
            opportunity={opportunity}
            pageConfig={pageConfig}
            prevPricingFlows={prevPricingFlows}
          />
        </>
      ) : (
        <>
          {/* header */}
          <div className="px-4 py-5 text-xs text-slate-500 font-medium flex items-center justify-between">
            <span>QUOTE OPTIONS</span>
            <div className="flex gap-x-2">
              {pageConfig.canCloneFromOppDetailsPage && (
                <button
                  className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
                  onClick={() => {
                    navigate('clonePricingFlow', {
                      state: { opportunity, prevPricingFlows },
                    });
                  }}
                >
                  <DocumentDuplicateIcon className="w-4 h-4 pl-0" />
                  <span className="text-sm font-medium">Clone existing</span>
                </button>
              )}
              <button
                className={classNames(
                  'border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border py-1 px-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900',
                  isLoading && 'bg-gray-100 opacity-60',
                )}
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  const newPricingFlow = (
                    await api.post('pricingflow', {
                      sfdcOpportunityId: opportunity.sfdcOpportunityId,
                    })
                  ).data;
                  navigate(`pricingflow/${newPricingFlow.id}`, {
                    relative: 'path',
                  });
                }}
              >
                <PlusIcon className="w-4 h-4 pl-0" />
                <span className="text-sm font-medium">Create a quote</span>
              </button>
            </div>
          </div>
          {/* body */}
          <QuotesList opportunity={opportunity} />
        </>
      )}
    </div>
  );
}

interface QuotesEmptyStateProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  prevPricingFlows: PricingFlowCommon[] | null;
}
function QuotesEmptyState(props: QuotesEmptyStateProps) {
  const navigate = useNavigate();
  const { opportunity, pageConfig, prevPricingFlows } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className="px-4 py-5 flex-grow flex flex-col items-center space-y-4">
      <div className="text-4xl">🌵</div>
      <div className="text-gray-500 text-sm flex flex-col items-center">
        <p>You do not have any quotes yet.</p>
        <p>Create your first quote</p>
      </div>
      <button
        className="border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const newPricingFlow = (
            await api.post('pricingflow', {
              sfdcOpportunityId: opportunity.sfdcOpportunityId,
            })
          ).data;
          navigate(`pricingflow/${newPricingFlow.id}`, { relative: 'path' });
        }}
      >
        {isLoading ? (
          <InlineSpinner />
        ) : (
          <>
            <PlusIcon className="w-4 h-4 pl-0" />
            <span className="font-medium text-sm">Create a new quote</span>
          </>
        )}
      </button>
      {pageConfig.canCloneFromOppDetailsPage && (
        <button
          className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
          onClick={() => {
            navigate('clonePricingFlow', {
              state: { opportunity, prevPricingFlows },
            });
          }}
        >
          <DocumentDuplicateIcon className="w-4 h-4 pl-0" />
          <span className="text-sm font-medium">Clone existing</span>
        </button>
      )}
    </div>
  );
}

interface QuotesListProps {
  opportunity: Opportunity;
}
function QuotesList(props: QuotesListProps) {
  const navigate = useNavigate();
  return (
    <div className="px-4 py-5 flex-grow space-y-4">
      {props.opportunity.pricingFlows?.map((pricingFlow, idx) => {
        return (
          <div className="flex justify-between" key={pricingFlow.id}>
            {/* left side */}
            <div className="space-y-1">
              <div className="font-medium text-slate-950 text-sm">
                Option {idx + 1}
              </div>
              <div className="text-xs text-slate-600">
                Created {dayjs(pricingFlow.createdAt).fromNow()}
              </div>
            </div>
            {/* right side */}
            <div>
              <button
                className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border py-1 px-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
                onClick={() => {
                  navigate(`pricingflow/${pricingFlow.id}`, {
                    relative: 'path',
                  });
                }}
              >
                <span className="text-sm font-medium">Edit</span>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
