import { useEffect } from 'react';

export const RedirectPage = (props: { url: string }) => {
  const { url } = props;
  useEffect(() => {
    window.location.replace(url);
  }, []);

  return null;
};
