import { Float, FloatProps } from '@headlessui-float/react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { classNames } from 'src/dashboard/App';

type MenuItem = {
  name: string;
  onClick: () => void;
};

type OverflowMenuProps = {
  menuItems: Array<MenuItem>;
  children?: React.ReactNode;
  placement?: FloatProps['placement'];
  offset?: FloatProps['offset'];
};

export default function OverflowMenu({
  menuItems,
  children,
  placement,
  offset,
}: OverflowMenuProps) {
  const defaultIcon = (
    <div>
      <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-fuchsia-800 focus:ring-offset-2 focus:ring-offset-gray-100">
        <span className="sr-only">Open options</span>
        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
    </div>
  );

  return (
    <Menu as="div" className="inline-block text-left">
      <Float placement={placement ?? 'right'} offset={offset}>
        {children ? <Menu.Button>{children}</Menu.Button> : defaultIcon}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {menuItems.map((menuItem, idx) => {
                return (
                  <Menu.Item key={idx}>
                    {({ active }) => (
                      <a
                        onClick={menuItem.onClick}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}
                      >
                        {menuItem.name}
                      </a>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Float>
    </Menu>
  );
}
