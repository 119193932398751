import { classNames } from 'src/dashboard/App';

type AlpacaHeaderProps = {
  title: string;
  description?: string;
  rightChildren: JSX.Element[];
  borderBottom?: boolean;
};
export default function AlpacaHeader(props: AlpacaHeaderProps) {
  const { title, description, rightChildren } = props;
  const borderBottom = props.borderBottom ?? true;
  return (
    <div
      className={classNames(
        'flex flex-col gap-4 px-8 py-4 xl:flex-row xl:justify-between',
        borderBottom ? 'border-b border-gray-200' : '',
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold">{title}</h1>
          {description != null && (
            <p className="text-base text-gray-700">{description}</p>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center gap-2">
        {rightChildren.map((child, i) => (
          <div key={i}>{child}</div>
        ))}
      </div>
    </div>
  );
}
