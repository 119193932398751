import { datadogRum } from '@datadog/browser-rum';

let initialized = false;

export function init() {
  if (
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN !== undefined &&
    process.env.REACT_APP_DATADOG_APPLICATION_ID
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'us5.datadoghq.com',
      service: 'dealops-client',
      env: process.env.REACT_APP_DATADOG_ENV,
      version: process.env.REACT_APP_GIT_SHA, // This is added in Render in the build command.
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
    });
    initialized = true;
  }
}

type SetUserParams = Parameters<typeof datadogRum.setUser>[0];

export function setUser(userData: SetUserParams) {
  if (initialized) {
    datadogRum.setUser(userData);
  }
}

type AddErrorFunctionType = typeof datadogRum.addError;

export function addError(...args: Parameters<AddErrorFunctionType>) {
  if (initialized) {
    datadogRum.addError(...args);
  }
}
