import { numericFormatter } from 'react-number-format';

export const formatNumber = (
  floatValue: number,
  numberDecimals: number,
  allowNegative: boolean = false,
) => {
  if (floatValue === Infinity) {
    return '∞';
  } else if (floatValue === -Infinity) {
    return '-∞';
  }
  return numericFormatter(floatValue.toString(), {
    allowLeadingZeros: false,
    thousandSeparator: ',',
    decimalScale: numberDecimals,
    fixedDecimalScale: true,
    allowNegative: allowNegative,
  });
};

export function formatCurrency(params: {
  amount: number | null;
  currency: string;
  locale?: string;
  // If minimunFractionDigits is not null, this determines how many decimal
  // points are shown
  minimumFractionDigits?: number;
  // If minimumFractionDigits are undefined, then rounding determines whether to
  // show the whole number (false) or whether to truncate to the locale's
  // default (true)
  rounding?: boolean;
}) {
  const { currency, minimumFractionDigits } = params;
  let { amount } = params;

  if (amount === null) {
    amount = 0;
  }

  const locale = params.locale ?? 'en-US';
  const rounding = params.rounding !== undefined ? params.rounding : true;

  if (rounding) {
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits,
    });
  } else {
    // Convert value to a string to count the decimal places
    const stringValue = amount.toString();

    // Count the decimal places
    const decimalPlaces = stringValue.includes('.')
      ? stringValue.split('.')[1].length
      : 0;

    const defaultMinimumFractionDigits = Math.max(2, decimalPlaces); // We should show at least 2 decimal places for usd

    // Use the determined decimal places for formatting
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency,

      minimumFractionDigits:
        minimumFractionDigits !== undefined
          ? minimumFractionDigits
          : defaultMinimumFractionDigits,
    });
  }
}

/**
 * Utility function to extract an Opportunity Id from a URL or an Id
 * @param idOrUrl url or id, e.g. https://salesforce.com/Opportunity/0061120000J5K3HQAV/view OR 0061120000J5K3HQAV
 * @returns and id, e.g. 0061120000J5K3HQAV
 */
export function getOpportunityIdFromIdOrUrl(idOrUrl: string): string {
  idOrUrl = idOrUrl.trim();
  const urlRegex = /\/Opportunity\/(.*?)\//;
  const match = idOrUrl.match(urlRegex);

  return match ? match[1] : idOrUrl;
}
