import AlpacaOpportunitySidebar from '../Components/AlpacaOpportunitySidebar';
import AlpacaQuoteInputs from '../Components/AlpacaQuoteInputs';

type AlpacaStep2ProductCategoriesProps = {
  nextStep: () => void;
};
export default function AlpacaStep2ProductCategories(
  props: AlpacaStep2ProductCategoriesProps,
) {
  const { nextStep } = props;
  return (
    <div className="flex flex-1 overflow-y-auto">
      <AlpacaOpportunitySidebar />
      <AlpacaQuoteInputs nextStep={nextStep} />
    </div>
  );
}
