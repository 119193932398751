import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Crepe } from '@milkdown/crepe';
import { useState } from 'react';
import api from 'src/api';
import CollapsibleSection from 'src/components/CollapsibleSection';
import { InlineSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import ApprovalRequestForm from './HamsterApprovalRequestForm';
import { HamsterPricingFlow } from './hamster_types';
interface ApproverCardProps {
  approverName: string;
  approverTitle: string;
}
function ApproverCard(props: ApproverCardProps) {
  return (
    <div className="flex flex-row items-center my-2">
      <UserCircleIcon className="h-10 w-10 text-slate-300 mx-2" />
      <div className="flex flex-col">
        <span className="text-sm font-medium text-gray-900">
          {props.approverName}
        </span>
        <span className="text-xs text-gray-500">{props.approverTitle}</span>
      </div>
    </div>
  );
}

interface AskForApprovalModalProps {
  hideModal: () => void;
  pricingFlow: HamsterPricingFlow;
}
export default function HamsterAskForApprovalModal(
  props: AskForApprovalModalProps,
) {
  const { hideModal, pricingFlow } = props;
  const [isApproversSectionExpanded, setIsApproversSectionExpanded] =
    useState<boolean>(true);
  const [message, setMessage] = useState<string | null>(null);
  const [crepe, setCrepe] = useState<Crepe | null>(null);

  return (
    <div className="w-full pt-2">
      <hr className="py-1" />
      <CollapsibleSection title={'APPROVERS'}>
        <div className="p-2">
          <div className="space-y-2">
            <span className="text-gray-500">
              First, you will need approval from the VP of Sales.
            </span>
            <div className="grid grid-cols-2">
              <ApproverCard approverName="Fay Wu" approverTitle="VP of Sales" />
            </div>
          </div>
          <div className="space-y-2">
            <span className="text-gray-500">
              Then, you will need 2 out of 4 approvals from Deal Desk.
            </span>
            <div className="grid grid-cols-2">
              <ApproverCard
                approverName="George Hyun"
                approverTitle="Head of RevOps"
              />
              <ApproverCard
                approverName="Jack LaMar"
                approverTitle="VP of Finance"
              />
              <ApproverCard
                approverName="Manou Febvret"
                approverTitle="VP of Global Sales"
              />
              <ApproverCard approverName="Michael Green" approverTitle="CFO" />
            </div>
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title="SLACK MESSAGE">
        <ApprovalRequestForm
          message={message}
          setMessage={setMessage}
          crepe={crepe}
          setCrepe={setCrepe}
          pricingFlow={pricingFlow}
        />
      </CollapsibleSection>
      {/* Bottom bar */}
      <div className="mt-5 pt-6 sm:mt-4 grid grid-cols-2 space-x-3">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={async () => {
            hideModal();
          }}
        >
          Back
        </button>
        <SendSlackTestMessageButton message={message} crepe={crepe} />
      </div>
    </div>
  );
}

function SendSlackTestMessageButton(props: {
  message: string | null;
  crepe: Crepe | null;
}) {
  const { message, crepe } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { showToast } = useToast();

  async function sendSlackTestMessage() {
    if (crepe == null) return;
    setIsLoading(true);
    console.log('Sending Slack test message. Original message');
    console.log(message);

    const markdown = crepe.getMarkdown();

    const response = await api.post('approval/sendSlackTestMessage', {
      markdown,
    });
    if (response.status !== 200) {
      console.error('Failed to send Slack test message');
    } else {
      console.log('✅ Sent Slack test message');
      setIsLoading(false);
      showToast({
        type: 'success',
        title: 'Slack test message sent',
        subtitle: 'Please check your Slack DM to confirm the test message.',
      });
    }

    setIsLoading(false);
  }

  return (
    <button
      type="submit"
      className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md bg-fuchsia-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 sm:ml-3 sm:mt-0 sm:w-auto gap-2"
      onClick={async () => {
        sendSlackTestMessage();
      }}
      disabled={isLoading || crepe == null}
    >
      <span className="w-4 mr-2">{isLoading && <InlineSpinner />}</span>
      Send request
    </button>
  );
}
