import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useEffect } from 'react';
import { classNames } from 'src/dashboard/App';

type ToastProps = {
  title: string;
  type: ToastType;
  subtitle: React.ReactNode;
  autoDismiss?: boolean;
};

export default function Toast(props: ToastProps & { hideToast: () => void }) {
  const { title, type, subtitle, hideToast } = props;
  const autoDismiss =
    props.autoDismiss !== undefined ? props.autoDismiss : true;

  useEffect(() => {
    if (autoDismiss) {
      const timer = setTimeout(() => {
        hideToast();
      }, 3000);
      return () => clearTimeout(timer);
    }
  });

  const iconColors = {
    success: 'text-green-200',
    error: 'text-red-200',
    info: 'text-blue-200',
  };

  const backgroundColors = {
    success: 'bg-fuchsia-900',
    error: 'bg-red-600',
    info: 'bg-fuchsia-900',
  };

  // If the type is success, icon should be CheckCircleIcon
  // If the type is error, icon should be ExclamationCircleIcon
  // If the type is info, icon should be InformationCircleIcon
  let Icon = InformationCircleIcon;
  if (type === 'success') {
    Icon = CheckCircleIcon;
  } else if (type === 'error') {
    Icon = ExclamationCircleIcon;
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-[60] flex items-end px-4 py-6 sm:items-start sm:py-16 lg:py-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5',
                backgroundColors[type],
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <Icon
                      className={`h-6 w-6 ${iconColors[type]}`}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-white">{title}</p>
                    <p className="mt-1 text-sm text-white">{subtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className={classNames(
                        'inline-flex rounded-md text-white hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-fuchsia-800 focus:ring-offset-2',
                        backgroundColors[type],
                      )}
                      onClick={() => {
                        hideToast();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

type ToastType = 'success' | 'error' | 'info';

type ToastContent = {
  title: string;
  subtitle: React.ReactNode;
  type: ToastType;
  autoDismiss?: boolean;
};

type ToastContextProps = {
  showToast: (content: ToastContent) => void;
  hideToast: () => void;
};

const ToastContext = React.createContext<ToastContextProps | undefined>(
  undefined,
);

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = React.useState<ToastContent | null>(null);

  const showToast: ToastContextProps['showToast'] = (content) => {
    setToast(content);
  };

  const hideToast = () => {
    if (toast != null) {
      setToast(null);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {toast && (
        <Toast
          title={toast.title}
          subtitle={toast.subtitle}
          type={toast.type}
          hideToast={hideToast}
          autoDismiss={toast.autoDismiss}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextProps => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
