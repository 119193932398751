import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { classNames } from 'src/dashboard/App';
import { relatedDealsHardcoded } from './hardcodedData';
import RelatedDealDetail from './RelatedDealDetail';
import { RelatedDeal } from './types';

export default function RelatedDeals(props: { relatedDeals: RelatedDeal[] }) {
  const { relatedDeals } = props;
  const [deal, setDeal] = useState<RelatedDeal | null>(
    relatedDealsHardcoded[0],
  );

  return (
    <div className="mt-4 flex flex-row items-start gap-4">
      <div className="flex w-48 flex-col gap-3.5 rounded-xl border border-gray-200 px-3.5 py-3.5">
        <span className="pl-2 text-xs text-gray-600">Deals</span>
        {relatedDeals.map((d, i) => {
          return (
            <RelatedDealsNameRow
              key={i}
              deal={d}
              onClick={() => setDeal(d)}
              current={deal?.id === d.id}
            />
          );
        })}
      </div>
      <RelatedDealDetail deal={deal} />
    </div>
  );
}

function RelatedDealsNameRow(props: {
  deal: RelatedDeal;
  onClick: () => void;
  current: boolean;
}) {
  return (
    <button
      className={classNames(
        props.current
          ? 'rounded-lg bg-gray-50 text-gray-700 hover:text-gray-500'
          : 'text-gray-500 hover:bg-gray-50',
        'p-2 text-sm font-semibold',
      )}
      onClick={props.onClick}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <span
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          title={props.deal.name}
        >
          {props.deal.name}
        </span>
        <div className="ml-1 h-5 w-5 flex-shrink-0">
          <ChevronRightIcon
            className={classNames(props.current ? '' : 'hidden')}
            aria-hidden="true"
          />
        </div>
      </div>
    </button>
  );
}
