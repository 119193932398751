import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import _, { isNil } from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Toggle from 'src/components/Toggle';
import { usePricingFlowContext } from '../../PricingFlow';
import { CurrencyValueType } from '../../types_common/price';
import {
  PenguinPricingFlow,
  PenguinPricingFlowWithProductVolumes,
  PenguinProduct,
  PenguinRampedCV,
  PenguinTier,
  PenguinTieredCV,
} from '../penguin_types';

// #RampableSupportProductCodes
function isRampableSupportProduct(productCode: string | undefined) {
  if (isNil(productCode)) {
    return false;
  }
  const rampableSupportProductCodes = [
    'FEE - SS5',
    'FEE - SS6',
    'FEE - SS7',
    'CRA - SUPB',
    'CRA - SUPP',
    'CRA - SUPR',
  ];
  return rampableSupportProductCodes.includes(productCode);
}

const Settings = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col gap-6 px-4 sm:px-6">{children}</div>
);

const Setting = ({
  onToggle,
  title,
  enabled,
  disabled,
}: {
  onToggle: (enabled: boolean) => void;
  title: string;
  enabled: boolean;
  disabled?: boolean;
}) => (
  <div className="flex items-center justify-between gap-3">
    <div className="font-medium">{title}</div>
    <Toggle enabled={enabled} onToggle={onToggle} disabled={disabled} />
  </div>
);

const SectionHeader = ({ title }: { title: string }) => (
  <div className="my-6 bg-gray-100 px-6 py-2 text-sm uppercase text-gray-500">
    {title}
  </div>
);

const Callout = ({
  title,
  content,
  href,
}: {
  title: string;
  content: string;
  href?: string;
}) => {
  return (
    <div className="flex gap-2 rounded-lg bg-gray-100 px-3 py-3 mt-8">
      <div className="shrink-0">
        <InformationCircleIcon className="h-6 w-6 text-gray-500" />
      </div>
      <div>
        <div className="mb-1 font-medium">{title}</div>
        <div className="mb-4 text-gray-500">{content}</div>
        {href && (
          <a
            href={href}
            className="font-medium"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        )}
      </div>
    </div>
  );
};

interface FormState {
  // productId comes from one of the user's selected products
  // (pricingFlow.products)
  // the boolean indicates whether the product is tiered/ramped or not
  tieredProducts: { [productId: string]: boolean };
  rampedProducts: { [productId: string]: boolean };
}

function generateInitialFormState(pricingFlow: PenguinPricingFlow): FormState {
  const tieredProducts = pricingFlow.products
    .filter((p: PenguinProduct) => {
      // does the product support tiering
      const productPrice = pricingFlow.pricingSheetData.productInfo[p.id];
      return !isNil(productPrice?.tiered);
    })
    .reduce(
      (acc, p) => {
        // does the product currently have tiering enabled
        acc[p.id] =
          pricingFlow.manualQuote?.products?.[p.id]?.type === 'tiered';
        return acc;
      },
      {} as { [productName: string]: boolean },
    );
  const rampedProducts = pricingFlow.products
    .filter((p: PenguinProduct) => {
      // does the product support ramping
      const productPrice = pricingFlow.pricingSheetData.productInfo[p.id];
      return isRampableSupportProduct(productPrice?.ProductCode);
    })
    .reduce(
      (acc, p) => {
        // does the product currently have ramping enabled
        acc[p.id] =
          pricingFlow.manualQuote?.products?.[p.id]?.type === 'ramped';
        return acc;
      },
      {} as { [productName: string]: boolean },
    );

  return {
    tieredProducts,
    rampedProducts,
  };
}

function getMaybeExistingFlatManualQuotePrice(
  flow: PenguinPricingFlow,
  productId: string,
) {
  const manualQuotePrice = flow.manualQuote?.products?.[productId];
  if (
    !isNil(manualQuotePrice) &&
    (manualQuotePrice.type === CurrencyValueType.FLAT ||
      manualQuotePrice.type === CurrencyValueType.PERCENT)
  ) {
    return manualQuotePrice;
  }
  return null;
}
function generateTieredProductQuote(
  flow: PenguinPricingFlowWithProductVolumes,
  productId: string,
): PenguinTieredCV {
  const product = flow.products.find((p) => p.id === productId);
  if (isNil(product)) {
    throw new Error(`Could not find product ${productId}`);
  }

  const existingPrice = getMaybeExistingFlatManualQuotePrice(flow, productId) ??
    flow.recommendedQuote.products[productId] ?? {
      currency: flow.additionalData.quoteCurrency,
      type: CurrencyValueType.FLAT,
      value: 0,
    };

  const productPrice = flow.pricingSheetData.productInfo;
  const stepSize = productPrice[productId]?.pricingTierStepSize ?? 1000;

  const tiers: PenguinTier[] = [
    { minimum: { type: 'count', value: 1 }, currencyValue: existingPrice },
    {
      minimum: { type: 'count', value: stepSize },
      currencyValue: existingPrice,
    },
    {
      minimum: { type: 'count', value: stepSize * 2 },
      currencyValue: existingPrice,
    },
  ];
  return { type: 'tiered', minimumType: 'count', tiers };
}
function generateRampedProductQuote(
  flow: PenguinPricingFlowWithProductVolumes,
  productId: string,
): PenguinRampedCV {
  const product = flow.products.find((p) => p.id === productId);
  if (isNil(product)) {
    throw new Error(`Could not find product ${productId}`);
  }

  const expectedVolume = product.volume;
  if (isNil(expectedVolume)) {
    throw new Error(`Tiered product missing volume ${productId}`);
  }

  const existingPrice = getMaybeExistingFlatManualQuotePrice(flow, productId) ??
    flow.recommendedQuote.products[productId] ?? {
      currency: flow.additionalData.quoteCurrency,
      type: CurrencyValueType.FLAT,
      value: 0,
    };

  const rampValues = _.times(flow.additionalData.subscriptionTerms, () =>
    _.cloneDeep(existingPrice),
  );
  return { type: 'ramped', rampValues };
}

function generateQuoteProductsWithTiersAndRamps(
  pricingFlow: PenguinPricingFlowWithProductVolumes,
  tieredProducts: FormState['tieredProducts'],
  rampedProducts: FormState['rampedProducts'],
): PenguinPricingFlowWithProductVolumes['manualQuote']['products'] {
  const { manualQuote } = pricingFlow;

  const oldQuote = manualQuote?.products ?? {};
  const newQuote: PenguinPricingFlow['manualQuote']['products'] = {};

  for (const productId of Object.keys(tieredProducts)) {
    const isEnabled = tieredProducts[productId];
    if (isEnabled) {
      const oldPrice = oldQuote?.[productId];
      if (oldPrice?.type === 'tiered') {
        // Don't clear tiers if they already exist
        newQuote[productId] = oldPrice;
      } else {
        newQuote[productId] = generateTieredProductQuote(
          pricingFlow,
          productId,
        );
      }
    }
  }
  for (const productId of Object.keys(rampedProducts)) {
    const isEnabled = rampedProducts[productId];
    if (isEnabled) {
      const oldPrice = oldQuote?.[productId];
      if (oldPrice?.type === 'ramped') {
        // Don't clear ramps if they already exist
        newQuote[productId] = oldPrice;
      } else {
        newQuote[productId] = generateRampedProductQuote(
          pricingFlow,
          productId,
        );
      }
    }
  }
  return newQuote;
}

export default function PricingTiersSettings(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { open, setOpen } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  const [formState, setRawFormState] = useState(() =>
    generateInitialFormState(pricingFlow),
  );
  useEffect(() => {
    setRawFormState(generateInitialFormState(pricingFlow));
  }, [pricingFlow]);

  // Deep merge when updating form state to allow merging partial state
  const setFormState = useCallback(
    // Technically this should be a deep partial, but it doesn't matter here
    // because the values are already partial
    (newState: Partial<typeof formState>) => {
      setRawFormState(_.merge({}, formState, newState));
    },
    [formState, setRawFormState],
  );

  const saveChanges = useCallback(() => {
    const { manualQuote } = pricingFlow;

    updateFlow(
      {
        ...pricingFlow,
        manualQuote: {
          ...manualQuote,
          products: generateQuoteProductsWithTiersAndRamps(
            pricingFlow,
            formState.tieredProducts,
            formState.rampedProducts,
          ),
        },
      },
      false,
    );
  }, [pricingFlow, updateFlow, formState]);
  const hasTierableProducts = Object.keys(formState.tieredProducts).length > 0;
  const hasRampableProducts = Object.keys(formState.rampedProducts).length > 0;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="flex text-xl font-medium leading-6 text-gray-900">
                        <div>Tiering and ramping settings</div>
                        <div className="flex grow flex-row-reverse">
                          <button
                            type="button"
                            className="relative rounded-md text-gray-700 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </Dialog.Title>
                    </div>
                    {hasTierableProducts && (
                      <>
                        <SectionHeader title="Tiered Products" />
                        <Settings>
                          {Object.keys(formState.tieredProducts).map(
                            (productId) => {
                              const product = pricingFlow.products.find(
                                (p) => p.id === productId,
                              );
                              if (isNil(product)) {
                                throw new Error(
                                  `Could not find product ${productId}`,
                                );
                              }
                              const isEnabled =
                                formState.tieredProducts[productId];
                              return (
                                <Setting
                                  key={productId}
                                  title={product.name}
                                  enabled={isEnabled}
                                  disabled={!editMode}
                                  onToggle={() => {
                                    setFormState({
                                      tieredProducts: {
                                        [productId]: !isEnabled,
                                      },
                                    });
                                  }}
                                />
                              );
                            },
                          )}
                        </Settings>
                      </>
                    )}
                    {hasRampableProducts && (
                      <>
                        <SectionHeader title="Ramped Products" />
                        <Settings>
                          {Object.keys(formState.rampedProducts).map(
                            (productId) => {
                              const product = pricingFlow.products.find(
                                (p) => p.id === productId,
                              );
                              if (isNil(product)) {
                                throw new Error(
                                  `Could not find product ${productId}`,
                                );
                              }
                              const isEnabled =
                                formState.rampedProducts[productId];
                              return (
                                <Setting
                                  key={productId}
                                  title={product.name}
                                  enabled={isEnabled}
                                  disabled={!editMode}
                                  onToggle={() => {
                                    setFormState({
                                      rampedProducts: {
                                        [productId]: !isEnabled,
                                      },
                                    });
                                  }}
                                />
                              );
                            },
                          )}
                        </Settings>
                      </>
                    )}
                    <Settings>
                      <Callout
                        title={'How to edit tiers and ramps'}
                        content="Enabling the tiered or ramped option grants you the ability to make direct edits to tiers or ramps from the pricing table."
                      />
                      <div className="flex flex-row items-center gap-3">
                        <button
                          type="button"
                          className="col-span-full flex-1 justify-center rounded-lg border border-gray-200 bg-white px-5 py-2 font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
                          onClick={() => {
                            setOpen(false);
                            // Reset form
                            setFormState(generateInitialFormState(pricingFlow));
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="col-span-full flex-1 justify-center rounded-lg border border-fuchsia-900 bg-fuchsia-900 px-5 py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
                          onClick={() => {
                            saveChanges();
                            setOpen(false);
                          }}
                          disabled={!editMode}
                        >
                          Save
                        </button>
                      </div>
                    </Settings>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
