import { datadogRum } from '@datadog/browser-rum';
import {
  ChartBarIcon,
  IdentificationIcon,
  ListBulletIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import dayjs, { Dayjs } from 'dayjs';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import api from 'src/api';
import AppPageHeader from 'src/components/AppPageHeader';
import GraphContainer from 'src/components/graphs/GraphContainer';
import { FullscreenSpinner } from 'src/components/Loading';
import { classNames } from '../App';
import { formatCurrencyValue } from '../PricingFlow/Alpaca/alpaca_utils';
import {
  PenguinCV,
  PenguinEffectiveContractualMin,
} from '../PricingFlow/Penguin/penguin_types';
import {
  CurrencyValuePercent,
  CurrencyValueType,
} from '../PricingFlow/types_common/price';

const EXPERIMENT_START_DATE = new Date('2024-09-09');
const EXPERIMENT_END_DATE = new Date('2024-10-06');

// #ExperimentTrafficDataTypes
type ExperimentGroup = 'control' | 'variant';
type ProductPricingData = {
  productName: string;
  // the following are null if there was no dealops pricing flow for the quote,
  // or the product was not present in the pricing flow (e.g. it was added in
  // sfdc after the dealops quote was created)
  productId: string | null;
  recommendedPrice: PenguinCV | null;
  quotePrice: PenguinCV | null;
};
type Status = 'Draft' | 'Pending' | 'Closed Won' | 'Closed Lost';
type TrafficDataPoint = {
  createdTime: Date;
  opportunityName: string;
  repName: string;
  status: Status;
  isClosed: boolean;
  isWon: boolean;
  group: ExperimentGroup;
  // null means that there was no dealops pricing flow
  pricingFlowId: string | null;
  monthlyMin: PenguinEffectiveContractualMin | null;
  // key is productName
  pricingData: Record<string, ProductPricingData | undefined>;
};

export default function ExperimentsPage() {
  // null means its loading
  const [trafficData, setTrafficData] = useState<TrafficDataPoint[] | null>(
    null,
  );
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get('experiment_traffic_data', {
          startDate: EXPERIMENT_START_DATE.toISOString(),
          endDate: EXPERIMENT_END_DATE.toISOString(),
        });
        if (response.data) {
          setTrafficData(
            response.data
              .filter((dataPointRaw: any) => {
                // Filter for createdTime is >= 2024-09-09 11:30 am ET, which is 1725895800 in UTC
                const parsedCreatedTime = new Date(dataPointRaw.createdTime);
                const etTime = new Date(Date.UTC(2024, 8, 9, 11 + 4, 30)); // ET is UTC-4 in September
                return parsedCreatedTime.getTime() >= etTime.getTime();
              })
              .map((dataPointRaw: any) => {
                return {
                  ...dataPointRaw,
                  createdTime: new Date(dataPointRaw.createdTime),
                };
              })
              .sort((dpa: TrafficDataPoint, dpb: TrafficDataPoint) => {
                return dpa.createdTime.getTime() - dpb.createdTime.getTime();
              }),
          );
        }
      } catch (error) {
        datadogRum.addError(error);
      }
    }

    fetchData();
  }, []); // Dependency array is empty to run only once on mount
  return (
    <>
      <AppPageHeader title={'Experiment Dashboard'} />
      <div className="pt-1 px-4 sm:px-6 lg:px-8 flex flex-col gap-y-8 mt-8 pb-10">
        <Overview />
        {!isNil(trafficData) ? (
          <>
            <SummaryMetrics trafficData={trafficData} />
            <TrafficChart trafficData={trafficData} />
            <TrafficTable trafficData={trafficData} />
          </>
        ) : (
          <FullscreenSpinner />
        )}
      </div>
    </>
  );
}

function Overview() {
  const content: { title: string; description: React.ReactNode }[] = [
    {
      title: 'Name',
      description: 'Raise suggested prices for Balance and Transactions',
    },
    {
      title: 'Start date',
      description: 'Sep 9, 2024',
    },
    {
      title: 'End date',
      description: 'Oct 6, 2024',
    },
    {
      title: 'Plan doc',
      description: (
        <a
          href="https://docs.google.com/document/d/1hhqgmt9WkNl279qoa8tpOHUw4YZMlyDE5O4S5QYUM4k/edit?usp=sharing"
          className="text-blue-500 hover:text-blue-700 underline"
          target="_blank"
          rel="noreferrer"
        >
          https://docs.google.com/document/d/1hhqgmt9WkNl279qoa8tpOHUw4YZMlyDE5O4S5QYUM4k
        </a>
      ),
    },
    {
      title: 'Description',
      description:
        'This is a sequential A/B test which tests raising the suggested prices for Balance (+25%) and Transactions (+15%) capped at self-serve prices, for new business, USD currency, CPQ Pricebook opportunities.',
    },
  ];
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <IdentificationIcon className="mr-2 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">Overview</div>
        </div>
      }
      className="flex grow"
    >
      <table className="w-full">
        <tbody>
          {content.map(({ title, description }) => {
            return (
              <tr>
                <td className="font-bold pr-4 align-top">{title}</td>
                <td className="align-top">{description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </GraphContainer>
  );
}

function averageCurrencyValue(cvsRaw: (PenguinCV | null | undefined)[]) {
  const cvs = cvsRaw.filter((cv): cv is PenguinCV => !isNil(cv));
  if (cvs.length === 0) {
    return null;
  }
  if (
    !cvs.every((cv) => {
      if (cv.type !== cvs[0].type) {
        return false;
      }
      if (
        cvs[0].type === CurrencyValueType.FLAT &&
        cv.type === CurrencyValueType.FLAT
      ) {
        if (cv.currency !== cvs[0].currency) {
          return false;
        }
      }
      return true;
    })
  ) {
    datadogRum.addError(
      `to take an average, currency values must all be the same type and currency`,
      { cvs },
    );
    return null;
  }
  return { ...cvs[0], value: _.mean(cvs.map((cv) => cv.value)) };
}
function almostEqual(v1: number | undefined, v2: number | undefined) {
  if (v1 === v2) {
    return true;
  }
  if (!isNil(v1) && !isNil(v2)) {
    return Math.abs(v1 - v2) < 0.00001;
  }
  return false;
}
interface SummaryMetricsProps {
  trafficData: TrafficDataPoint[];
}
function SummaryMetrics(props: SummaryMetricsProps) {
  const controlData = props.trafficData.filter(
    (data) => data.group === 'control',
  );
  const variantData = props.trafficData.filter(
    (data) => data.group === 'variant',
  );
  function winRate(data: TrafficDataPoint[]): CurrencyValuePercent | null {
    const numWins = data.filter((d) => d.isClosed && d.isWon).length;
    const totalClosed = data.filter((d) => d.isClosed).length;
    if (totalClosed === 0) {
      return null;
    }
    return {
      type: CurrencyValueType.PERCENT,
      value: (100 * numWins) / totalClosed,
    };
  }
  function adherence(
    productName: string,
    data: TrafficDataPoint[],
  ): CurrencyValuePercent | null {
    const pricingData = data
      .map((d) =>
        Object.values(d.pricingData).find(
          (pd) => pd?.productName === productName,
        ),
      )
      .filter((pd): pd is ProductPricingData => !isNil(pd));
    if (pricingData.length === 0) {
      return null;
    }
    const numAdhered = pricingData.filter((pd) =>
      almostEqual(pd.quotePrice?.value, pd.recommendedPrice?.value),
    ).length;
    return {
      type: CurrencyValueType.PERCENT,
      value: (100 * numAdhered) / pricingData.length,
    };
  }
  const quoteSubmissionRate = ((): CurrencyValuePercent | null => {
    const pricingFlowsSubmitted = new Set(
      props.trafficData
        .filter((d) => d.status !== 'Draft')
        .map((d) => d.pricingFlowId),
    ).size;
    const allPricingFlows = new Set(
      props.trafficData.map((d) => d.pricingFlowId),
    ).size;
    if (allPricingFlows === 0) {
      return null;
    }
    return {
      type: CurrencyValueType.PERCENT,
      value: (100 * pricingFlowsSubmitted) / allPricingFlows,
    };
  })();

  const thProps = {
    scope: 'col',
    className:
      'min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap',
  };
  const tdProps = (dataIsNil: boolean) => {
    return {
      className: classNames(
        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 border-b border-b-slate-300',
        dataIsNil ? 'text-gray-400' : 'text-gray-900',
      ),
    };
  };

  type Metric = {
    name: string;
    historicalValue: React.ReactNode;
    controlValue: string;
    variantValue: string;
  };
  const primaryMetrics: Metric[] = [
    {
      name: 'ASP: Balance',
      historicalValue: (
        <span>
          See charts from the{' '}
          <a
            href="https://docs.google.com/document/d/1hhqgmt9WkNl279qoa8tpOHUw4YZMlyDE5O4S5QYUM4k/edit#heading=h.1a6mj68oja90"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            experiment proposal
          </a>
        </span>
      ),
      controlValue: formatCurrencyValue(
        averageCurrencyValue(
          controlData.map((data) => data.pricingData['Balance']?.quotePrice),
        ),
        3,
      ),
      variantValue: formatCurrencyValue(
        averageCurrencyValue(
          variantData.map((data) => data.pricingData['Balance']?.quotePrice),
        ),
        3,
      ),
    },
    {
      name: 'ASP: Transactions',
      historicalValue: (
        <span>
          See charts from the{' '}
          <a
            href="https://docs.google.com/document/d/1hhqgmt9WkNl279qoa8tpOHUw4YZMlyDE5O4S5QYUM4k/edit#heading=h.1a6mj68oja90"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            experiment proposal
          </a>
        </span>
      ),
      controlValue: formatCurrencyValue(
        averageCurrencyValue(
          controlData.map(
            (data) => data.pricingData['Transactions']?.quotePrice,
          ),
        ),
        3,
      ),
      variantValue: formatCurrencyValue(
        averageCurrencyValue(
          variantData.map(
            (data) => data.pricingData['Transactions']?.quotePrice,
          ),
        ),
        3,
      ),
    },
  ];
  const guardrailMetrics: Metric[] = [
    {
      name: 'Quote creation volume (Dealops vs CPQ)',
      historicalValue: `${formatCurrencyValue(
        {
          type: CurrencyValueType.PERCENT,
          value: 48,
        },
        0,
      )} in Dealops`,
      controlValue: `${formatCurrencyValue(
        {
          type: 'count',
          value: props.trafficData.filter((d) => isNil(d.pricingFlowId)).length,
        },
        0,
      )} in CPQ`,
      variantValue: `${formatCurrencyValue(
        {
          type: 'count',
          value: props.trafficData.filter((d) => !isNil(d.pricingFlowId))
            .length,
        },
        0,
      )} in Dealops`,
    },
    {
      name: 'Dealops quote submission rate',
      historicalValue: formatCurrencyValue(
        {
          type: CurrencyValueType.PERCENT,
          value: 62,
        },
        0,
      ),
      controlValue: formatCurrencyValue(null),
      variantValue: formatCurrencyValue(quoteSubmissionRate, 0),
    },
    {
      name: 'Suggested price adherence: Balance',
      historicalValue: formatCurrencyValue(
        {
          type: CurrencyValueType.PERCENT,
          value: 43,
        },
        0,
      ),
      controlValue: formatCurrencyValue(adherence('Balance', controlData), 0),
      variantValue: formatCurrencyValue(adherence('Balance', variantData), 0),
    },
    {
      name: 'Suggested price adherence: Transactions',
      historicalValue: formatCurrencyValue(
        {
          type: CurrencyValueType.PERCENT,
          value: 23,
        },
        0,
      ),
      controlValue: formatCurrencyValue(
        adherence('Transactions', controlData),
        0,
      ),
      variantValue: formatCurrencyValue(
        adherence('Transactions', variantData),
        0,
      ),
    },
    {
      name: 'Adjusted win rate: Balance',
      historicalValue: formatCurrencyValue(
        {
          type: CurrencyValueType.PERCENT,
          value: 76,
        },
        0,
      ),
      controlValue: formatCurrencyValue(winRate(controlData), 0),
      variantValue: formatCurrencyValue(winRate(variantData), 0),
    },
    {
      name: 'Adjusted win rate: Transactions',
      historicalValue: formatCurrencyValue(
        {
          type: CurrencyValueType.PERCENT,
          value: 80,
        },
        0,
      ),
      controlValue: formatCurrencyValue(winRate(controlData), 0),
      variantValue: formatCurrencyValue(winRate(variantData), 0),
    },
  ];
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <StarIcon className="mr-2 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">Metrics</div>
        </div>
      }
    >
      <div className="overflow-auto">
        <table className="h-full w-full">
          <thead className="bg-gray-50">
            <tr>
              <th {...thProps}>Primary metric</th>
              <th {...thProps}>Historical Benchmark</th>
              <th {...thProps}>Control (CPQ quotes)</th>
              <th {...thProps}>Experiment (Dealops quotes)</th>
            </tr>
          </thead>
          <tbody>
            {primaryMetrics.map((metric) => {
              return (
                <tr>
                  <td {...tdProps(false)}>{metric.name}</td>
                  <td {...tdProps(false)}>{metric.historicalValue}</td>
                  <td {...tdProps(metric.controlValue === 'N/A')}>
                    {metric.controlValue}
                  </td>
                  <td {...tdProps(metric.variantValue === 'N/A')}>
                    {metric.variantValue}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <thead className="bg-gray-50">
            <tr>
              <th {...thProps}>Guardrail metrics</th>
              <th {...thProps}>Historical Benchmark</th>
              <th {...thProps}>Control (CPQ quotes)</th>
              <th {...thProps}>Experiment (Dealops quotes)</th>
            </tr>
          </thead>
          <tbody>
            {guardrailMetrics.map((metric) => {
              return (
                <tr>
                  <td {...tdProps(false)}>{metric.name}</td>
                  <td {...tdProps(false)}>{metric.historicalValue}</td>
                  <td {...tdProps(metric.controlValue === 'N/A')}>
                    {metric.controlValue}
                  </td>
                  <td {...tdProps(metric.variantValue === 'N/A')}>
                    {metric.variantValue}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </GraphContainer>
  );
}
interface TrafficChartProps {
  trafficData: TrafficDataPoint[];
}
function TrafficChart(props: TrafficChartProps) {
  interface RechartDatapoint {
    date: string;
    controlCount: Number;
    variantCount: number;
  }
  function dateToDayString(date: Date | Dayjs) {
    return date.toISOString().split('T')[0];
  }
  const allDates = (() => {
    const startDate = dayjs(EXPERIMENT_START_DATE);
    const endDate = dayjs(EXPERIMENT_END_DATE);
    const allDays = [];
    let currentDate = startDate;
    while (currentDate.isBefore(endDate)) {
      allDays.push(dateToDayString(currentDate));
      currentDate = currentDate.add(1, 'day');
    }
    allDays.push(dateToDayString(currentDate));
    return allDays;
  })();
  const dateToTraffic = props.trafficData.reduce(
    (acc: { [date: string]: TrafficDataPoint[] }, trafficDataPoint) => {
      const date = dateToDayString(trafficDataPoint.createdTime);
      if (date in acc) {
        acc[date].push(trafficDataPoint);
      } else {
        acc[date] = [trafficDataPoint];
      }
      return acc;
    },
    {},
  );
  const data: RechartDatapoint[] = allDates.map((date) => {
    return {
      date,
      controlCount:
        dateToTraffic[date]?.filter((dp) => dp.group === 'control').length ?? 0,
      variantCount:
        dateToTraffic[date]?.filter((dp) => dp.group === 'variant').length ?? 0,
    };
  });
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <ChartBarIcon className="mr-2 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">Traffic by day</div>
        </div>
      }
    >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 70 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="date" angle={-45} dy={40} dx={-30} />
          <YAxis
            tickFormatter={(tick) => {
              const tickValue = Number(tick);
              if (Number.isInteger(tickValue) && tickValue > 0) {
                return tickValue.toString();
              }
              return '';
            }}
          />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <ReferenceLine
            x={dateToDayString(new Date())}
            stroke="red"
            label="Today"
          />
          <Bar dataKey="controlCount" fill="#8884d8" name="Control" />
          <Bar dataKey="variantCount" fill="#82ca9d" name="Experiment" />
        </BarChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
}
interface TrafficTableProps {
  trafficData: TrafficDataPoint[];
}
type TrafficTableCommonHeader = { id: keyof TrafficDataPoint; name: string };
function TrafficTable(props: TrafficTableProps) {
  const trafficTableCommonHeaders: TrafficTableCommonHeader[] = [
    { id: 'createdTime', name: 'Created time' },
    { id: 'pricingFlowId', name: 'Pricing flow ID' },
    { id: 'opportunityName', name: 'Opportunity name' },
    { id: 'repName', name: 'Rep name' },
    { id: 'status', name: 'Status' },
    { id: 'monthlyMin', name: 'Monthly minimum' },
    { id: 'group', name: 'Group' },
  ];
  const productsInExperiment = ['Balance', 'Transactions'];

  const thProps = {
    scope: 'col',
    className:
      'min-w-[160px] sticky top-0 z-10 hidden border-b bg-gray-50 px-3 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap',
  };
  const tdProps = (dataIsNil: boolean) => {
    return {
      className: classNames(
        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 border-b border-b-slate-300',
        dataIsNil ? 'text-gray-400' : 'text-gray-900',
      ),
    };
  };
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <ListBulletIcon className="mr-2 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">All traffic</div>
        </div>
      }
    >
      <div className="overflow-auto">
        <table className="h-full w-full">
          <thead className="bg-gray-50">
            <tr>
              {trafficTableCommonHeaders.map((header) => {
                return <th {...thProps}>{header.name}</th>;
              })}
              {productsInExperiment.flatMap((productName) => {
                return [
                  <th {...thProps}>{productName} suggested price</th>,
                  <th {...thProps}>{productName} quote price</th>,
                ];
              })}
            </tr>
          </thead>
          <tbody>
            {props.trafficData.map((trafficDataPoint) => {
              return (
                <tr>
                  {trafficTableCommonHeaders.map((header) => {
                    const data = (() => {
                      switch (header.id) {
                        case 'createdTime':
                          return trafficDataPoint.createdTime.toDateString();
                        case 'pricingFlowId':
                          return (
                            trafficDataPoint[header.id] ??
                            'N/A - CPQ created quote'
                          );
                        case 'opportunityName':
                        case 'repName':
                        case 'status':
                        case 'group':
                          return trafficDataPoint[header.id];
                        case 'monthlyMin':
                          return formatCurrencyValue(
                            trafficDataPoint.monthlyMin,
                          );
                        case 'pricingData':
                        default:
                          datadogRum.addError(
                            `didn't expect to render table data for ${header.id}`,
                          );
                          return null;
                      }
                    })();
                    return (
                      <td {...tdProps(isNil(data) || data === 'N/A')}>
                        {data}
                      </td>
                    );
                  })}
                  {productsInExperiment.flatMap((productName) => {
                    const pricingData =
                      trafficDataPoint.pricingData[productName];
                    return [
                      <td {...tdProps(isNil(pricingData?.recommendedPrice))}>
                        {formatCurrencyValue(pricingData?.recommendedPrice, 3)}
                      </td>,
                      <td {...tdProps(isNil(pricingData?.quotePrice))}>
                        {formatCurrencyValue(pricingData?.quotePrice, 3)}
                      </td>,
                    ];
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </GraphContainer>
  );
}
