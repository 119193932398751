import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from 'src/api';
import { FullscreenSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { OpportunityCommon, PricingFlowCommon } from '../PricingFlow/types';

const PreviousPricingFlows = () => {
  const { sfdcOppId } = useParams();
  const location = useLocation();
  const opportunityFromRouting: OpportunityCommon | null =
    location.state?.opportunity ?? null;
  const prevPricingFlowsFromRouting: PricingFlowCommon[] | null =
    location.state?.prevPricingFlows ?? null;

  const navigate = useNavigate();
  const [showAll, setShowAll] = useState<Boolean>(false);
  // null means it's loading
  const [prevPricingFlows, setPrevPricingFlows] = useState<
    PricingFlowCommon[] | null
  >(prevPricingFlowsFromRouting);
  const [opportunity, setOpportunity] = useState<OpportunityCommon | null>(
    opportunityFromRouting,
  );
  const [isLoadingNewPricingFlow, setIsLoadingNewPricingFlow] =
    useState<boolean>(false);
  const { showToast } = useToast();
  const createNewPricingFlowAndNavigate = async (
    flowToCloneId: string | null,
  ) => {
    setIsLoadingNewPricingFlow(true);
    const newPricingFlow = (
      await api.post('pricingFlow', {
        sfdcOpportunityId: sfdcOppId,
        cloneFromPricingFlowId: flowToCloneId,
      })
    ).data;
    navigate(`/app/opportunity/${sfdcOppId}/pricingflow/${newPricingFlow.id}`);
  };
  useEffect(() => {
    if (isNil(prevPricingFlows)) {
      api.get('pricingFlows', { matchingPricingFlowId: null }).then((res) => {
        setPrevPricingFlows(res.data);
      });
    }
    if (isNil(opportunity)) {
      api.get('opportunity', { sfdcOpportunityId: sfdcOppId }).then((res) => {
        setOpportunity(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (!isNil(prevPricingFlows) && prevPricingFlows.length === 0) {
      showToast({
        title: 'No previous pricing flows found, creating a new one',
        subtitle: '',
        type: 'info',
      });
      createNewPricingFlowAndNavigate(null);
    }
  }, [prevPricingFlows]);

  if (
    isNil(opportunity) ||
    isNil(prevPricingFlows) ||
    isLoadingNewPricingFlow
  ) {
    return <FullscreenSpinner />;
  }

  const initialNumberOfQuotes = 3;
  const flowsToShow = showAll
    ? prevPricingFlows
    : prevPricingFlows.slice(0, initialNumberOfQuotes);
  return (
    <div className="mb-20 mt-10 w-[500px] divide-y rounded-2xl border border-gray-200  text-sm">
      <div className="flex flex-col items-center justify-center gap-1 px-4 py-4">
        <span className="text-xs text-gray-500">Opportunity #{sfdcOppId}</span>
        <span className="text-center text-2xl text-gray-900">
          {opportunity.sfdcOpportunityName}
        </span>
      </div>
      <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
        PREVIOUS QUOTES
      </div>

      {flowsToShow.map((flow: PricingFlowCommon, index) => {
        const flowIndexInItsOpportunity = _.findIndex(
          flow.opportunity.pricingFlows,
          (pf) => pf.id === flow.id,
        );
        const title = `${flow.opportunity.sfdcOpportunityName} (Option ${(flowIndexInItsOpportunity ?? 0) + 1})`;
        return (
          <div
            key={index}
            className="flex items-center justify-between gap-12 px-4 py-4"
          >
            <div className="flex-1 truncate">
              <a
                className="hover:text-fuchsia-900"
                href={`/app/opportunity/${flow.opportunity.sfdcOpportunityId}/pricingFlow/${flow.id}`}
                target="_blank"
                rel="noreferrer"
                title={title}
              >
                {title}
              </a>
            </div>
            <div>
              <button
                onClick={async () => {
                  createNewPricingFlowAndNavigate(flow.id);
                }}
                className="flex inline-flex items-center justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
              >
                Use as template
                <DocumentDuplicateIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        );
      })}

      {!showAll && initialNumberOfQuotes < prevPricingFlows.length && (
        <div className="flex justify-center py-4">
          <button
            onClick={() => setShowAll(true)}
            className="inline-flex items-center justify-center rounded-lg border border-gray-200 px-5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Show all previous quotes ({prevPricingFlows.length})
          </button>
        </div>
      )}

      <div className="flex items-center gap-12 border-t border-gray-200 px-4 py-4">
        <button
          // navigate back to the opportunity detail page
          onClick={() =>
            navigate(`/app/opportunity/${opportunity.sfdcOpportunityId}`)
          }
          className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          role="link"
          tabIndex={0}
        >
          Back
        </button>
        <div className="ml-auto">
          <button
            onClick={async () => {
              createNewPricingFlowAndNavigate(null);
            }}
            className=" rounded-lg bg-fuchsia-900 px-5 py-2 text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Start from scratch
          </button>
        </div>
      </div>
    </div>
  );
};

export default function ClonePricingFlowPage() {
  return (
    <div
      className="relative flex flex-col items-center justify-center bg-repeat-x"
      style={{ backgroundImage: `url(${backgroundLines})` }}
    >
      {/* Dealops target logo */}
      <div className="mt-20 h-24 w-24">
        <img
          className="absolute h-24 w-24"
          src={fadedCircleBg}
          alt="faded circle"
        />
        <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
          <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
        </div>
      </div>

      {/** Heading */}
      <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
        Use one of your previous quotes as a template or start from scratch
      </h1>
      <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
        If you select one of your previous quotes as a template, we'll copy over
        product selection and manually configured prices.
      </p>

      {/* Opportunity details */}
      <PreviousPricingFlows />
    </div>
  );
}
