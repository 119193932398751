import { Combobox, Popover, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  AlpacaTFxBucket,
  AlpacaTFxBucketProduct,
  AlpacaTFxProductPrice,
  AlpacaTFxSingleBuyCurrencyProduct,
  ALPACA_FX_BUCKETS,
} from '../alpaca_types';

type AlpacaAddBucketButtonProps = {
  currencies: AlpacaTFxProductPrice[];
  onSelectBucket: (
    bucketOrCurrency: AlpacaTFxBucket | AlpacaTFxProductPrice,
  ) => void;
  products: (AlpacaTFxBucketProduct | AlpacaTFxSingleBuyCurrencyProduct)[];
};
export default function AlpacaAddBucketButton(
  props: AlpacaAddBucketButtonProps,
) {
  const { currencies, onSelectBucket, products } = props;
  const [query, setQuery] = useState('');
  const { editMode } = usePricingFlowContext();

  const filteredSingleCurrencies = currencies
    .filter((currency) => {
      if (
        // already selected
        products.some((p) => {
          return p.subCategory === 'single_buy' && p.currencyId === currency.id;
        })
      ) {
        return false;
      }
      return currency.name.toLowerCase().includes(query);
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const filteredBuckets = ALPACA_FX_BUCKETS.filter((bucket) => {
    if (
      // already selected
      products.some((p) => {
        return p.subCategory === 'bucket' && p.bucket === bucket;
      })
    ) {
      return false;
    }
    return bucket.toLowerCase().includes(query);
  });

  return (
    <Popover className="relative">
      <Popover.Button
        className={classNames(
          'flex items-center block rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800',
          !editMode && 'cursor-not-allowed opacity-50',
        )}
        disabled={!editMode}
      >
        <PlusIcon className="h-4 w-4 mr-2" />
        <span>Add a bucket or single buy currency</span>
      </Popover.Button>

      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setQuery('')}
      >
        <Popover.Panel className="absolute bg-white ring-1 ring-gray-900/5 drop-shadow-xl rounded-md z-30 ">
          <Combobox
            onChange={(
              bucketOrCurrency: AlpacaTFxBucket | AlpacaTFxProductPrice,
            ) => {
              onSelectBucket(bucketOrCurrency);
            }}
          >
            {/* search box */}
            <div className="px-4">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                autoFocus
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder:text-gray-400 focus:ring-0 text-sm font-medium"
                placeholder="Search..."
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setQuery(event.target.value)
                }
                displayValue={() => query}
              />
            </div>

            {/* options */}
            {filteredSingleCurrencies.length + filteredBuckets.length > 0 && (
              <Combobox.Options
                static
                className="max-h-80 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                as="ul"
              >
                {filteredBuckets.length > 0 && (
                  <li key={'buckets'}>
                    <h2 className="bg-gray-100 px-4 py-2 text-xs font-semibold text-gray-900 text-left">
                      Buckets
                    </h2>
                    <ul className="text-sm text-gray-800">
                      {filteredBuckets.map((bucket) => (
                        <Combobox.Option
                          key={bucket}
                          value={bucket}
                          className={({ active }) =>
                            classNames(
                              'cursor-default select-none px-4 py-2 border-t text-sm text-gray-800 font-medium text-left',
                              active && 'bg-fuchsia-900 text-white',
                            )
                          }
                        >
                          {bucket}
                        </Combobox.Option>
                      ))}
                    </ul>
                  </li>
                )}
                {filteredSingleCurrencies.length > 0 && (
                  <li key={'buy_currencies'}>
                    <h2 className="bg-gray-100 px-4 py-2 text-xs font-semibold text-gray-900 text-left">
                      Single buy currencies
                    </h2>
                    <ul className="text-sm text-gray-800">
                      {filteredSingleCurrencies.map((currency) => (
                        <Combobox.Option
                          key={currency.id}
                          value={currency}
                          className={({ active }) =>
                            classNames(
                              'cursor-default select-none px-4 py-2 border-t text-sm text-gray-800 font-medium text-left',
                              active && 'bg-fuchsia-900 text-white',
                            )
                          }
                        >
                          {currency.name}
                        </Combobox.Option>
                      ))}
                    </ul>
                  </li>
                )}
              </Combobox.Options>
            )}

            {query !== '' &&
              filteredBuckets.length + filteredSingleCurrencies.length ===
                0 && (
                <p className="p-4 text-sm text-gray-500">No options found</p>
              )}
          </Combobox>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
