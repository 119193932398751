import { UserPlusIcon } from '@heroicons/react/24/outline';
import * as d3 from 'd3';
import { ReactNode, useEffect, useRef } from 'react';
import { useTooltip } from '../MouseTooltip';
import GraphContainer, { RenderChildWithSize } from './GraphContainer';

type Data = {
  label: string;
  value: number;
  color: string;
};

export function Gauge<GaugeData extends Data>({
  width,
  data,
  formatTooltip,
}: {
  width: number;
  data: GaugeData[];
  formatTooltip?: (arg0: GaugeData) => ReactNode;
}) {
  const { setTooltipText } = useTooltip();
  const svgRef = useRef<SVGSVGElement>(null);
  const height = Math.round(width / 2);

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);
    // Clear existing chart
    svg.selectAll('*').remove();

    const radius = Math.min(width, height);

    svg
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2, -height, width, height].join(' '))
      .attr('style', 'max-width: 100%; height: auto;');

    const arc = d3
      .arc()
      .innerRadius(radius * 0.8)
      .outerRadius(radius - 1);

    const pie = d3
      .pie<GaugeData>()
      .padAngle(3 / radius)
      .sort(null)
      .value((d) => d.value)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2);

    svg
      .append('g')
      .selectAll('path')
      .data(pie(data))
      .join('path')
      .attr('fill', (d) => d.data.color)
      .attr('stroke-width', 4)
      .attr('d', arc as any)
      .on('mouseover', function (event, d) {
        if (formatTooltip !== undefined) {
          d3.select(this).attr('stroke', d.data.color);
          setTooltipText(formatTooltip(d.data));
        }
      })
      .on('mouseout', function (event, d) {
        d3.select(this).attr('stroke', null);
        setTooltipText(null);
      });
  }, [width, height]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
}

function ColoredCircleWithNumber({
  color,
  number,
  textColor,
}: {
  color: string;
  textColor: string;
  number: number;
}) {
  return (
    <div
      className={`flex h-8 w-8 items-center justify-center rounded-full border-2 text-white `}
      style={{ background: color, color: textColor, borderColor: textColor }}
    >
      <span className={`text-lg font-bold`}>{number}</span>
    </div>
  );
}

export function GaugeChart({
  data,
  title,
}: {
  data: (Data & { textColor: string })[];
  title: ReactNode;
}) {
  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="relative flex w-full flex-1 shrink flex-col items-center justify-center self-stretch overflow-hidden pb-5">
        <div className="pointer-events-none absolute flex h-full w-full items-end justify-center">
          {title}
        </div>
        <RenderChildWithSize
          className="flex w-full flex-1 items-center justify-center overflow-hidden px-8"
          childFunction={(width, height) => {
            const gaugeWidth = Math.min(width, height * 2);
            return (
              <Gauge
                width={gaugeWidth}
                data={data}
                formatTooltip={(d) => d.value}
              />
            );
          }}
        />
      </div>

      <div className="h-1 w-full shrink-0 border-b border-gray-200"></div>

      <div className="flex w-full shrink-0 items-stretch justify-around pt-4">
        {data.map((d, i) => {
          return (
            <>
              {i !== 0 && <div className="w-0 border-r border-gray-300"></div>}
              <div
                className="flex flex-col items-center"
                style={{ flexBasis: `${100 / data.length}%` }}
              >
                <ColoredCircleWithNumber
                  color={d.color}
                  textColor={d.textColor}
                  number={i + 1}
                />
                <div className="text-nowrap text-gray-600">{d.label}</div>
                <div className="font-semibold text-black">{d.value}</div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export const GAUGE_CHART_EXAMPLE_ARGS = {
  data: [
    { label: 'Manager', value: 200, color: '#73E2A3', textColor: '#099250' },
    {
      label: 'Head',
      value: 100,
      color: '#8098F9',
      textColor: '#444CE7',
    },
    {
      label: 'Deal desk',
      value: 300,
      color: '#FF9C66',
      textColor: '#E62E05',
    },
    { label: 'Finance', value: 200, color: '#FDA29B', textColor: '#B42318' },
  ],
  title: (
    <div className="flex flex-col items-center">
      <div className="text-xl text-gray-500">AVERAGE</div>
      <div className="text-center text-3xl">
        <p>Deal </p>Desk
      </div>
      <div></div>
    </div>
  ),
};

export function GaugeChartExample({
  data,
  title,
}: {
  data: (Data & { textColor: string })[];
  title: ReactNode;
}) {
  return (
    <GraphContainer
      className="h-full"
      header={
        <div className="flex w-full items-center text-gray-900">
          <div className="mr-1 w-6 stroke-gray-600">
            <UserPlusIcon className="text-gray-800" />
          </div>{' '}
          <div className="font-semibold text-gray-900">Approval Level</div>
        </div>
      }
    >
      <GaugeChart data={data} title={title} />
    </GraphContainer>
  );
}
