import { datadogRum } from '@datadog/browser-rum';
import {
  LifebuoyIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import { ChartBarIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import api from 'src/api';
import Badge from 'src/components/Badge';
import GraphContainer, {
  RenderChildWithSize,
} from 'src/components/graphs/GraphContainer';
import { Legend } from 'src/components/graphs/helpers';
import LinePlot, { Line } from 'src/components/graphs/LinePlot';
import Toggle from 'src/components/Toggle';
import { doesSearchQueryMatch } from 'src/utils';
import { Organization, User } from '../../types';
import { PricingCurve } from '../PricingFlow/types';

// ##PricingCurveWithStatsType
type PricingCurveWithStats = {
  curve: PricingCurve & { product: { name: string } };
  analytics: {
    usagePercentage: number;
    usageCount: number;
    avgMarkup: number;
    avgMarkupPercent: number;
    dealsWon: number;
    dealsLost: number;
    revenueUplift: number;
  };
};
interface PricingCurvesProps {
  user: User;
  organization: Organization;
}

function nameToColor(name: string): string {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const color = '#' + (hash & 0xffffff).toString(16).padStart(6, '0');
  return color;
}

export default function PricingCurves(props: PricingCurvesProps) {
  const { user, organization } = props;
  const [curvesWithStats, setCurvesWithStats] = useState<
    PricingCurveWithStats[]
  >([]);
  const shouldRenderRealData = organization.name?.includes('Plaid') ?? false;

  useEffect(() => {
    async function fetchData() {
      try {
        if (shouldRenderRealData) {
          const response = await api.get('pricingCurves');
          if (response.data) {
            setCurvesWithStats(response.data);
          }
        } else {
          setCurvesWithStats(CURVES_LIST_DATA_WITH_STATS);
        }
      } catch (error) {
        datadogRum.addError(error);
      }
    }

    fetchData();
  }, []); // Dependency array is empty to run only once on mount

  return (
    <PricingCurvesOverview
      user={user}
      curvesWithStats={curvesWithStats}
      setCurvesWithStats={setCurvesWithStats}
      shouldRenderRealData={shouldRenderRealData}
    />
  );
}

interface PricingCurvesOverviewProps {
  user: User;
  curvesWithStats: PricingCurveWithStats[];
  setCurvesWithStats: React.Dispatch<
    React.SetStateAction<PricingCurveWithStats[]>
  >;
  shouldRenderRealData: boolean;
}
function PricingCurvesOverview(props: PricingCurvesOverviewProps) {
  const { curvesWithStats, setCurvesWithStats, shouldRenderRealData } = props;

  return (
    <div className="flex flex-col gap-6 p-6">
      <Header />
      <CurvesList
        curvesWithStats={curvesWithStats}
        setCurvesWithStats={setCurvesWithStats}
      />
      {shouldRenderRealData ? null : (
        <>
          <Overview /> <PricingCurvesByProduct />
        </>
      )}
    </div>
  );
}

function Header() {
  return (
    <div className="align-items flex justify-between">
      {/* Left side of header */}
      <div>
        <div className="text-2xl font-semibold">Pricing Curves</div>
        <div className="text-gray-500">
          Create custom pricing guidelines using properties from your
          opportunities.
        </div>
      </div>
      {/* Right side of header */}
      <div className="flex flex-row items-center gap-2">
        <button
          type="button"
          className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
        >
          <LifebuoyIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
          Help
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-lg border border-gray-300 bg-fuchsia-900 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
          onClick={() => {
            window.location.href = '/app/pricingcurves/new';
            console.log('Create a curve');
          }}
        >
          <PlusIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
          {'Create a curve'}
        </button>
      </div>
    </div>
  );
}

type CurvesListProps = {
  curvesWithStats: PricingCurveWithStats[];
  setCurvesWithStats: React.Dispatch<
    React.SetStateAction<PricingCurveWithStats[]>
  >;
};
function CurvesList(props: CurvesListProps) {
  const { curvesWithStats, setCurvesWithStats } = props;
  return (
    <div className="w-full">
      <div className="flex flex-col gap-y-4 rounded-md p-4 outline outline-gray-200">
        {/* Header */}
        <div className="flex w-full items-center text-gray-900">
          <div className="mr-1 w-6 stroke-gray-600">
            <CircleStackIcon className="text-gray-800" />
          </div>{' '}
          <div className="font-medium text-gray-900">Curves list</div>
        </div>

        <div className="border-b border-gray-200"></div>
        <div className="flex-1 self-stretch overflow-hidden">
          {/* Table */}
          <CurvesListTable
            curvesWithStats={curvesWithStats}
            setCurvesWithStats={setCurvesWithStats}
          />
        </div>
      </div>
    </div>
  );
}

function generateRandomValuesWithFixedXLocations(
  xLocations: any[],
  valueRange = [0, 1.5],
) {
  const values = xLocations.map(() =>
    parseFloat(
      (Math.random() * (valueRange[1] - valueRange[0]) + valueRange[0]).toFixed(
        2,
      ),
    ),
  );
  return smoothValues(values).map((value, index) => ({
    xLocation: xLocations[index],
    value,
  }));
}

function smoothValues(values: string | any[], windowSize = 3) {
  const smoothedValues = [];
  for (let i = 0; i < values.length; i++) {
    let windowSum = 0;
    let windowCount = 0;
    for (
      let j = Math.max(0, i - windowSize);
      j <= Math.min(values.length - 1, i + windowSize);
      j++
    ) {
      windowSum += values[j];
      windowCount++;
    }
    smoothedValues.push(parseFloat((windowSum / windowCount).toFixed(2)));
  }
  return smoothedValues;
}

// Define xLocations
const xLocations = [
  100, 500, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500,
  7000, 7500,
];

export const generateOverviewCurveData = () => {
  return [
    {
      label: 'Base (finance guidance)',
      color: '#0BA5EC',
      points: generateRandomValuesWithFixedXLocations(xLocations),
    },
    {
      label: 'Extra 10% discount',
      color: '#16B364',
      points: generateRandomValuesWithFixedXLocations(xLocations),
    },
    {
      label: 'Win vs Adyen',
      color: '#FF4405',
      points: generateRandomValuesWithFixedXLocations(xLocations),
    },
    {
      label: 'Randomized Curve',
      color: '#FFA500', // Orange color
      points: generateRandomValuesWithFixedXLocations(xLocations),
    },
  ];
};

export const OVERVIEW_CURVE_DATA: (Line & { label: string })[] = [
  {
    label: 'Base (finance guidance)',
    color: '#0BA5EC',
    points: [
      { xLocation: 100, value: 1.0 },
      { xLocation: 500, value: 0.82 },
      { xLocation: 1500, value: 0.91 },
      { xLocation: 2000, value: 0.75 },
      { xLocation: 2500, value: 0.86 },
      { xLocation: 3000, value: 0.88 },
      { xLocation: 3500, value: 0.89 },
      { xLocation: 4000, value: 0.92 },
      { xLocation: 4500, value: 0.95 },
      { xLocation: 5000, value: 0.98 },
      { xLocation: 5500, value: 0.95 },
      { xLocation: 6000, value: 0.92 },
      { xLocation: 6500, value: 0.89 },
      { xLocation: 7000, value: 0.86 },
      { xLocation: 7500, value: 0.83 },
    ],
  },
  {
    label: 'Extra 10% discount',
    color: '#16B364',
    points: [
      { xLocation: 100, value: 1.0 },
      { xLocation: 500, value: 0.77 },
      { xLocation: 1500, value: 0.85 },
      { xLocation: 2000, value: 0.91 },
      { xLocation: 2500, value: 0.8 },
      { xLocation: 3000, value: 0.89 },
      { xLocation: 3500, value: 0.94 },
      { xLocation: 4000, value: 0.88 },
      { xLocation: 4500, value: 0.9 },
      { xLocation: 5000, value: 0.96 },
      { xLocation: 5500, value: 0.91 },
      { xLocation: 6000, value: 0.85 },
      { xLocation: 6500, value: 0.87 },
      { xLocation: 7000, value: 0.82 },
      { xLocation: 7500, value: 0.79 },
    ],
  },
  {
    label: 'Win vs Adyen',
    color: '#FF4405',
    points: [
      { xLocation: 100, value: 0.8 },
      { xLocation: 500, value: 0.85 },
      { xLocation: 1500, value: 0.8 },
      { xLocation: 2000, value: 0.9 },
      { xLocation: 2500, value: 0.82 },
      { xLocation: 3000, value: 0.87 },
      { xLocation: 3500, value: 0.93 },
      { xLocation: 4000, value: 0.89 },
      { xLocation: 4500, value: 0.91 },
      { xLocation: 5000, value: 0.7 },
      { xLocation: 5500, value: 0.92 },
      { xLocation: 6000, value: 0.86 },
      { xLocation: 6500, value: 0.84 },
      { xLocation: 7000, value: 0.81 },
      { xLocation: 7500, value: 0.78 },
    ],
  },
];

function Overview() {
  return (
    <div className="h-[300px] w-full">
      <CurvesChart
        chartName="Overview"
        chartData={generateOverviewCurveData()}
      />
    </div>
  );
}

export function CurvesChart(props: {
  chartName: string;
  chartData: (Line & { label: string })[];
}) {
  const { chartName, chartData } = props;
  return (
    <GraphContainer
      className="h-full w-full"
      header={
        <div className="flex w-full items-center text-gray-900">
          <div className="mr-1 w-6 stroke-gray-600">
            <ChartBarIcon className="text-gray-800" />
          </div>
          <div className="font-medium text-gray-900">{chartName}</div>
        </div>
      }
    >
      <div className="flex h-full w-full flex-col">
        <RenderChildWithSize
          className="h-full w-full overflow-hidden"
          childFunction={(width, height) => {
            return (
              <LinePlot
                width={width}
                height={height}
                start={100}
                end={7500}
                lines={chartData}
                formatXAxis={(x: number) => {
                  return x.toString();
                }}
                formatTooltip={(linesAndPoints) => {
                  return (
                    <div key={JSON.stringify(linesAndPoints)}>
                      {linesAndPoints.map(({ line, point }) => {
                        return (
                          <div key={JSON.stringify(line)}>
                            <p style={{ color: line.color }}>{point.value}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            );
          }}
        />
        <Legend className="self-end" mappings={chartData} />
      </div>
    </GraphContainer>
  );
}

type ProductChartData = {
  name: string;
  chartData: (Line & { label: string })[];
};

const PRODUCT_CURVE_DATA: ProductChartData[] = [
  {
    name: 'Auth',
    chartData: generateOverviewCurveData(),
  },
  {
    name: 'Fraud',
    chartData: generateOverviewCurveData(),
  },
  {
    name: 'Radio',
    chartData: generateOverviewCurveData(),
  },
  {
    name: 'TV',
    chartData: generateOverviewCurveData(),
  },
  {
    name: 'Musical',
    chartData: generateOverviewCurveData(),
  },
];
function PricingCurvesByProduct() {
  const [searchParam, setSearchParam] = useState('');
  const [products, setProducts] = useState<ProductChartData[]>([]);

  useEffect(() => {
    setProducts(PRODUCT_CURVE_DATA);
  }, []);

  return (
    <div className="flex flex-col gap-4 pb-40">
      <SearchInput onChange={setSearchParam} />

      <div className="grid grid-cols-2 gap-4">
        {products
          .filter((product) => doesSearchQueryMatch(searchParam, product.name))
          .map((product) => {
            return (
              <div className="h-[300px] w-full" key={product.name}>
                <CurvesChart
                  chartName={product.name}
                  chartData={product.chartData}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-72 flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

const CURVES_LIST_DATA_WITH_STATS: PricingCurveWithStats[] = [
  {
    curve: {
      name: 'Base (finance guidance)',
      isActive: true,
      id: 'demo',
      productId: 'demo',
      condition: 'demo',
      priority: 0,
      pricingInformation: null as any,
      product: { name: 'Auth' },
    },
    analytics: {
      avgMarkup: 0.3,
      avgMarkupPercent: 0.12,
      usagePercentage: 77,
      usageCount: 12,
      dealsWon: 8,
      dealsLost: 4,
      revenueUplift: 1301,
    },
  },
  {
    curve: {
      name: 'Extra 10% discount',
      isActive: true,
      id: 'demo',
      productId: 'demo',
      condition: 'demo',
      priority: 0,
      pricingInformation: null as any,
      product: { name: 'Auth' },
    },
    analytics: {
      avgMarkup: -0.01,
      avgMarkupPercent: -0.1,
      usagePercentage: 65,
      usageCount: 32,
      dealsWon: 30,
      dealsLost: 2,
      revenueUplift: 12102,
    },
  },
  {
    curve: {
      name: 'Win vs Adyen',
      isActive: false,
      id: 'demo',
      productId: 'demo',
      condition: 'demo',
      priority: 0,
      pricingInformation: null as any,
      product: { name: 'Fraud' },
    },
    analytics: {
      avgMarkup: -0.013,
      avgMarkupPercent: -0.1,
      usagePercentage: 49,
      usageCount: 100,
      dealsWon: 99,
      dealsLost: 1,
      revenueUplift: 14583,
    },
  },
  {
    curve: {
      name: 'Win vs Braintree',
      isActive: false,
      id: 'demo',
      productId: 'demo',
      condition: 'demo',
      priority: 0,
      pricingInformation: null as any,
      product: { name: 'Radio' },
    },
    analytics: {
      avgMarkup: -0.08,
      avgMarkupPercent: -0.1,
      usagePercentage: 95,
      usageCount: 20,
      dealsWon: 12,
      dealsLost: 8,
      revenueUplift: 1502,
    },
  },
];
type CurvesListTableProps = {
  curvesWithStats: PricingCurveWithStats[];
  setCurvesWithStats: React.Dispatch<
    React.SetStateAction<PricingCurveWithStats[]>
  >;
};
function CurvesListTable(props: CurvesListTableProps) {
  const { curvesWithStats, setCurvesWithStats } = props;
  curvesWithStats.sort((a, b) => {
    return (
      a.curve.product.name.localeCompare(b.curve.product.name) ||
      a.curve.priority - b.curve.priority
    );
  });

  return (
    <div className="w-full rounded-xl bg-white">
      <table className="w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="min-w-[172px]  rounded-tl-md bg-gray-50 px-6 py-2 text-left text-xs font-medium text-gray-700  ">
              Curve name
            </th>
            <th className="min-w-[172px]  rounded-tl-md bg-gray-50 px-6 py-2 text-left text-xs font-medium text-gray-700  ">
              Product name
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium text-gray-700">
              Avg markup
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium text-gray-700">
              Avg markup %
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              Usage
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              Win/Loss rate
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              Revenue Uplift
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              Status
            </th>
            <th className="rounded-tr-md border-gray-200 bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {curvesWithStats.map((row) => {
            const enabled = row.curve.isActive;
            const onToggle = (enabled: boolean) => {
              setCurvesWithStats(
                curvesWithStats.map((curveWithStats) =>
                  curveWithStats.curve.name === row.curve.name
                    ? {
                        ...curveWithStats,
                        curve: { ...curveWithStats.curve, isActive: enabled },
                      }
                    : curveWithStats,
                ),
              );
            };
            return (
              <tr key={row.curve.name} className="text-xs text-gray-600">
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <div className="flex items-center gap-x-2 whitespace-nowrap">
                    <div
                      className="h-3 w-3 rounded-full"
                      style={{ background: nameToColor(row.curve.name ?? '') }}
                    ></div>
                    <span className="font-medium text-gray-900">
                      {row.curve.name.length
                        ? row.curve.name
                        : row.curve.condition}
                    </span>
                  </div>
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <span className="font-medium text-gray-900">
                    {row.curve.product.name}
                  </span>
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  {row.analytics.avgMarkup.toFixed(3)}
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  {(row.analytics.avgMarkupPercent * 100).toFixed(1)}%
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <div className="flex flex-col">
                    <span>{row.analytics.usagePercentage.toFixed(1)}%</span>
                    <span>{row.analytics.usageCount} quotes</span>
                  </div>
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <div className="flex flex-col">
                    <span>{row.analytics.dealsWon} Won</span>
                    <span>{row.analytics.dealsLost} Lost</span>
                  </div>
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  ${row.analytics.revenueUplift.toFixed(2)}
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <Badge color={row.curve.isActive ? 'green' : 'gray'}>
                    <div
                      className="mr-2 h-2 w-2 rounded-full"
                      style={{
                        background: row.curve.isActive ? 'green' : 'gray',
                      }}
                    ></div>
                    {row.curve.isActive ? 'Active' : 'Paused'}
                  </Badge>
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <Toggle enabled={enabled} onToggle={onToggle} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
