import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Organization, User } from '../../types';
import PricingFlowPageRoot from '../PricingFlow/PricingFlow';
import ClonePricingFlowPage from './ClonePricingFlowPage';
import OpportunityDetailPage from './OpportunityDetailPage';
import OpportunitySearchPage from './OpportunitySearchPage';

interface OpportunityRouterProps {
  user: User;
  organization: Organization;
}

export default function OpportunityRouter(props: OpportunityRouterProps) {
  const location = useLocation();
  return (
    <Routes>
      {/* opportunity routes */}
      <Route
        /**
         *  /app/opportunity
         */
        index
        caseSensitive={false}
        element={
          <OpportunitySearchPage
            user={props.user}
            organization={props.organization}
          />
        }
      />
      <Route
        /**
         *  /app/opportunity/:sfdcOppId
         */
        path=":sfdcOppId"
        caseSensitive={false}
        element={
          <OpportunityDetailPage
            user={props.user}
            organization={props.organization}
          />
        }
      />
      <Route
        /**
         * /app/opportunity/:sfdcOppId/clonePricingFlow
         */
        path=":sfdcOppId/clonePricingFlow"
        caseSensitive={false}
        element={<ClonePricingFlowPage />}
      />
      <Route
        /**
         * /app/opportunity/:sfdcOppId/pricingflow
         */
        path=":sfdcOppId/pricingflow"
        caseSensitive={false}
        element={<Navigate to="/app/opportunity" replace />}
      />
      <Route
        /**
         * /app/opportunity/:sfdcOppId/pricingflow/:pricingFlowId
         */
        path=":sfdcOppId/pricingflow/:pricingFlowId"
        caseSensitive={false}
        element={
          <PricingFlowPageRoot
            user={props.user}
            organization={props.organization}
          />
        }
      />
    </Routes>
  );
}
