import { Route, Routes } from 'react-router-dom';
import { useUserContext } from 'src/components/UserContext';
import PriceDifferentialAnalysisDemo from './Demo/PriceDifferential';
import Home from './Home';
import PriceDifferentialAnalysis from './ProductASPsDashboard';

const AnalyticsRouter = () => {
  // get current user + organization
  let user = useUserContext();
  let isDemo = user.organization?.name?.includes('Dealops');
  return (
    <>
      {/* Route Configuration */}
      <Routes>
        {isDemo ? (
          <>
            <Route path="/" element={<Home />} />
            <Route
              path="/differentials"
              element={<PriceDifferentialAnalysisDemo />}
            />
          </>
        ) : (
          <Route path="/" element={<PriceDifferentialAnalysis />} />
        )}
      </Routes>
    </>
  );
};

export default AnalyticsRouter;
