import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo.svg';
import LogoSquare from '../assets/Logo_mobile.svg';

const navigation = [
  { name: 'Product', href: '#product' },
  //   { name: 'Team', href: 'https://www.google.com' },
  { name: 'Privacy & Security', href: '/security' },
];

export const smoothScrollTo = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  id: string,
) => {
  e.preventDefault();
  document.querySelector(id)?.scrollIntoView({ behavior: 'smooth' });
};

export default function StickyNav() {
  const [show, setShow] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // This function will be called on scroll to determine whether to show the StickyNav
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const shouldShow = currentScrollPos > 400;
    setShow(shouldShow);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 z-50 w-full transition-transform duration-300 ${
        !show || mobileMenuOpen ? '-translate-y-full' : 'translate-y-0'
      }`}
    >
      <nav
        className="mx-8 md:mx-auto flex max-w-3xl items-center justify-between mt-10 p-3.5 bg-white rounded-xl shadow"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <span className="sr-only">dealops</span>
          <img src={LogoSquare} className="hidden lg:flex" />
          <img src={Logo} className="lg:hidden flex" />

          <div className={`gap-x-4 pl-4 pt-1 hidden lg:flex mb-1`}>
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                onClick={(e) =>
                  item.href.includes('#') ? smoothScrollTo(e, item.href) : null
                }
                className="text-sm font-semibold leading-6 rounded-lg px-3 py-1 text-[#5B0F4D] transition duration-300 ease-in-out hover:text-[#981781] hover:bg-[#EED4E5] hover:bg-opacity-70"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex lg:hidden">
          <Link
            to={'https://calendly.com/spyrikaras'}
            className="bg-[#83196E] hover:bg-fuchsia-800 focus-visible:outline-cta flex items-center justify-center rounded-md px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mr-4"
          >
            Book a demo
          </Link>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to="/login"
            className="text-sm font-semibold leading-6 rounded-lg text-[#5B0F4D] max-h-8 mt-1 py-1 px-3  transition duration-300 ease-in-out hover:text-[#981781] hover:bg-[#EED4E5] hover:bg-opacity-70"
          >
            Log in
          </Link>
          <Link
            to={'https://calendly.com/spyrikaras'}
            className="bg-[#83196E] hover:bg-fuchsia-800 focus-visible:outline-cta flex items-center justify-center rounded-md px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ml-4"
          >
            Book a demo
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <span className="sr-only">dealops</span>
            <img className="h-8 w-auto" src={Logo} alt="dealops" />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </Link>
              </div>
              <div className="py-6">
                <Link
                  to="https://calendly.com/spyrikaras"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Book a demo
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
