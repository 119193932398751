import { datadogRum } from '@datadog/browser-rum';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import { Organization, User } from '../../../../types';
import StepDetailsTopbar from '../Components/StepDetailsTopbar';
import {
  PenguinAdditionalData,
  PenguinPricingFlow,
  PenguinPricingSheet,
  PenguinProduct,
} from '../penguin_types';

import { ProductSelectField } from 'src/components/Fields';

import { isNil } from 'lodash';
import { useEffect } from 'react';
import { usePricingFlowContext } from '../../PricingFlow';
import BottomBar from '../Components/BottomBar';
import OpportunityOverviewBar from '../Components/OpportunityOverviewBar';

type ProductCategories = {
  name: string;
  description: string;
  id: string;
}[];

export default function Step1ModelSelection(props: {
  user: User;
  organization: Organization;
  nextStage: () => void;
  previousStage: () => void;
  selectedProductCategories: string[];
  setSelectedProductCategories: (categories: string[]) => void;
}) {
  const {
    nextStage,
    selectedProductCategories,
    setSelectedProductCategories,
    previousStage,
    organization,
  } = props;

  const { pricingFlow, updateFlow } =
    usePricingFlowContext<PenguinPricingFlow>();

  const updateAdditionalData = (
    additionalData: PenguinPricingFlow['additionalData'],
  ) => {
    const preselectedProducts = getPreselectedProductsForModels(
      pricingFlow.pricingSheetData,
      additionalData.productCategories ?? [],
    );

    // Merge the preselected products with the existing products on pricingFlow
    const products = mergeProducts(
      pricingFlow.products ?? [],
      preselectedProducts,
    );

    updateFlow(
      {
        ...pricingFlow,
        products,
        additionalData,
      },
      false,
    );
  };

  // Add preselected products but doesn't override product info in the existing pricingFlow.products
  function mergeProducts(
    existingProducts: PenguinProduct[],
    newProducts: PenguinProduct[],
  ): PenguinProduct[] {
    return [
      ...existingProducts,
      ...newProducts
        .filter(
          (newProduct) =>
            !existingProducts.some(
              (existingProduct) => existingProduct.id === newProduct.id,
            ),
        )
        .sort((a, b) => {
          const productPrices = pricingFlow.pricingSheetData.productInfo;
          const ppa = productPrices[a.id];
          const ppb = productPrices[b.id];
          if (!isNil(ppa) && !isNil(ppb)) {
            return ppa.displayOrder - ppb.displayOrder;
          }
          return 0;
        }),
    ];
  }

  /*
    In Penguin pricing, we have a concept of preselected products for each product category (product model)
    When a user selects a product category, we want to preselect the products for that category
    If previously selected categories are unselected, for now we don't remove the products from the products list on pricingFlow
  */
  function getPreselectedProductsForModels(
    pricingSheet: PenguinPricingSheet,
    productCategories: string[],
  ): PenguinProduct[] {
    let preselectedProducts: PenguinProduct[] = [];

    try {
      const existingPricingCategories = new Set(
        pricingFlow.additionalData?.productCategories ?? [],
      );

      const addedCategories = productCategories.filter(
        (category) => !existingPricingCategories.has(category),
      );

      /*
        Only for newly added categories, that havent' been selected before, add the preselectedProducts for each category
      */

      addedCategories.forEach((category) => {
        const subcategories =
          pricingSheet.categories[category].preselectedProducts;

        // For each subGroup, add the products to preselectedProducts
        Object.values(subcategories)
          .flat()
          .forEach((id) => {
            if (pricingSheet.productInfo.hasOwnProperty(id)) {
              const { name, fixedVolume } = pricingSheet.productInfo[id]!;

              preselectedProducts.push({
                id: id,
                name,
                volume: fixedVolume, // set to either fixedVolume, or undefined since user has not manually touched this value yet
                rampType: 'fixed',
              });
            } else {
              datadogRum.addError(
                new Error(`could not find product in pricing sheet ${id}`),
              );
            }
          });
      });
    } catch (error) {
      datadogRum.addError(new Error('Error preselecting products'), {
        message: `Error preselecting products: ${error} `,
      });
      return [];
    }

    return preselectedProducts;
  }

  const pricingSheetData = pricingFlow.pricingSheetData;
  const categorySortOrder = [
    'Account Funding',
    'Pay-ins',
    'PFM',
    'EWA: Monitor + Repay',
    'Underwriting',
    'Anti-Fraud (IDV)',
  ];
  const productCategories: ProductCategories = Object.keys(
    pricingSheetData.categories,
  )
    .map((categoryId) => {
      const category = pricingSheetData.categories[categoryId];
      return {
        name: category.name,
        description: category.description,
        id: categoryId,
      };
    })
    .sort((a, b) => {
      return (
        categorySortOrder.indexOf(a.name) - categorySortOrder.indexOf(b.name)
      );
    });
  productCategories.forEach((pc) => {
    if (!categorySortOrder.includes(pc.name)) {
      datadogRum.addError(new Error(`Unexpected category name ${pc.name}`));
    }
  });
  useEffect(() => {
    if (productCategories.length === 0) {
      nextStage();
    }
  }, [productCategories]);

  return (
    <div className="relative flex h-full flex-1 flex-row overflow-hidden">
      <OpportunityOverviewBar
        opportunityData={pricingFlow.opportunity.opportunityData}
      />
      <div className="grow overflow-auto px-6">
        {/* <TopStepBar user={user} pricingFlow={pricingFlow} /> */}
        <StepDetailsTopbar
          stepName={'Use Case Selection'}
          stepDescription="Select all applicable use cases"
          stepIcon={
            <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
          }
        />
        <div className="w-full grow">
          <ModelSelectionForm
            productCategories={productCategories}
            additionalData={pricingFlow.additionalData}
            organizationName={organization.name ?? ''}
            updateAdditionalData={updateAdditionalData}
            selectedProductCategories={selectedProductCategories}
            setSelectedProductCategories={setSelectedProductCategories}
            nextStage={nextStage}
          />
        </div>
      </div>
      <BottomBar
        primaryButtonProps={{ label: 'Next', onClick: async () => nextStage() }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
      />
    </div>
  );
}

function ModelSelectionForm(props: {
  productCategories: ProductCategories;
  additionalData: PenguinAdditionalData;
  organizationName: string;
  updateAdditionalData: (additionalData: PenguinAdditionalData) => void;
  selectedProductCategories: string[];
  setSelectedProductCategories: (categories: string[]) => void;
  nextStage: () => void;
}) {
  const { editMode } = usePricingFlowContext<PenguinPricingFlow>();
  const {
    organizationName,
    productCategories,
    selectedProductCategories,
    setSelectedProductCategories,
    nextStage,
  } = props;
  const setSelectedCategories = (categories: string[]) => {
    setSelectedProductCategories(categories);
    props.updateAdditionalData({
      ...props.additionalData,
      productCategories: categories,
    });
  };

  const continueButton = (
    <button
      type="button"
      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      onClick={nextStage}
    >
      Select {organizationName} products directly
    </button>
  );

  if (productCategories.length === 0) {
    return (
      <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 pb-10">
        <div className="flex h-full flex-col items-center justify-center">
          <p className="text-lg font-medium text-gray-900 mb-4">
            No use cases available.
          </p>
          {continueButton}
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 pb-10">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
        {productCategories.map((productCategory) => {
          const isSelected =
            selectedProductCategories.find(
              (selectedCategory) => selectedCategory === productCategory.id,
            ) != null;
          return (
            <ProductSelectField
              key={productCategory.id}
              id={productCategory.id}
              className="h-full"
              name={productCategory.name}
              description={productCategory.description}
              disabled={!editMode}
              onChange={() => {
                if (isSelected) {
                  setSelectedCategories(
                    selectedProductCategories.filter(
                      (selectedCategory) =>
                        selectedCategory !== productCategory.id,
                    ),
                  );
                } else {
                  setSelectedCategories(
                    selectedProductCategories.concat([productCategory.id]),
                  );
                }
              }}
              checked={isSelected}
            />
          );
        })}
      </div>
      <hr className="col-span-4 my-4" />
      <div className="text-md flex w-full flex-col items-center">
        {continueButton}
      </div>
    </div>
  );
}
