import { datadogRum } from '@datadog/browser-rum';
import _, { isNil, last, sum } from 'lodash';
import { unreachable } from 'src/typeUtils';
import {
  Currency,
  CurrencyValueFlat,
  CurrencyValueType,
} from '../types_common/price';
import {
  PenguinAdditionalData,
  PenguinEffectiveContractualMin,
  PenguinManualQuote,
  PenguinPricingFlowWithProductVolumes,
  PenguinPricingSheet,
  PenguinProduct,
  ProductsInRecommendedQuote,
} from './penguin_types';

// #PenguinMonthlyMinConfig
// Keep these in sync!
const PENGUIN_MONTHLY_MIN_STEP_SIZE = 500;
export const TARGET_MINIMUM_SPEND_PERCENTAGE = 0.6;

//////////////////////////////////////////////////////////
//////////////// Synced below this line //////////////////
//////////////////////////////////////////////////////////

export const MONTHLY_MIN_PRODUCT_CODES = new Set([
  'MIN - CRA',
  'MIN - FLAT',
  'MIN - FLATEC',
  'MIN - SHA',
]);
export const RAMPABLE_SUPPORT_PRODUCT_CODES = new Set([
  'FEE - SS5',
  'FEE - SS6',
  'FEE - SS7',
  'FEE - PLP',
  'CRA - SUPB',
  'CRA - SUPP',
  'CRA - SUPR',
]);

// #PenguinMinContractualSpend
// Compute what monthly min we need to hit a particular target monthly
// contractual spend. Basically an inverse of
// computeEffectiveMinimumContractualSpend
export function getMonthlyMinForTargetMonthlyContractualSpend({
  targetSpend,
  products,
  manualQuote,
  productsInRecommendedQuote,
  pricingSheetData,
  additionalData,
  monthIdx,
}: {
  targetSpend: CurrencyValueFlat;
  products: PenguinProduct[];
  manualQuote: PenguinManualQuote | null;
  productsInRecommendedQuote: ProductsInRecommendedQuote;
  pricingSheetData: PenguinPricingSheet;
  additionalData: PenguinAdditionalData | null;
  // When computing a ramp value for a ramped monthly min, if support products
  // are ramped, consider what the customer will be spending at that particular
  // month
  monthIdx: number | null;
}): CurrencyValueFlat {
  const targetSpendValue = targetSpend.value;
  const fullSupportProductValue = sum(
    products
      .filter((p) => {
        const productPrice = pricingSheetData?.productInfo[p.id];
        return doesProductContributeToMonthlyMin(productPrice?.ProductCode);
      })
      .map((p) => {
        const price =
          manualQuote?.products?.[p.id] ?? productsInRecommendedQuote[p.id];
        if (isNil(price)) {
          return 0;
        }
        switch (price.type) {
          case CurrencyValueType.FLAT:
          case CurrencyValueType.PERCENT:
            return price.value;
          case 'ramped':
            if (!isNil(monthIdx)) {
              return price.rampValues[monthIdx]?.value ?? 0;
            } else {
              return last(price.rampValues)?.value ?? 0;
            }
          case 'tiered':
            datadogRum.addError(`support products should not be tierable`);
            return 0;
          default:
            unreachable(price);
        }
      }),
  );
  const numMonthlyMinProducts = (() => {
    const maybeNumMonthlyMinProducts =
      additionalData?.additionalMonthlyMinimumProductCodes?.length;
    if (!isNil(maybeNumMonthlyMinProducts) && maybeNumMonthlyMinProducts > 0) {
      return maybeNumMonthlyMinProducts;
    }
    return 1;
  })();
  const targetMinValue =
    _.round(
      Math.max(
        0,
        (targetSpendValue - fullSupportProductValue) / numMonthlyMinProducts,
      ) / PENGUIN_MONTHLY_MIN_STEP_SIZE,
    ) * PENGUIN_MONTHLY_MIN_STEP_SIZE;
  return { ...targetSpend, value: targetMinValue };
}

function doesProductContributeToMonthlyMin(productCode: string | undefined) {
  if (isNil(productCode)) {
    return false;
  }
  return (
    RAMPABLE_SUPPORT_PRODUCT_CODES.has(productCode) ||
    MONTHLY_MIN_PRODUCT_CODES.has(productCode)
  );
}

// #PenguinMinContractualSpend
export function computeEffectiveMinimumContractualSpend(
  pricingFlow: PenguinPricingFlowWithProductVolumes,
): PenguinEffectiveContractualMin {
  /**
   * Portion from "monthly usage min" type products
   */
  const baseMonthlyMin =
    pricingFlow.manualQuote?.monthlyMinimum ??
    pricingFlow.recommendedQuote.monthlyMinimum;
  const fullMonthlyMinValue =
    baseMonthlyMin.value *
    (pricingFlow.additionalData?.additionalMonthlyMinimumProductCodes ?? [])
      .length;
  /**
   * Portion from support products
   */
  const fullSupportProductValue = sum(
    pricingFlow.products
      .filter((p) => {
        const productPrice = pricingFlow.pricingSheetData.productInfo[p.id];
        return doesProductContributeToMonthlyMin(productPrice?.ProductCode);
      })
      .map((p) => {
        const price =
          pricingFlow.manualQuote?.products?.[p.id] ??
          pricingFlow.recommendedQuote.products[p.id];
        if (isNil(price)) {
          return 0;
        }
        switch (price.type) {
          case CurrencyValueType.FLAT:
          case CurrencyValueType.PERCENT:
            return price.value;
          case 'ramped':
            return last(price.rampValues)?.value ?? 0;
          case 'tiered':
            datadogRum.addError(`support products should not be tierable`);
            return 0;
          default:
            unreachable(price);
        }
      }),
  );
  const fullValue = fullMonthlyMinValue + fullSupportProductValue;
  // ##ProratePenguinMonthlyMin
  // Maybe prorate the monthly minimum
  //   If subscription term is >= 12 months, use the monthly minimum directly in
  //   the computation of monthly minimum tiers. However, if the subscription
  //   term is less than a year, prorate the monthly minimum when computing the
  //   tier by the length of the subscription.  E.g. suppose the monthly min
  //   configured on the deal is 1000, but the subscription term is only 6
  //   months. Then, when selecting the tier, you would act as though the
  //   monhtly minimum is 1000 * (6/12) = 500.  Record of manual CPQ testing
  //   here:
  //   https://www.notion.so/dealops/george-s-requests-for-plaid-cpq-testing-a2b6c5a694ab4ae3ad10609b5b16da27?pvs=4#f1bad6e8553b4fd1b382b80e92fa97f3
  const subscriptionTermsForApprovalTiers = Math.min(
    pricingFlow.additionalData?.subscriptionTerms ?? 12,
    12,
  );
  const proratedValue = fullValue * (subscriptionTermsForApprovalTiers / 12);
  return {
    value: proratedValue,
    type: CurrencyValueType.FLAT,
    currency:
      pricingFlow.additionalData?.quoteCurrency || baseMonthlyMin.currency,
  } as PenguinEffectiveContractualMin;
}

type QuoteLine = {
  SBQQ__ProductCode__c: string;
  SBCF_Net_Unit_Price__c: number;
};
// #PenguinMinContractualSpend
export function computeEffectiveMinimumContractualSpendFromSFDCQuotelines(
  quotelines: QuoteLine[],
  currency: Currency,
  subscriptionTerms: number,
): PenguinEffectiveContractualMin {
  const fullValue = _.chain(quotelines)
    .filter((ql) => doesProductContributeToMonthlyMin(ql.SBQQ__ProductCode__c))
    .groupBy((ql) => ql.SBQQ__ProductCode__c)
    .map((group) =>
      _.max([0, _.max(group.map((ql) => ql.SBCF_Net_Unit_Price__c))]),
    )
    .sum()
    .value();
  // #ProratePenguinMonthlyMin
  const subscriptionTermsForApprovalTiers = Math.min(
    subscriptionTerms ?? 12,
    12,
  );
  const proratedValue = fullValue * (subscriptionTermsForApprovalTiers / 12);
  return {
    value: proratedValue,
    type: CurrencyValueType.FLAT,
    currency,
  } as PenguinEffectiveContractualMin;
}

//////////////////////////////////////////////////////////
//////////////// Synced above this line //////////////////
//////////////////////////////////////////////////////////
