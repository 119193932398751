export default function StepDetailsTopbar(props: {
  stepName: string;
  stepDescription: string;
  stepIcon: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { stepName, stepDescription, stepIcon } = props;
  return (
    <div className="flex flex-row items-center border-b py-4">
      <div className="rounded-lg border border-gray-300 p-2 shadow-sm">
        {stepIcon}
      </div>
      <div className="ml-4">
        <p className="text-lg font-medium text-gray-900">{stepName}</p>
        <p className="text-sm text-gray-600">{stepDescription}</p>
      </div>
      <div className="flex flex-grow flex-row-reverse">{props.children}</div>
    </div>
  );
}
