import { datadogRum } from '@datadog/browser-rum';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { AxiosResponse } from 'axios';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Spinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { PricingFlowCommon, PricingFlowType } from '../../types';

type PenguinFinalizeQuoteModalProps = {
  close: () => void;
  eventualResponse: Promise<AxiosResponse<any, any>>;
};

function getQuoteSFDCUrl(pricingFlow: PricingFlowCommon): string {
  switch (pricingFlow.type) {
    case PricingFlowType.PENGUIN:
      const instanceUrl = pricingFlow.opportunity.sfdcInstanceUrl ?? '';

      const baseUrl = instanceUrl.includes('staging')
        ? instanceUrl.replace(
            '.sandbox.my.salesforce.com',
            '--sbqq.sandbox.vf.force.com/apex/sb?id=',
          )
        : instanceUrl.replace(
            '.my.salesforce.com',
            '--sbqq.vf.force.com/apex/sb?id=',
          );

      const jsonData = {
        componentDef: 'one:alohaPage',
        attributes: {
          address: baseUrl + pricingFlow.sfdcQuoteId,
        },
        state: {},
      };

      const encodedJson = btoa(JSON.stringify(jsonData));

      return (
        instanceUrl.replace(
          'my.salesforce.com',
          'lightning.force.com/one/one.app#',
        ) + encodedJson
      );
    case PricingFlowType.COMPLEX_DEMO:
      return `${pricingFlow.opportunity.sfdcInstanceUrl}/lightning/r/Quote/${pricingFlow.sfdcQuoteId}/view`;
    default:
      throw new Error('Unsupported pricing flow type');
  }
}

export default function PenguinFinalizeQuoteModal(
  props: PenguinFinalizeQuoteModalProps,
) {
  const { close, eventualResponse } = props;
  const [maybeQuoteUrl, setQuoteUrl] = useState<string | null>(null);
  const [maybeError, setError] = useState<string | null>(null);
  const { showToast } = useToast();
  useEffect(() => {
    eventualResponse
      .then((response) => {
        const url = getQuoteSFDCUrl(response.data);
        setQuoteUrl(url);
        showToast({
          title: 'Quote successfully generated!',
          subtitle: (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Click here to see your quote in SFDC
            </a>
          ),
          type: 'success',
          autoDismiss: false,
        });
      })
      .catch((error) => {
        datadogRum.addError(error);
        console.log(error);
        // @ts-ignore
        const userFriendlyError =
          error?.response?.data?.error ?? 'Unknown error';
        console.log(userFriendlyError);
        setError(userFriendlyError);
      });
  }, []);
  const isLoading = isNil(maybeQuoteUrl);
  return (
    <div className="mt-4 mx-[-24px] mb-[-24px]">
      <div className="px-6">
        <div className="text-base text-slate-600 py-4">
          Once you load your quote in Salesforce, please:
          <ol className="list-decimal pl-5 space-y-1 py-2">
            <li>Review your quote</li>
            <li>
              <strong>Click "Calculate"</strong>
            </li>
            <li>Click "Save"</li>
          </ol>
          If you do not recalculate your quote, you may be subject to extra
          approvals.
        </div>

        {/* Warning */}
        {maybeError && (
          <div className="rounded-md bg-orange-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-orange-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-orange-800">
                  Something went wrong, please contact us at
                  support@dealops.com. (Salesforce error: {maybeError})
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Buttons */}
      <div className="flex flex-row justify-between pt-4 mt-6 border-t border-100 gap-4">
        <button
          type="button"
          className="rounded-md flex-1 bg-white ml-6 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={close}
        >
          Close
        </button>
        <button
          type="button"
          className="rounded-md flex-1 bg-fuchsia-900 mr-6 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-fuchsia-800"
          disabled={isLoading}
          onClick={() => {
            if (!isNil(maybeQuoteUrl)) {
              window.open(maybeQuoteUrl, '_blank');
            }
          }}
        >
          {isLoading ? (
            <div className="flex flex-row justify-left items-center">
              <Spinner height={24} width={24} />
              <p className="ml-4">Generating quote...</p>
            </div>
          ) : (
            `Take me to my quote`
          )}
        </button>
      </div>
    </div>
  );
}
