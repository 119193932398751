import { datadogRum } from '@datadog/browser-rum';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import {
  CheckIcon,
  DocumentDuplicateIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { useToast } from 'src/components/Toast';
import { classNames } from '../App';

import { useState } from 'react';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import { PricingFlowCommon } from './types';

export default function ShareButton(props: {
  textToCopy: string;
  pricingFlow: PricingFlowCommon;
}) {
  const { textToCopy } = props;
  const { showToast } = useToast();
  const createAnalyticsEvent = useAnalyticsContext();

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      showToast({
        title: 'Copied to Clipboard',
        subtitle: textToCopy,
        type: 'success',
      });
      createAnalyticsEvent({
        name: 'pricing_flow__clicked_share_pricing_flow_link',
        eventData: {
          pricing_flow_id: props.pricingFlow.id,
        },
      });
    } catch (error) {
      datadogRum.addError(error);
      console.error('Failed to copy to clipboard: ', error);
    }
  };

  return (
    <button
      type="button"
      className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
      onClick={handleCopyClick}
    >
      <ArrowTopRightOnSquareIcon
        className="-ml-0.5 mr-2 h-5 w-5"
        aria-hidden="true"
      />
      Share
    </button>
  );
}

export function ShareLink(props: {
  url: string;
  pricingFlow: PricingFlowCommon;
}) {
  const { url } = props;
  const [isCopied, setIsCopied] = useState(false);
  const createAnalyticsEvent = useAnalyticsContext();

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setIsCopied(true);

      // Reset after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      createAnalyticsEvent({
        name: 'pricing_flow__clicked_share_pricing_flow_link',
        eventData: {
          pricing_flow_id: props.pricingFlow.id,
        },
      });
    } catch (error) {
      datadogRum.addError(error);
      console.error('Failed to copy to clipboard: ', error);
    }
  };

  return (
    <div className="flex block w-full rounded-lg border border-slate-200 bg-slate-100 text-sm items-stretch">
      <div className="flex items-center justify-center rounded-l-lg ">
        <LinkIcon className="h-6 w-6 text-slate-700 py-1 mx-1" />
      </div>
      <div className="flex-1 overflow-x-auto whitespace-nowrap text-slate-600 mr-1 py-2">
        {url}
      </div>
      <button
        type="button"
        className={classNames(
          'flex items-center justify-center rounded-r-lg border-l border-slate-200 transition-colors duration-300',
          isCopied ? 'bg-green-500' : 'bg-white',
        )}
        onClick={handleCopyClick}
        style={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}
      >
        {isCopied ? (
          <CheckIcon className="h-6 w-6 text-white py-1 mx-1" />
        ) : (
          <DocumentDuplicateIcon className="h-6 w-6 text-slate-700 py-1 mx-1" />
        )}
      </button>
    </div>
  );
}
