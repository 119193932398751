import {
  DistributionBarExample,
  DISTRIBUTION_BAR_EXAMPLE_ARGS,
} from './DistributionComparison';
import { GaugeChartExample, GAUGE_CHART_EXAMPLE_ARGS } from './GaugeChart';
import { GoalTrackerExample, GOAL_TRACKER_EXAMPLE_ARGS } from './GoalTracker';
import {
  HorizontalBarChartExample1,
  HorizontalBarChartExample2,
  HORIZONTAL_BAR_CHART_EXAMPLE_1_ARGS,
  HORIZONTAL_BAR_CHART_EXAMPLE_2_ARGS,
} from './HorizontalBarChart';
import {
  LinePlotExample1,
  LinePlotExample2,
  LINE_PLOT_EXAMPLE_1_ARGS,
  LINE_PLOT_EXAMPLE_2_ARGS,
} from './LinePlot';
import {
  StackedColumnChartExample,
  STACKED_COLUMN_CHART_EXAMPLE_ARGS,
} from './StackedColumnChart';

export default function GraphDemo() {
  return (
    <div className="flex h-screen w-screen p-5">
      <div className="grid w-2/3 grid-cols-2 grid-rows-2 gap-2">
        <LinePlotExample1 {...LINE_PLOT_EXAMPLE_1_ARGS} />
        <LinePlotExample2 {...LINE_PLOT_EXAMPLE_2_ARGS} />
        <HorizontalBarChartExample1 {...HORIZONTAL_BAR_CHART_EXAMPLE_1_ARGS} />
        <GaugeChartExample {...GAUGE_CHART_EXAMPLE_ARGS} />
      </div>
      <div className="w-1/3 space-y-2 px-2">
        <GoalTrackerExample {...GOAL_TRACKER_EXAMPLE_ARGS} />
        <StackedColumnChartExample {...STACKED_COLUMN_CHART_EXAMPLE_ARGS} />
        <DistributionBarExample {...DISTRIBUTION_BAR_EXAMPLE_ARGS} />
        <HorizontalBarChartExample2 {...HORIZONTAL_BAR_CHART_EXAMPLE_2_ARGS} />
      </div>
    </div>
  );
}
