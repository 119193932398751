export const hardcodedResponseData = {
  opportunity: {
    attributes: {
      type: 'Opportunity',
      url: '/services/data/v58.0/sobjects/Opportunity/006Dp000005M6kwIAC',
    },
    Id: '006Dp000005M6kwIAC',
    IsDeleted: false,
    AccountId: '001Dp00000HWenaIAD',
    IsPrivate: false,
    Name: 'Dickenson Mobile Generators',
    Description: null,
    StageName: 'Qualification',
    Amount: 15000,
    Probability: 10,
    ExpectedRevenue: 1500,
    TotalOpportunityQuantity: null,
    CloseDate: '2023-03-28',
    Type: 'New Customer',
    NextStep: null,
    LeadSource: 'Purchased List',
    IsClosed: false,
    IsWon: false,
    ForecastCategory: 'Pipeline',
    ForecastCategoryName: 'Pipeline',
    CampaignId: null,
    HasOpportunityLineItem: false,
    Pricebook2Id: null,
    OwnerId: '005Dp000003efdWIAQ',
    CreatedDate: '2023-05-19T23:17:55.000+0000',
    CreatedById: '005Dp000003efdWIAQ',
    LastModifiedDate: '2023-05-19T23:17:55.000+0000',
    LastModifiedById: '005Dp000003efdWIAQ',
    SystemModstamp: '2023-05-19T23:17:55.000+0000',
    LastActivityDate: null,
    PushCount: 0,
    LastStageChangeDate: null,
    FiscalQuarter: 1,
    FiscalYear: 2015,
    Fiscal: '2015 1',
    ContactId: null,
    LastViewedDate: '2023-11-09T19:26:10.000+0000',
    LastReferencedDate: '2023-11-09T19:26:10.000+0000',
    SyncedQuoteId: null,
    HasOpenActivity: false,
    HasOverdueTask: false,
    LastAmountChangedHistoryId: null,
    LastCloseDateChangedHistoryId: null,
    DeliveryInstallationStatus__c: null,
    TrackingNumber__c: null,
    OrderNumber__c: null,
    CurrentGenerators__c: null,
    MainCompetitors__c: 'Honda',
    Segment__c: null,
  },
  user: {
    attributes: {
      type: 'User',
      url: '/services/data/v58.0/sobjects/User/005Dp000003efdWIAQ',
    },
    Id: '005Dp000003efdWIAQ',
    Username: 'fay@dealops.com',
    LastName: 'Wu',
    FirstName: 'Fay',
    Name: 'Fay Wu',
    CompanyName: 'Dealops',
    Division: null,
    Department: null,
    Title: null,
    Street: null,
    City: null,
    State: null,
    PostalCode: null,
    Country: 'US',
    Latitude: null,
    Longitude: null,
    GeocodeAccuracy: null,
    Address: {
      city: null,
      country: 'US',
      geocodeAccuracy: null,
      latitude: null,
      longitude: null,
      postalCode: null,
      state: null,
      street: null,
    },
    Email: 'fay@dealops.com',
    EmailPreferencesAutoBcc: true,
    EmailPreferencesAutoBccStayInTouch: false,
    EmailPreferencesStayInTouchReminder: true,
    SenderEmail: null,
    SenderName: null,
    Signature: null,
    StayInTouchSubject: null,
    StayInTouchSignature: null,
    StayInTouchNote: null,
    Phone: null,
    Fax: null,
    MobilePhone: '+1 4243532998',
    Alias: 'FWu',
    CommunityNickname: 'wu.mfay',
    BadgeText: '',
    IsActive: true,
    TimeZoneSidKey: 'America/Los_Angeles',
    UserRoleId: null,
    LocaleSidKey: 'en_US',
    ReceivesInfoEmails: false,
    ReceivesAdminInfoEmails: true,
    EmailEncodingKey: 'UTF-8',
    ProfileId: '00eDp000001fIkCIAU',
    UserType: 'Standard',
    LanguageLocaleKey: 'en_US',
    EmployeeNumber: null,
    DelegatedApproverId: null,
    ManagerId: null,
    LastLoginDate: '2023-11-09T19:26:10.000+0000',
    LastPasswordChangeDate: '2023-10-23T17:49:23.000+0000',
    CreatedDate: '2023-05-19T23:17:55.000+0000',
    CreatedById: '005Dp000003efdWIAQ',
    LastModifiedDate: '2023-10-26T03:08:44.000+0000',
    LastModifiedById: '005Dp000003efdWIAQ',
    SystemModstamp: '2023-11-06T19:49:47.000+0000',
    NumberOfFailedLogins: 0,
    OfflineTrialExpirationDate: null,
    OfflinePdaTrialExpirationDate: null,
    UserPermissionsMarketingUser: true,
    UserPermissionsOfflineUser: true,
    UserPermissionsCallCenterAutoLogin: false,
    UserPermissionsSFContentUser: true,
    UserPermissionsKnowledgeUser: false,
    UserPermissionsInteractionUser: false,
    UserPermissionsSupportUser: true,
    UserPermissionsJigsawProspectingUser: false,
    UserPermissionsSiteforceContributorUser: false,
    UserPermissionsSiteforcePublisherUser: false,
    UserPermissionsWorkDotComUserFeature: false,
    ForecastEnabled: true,
    UserPreferencesActivityRemindersPopup: true,
    UserPreferencesEventRemindersCheckboxDefault: true,
    UserPreferencesTaskRemindersCheckboxDefault: true,
    UserPreferencesReminderSoundOff: false,
    UserPreferencesDisableAllFeedsEmail: false,
    UserPreferencesDisableFollowersEmail: false,
    UserPreferencesDisableProfilePostEmail: false,
    UserPreferencesDisableChangeCommentEmail: false,
    UserPreferencesDisableLaterCommentEmail: false,
    UserPreferencesDisProfPostCommentEmail: false,
    UserPreferencesContentNoEmail: false,
    UserPreferencesContentEmailAsAndWhen: false,
    UserPreferencesApexPagesDeveloperMode: false,
    UserPreferencesReceiveNoNotificationsAsApprover: false,
    UserPreferencesReceiveNotificationsAsDelegatedApprover: false,
    UserPreferencesHideCSNGetChatterMobileTask: false,
    UserPreferencesDisableMentionsPostEmail: false,
    UserPreferencesDisMentionsCommentEmail: false,
    UserPreferencesHideCSNDesktopTask: false,
    UserPreferencesHideChatterOnboardingSplash: false,
    UserPreferencesHideSecondChatterOnboardingSplash: false,
    UserPreferencesDisCommentAfterLikeEmail: false,
    UserPreferencesDisableLikeEmail: false,
    UserPreferencesSortFeedByComment: false,
    UserPreferencesDisableMessageEmail: false,
    UserPreferencesJigsawListUser: false,
    UserPreferencesDisableBookmarkEmail: false,
    UserPreferencesDisableSharePostEmail: false,
    UserPreferencesEnableAutoSubForFeeds: false,
    UserPreferencesDisableFileShareNotificationsForApi: false,
    UserPreferencesShowTitleToExternalUsers: true,
    UserPreferencesShowManagerToExternalUsers: false,
    UserPreferencesShowEmailToExternalUsers: false,
    UserPreferencesShowWorkPhoneToExternalUsers: false,
    UserPreferencesShowMobilePhoneToExternalUsers: false,
    UserPreferencesShowFaxToExternalUsers: false,
    UserPreferencesShowStreetAddressToExternalUsers: false,
    UserPreferencesShowCityToExternalUsers: false,
    UserPreferencesShowStateToExternalUsers: false,
    UserPreferencesShowPostalCodeToExternalUsers: false,
    UserPreferencesShowCountryToExternalUsers: false,
    UserPreferencesShowProfilePicToGuestUsers: false,
    UserPreferencesShowTitleToGuestUsers: false,
    UserPreferencesShowCityToGuestUsers: false,
    UserPreferencesShowStateToGuestUsers: false,
    UserPreferencesShowPostalCodeToGuestUsers: false,
    UserPreferencesShowCountryToGuestUsers: false,
    UserPreferencesShowForecastingChangeSignals: false,
    UserPreferencesHideS1BrowserUI: false,
    UserPreferencesDisableEndorsementEmail: false,
    UserPreferencesPathAssistantCollapsed: false,
    UserPreferencesCacheDiagnostics: false,
    UserPreferencesShowEmailToGuestUsers: false,
    UserPreferencesShowManagerToGuestUsers: false,
    UserPreferencesShowWorkPhoneToGuestUsers: false,
    UserPreferencesShowMobilePhoneToGuestUsers: false,
    UserPreferencesShowFaxToGuestUsers: false,
    UserPreferencesShowStreetAddressToGuestUsers: false,
    UserPreferencesLightningExperiencePreferred: true,
    UserPreferencesPreviewLightning: false,
    UserPreferencesHideEndUserOnboardingAssistantModal: false,
    UserPreferencesHideLightningMigrationModal: false,
    UserPreferencesHideSfxWelcomeMat: false,
    UserPreferencesHideBiggerPhotoCallout: false,
    UserPreferencesGlobalNavBarWTShown: false,
    UserPreferencesGlobalNavGridMenuWTShown: false,
    UserPreferencesCreateLEXAppsWTShown: false,
    UserPreferencesFavoritesWTShown: false,
    UserPreferencesRecordHomeSectionCollapseWTShown: false,
    UserPreferencesRecordHomeReservedWTShown: false,
    UserPreferencesFavoritesShowTopFavorites: false,
    UserPreferencesExcludeMailAppAttachments: false,
    UserPreferencesSuppressTaskSFXReminders: false,
    UserPreferencesSuppressEventSFXReminders: false,
    UserPreferencesPreviewCustomTheme: false,
    UserPreferencesHasCelebrationBadge: false,
    UserPreferencesUserDebugModePref: false,
    UserPreferencesSRHOverrideActivities: false,
    UserPreferencesNewLightningReportRunPageEnabled: false,
    UserPreferencesReverseOpenActivitiesView: false,
    UserPreferencesShowTerritoryTimeZoneShifts: false,
    UserPreferencesHasSentWarningEmail: false,
    UserPreferencesHasSentWarningEmail238: false,
    UserPreferencesHasSentWarningEmail240: false,
    UserPreferencesNativeEmailClient: false,
    ContactId: null,
    AccountId: null,
    CallCenterId: null,
    Extension: null,
    FederationIdentifier: null,
    AboutMe: null,
    FullPhotoUrl:
      'https://somethingnew-dev-ed.develop.file.force.com/profilephoto/005/F',
    SmallPhotoUrl:
      'https://somethingnew-dev-ed.develop.file.force.com/profilephoto/005/T',
    IsExtIndicatorVisible: false,
    OutOfOfficeMessage: '',
    MediumPhotoUrl:
      'https://somethingnew-dev-ed.develop.file.force.com/profilephoto/005/M',
    DigestFrequency: 'D',
    DefaultGroupNotificationFrequency: 'N',
    JigsawImportLimitOverride: 300,
    LastViewedDate: '2023-11-09T19:26:12.000+0000',
    LastReferencedDate: '2023-11-09T19:26:12.000+0000',
    BannerPhotoUrl: '/profilephoto/005/B',
    SmallBannerPhotoUrl: '/profilephoto/005/D',
    MediumBannerPhotoUrl: '/profilephoto/005/E',
    IsProfilePhotoActive: false,
    IndividualId: null,
  },
  account: {
    attributes: {
      type: 'Account',
      url: '/services/data/v58.0/sobjects/Account/001Dp00000HWenaIAD',
    },
    Id: '001Dp00000HWenaIAD',
    IsDeleted: false,
    MasterRecordId: null,
    Name: 'Dickenson plc',
    Type: 'Customer - Channel',
    ParentId: null,
    BillingStreet: '1301 Hoch Drive',
    BillingCity: 'Lawrence',
    BillingState: 'KS',
    BillingPostalCode: '66045',
    BillingCountry: 'USA',
    BillingLatitude: null,
    BillingLongitude: null,
    BillingGeocodeAccuracy: null,
    BillingAddress: {
      city: 'Lawrence',
      country: 'USA',
      geocodeAccuracy: null,
      latitude: null,
      longitude: null,
      postalCode: '66045',
      state: 'KS',
      street: '1301 Hoch Drive',
    },
    ShippingStreet: '1301 Hoch Drive',
    ShippingCity: 'Lawrence',
    ShippingState: 'KS',
    ShippingPostalCode: '66045',
    ShippingCountry: 'USA',
    ShippingLatitude: null,
    ShippingLongitude: null,
    ShippingGeocodeAccuracy: null,
    ShippingAddress: {
      city: 'Lawrence',
      country: 'USA',
      geocodeAccuracy: null,
      latitude: null,
      longitude: null,
      postalCode: '66045',
      state: 'KS',
      street: '1301 Hoch Drive',
    },
    Phone: '(785) 241-6200',
    Fax: '(785) 241-6201',
    AccountNumber: 'CC634267',
    Website: 'dickenson-consulting.com',
    PhotoUrl: '/services/images/photo/001Dp00000HWenaIAD',
    Sic: '6752',
    Industry: 'Consulting',
    AnnualRevenue: 50000000,
    NumberOfEmployees: 120,
    Ownership: 'Private',
    TickerSymbol: null,
    Description: null,
    Rating: null,
    Site: null,
    OwnerId: '005Dp000003efdWIAQ',
    CreatedDate: '2023-05-19T23:17:55.000+0000',
    CreatedById: '005Dp000003efdWIAQ',
    LastModifiedDate: '2023-05-19T23:17:55.000+0000',
    LastModifiedById: '005Dp000003efdWIAQ',
    SystemModstamp: '2023-05-19T23:17:55.000+0000',
    LastActivityDate: null,
    LastViewedDate: '2023-11-09T19:26:13.000+0000',
    LastReferencedDate: '2023-11-09T19:26:13.000+0000',
    Jigsaw: null,
    JigsawCompanyId: null,
    CleanStatus: 'Pending',
    AccountSource: null,
    DunsNumber: null,
    Tradestyle: null,
    NaicsCode: null,
    NaicsDesc: null,
    YearStarted: null,
    SicDesc: null,
    DandbCompanyId: null,
    OperatingHoursId: null,
    CustomerPriority__c: 'Low',
    SLA__c: 'Bronze',
    Active__c: 'Yes',
    NumberofLocations__c: 2,
    UpsellOpportunity__c: 'No',
    SLASerialNumber__c: '7425',
    SLAExpirationDate__c: '2023-12-14',
  },
};

export const relatedDealsHardcoded = [
  {
    id: '1',
    name: 'Clickup',
    opportunity: hardcodedResponseData.opportunity,
    account: hardcodedResponseData.account,
    user: hardcodedResponseData.user,
  },
  {
    id: '2',
    name: 'Atlassian',
    opportunity: hardcodedResponseData.opportunity,
    account: hardcodedResponseData.account,
    user: hardcodedResponseData.user,
  },
  {
    id: '3',
    name: 'Github',
    opportunity: hardcodedResponseData.opportunity,
    account: hardcodedResponseData.account,
    user: hardcodedResponseData.user,
  },
];
