import axios, { AxiosResponse } from 'axios';

axios.defaults.withCredentials = true;

const get = async <T = any>(
  url: string,
  params?: any,
): Promise<AxiosResponse<T>> => {
  const response = await axios.get<T>(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/${url}`,
    { withCredentials: true, params },
  );
  return response;
};

const post = async (url: string, body: Object) => {
  return await axios.post(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/${url}`,
    body,
    { withCredentials: true },
  );
};

const put = async (url: string, body: Object) => {
  return await axios.put(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/${url}`,
    body,
    { withCredentials: true },
  );
};

const deleteRequest = async (url: string, body: Object) => {
  return await axios.delete(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/${url}`,
    body,
  );
};

const api = {
  get,
  post,
  put,
  delete: deleteRequest,
};

export default api;
