import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { classNames } from 'src/dashboard/App';
import {
  AlpacaStep,
  ALPACA_CANONICAL_STEP_ORDER,
} from './AlpacaPricingFlowPage';

function icon(
  customSteps: string[],
  currentStep: string,
  stepToDisplay: string,
) {
  const currentStepIndex = customSteps.indexOf(currentStep);
  const stepToDisplayIndex = customSteps.indexOf(stepToDisplay);
  if (currentStepIndex < stepToDisplayIndex) {
    return (
      <div
        className={
          'flex h-5 w-5 items-center justify-center rounded-full border border-gray-400 text-xs'
        }
      >
        {stepToDisplayIndex + 1}
      </div>
    );
  } else if (currentStepIndex === stepToDisplayIndex) {
    return (
      <div
        className={
          'flex h-5 w-5 items-center justify-center rounded-full border border-none border-gray-400 bg-fuchsia-50 text-xs text-fuchsia-900'
        }
      >
        {stepToDisplayIndex + 1}
      </div>
    );
  } else if (currentStepIndex > stepToDisplayIndex) {
    return (
      <CheckCircleIcon
        className="-m-1 h-7 w-7 text-green-500"
        aria-hidden="true"
      />
    );
  }
}

export default function FlowProgressBar(props: {
  step: AlpacaStep;
  setStep: (step: AlpacaStep) => void;
  customSteps: AlpacaStep[] | null;
}) {
  const { step, setStep, customSteps } = props;

  return (
    <div className="sticky top-0 z-20 flex flex-row items-center justify-center gap-3 border-b bg-white px-4 py-6 text-gray-600">
      <CustomSteps
        customSteps={customSteps ?? []}
        setStep={setStep}
        currentStep={step}
      />
    </div>
  );
}

function CustomSteps(props: {
  customSteps: AlpacaStep[];
  setStep: (step: AlpacaStep) => void;
  currentStep: AlpacaStep;
}) {
  const { customSteps, setStep, currentStep } = props;

  let stepsToRender: AlpacaStep[] = ['Quote Inputs' as AlpacaStep].concat(
    customSteps.sort((a, b) => {
      return (
        ALPACA_CANONICAL_STEP_ORDER.indexOf(a) -
        ALPACA_CANONICAL_STEP_ORDER.indexOf(b)
      );
    }),
  );

  if (customSteps.length === 0) {
    // Since there are no customSteps yet, we show that the next steps will be to do pricing
    // (this is not clickable)
    stepsToRender = stepsToRender.concat(['Pricing' as AlpacaStep]);
  } else {
    stepsToRender = stepsToRender.concat(['Deal Summary' as AlpacaStep]);
  }

  // @TODO(fay) do consolidation
  // const showConsolidatedSteps = customSteps.length > 2;

  return (
    <div className="flex flex-row items-center justify-center gap-3 bg-white text-gray-600">
      {stepsToRender.map((step, index) => {
        const disabled = step === ('Pricing' as AlpacaStep);
        return (
          <div
            key={step}
            className="flex flex-row items-center justify-center gap-3"
          >
            {index > 0 && (
              <ChevronRightIcon
                className="h-4 w-4 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
            )}

            <button
              className={classNames(
                disabled ? '' : 'hover:text-fuchsia-900',
                'flex flex-row items-center gap-2 text-sm',
              )}
              onClick={() => setStep(step)}
              disabled={disabled}
            >
              {icon(stepsToRender, currentStep, step)}
              {step == currentStep ? (
                <span className="font-semibold">{step}</span>
              ) : (
                <span>{step}</span>
              )}
            </button>
          </div>
        );
      })}
    </div>
  );
}
