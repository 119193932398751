import { Link } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Investors } from './components/Investors';
import Nav from './components/Nav';
import {
  ProductFeatureCards,
  SectionCard,
  SectionText,
} from './components/Sections';

import Rive from '@rive-app/react-canvas';
import { useState } from 'react';
import HeroImage from './assets/Hero.svg';
import HeroAnimation from './assets/Hero_animation.riv';
import HeroMobile from './assets/Hero_mobile.svg';
import rectangle from './assets/rectangle.svg';
import StickyNav from './components/StickyNav';

const VerticalLine = () => (
  <>
    <div className="relative w-full pb-8 flex justify-center -z-20">
      <hr className="absolute left-1/2 transform -translate-x-1/2 translate-y-8 border-l border-pink-200 h-screen" />
    </div>
  </>
);

const HorizontalLine = () => (
  <>
    <div className="w-full invisible lg:visible">
      <hr className="border-t border-pink-200 w-full max-w-[1175px] mx-auto translate-y-11" />
    </div>
    <div className="relative flex flex-row justify-between top-[38px] max-w-[1175px] mx-auto">
      <img src={rectangle} className="invisible lg:visible" />
      <img src={rectangle} className="invisible lg:visible" />
      <img src={rectangle} className="invisible lg:visible" />
    </div>
  </>
);

function LandingPage() {
  const [useStaticFallbackImage, setUseStaticFallbackImage] = useState(false);

  return (
    <section>
      <StickyNav />
      <SectionCard containerStyle="bg-gradient-to-br from-pink-100 to-zinc-50 pt-0 pb-0 sm:!px-0">
        <Nav />
        <main className="pt-16">
          <div className="sm:px-12">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center">
              <h1 className="font-['Kepler'] font-display mx-auto max-w-4xl text-5xl font-bold tracking-tight text-[#5B0F4D] sm:text-[85px]">
                Data-driven deal pricing
              </h1>
              <p className="mx-auto mt-6 max-w-2xl text-lg sm:text-xl text-[#5B0F4D] ">
                Optimize sales rep pricing performance and recover lost revenue.
              </p>
              <div className="mt-10 flex justify-center gap-x-6">
                <Link
                  to={'https://calendly.com/spyrikaras'}
                  className="bg-[#83196E] hover:bg-fuchsia-800 focus-visible:outline-cta flex items-center justify-center rounded-md px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Book a demo
                </Link>
              </div>
            </div>
            <HorizontalLine />
          </div>
          <VerticalLine />
          {useStaticFallbackImage ? (
            <img
              src={HeroImage}
              alt="hero"
              className="w-full max-w-3xl mx-auto hidden sm:block -translate-x-1 -translate-y-1.5"
            />
          ) : (
            <Rive
              src={HeroAnimation}
              className="min-h-[300px] hidden sm:block "
              onError={() => setUseStaticFallbackImage(true)}
            />
          )}
          <img
            src={HeroMobile}
            alt="hero"
            className="w-full max-w-3xl mx-auto sm:hidden xs:px-20"
          />
        </main>
      </SectionCard>
      <Investors />
      <SectionCard
        containerStyle="bg-gradient-to-b from-slate-100 to-slate-50"
        id="product"
      >
        <SectionText
          superText={'Boost rep productivity'}
          title={'Cut deal pricing time to 3 minutes'}
          subtitle={
            'Eliminate hours spent on spreadsheets, unintuitive CPQ interfaces, and deal desk meetings. Price faster and smarter.'
          }
        />
        <ProductFeatureCards
          features={[
            {
              id: 1,
              name: 'Smart configuration',
              description:
                'Configure deals in seconds with use-case-based selling, SKU preselection, and cloning successful past deals.',
              imageSrc: require('./assets/features/feature_1_1.svg').default,
              imageAlt: 'placeholder',
              textContainerStyle: 'bg-white',
            },
            {
              id: 2,
              name: 'Automated pricing, structuring and forecasting',
              description:
                'Harness full automation for even the most complex deal structures, incl. tiers, ramps, and contractual minimums.',
              imageSrc: require('./assets/features/feature_1_2.svg').default,
              imageAlt: 'placeholder',
              textContainerStyle: 'bg-white',
            },
            {
              id: 3,
              name: 'One-click quote generation',
              description:
                'Once your deal is set, press a single button to generate your quote instantly.',
              imageSrc: require('./assets/features/feature_1_3.svg').default,
              imageAlt: 'placeholder',
              textContainerStyle: 'bg-white',
            },
          ]}
        />
      </SectionCard>
      <SectionCard containerStyle="bg-gradient-to-b from-slate-100 to-slate-50">
        <SectionText
          superText={'Recover revenue lost to poor pricing performance'}
          title={'Optimize deal pricing with custom pricing recommendations'}
          subtitle={
            'Our advanced algorithms integrate rules, historical deal insights, competitive intelligence, user intent signals, and more for smarter, more effective pricing.'
          }
        />
        <ProductFeatureCards
          features={[
            {
              id: 1,
              name: 'Enforce pricing guidance',
              description: `Leverage our deal-specific pricing recommendations that align with your finance team's guidance, reducing the need for approvals.`,
              imageSrc: require('./assets/features/feature_2_1.svg').default,
              imageAlt: 'placeholder',
              imageContainerStyle: 'aspect-w-[7.4]',
              textContainerStyle: 'bg-white',
            },
            {
              id: 2,
              name: 'Update pricing guidance in real-time',
              description: `Integrate real-time market insights and rep feedback into your pricing guidance to maximize win rates and margins.`,
              imageSrc: require('./assets/features/feature_2_2.svg').default,
              imageAlt: 'placeholder',
              imageContainerStyle: 'aspect-w-[7.4]',
              textContainerStyle: 'bg-white',
            },
            {
              id: 3,
              name: 'A/B test negotiation strategies',
              description: `Experiment with pricing, floors, and structures to discover customer willingness to pay and boost sales performance.`,
              imageSrc: require('./assets/features/feature_2_3.svg').default,
              imageAlt: 'placeholder',
              imageContainerStyle: 'aspect-w-[7.4]',
              textContainerStyle: 'bg-white',
            },
          ]}
        />
      </SectionCard>
      <SectionCard containerStyle="bg-neutral-800">
        <SectionText
          superText={'Without any compromise'}
          superTextStyle="text-neutral-400"
          title={
            'Built around your data, tailored to your workflow, with security at its core'
          }
          titleStyle="text-white"
          subtitle={
            'Our platform was built to fit your specific needs and complexities, offering a secure, customized approach to pricing challenges.'
          }
          subtitleStyle="text-neutral-400"
        />
        <ProductFeatureCards
          features={[
            {
              id: 1,
              name: 'Import your pricing guidance from any source',
              nameStyle: 'text-white',
              description: `Salesforce, multiple spreadsheets, Notion, Confluence? Consider it done!`,
              descriptionStyle: 'text-neutral-400',
              imageSrc: require('./assets/features/feature_3_1.svg').default,
              imageAlt: 'placeholder',
              imageContainerStyle: 'aspect-w-[7.4] bg-neutral-700/30',
              containerStyle: 'border-neutral-400/30',
              textContainerStyle: 'bg-neutral-700/30',
            },
            {
              id: 2,
              name: 'Seamless integration with your tech stack',
              nameStyle: 'text-white',

              description: `Out-of-the-box integrations with popular CRMs, CPQs, and billing platforms.`,
              descriptionStyle: 'text-neutral-400',

              imageSrc: require('./assets/features/feature_3_2.svg').default,
              imageAlt: 'placeholder',
              imageContainerStyle: 'aspect-w-[7.4] bg-neutral-700/30',
              containerStyle: 'border-neutral-400/30',
              textContainerStyle: 'bg-neutral-700/30',
            },
            {
              id: 3,
              name: 'Commitment to privacy and security',
              nameStyle: 'text-white',
              description: `SOC 2 Type II certified, Dealops prioritizes your privacy. Your data remains yours and completely confidential.`,
              descriptionStyle: 'text-neutral-400',
              imageSrc: require('./assets/features/feature_3_3.svg').default,
              imageAlt: 'placeholder',
              imageContainerStyle: 'aspect-w-[7.4] bg-neutral-700/30',
              containerStyle: 'border-neutral-400/30',
              textContainerStyle: 'bg-neutral-700/30',
            },
          ]}
        />
      </SectionCard>
      <Footer />
    </section>
  );
}

export default LandingPage;
