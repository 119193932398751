import React, {
  createContext,
  MouseEvent,
  ReactNode,
  useContext,
  useState,
} from 'react';

interface TooltipContextType {
  setTooltipText: React.Dispatch<
    React.SetStateAction<string | null | ReactNode>
  >;
}

const TooltipContext = createContext<TooltipContextType | undefined>(undefined);

export const useTooltip = () => {
  const context = useContext(TooltipContext);
  if (context == null) {
    console.error(
      'You are trying to use a tooltip outside of the TooltipContext',
    );
    return { setTooltipText: (arg0: string | null | ReactNode) => {} };
  } else {
    return context;
  }
};

interface MouseTooltipProps {
  children: ReactNode;
}

const MouseTooltip: React.FC<MouseTooltipProps> = ({ children }) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [tooltipText, setTooltipText] = useState<string | null | ReactNode>(
    null,
  );

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    setPosition({ x: e.clientX + 15, y: e.clientY + 15 }); // Adjust 10 pixels down
  };

  return (
    <div
      className="relative inline-block w-full h-full"
      onMouseMove={handleMouseMove}
    >
      <TooltipContext.Provider value={{ setTooltipText }}>
        {children}
      </TooltipContext.Provider>
      {tooltipText != null && (
        <div
          className="fixed z-[100] whitespace-pre rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700"
          style={{ left: position.x, top: position.y }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default MouseTooltip;
