import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo.svg';
import rectangle from '../assets/rectangle.svg';

const navigation = [
  { name: 'Product', href: '#product' },
  //   { name: 'Team', href: 'https://www.google.com' },
  { name: 'Privacy & Security', href: '/security' },
];

export const smoothScrollTo = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  id: string,
) => {
  e.preventDefault();
  document.querySelector(id)?.scrollIntoView({ behavior: 'smooth' });
};

export const HorizontalLineTop = () => (
  <>
    <div className="relative w-full top-12 invisible sm:visible">
      <hr className="absolute left-1/2 -translate-x-1/2 border-t border-pink-200 w-screen" />
    </div>
    <div className="relative flex flex-row justify-between top-11 max-w-[1175px] mx-auto">
      <img src={rectangle} className="invisible sm:visible" />
      <img src={rectangle} className="invisible sm:visible" />
    </div>
  </>
);

export const HorizontalLineBottom = () => (
  <>
    <div className="relative w-full -top-3 invisible sm:visible">
      <hr className="absolute left-1/2 -translate-x-1/2 border-t border-pink-200 w-screen" />
    </div>
    <div className="relative flex flex-row justify-between -top-[17px] max-w-[1175px] mx-auto">
      <img src={rectangle} className="invisible sm:visible" />
      <img src={rectangle} className="invisible sm:visible" />
    </div>
  </>
);

export const VerticalLines = () => (
  <div className="flex flex-row justify-between mx-auto max-w-[1175px] px-[6px] invisible lg:visible">
    <div className="relative flex justify-center -z-10">
      <hr className="absolute left-1/2 transform -translate-x-1/2 translate-y-12 border-l border-pink-200 h-screen" />
    </div>
    <div className="relative flex justify-center -z-10">
      <hr className="absolute left-1/2 transform -translate-x-1/2 translate-y-12 border-l border-pink-200 h-screen" />
    </div>
  </div>
);

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="sm:px-12">
      <VerticalLines />
      <HorizontalLineTop />
      <nav
        className="mx-auto flex max-w-6xl items-center justify-between pt-12 p-6"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <span className="sr-only">dealops</span>
          <img src={Logo} />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-8">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={(e) =>
                item.href.includes('#') ? smoothScrollTo(e, item.href) : null
              }
              className="text-sm font-semibold leading-6 rounded-lg px-3 py-1 text-[#5B0F4D] transition duration-300 ease-in-out hover:text-[#981781] hover:bg-[#EED4E5] hover:bg-opacity-70"
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
          <Link
            to="/login"
            className="text-sm font-semibold leading-6 rounded-lg text-[#5B0F4D] max-h-8 mt-1 py-1 px-3  transition duration-300 ease-in-out hover:text-[#981781] hover:bg-[#EED4E5] hover:bg-opacity-70"
          >
            Log in
          </Link>
          <Link
            to={'https://calendly.com/spyrikaras'}
            className="bg-[#83196E] hover:bg-fuchsia-800 focus-visible:outline-cta flex items-center justify-center rounded-md px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ml-4"
          >
            Book a demo
          </Link>
        </div>
      </nav>
      <HorizontalLineBottom />
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <span className="sr-only">dealops</span>
            <img className="h-8 w-auto" src={Logo} alt="dealops" />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </Link>
              </div>
              <div className="py-6">
                <Link
                  to="https://calendly.com/spyrikaras"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Book a demo
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
