import React, { useCallback, useEffect, useState } from 'react';
import { usePricingFlowContext } from 'src/dashboard/PricingFlow/PricingFlow';
import './AlpacaRangeSlider.css';

/**
 * Ported from https://tailwindcomponents.com/component/multi-range-slider
 * with chatgpt: https://chatgpt.com/share/baf8458c-ddfb-49cd-9408-6ca1dcf36db4
 */

type AlpacaRangeSliderProps = {
  firstSeparator: number;
  secondSeparator: number;
  setFirstSeparator: React.Dispatch<React.SetStateAction<number>>;
  setSecondSeparator: React.Dispatch<React.SetStateAction<number>>;
  onMouseUp: () => void;
};

const AlpacaRangeSlider: React.FC<AlpacaRangeSliderProps> = ({
  firstSeparator,
  secondSeparator,
  setFirstSeparator,
  setSecondSeparator,
  onMouseUp,
}: AlpacaRangeSliderProps) => {
  const { editMode } = usePricingFlowContext();
  const min = 0;
  const max = 100;
  const [minThumb, setMinThumb] = useState<number>(0);
  const [maxThumb, setMaxThumb] = useState<number>(0);

  const minTrigger = useCallback(() => {
    setFirstSeparator((prevFirstSeparator: number) =>
      Math.min(prevFirstSeparator, secondSeparator),
    );
    setMinThumb(((firstSeparator - min) / (max - min)) * 100);
  }, [firstSeparator, secondSeparator, min, max]);

  const maxTrigger = useCallback(() => {
    setSecondSeparator((prevSecondSeparator) =>
      Math.max(prevSecondSeparator, firstSeparator),
    );
    setMaxThumb(100 - ((secondSeparator - min) / (max - min)) * 100);
  }, [firstSeparator, secondSeparator, min, max]);

  useEffect(() => {
    minTrigger();
    maxTrigger();
  }, [minTrigger, maxTrigger]);

  return (
    <div className="flex justify-center items-center h-8">
      <div className="relative max-w-xl w-full">
        <div>
          <input
            type="range"
            min={min}
            max={max}
            onInput={minTrigger}
            value={firstSeparator}
            onChange={(e) => setFirstSeparator(Number(e.target.value))}
            className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
            onMouseUp={onMouseUp}
            disabled={!editMode}
          />
          <input
            type="range"
            min={min}
            max={max}
            onInput={maxTrigger}
            value={secondSeparator}
            onChange={(e) => setSecondSeparator(Number(e.target.value))}
            className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
            onMouseUp={onMouseUp}
            disabled={!editMode}
          />
          <div className="relative z-10 h-2">
            <div className="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-[#EFEFEF] border border-gray-400 h-[7px]"></div>
            <div
              className="absolute z-20 top-0 bottom-0 rounded-md bg-fuchsia-800 h-[7px]"
              style={{ right: `${maxThumb}%`, left: `${minThumb}%` }}
            ></div>
            <div
              className="absolute z-30 w-4 h-4 top-0 left-0 bg-fuchsia-800 rounded-full -mt-[5px] -ml-1"
              style={{ left: `${minThumb}%` }}
            ></div>
            <div
              className="absolute z-30 w-4 h-4 top-0 right-0 bg-fuchsia-800 rounded-full -mt-[5px] -mr-3"
              style={{ right: `${maxThumb}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlpacaRangeSlider;
