import { useState } from 'react';
import { InlineSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
type ButtonConstructor = {
  label: string;
  successLabel?: string;
  errorLabel?: string;
  onClick: () => Promise<void | ButtonState>;
};

export default function BottomBar({
  helpText,
  primaryButtonProps = { label: 'Next', onClick: () => Promise.resolve() },
  secondaryButtonProps,
}: {
  helpText?: string;
  primaryButtonProps: ButtonConstructor;
  secondaryButtonProps?: ButtonConstructor;
}) {
  return (
    <div className="fixed bottom-0 left-0 z-30 flex h-16 w-full flex-row-reverse items-center border-t bg-white">
      <div className="mx-6 flex flex-row items-center gap-3">
        {helpText}
        {secondaryButtonProps && (
          <Button
            onClick={secondaryButtonProps.onClick}
            label={secondaryButtonProps.label}
            successLabel={secondaryButtonProps.successLabel}
            errorLabel={secondaryButtonProps.errorLabel}
            type="secondary"
          />
        )}
        <Button
          onClick={primaryButtonProps.onClick}
          label={primaryButtonProps.label}
          successLabel={primaryButtonProps.successLabel}
          errorLabel={primaryButtonProps.errorLabel}
          type="primary"
        />
      </div>
    </div>
  );
}

type ButtonState = 'idle' | 'loading' | 'success' | 'error';
type ButtonType = 'primary' | 'secondary';

function Button(props: {
  label: string;
  successLabel?: string;
  errorLabel?: string;
  type: ButtonType;
  onClick: () => Promise<void | ButtonState>;
}) {
  const { onClick, label, successLabel, errorLabel, type } = props;
  const { hideToast } = useToast();
  const [state, setState] = useState<ButtonState>('idle');

  const handleClick = async () => {
    hideToast();
    setState('loading');
    const responseState = await onClick();
    if (responseState) {
      setState(responseState);
    } else {
      errorLabel == null ? setState('idle') : setState('error');
    }
  };

  return (
    <button
      onClick={handleClick}
      className={classNames(
        // Button types
        type === 'primary'
          ? 'border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900'
          : '',
        type === 'secondary'
          ? 'border-gray-200 bg-white text-black hover:bg-gray-200 focus-visible:outline-fuchsia-900'
          : '',

        // Button state
        state === 'loading' ? 'pointer-events-none' : '',
        state === 'success'
          ? 'pointer-events-none border-green-600 bg-green-600'
          : '',
        state === 'error'
          ? 'pointer-events-none border-red-600 bg-red-600'
          : '',

        // Common styles
        'col-span-full inline-flex w-60 items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-5 py-2 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
      )}
      disabled={state !== 'idle'}
    >
      {state === 'loading' && <InlineSpinner />}

      {state === 'idle' && label}
      {state === 'success' && successLabel}
      {state === 'error' && errorLabel}
    </button>
  );
}
