import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useRef, useState } from 'react';
import { classNames } from 'src/dashboard/App';
import { InlineSpinner } from './Loading';

type ModalProps = {
  type?: 'alert';
  dismissable?: boolean;
  title: string;
  children: React.ReactNode;
  primaryButton?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
  className?: string;
};

const iconMapping = {
  alert: (
    <ExclamationTriangleIcon
      className="h-6 w-6 text-red-600"
      aria-hidden="true"
    />
  ),
};

export default function Modal(props: ModalProps & { hideModal: () => void }) {
  const { title, primaryButton, secondaryButton, hideModal, className } = props;
  const dismissable =
    props.dismissable !== undefined ? props.dismissable : true;
  const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);

  const cancelButtonRef = useRef(null);

  const icon =
    props.type && iconMapping[props.type] ? (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        {iconMapping[props.type]}
      </div>
    ) : null;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => (dismissable ? hideModal() : null)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full w-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6',
                  className,
                )}
              >
                {dismissable && (
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-fuchsia-800 focus:ring-offset-2"
                      onClick={() => hideModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                )}

                <div className="sm:items-start">
                  {icon}

                  <div className="mt-3 text-center  sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">{props.children}</div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {primaryButton && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-fuchsia-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 sm:ml-3 sm:w-auto"
                      disabled={primaryButtonLoading}
                      onClick={async () => {
                        setPrimaryButtonLoading(true);
                        await primaryButton.onClick();
                        hideModal();
                      }}
                    >
                      {/* @TODO(fay) fix the style of the spinner */}
                      {primaryButtonLoading && <InlineSpinner />}{' '}
                      {primaryButton.text}
                    </button>
                  )}
                  {secondaryButton && (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      disabled={secondaryButtonLoading}
                      onClick={async () => {
                        setSecondaryButtonLoading(true);
                        await secondaryButton.onClick();
                        hideModal();
                      }}
                      ref={cancelButtonRef}
                    >
                      {secondaryButtonLoading && <InlineSpinner />}{' '}
                      {secondaryButton.text}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

type ModalContextProps = {
  showModal: (content: ModalProps) => void;
  hideModal: () => void;
};

const ModalContext = React.createContext<ModalContextProps | undefined>(
  undefined,
);

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = React.useState<ModalProps | null>(null);

  const showModal: ModalContextProps['showModal'] = (content) => {
    setModal(content);
  };

  const hideModal = () => {
    setModal(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modal && <Modal {...modal} hideModal={hideModal} />}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
