import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

type AppPageHeaderProps = {
  title: string;
  subtitle?: string;
  onClickBack?: () => void;
  backPageLabel?: string;
  rightChildren?: ReactNode;
};

export default function AppPageHeader(props: AppPageHeaderProps) {
  const { title, subtitle, backPageLabel, onClickBack, rightChildren } = props;
  const navigate = useNavigate();

  return (
    <div className="bg-white px-4 pt-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-8">
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <button
            type="button"
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={onClickBack ? onClickBack : () => navigate('/app')}
          >
            <ChevronLeftIcon
              className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            Back
          </button>
        </nav>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <NavLink
                  to="/app"
                  className="text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  Home
                </NavLink>
              </div>
            </li>
            {backPageLabel && (
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <button
                    type="button"
                    onClick={onClickBack}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {backPageLabel}
                  </button>
                </div>
              </li>
            )}
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <div
                  aria-current="page"
                  className="ml-4 text-sm font-medium text-gray-500"
                >
                  {title}
                </div>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {title}
          </h2>
          {subtitle && <h3 className="text-gray-700">{subtitle}</h3>}
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          {rightChildren}
        </div>
      </div>
    </div>
  );
}
