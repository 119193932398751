import { datadogRum } from '@datadog/browser-rum';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import AppPageHeader from 'src/components/AppPageHeader';
import { InlineSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import OverflowMenu from 'src/components/OverflowMenu';
import { useToast } from 'src/components/Toast';
import logoSalesforce from '../images/logos/salesforce-logo.png';
import logoSlack from '../images/logos/Slack-logo-RGB.png';
import { Connection, Organization, Role, User } from '../types';

interface SettingsPageProps {
  user: User;
  organization: Organization;
}

const AMPERSAND_API_HOST = 'https://api.withampersand.com';

export default function SettingsPage(props: SettingsPageProps) {
  const { user, organization } = props;
  const { showToast } = useToast();

  // if query param error is present show toast
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    if (error) {
      showToast({
        title: 'Something went wrong. Please try again.',
        subtitle: '',
        type: 'error',
      });
    }
  }, [showToast]);

  const [connections, setConnections] = useState<Array<Connection>>([]);
  async function fetchConnections() {
    try {
      let connectionsResponse = await api.get(`connections`);
      setConnections(connectionsResponse.data);
    } catch (error: any) {
      datadogRum.addError(error);
      console.log(error);
    }
  }
  useEffect(() => {
    fetchConnections();
  }, [props.organization.id]);

  return (
    <>
      <AppPageHeader
        title={'Settings'}
        subtitle={`Settings for you and ${props.organization.name}`}
      />
      <PersonalSettings
        user={user}
        organization={organization}
        connections={connections}
      />
      <Integrations
        user={user}
        organization={organization}
        connections={connections}
        refreshConnections={fetchConnections}
      />
      <Team user={user} organization={organization} />
      <div className="pb-6" />
    </>
  );
}

function PersonalSettings(
  props: SettingsPageProps & { connections: Array<Connection> },
) {
  const { showToast } = useToast();

  const slackConnection = props.connections.find((c) => c.provider === 'SLACK');
  const connected =
    slackConnection &&
    props.user.slackUserId !== undefined &&
    props.user.slackUserId !== null;

  async function handleConnectSlack() {
    try {
      console.log('hel');
      const response = await api.post('connections', { provider: 'slack' });
      console.log('got a url back');
      window.location.href = response.data.redirectUrl;
    } catch (error: any) {
      datadogRum.addError(error);
      console.log(error);
      showToast({
        title: 'Something went wrong. Please try again.',
        subtitle: '',
        type: 'error',
      });
    }
  }

  return (
    <div className="mx-4 mt-4 rounded-lg border border-gray-300 bg-white px-4 py-6 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            User settings
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Settings for you, {props.user.name}.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        {/* Slack connection */}
        <div className="flex items-center justify-between gap-x-3 rounded-md border border-gray-300 p-4">
          <div className="flex items-center gap-x-3">
            <img className="h-5" src={logoSlack} alt="Slack" />
            {connected ? (
              <span className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Connected
              </span>
            ) : (
              <span className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                Not connected
              </span>
            )}
          </div>

          {connected ? (
            <button
              className="text-sm text-blue-700"
              onClick={handleConnectSlack}
            >
              Update
            </button>
          ) : (
            <button
              className="text-sm text-blue-700"
              onClick={handleConnectSlack}
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function SalesforceConnector({
  salesforceConnection,
  refreshConnections,
}: {
  salesforceConnection?: Connection;
  refreshConnections: () => Promise<void>;
}) {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [providerWorkspaceRef, setProviderWorkspaceRef] = useState<string>('');

  const hasConnection = salesforceConnection !== undefined;

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (event.origin !== AMPERSAND_API_HOST) {
        return;
      }

      if (event.data && event.data.eventType === 'AUTHORIZATION_SUCCEEDED') {
        try {
          const result = await api.post('ampersand_create_connection', {
            connection: event.data.data.connection,
            providerWorkspaceRef: providerWorkspaceRef,
            connectionId: event.data.data.connection,
          });

          if (result.status === 200 && result.data.id) {
            setIsLoading(false);
            await refreshConnections();
            showToast({
              title: 'Salesforce connection created successfully!',
              subtitle: '',
              type: 'success',
            });
          }
        } catch (error: any) {
          console.log(error);
          datadogRum.addError(error);
          showToast({
            title: "Couldn't create a Salesforce connection, please try again.",
            subtitle: '',
            type: 'error',
          });
          setIsLoading(false);
        }
      } else {
        showToast({
          title: "Couldn't connect to Salesforce, please try again.",
          subtitle: '',
          type: 'error',
        });
        setIsLoading(false);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [providerWorkspaceRef]);

  async function handleConnectSalesforce() {
    try {
      setIsLoading(true);
      const response = await api.post('connections', {
        provider: 'salesforce',
        providerWorkspaceRef: providerWorkspaceRef,
      });

      let redirectUrl = response.data.redirectUrl;
      window.open(redirectUrl, '_blank');
    } catch (error: any) {
      datadogRum.addError(error);
      showToast({
        title: 'Something went wrong. Please try again.',
        subtitle: '',
        type: 'error',
      });
      setIsLoading(false);
    }
  }

  async function handleDisconnectSalesforce() {
    if (!salesforceConnection)
      throw new Error('No salesforce connection ID found');
    try {
      setIsLoading(true);

      const response = await api.delete(
        `connections/${salesforceConnection.id}`,
        {},
      );

      if (response.status === 200) {
        await refreshConnections();
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-end">
        <InlineSpinner />
      </div>
    );
  }

  return (
    <>
      {hasConnection ? (
        <button
          className="text-sm text-blue-700"
          onClick={handleDisconnectSalesforce}
        >
          Disconnect
        </button>
      ) : (
        <div className="flex flex-row items-end items-center gap-4">
          <input
            value={providerWorkspaceRef}
            onChange={(e) => setProviderWorkspaceRef(e.target.value)}
            className={
              'w-96 rounded-md border border-gray-300 p-2 text-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent'
            }
            placeholder="Salesforce instance e.g. mycompany.sandbox"
          />
          <button
            className="text-sm text-blue-700"
            onClick={() => {
              handleConnectSalesforce();
            }}
          >
            Connect
          </button>
        </div>
      )}
    </>
  );
}

function SlackConnector({
  slackConnection,
  refreshConnections,
}: {
  slackConnection?: Connection;
  refreshConnections: () => Promise<void>;
}) {
  const hasConnection = slackConnection !== undefined;

  const { showToast } = useToast();

  async function handleConnectSlack() {
    try {
      const response = await api.post('connections', { provider: 'slack' });
      window.location.href = response.data.redirectUrl;
    } catch (error: any) {
      datadogRum.addError(error);
      console.log(error);
      showToast({
        title: 'Something went wrong. Please try again.',
        subtitle: '',
        type: 'error',
      });
    }
  }
  async function handleDisconnectSlack() {
    if (!slackConnection) throw new Error('No slack connection ID found');

    const response = await api.delete(`connections/${slackConnection.id}`, {});

    if (response.status === 200) refreshConnections();
  }

  return (
    <>
      {hasConnection ? (
        <button
          className="text-sm text-blue-700"
          onClick={handleDisconnectSlack}
        >
          Disconnect
        </button>
      ) : (
        <button className="text-sm text-blue-700" onClick={handleConnectSlack}>
          Connect
        </button>
      )}
    </>
  );
}

function Integrations(
  props: SettingsPageProps & {
    connections: Array<Connection>;
    refreshConnections: () => Promise<void>;
  },
) {
  const { connections, refreshConnections } = props;

  // Salesforce
  const salesforceConnection = connections.find(
    (c) => c.provider === 'SALESFORCE',
  );
  const salesforceConnected = salesforceConnection !== undefined;

  // Slack
  const slackConnection = connections.find((c) => c.provider === 'SLACK');
  const slackConnected =
    slackConnection !== undefined && slackConnection !== null;

  return (
    <div className="mx-4 mt-4 rounded-lg border border-gray-300 bg-white px-4 py-6 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Integrations
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            All integrations connected to your organization.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        {/* Salesforce connection */}
        <div className="flex items-center justify-between gap-x-3 rounded-md border border-gray-300 p-4">
          <div className="flex items-center gap-x-3">
            <img className="h-5" src={logoSalesforce} alt="Salesforce" />
            {salesforceConnected ? (
              <span className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Connected
              </span>
            ) : (
              <span className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                Not connected
              </span>
            )}
          </div>

          <SalesforceConnector
            salesforceConnection={salesforceConnection}
            refreshConnections={refreshConnections}
          />
        </div>

        {/* Slack connection */}
        <div className="mt-2 flex items-center justify-between gap-x-3 rounded-md border border-gray-300 p-4">
          <div className="flex items-center gap-x-3">
            <img className="h-5" src={logoSlack} alt="Slack" />
            {slackConnected ? (
              <span className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Connected
              </span>
            ) : (
              <span className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                Not connected
              </span>
            )}
          </div>

          <SlackConnector
            slackConnection={slackConnection}
            refreshConnections={refreshConnections}
          />
        </div>
      </div>
    </div>
  );
}

function AddUserModalContent(props: { refreshUsers: () => void }) {
  const { hideModal } = useModal();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const createAnalyticsEvent = useAnalyticsContext();

  // Event handler for input changes
  const handleInputChange =
    (field: 'name' | 'email' | 'role') =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.target;
      setFormData({ ...formData, [field]: value });
    };

  // Event handler for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post(`users`, {
        email: formData.email,
        name: formData.name,
        role: formData.role,
      });
      console.log('response: ', response);

      await props.refreshUsers();
      createAnalyticsEvent({
        name: 'user__added_new_user',
        eventData: {
          target_user_id: response.data.id,
        },
      });
      // Close the modal after a successful invite
      hideModal();
    } catch (error) {
      datadogRum.addError(error);
      setError('Unable to invite user, contact support@dealops.com');
    }
    setLoading(false);
  };

  return (
    <form className="mt-5 w-full" onSubmit={handleSubmit}>
      <div className="w-full pb-4">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          placeholder={`user@acme.com`}
          value={formData.email}
          onChange={handleInputChange('email')}
          required
        />
      </div>
      <div className="w-full pb-4">
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          placeholder={`Marie Curie`}
          value={formData.name}
          onChange={handleInputChange('name')}
          required
        />
      </div>
      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Role
        </label>
        <select
          name="role"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          value={formData.role}
          onChange={handleInputChange('role')}
          required
        >
          {Object.values(Role).map((role) => (
            <option key={role} value={role}>
              {getUserFriendlyRole(role)}
            </option>
          ))}
        </select>
      </div>
      {error && <span className="text-red-500">{error}</span>}
      <div className="mt-5 pt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-fuchsia-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 sm:ml-3 sm:mt-0 sm:w-auto gap-2"
          disabled={loading}
        >
          {loading && <InlineSpinner />} Add
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          disabled={loading}
          onClick={async () => {
            hideModal();
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

function ReactivateUserModalContent(props: {
  user: User;
  refreshUsers: () => void;
}) {
  const { hideModal } = useModal();
  const [formData, setFormData] = useState({
    name: props.user.name ?? '',
    email: '',
    role: props.user.role,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const createAnalyticsEvent = useAnalyticsContext();

  // Event handler for input changes
  const handleInputChange =
    (field: 'email') =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.target;
      setFormData({ ...formData, [field]: value });
    };

  // Event handler for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post(`users/${props.user.id}/reactivate`, {
        email: formData.email,
        name: formData.name,
        role: formData.role,
      });
      if (response.status === 200) {
        await props.refreshUsers();
        createAnalyticsEvent({
          name: 'user__reactivated_user',
          eventData: {
            target_user_id: response.data.id,
          },
        });
        hideModal();
      }
    } catch (error: any) {
      datadogRum.addError(error);
      console.log('error: ', error.response.data.error);
      setError('Unable to reactivate user, contact support@dealops.com');
    }
    setLoading(false);
  };

  return (
    <form className="mt-5 w-full" onSubmit={handleSubmit}>
      <div className="w-full pb-4">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          placeholder={`user@acme.com`}
          value={formData.email}
          onChange={handleInputChange('email')}
          required
        />
      </div>
      <div className="w-full pb-4">
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          placeholder={`Marie Curie`}
          value={formData.name}
          onChange={() => {}}
          disabled={true}
          required
        />
      </div>
      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Role
        </label>
        <select
          name="role"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          value={formData.role}
          onChange={() => {}}
          disabled={true}
          required
        >
          {Object.values(Role).map((role) => (
            <option key={role} value={role}>
              {getUserFriendlyRole(role)}
            </option>
          ))}
        </select>
      </div>
      {error && <span className="text-red-500">{error}</span>}
      <div className="mt-5 pt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-fuchsia-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 sm:ml-3 sm:mt-0 sm:w-auto gap-2"
          disabled={loading}
        >
          {loading && <InlineSpinner />} Add
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          disabled={loading}
          onClick={async () => {
            hideModal();
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

function getUserFriendlyRole(role: Role) {
  switch (role) {
    case Role.ADMIN:
      return 'Admin';
    case Role.USER:
      return 'Rep';
    case Role.VIEW_ONLY:
      return 'Manager view-only';
  }
}

function EditUserModalContent(props: { user: User; refreshUsers: () => void }) {
  const { user, refreshUsers } = props;
  const { hideModal } = useModal();
  const [formData, setFormData] = useState<{
    name: string;
    role: Role;
  }>({
    name: user.name ?? '',
    role: user.role,
  });
  const { showToast } = useToast();
  const createAnalyticsEvent = useAnalyticsContext();

  // Event handler for input changes
  const handleInputChange =
    (field: 'name' | 'role') =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.target;
      setFormData({ ...formData, [field]: value });
    };

  // Event handler for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    handleEditUser({
      ...user,
      ...formData,
    });
    refreshUsers();
    hideModal();
  };

  async function handleEditUser(newUser: User) {
    try {
      const response = await api.put(`users/${newUser.id}`, newUser);
      if (response.status === 200) {
        if (newUser.role !== props.user.role) {
          createAnalyticsEvent({
            name: 'user__changed_user_role',
            eventData: {
              target_user_id: response.data.id,
              old_role: props.user.role,
              new_role: response.data.role,
            },
          });
        }
        await refreshUsers();
      }
    } catch (error: any) {
      datadogRum.addError(error);
      console.log('error: ', error.response.data.error);
      showToast({
        title: 'Could not edit user',
        subtitle: '',
        type: 'error',
      });
    }
  }

  return (
    <form className="mt-5 w-full" onSubmit={handleSubmit}>
      <div className="w-full pb-4">
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          placeholder={`Marie Curie`}
          value={formData.name}
          onChange={handleInputChange('name')}
          required
        />
      </div>
      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Role
        </label>
        <select
          name="role"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
          value={formData.role}
          onChange={handleInputChange('role')}
          required
        >
          {Object.values(Role).map((role) => (
            <option key={role} value={role}>
              {getUserFriendlyRole(role)}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-5 pt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-fuchsia-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 sm:ml-3 sm:mt-0 sm:w-auto"
        >
          Update
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={async () => {
            hideModal();
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

function Team(props: SettingsPageProps) {
  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState(false);
  const { showModal } = useModal();
  const { showToast } = useToast();
  const createAnalyticsEvent = useAnalyticsContext();

  async function refreshUsers() {
    try {
      let usersResponse = await api.get(`users`);
      // Sort users so it shows active first in alphabetical order, then deactivated in alphabetical order.
      usersResponse.data.sort((a: User, b: User) => {
        if (a.status === b.status) {
          return (a.name ?? '').localeCompare(b.name ?? '');
        }
        return a.status === 'ACTIVE' ? -1 : 1;
      });
      setUsers(usersResponse.data);
    } catch (error: any) {
      datadogRum.addError(error);
      console.log(error);
    }
  }

  useEffect(() => {
    refreshUsers();
  }, [props.organization.id]);

  async function handleInviteUser() {
    showModal({
      title: 'Add teammate',
      children: <AddUserModalContent refreshUsers={refreshUsers} />,
    });
  }

  async function handleDeactivateUser(user: User) {
    setLoading(true);
    try {
      const response = await api.post(`users/${user.id}/deactivate`, {});
      if (response.status === 200) {
        createAnalyticsEvent({
          name: 'user__deactivated_user',
          eventData: {
            target_user_id: response.data.id,
          },
        });
        await refreshUsers();
      }
    } catch (error: any) {
      datadogRum.addError(error);
      console.log('error: ', error.response.data.error);
      showToast({
        title: 'Could not deactivate user',
        subtitle: '',
        type: 'error',
      });
    }

    setLoading(false);
  }

  return (
    <div className="mx-4 mt-4 rounded-lg border border-gray-300 bg-white px-4 py-6 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Team
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the teammates in your organization.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-fuchsia-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            onClick={handleInviteUser}
          >
            Add teammate
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-opacity-75 py-3.5 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((user, idx) => (
                    <Teammate
                      key={idx}
                      user={user}
                      handleDeactivateUser={handleDeactivateUser}
                      currentUser={props.user}
                      refreshUsers={refreshUsers}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="p-0">
                      <div className="display-block py-20 text-center">
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                          No Teammates
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Get started by inviting a teammate.
                        </p>
                        <div className="mt-6">
                          {loading ? (
                            <InlineSpinner />
                          ) : (
                            <button
                              type="button"
                              className="inline-flex items-center rounded-md bg-fuchsia-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
                              onClick={handleInviteUser}
                            >
                              <PlusIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Invite
                            </button>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function Teammate(props: {
  user: User;
  handleDeactivateUser: (user: User) => void;
  currentUser: User;
  refreshUsers: () => void;
}) {
  const { user, handleDeactivateUser, currentUser, refreshUsers } = props;
  const { showToast } = useToast();
  const { showModal } = useModal();
  const isUserActive = user.status === 'ACTIVE';

  function handleOnClickDeactivate() {
    if (currentUser.id === user.id) {
      showToast({
        title: 'You cannot deactivate yourself',
        subtitle: '',
        type: 'error',
      });
      return;
    }
    handleDeactivateUser(user);
  }

  function handleOnClickReactivate() {
    if (currentUser.id === user.id) {
      showToast({
        title: 'You cannot reactivate yourself',
        subtitle: '',
        type: 'error',
      });
      return;
    }
    showModal({
      title: 'Reactivate teammate',
      children: (
        <ReactivateUserModalContent user={user} refreshUsers={refreshUsers} />
      ),
    });
  }

  function handleOnClickEdit() {
    showModal({
      title: 'Edit teammate',
      children: (
        <EditUserModalContent user={user} refreshUsers={refreshUsers} />
      ),
    });
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
        {user.name} {currentUser.id === user.id && '(You)'}
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {getUserFriendlyRole(user.role)}
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {user.status.charAt(0).toUpperCase() +
          user.status.slice(1).toLowerCase()}
      </td>
      <td className="whitespace-nowrap py-4 pr-8 text-right text-sm font-medium">
        <OverflowMenu
          menuItems={[
            isUserActive
              ? { name: 'Deactivate', onClick: handleOnClickDeactivate }
              : { name: 'Reactivate', onClick: handleOnClickReactivate },
            { name: 'Edit', onClick: handleOnClickEdit },
          ]}
          placement="left-start"
          offset={8}
        />
      </td>
    </tr>
  );
}
