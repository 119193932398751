import { ReactNode, useEffect, useRef, useState } from 'react';

export default function GraphContainer({
  header,
  children,
  className,
}: {
  header: ReactNode;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={className}>
      <div className="flex h-full w-full flex-col items-stretch justify-stretch gap-y-4 rounded-md p-4 outline outline-gray-200">
        <div>{header}</div>
        <div className="border-b border-gray-200"></div>
        <div className="overflow-show flex-1 self-stretch">{children}</div>
      </div>
    </div>
  );
}

// This component first renders and then initializes its childrens with its rendered size.
// If you use the height or width and the child causes those values to change this will result in
// buggy rendering. Therefore it is best to use this component only when the width or height is
// known from the start
export function RenderChildWithSize({
  className,
  childFunction,
}: {
  className?: string;
  childFunction: (width: number, height: number) => ReactNode;
}) {
  const componentRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();

  useEffect(() => {
    const updateSize = () => {
      if (componentRef.current) {
        setWidth(componentRef.current.offsetWidth);
        setHeight(componentRef.current.offsetHeight);
      }
    };
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  let child = null;
  if (width !== undefined && height !== undefined) {
    child = childFunction(width, height);
  }

  return (
    <div className={className} ref={componentRef}>
      {child}
    </div>
  );
}
