import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'src/dashboard/App';

type BreadcrumbStep = {
  label: string;
  onClick?: () => void;
  hoverComponent?: ReactNode;
};

type HeaderBreadcrumbsProps = {
  steps: BreadcrumbStep[];
};

export default function HeaderBreadcrumbs(props: HeaderBreadcrumbsProps) {
  const { steps } = props;
  const navigate = useNavigate();

  return (
    <div className="bg-white px-4 pt-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-8">
      <div>
        {/* Mobile Back Button */}
        <nav className="sm:hidden" aria-label="Back">
          <button
            type="button"
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={
              steps.length > 1
                ? (steps[steps.length - 2].onClick ?? (() => navigate(-1)))
                : () => navigate(-1)
            }
          >
            <ChevronLeftIcon
              className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            Back
          </button>
        </nav>

        {/* Breadcrumb Navigation */}
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2">
            {steps.map((step, index) => (
              <>
                {index !== 0 && (
                  <li>
                    <span className="text-gray-400 mx-2">/</span>
                  </li>
                )}
                <li key={step.label}>
                  <div className="flex items-center justify-start">
                    <div className="relative group">
                      {step.onClick && index !== steps.length - 1 ? (
                        <button
                          type="button"
                          onClick={step.onClick}
                          className={classNames(
                            'text-sm font-medium text-gray-500  hover:text-gray-700',
                          )}
                        >
                          {step.label}
                        </button>
                      ) : (
                        <div
                          aria-current={
                            index === steps.length - 1 ? 'page' : undefined
                          }
                          className={classNames(
                            'text-sm font-medium text-gray-500',
                            index === steps.length - 1 &&
                              'text-slate-800 font-semibold',
                          )}
                        >
                          {step.label}
                        </div>
                      )}
                      {step.hoverComponent && (
                        <div className="absolute hidden group-hover:block">
                          {step.hoverComponent}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
