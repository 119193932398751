import { Link } from 'react-router-dom';
import logo from '../assets/Logo.svg';
import rectangle from '../assets/rectangle.svg';
import { smoothScrollTo } from './Nav';

export function Footer() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-3 sm:px-6 lg:px-8 ">
        <div
          className={`relative isolate overflow-hidden text-center rounded-3xl px-3 sm:px-20 py-16 bg-gradient-to-br from-pink-100 to-zinc-50`}
        >
          <div className="flex flex-col lg:flex-row justify-between w-full">
            <div className="flex flex-col text-center lg:text-left ">
              <h3 className="text-3xl font-semibold leading-8 text-[#5B0F4D] sm:leading-6">
                Drop your pricing spreadsheets today!
              </h3>
              <p className="text-fuchsia-900 mt-6 leading-6 text-xl font-regular">
                Help your sales reps close deals faster. Reduce
                over-discounting.
              </p>
            </div>

            <div className="mt-4 sm:flex-shrink-0 lg:ml-4 lg:mt-0 pt-4 lg:pt-0 max-w-32 mx-auto lg:mx-0 lg:max-w-none">
              <Link
                to={'https://calendly.com/spyrikaras'}
                className="bg-[#83196E] hover:bg-fuchsia-800 focus-visible:outline-cta flex w-full items-center justify-center rounded-md px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Book a demo
              </Link>
            </div>
          </div>
          <div className="relative w-full pb-12">
            <hr className="absolute left-1/2 -translate-x-1/2 mt-12 border-t border-pink-200 w-screen" />
          </div>
          <div className="relative flex flex-row justify-between -top-[5px]">
            <img src={rectangle} />
            <img src={rectangle} />
          </div>
          <div className="flex flex-col-reverse lg:flex-row lg:justify-between items-center lg:items-start pt-10">
            <div className="flex flex-col text-left">
              <img src={logo} alt="Dealops" className="w-32 mx-auto lg:mx-0" />
              <p className="mt-8 text-sm text-pink-800/70">
                &copy; {new Date().getFullYear()} Dealops Inc. All rights
                reserved.
              </p>
            </div>

            <div className="mt-4 sm:flex-shrink-0 md:ml-4 md:mt-0 lg:pb-0 pb-14">
              <nav className="flex justify-between space-x-8">
                <Link
                  to={'#product'}
                  onClick={(e) => smoothScrollTo(e, '#product')}
                  className="font-semibold text-[#5B0F4D] hover:text-[#A33B8C]"
                >
                  Product
                </Link>
                {/* <Link to={'/'} className="font-semibold text-[#5B0F4D]">
                  Team
                </Link> */}
                <Link
                  to={'/security/privacy'}
                  className="font-semibold text-[#5B0F4D] hover:text-[#A33B8C]"
                >
                  Privacy
                </Link>
                <Link
                  to={'/security/tos'}
                  className="font-semibold text-[#5B0F4D] hover:text-[#A33B8C]"
                >
                  Terms
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
