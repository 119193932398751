import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setupDeveloperMode } from 'src/utils/developerMode';
import { setUser as setDatadogRumUser } from '../analytics/datadog';
import './App.css';

import {
  BeakerIcon,
  CalculatorIcon,
  ChartBarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  HomeIcon,
  LockClosedIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline';
import api from 'src/api';
import Alert from 'src/components/Alert';
import Loading from 'src/components/Loading';
import { ModalProvider } from 'src/components/Modal';
import { ToastProvider } from 'src/components/Toast';
import SidebarDesktop from '../components/SidebarDesktop';
import SidebarMobile from '../components/SidebarMobile';
import { Organization, Role, User } from '../types';
import { LAST_LOCATION_LOCAL_STORAGE_KEY } from './constants';
import DashboardHome from './DashboardHome';
import DemoExperimentsPage from './Experiments/DemoExperimentsPage';
import InternalAnalyticsPage from './InternalAnalyticsPage';
import SettingsPage from './SettingsPage';

import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';
import MouseTooltip from 'src/components/MouseTooltip';
import { UserContextProvider } from 'src/components/UserContext';
import AnalyticsRouter from './Analytics/AnalyticsRouter';
import ExperimentsPage from './Experiments/ExperimentsPage';
import OpportunityRouter from './Opportunity/OpportunityRouter';
import PricingCurvesRouter from './PricingCurves/PricingCurvesRouter';
import PricingFlowNavigator from './PricingFlow/PricingFlowNavigator';
import ProofreadDemo from './ProofreadDemo';

setupDeveloperMode();

export type NavigationPage = {
  name: string;
  to: string;
  icon?: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  shortName?: string;
  component: React.FC<any>;
  hidden?: boolean;
  userCanView: (user: User | null) => boolean;
};
export type Navigation = { [key: string]: NavigationPage };

enum Tab {
  HOME = 'home',
  PRODUCTS = 'products',
  ANALYTICS = 'analytics',
  ANALYTICS_INTERNAL = 'analytics-internal',
  SETTINGS = 'settings',
  PRICINGFLOW = 'pricingflow',
  OPPORTUNITY = 'opportunity',
  PRICINGCURVES = 'pricingcurves',
  EXPERIMENTS_DEMO = 'experiments-demo',
  EXPERIMENTS = 'experiments',
  PROOFREAD_DEMO = 'proofread-demo',
}

const SIDE_BAR: Navigation = {
  [Tab.HOME]: {
    name: 'Dashboard',
    to: '/app',
    icon: HomeIcon,
    component: DashboardHome,
    userCanView: (user: User | null) => true,
  },
  [Tab.ANALYTICS]: {
    name: 'Analytics',
    to: '/app/analytics',
    icon: ChartPieIcon,
    component: AnalyticsRouter,
    userCanView: (user: User | null) =>
      user?.permissions.includes('view_analytics') ?? false,
  },
  [Tab.SETTINGS]: {
    name: 'Settings',
    to: '/app/settings',
    icon: Cog6ToothIcon,
    component: SettingsPage,
    userCanView: (user: User | null) => user?.role === Role.ADMIN,
  },
  [Tab.OPPORTUNITY]: {
    name: 'Pricing calculator for Opportunity',
    to: '/app/opportunity',
    icon: CalculatorIcon,
    component: OpportunityRouter,
    userCanView: (user: User | null) => user != null,
  },
  [Tab.PRICINGFLOW]: {
    name: 'Pricing calculator for Opportunity',
    to: '/app/pricingflow',
    icon: CalculatorIcon,
    component: PricingFlowNavigator,
    userCanView: (user: User | null) => false,
  },
  [Tab.PRICINGCURVES]: {
    name: 'Pricing curves',
    to: '/app/pricingcurves',
    icon: ChartBarIcon,
    component: PricingCurvesRouter,
    userCanView: (user: User | null) =>
      user?.role === Role.ADMIN &&
      (user?.permissions.includes('view_pricing_curves') ?? false),
  },
  [Tab.EXPERIMENTS_DEMO]: {
    name: 'Experiments (DEMO)',
    to: '/app/experiments-demo',
    icon: BeakerIcon,
    component: DemoExperimentsPage,
    userCanView: (user: User | null) =>
      user?.permissions.includes('view_experiments_demo') ?? false,
  },
  [Tab.EXPERIMENTS]: {
    name: 'Experiments',
    to: '/app/experiments',
    icon: BeakerIcon,
    component: ExperimentsPage,
    userCanView: (user: User | null) =>
      user?.permissions.includes('view_experiments') ?? false,
  },
  [Tab.ANALYTICS_INTERNAL]: {
    name: 'Analytics (Internal)',
    to: '/app/analytics-internal',
    icon: LockClosedIcon,
    component: InternalAnalyticsPage,
    userCanView: (user: User | null) =>
      user?.permissions.includes('view_analytics_internal') ?? false,
  },
  [Tab.PROOFREAD_DEMO]: {
    name: 'Proofread (DEMO)',
    to: '/app/proofread-demo',
    icon: NewspaperIcon,
    component: ProofreadDemo,
    userCanView: (user: User | null) =>
      user?.permissions.includes('view_proofread_demo') ?? false,
  },
};

export function classNames(
  ...classes: (string | boolean | undefined | null)[]
) {
  return classes.filter(Boolean).join(' ');
}

function App() {
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState<NavigationPage>(SIDE_BAR[Tab.HOME]);
  // null means loading
  // false means session failed stytch auth (e.g. it expired or someone is
  // trying to hack in a bogus token)
  // true means it's valid
  const [sessionIsValid, setSessionIsValid] = useState<boolean | null>(null);
  const { page } = useParams();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [skipLastLocation, setSkipLastLocation] = useState(false);

  useEffect(() => {
    const doEffect = async () => {
      const res = await api.get('is_existing_session_valid');
      setSessionIsValid(res.data.isSessionValid);
    };
    doEffect();
  }, []);

  useEffect(() => {
    if (page) {
      const pageKey = page.toLowerCase();
      if (SIDE_BAR[pageKey]) setTab(SIDE_BAR[pageKey]);
      else navigate('/app');
    } else {
      setTab(SIDE_BAR[Tab.HOME]);
    }
  }, [page]);

  useEffect(() => {
    if (sessionIsValid === false) {
      if (!skipLastLocation) {
        setSkipLastLocation(false);
        const lastLocation = `${pathname}${search}${hash}`;
        localStorage.setItem(LAST_LOCATION_LOCAL_STORAGE_KEY, lastLocation);
      }
      navigate('/login');
      return;
    }
    if (sessionIsValid) {
      const fetchUser = async () => {
        try {
          const response = await api.get('user');
          if (response.data) {
            setUser(response.data);
            setOrganization(response.data.organization);
            setDatadogRumUser({
              id: response.data.id,
              organizationId: response.data.organizationId,
              role: response.data.role,
              createdAt: response.data.createdAt,
            });
            const lastLocation = localStorage.getItem(
              LAST_LOCATION_LOCAL_STORAGE_KEY,
            );
            if (lastLocation) {
              navigate(lastLocation);
              localStorage.removeItem(LAST_LOCATION_LOCAL_STORAGE_KEY);
            }
          } else {
            setError(
              '⚠️ Something went wrong. Please contact support@dealops.com',
            );
          }
        } catch (error) {
          setError(
            '⚠️ Something went wrong. Please contact support@dealops.com.',
          );
          datadogRum.addError(error);
        }
      };

      fetchUser();
    }
  }, [navigate, sessionIsValid]);

  // Make a copy of the sidebar which doesn't have any hidden tabs and doesn't have any tabs the user can't view
  const sideBar = _.pickBy(SIDE_BAR, (value) => {
    return !value.hidden && value.userCanView(user);
  });

  const logout = () => {
    setSkipLastLocation(true);
    localStorage.removeItem(LAST_LOCATION_LOCAL_STORAGE_KEY);
    api.post('logout', {});
    navigate('/');
  };

  return (
    <UserContextProvider>
      <MouseTooltip>
        <div className="h-full">
          {error && <Alert title="Error">{error}</Alert>}
          <SidebarMobile
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            tab={tab}
            navigation={sideBar}
            user={user}
            onLogout={logout}
          />

          <SidebarDesktop
            tab={tab}
            navigation={sideBar}
            user={user}
            onLogout={logout}
          />

          {/* TODO: This height is based on SidebarMobile's height. Use a variable instead to couple to two. */}
          <main className="h-[calc(100%-4rem)] lg:h-full lg:pl-[57px] h-screen flex flex-col">
            <ToastProvider>
              <ModalProvider>
                {sessionIsValid === true && user && organization ? (
                  <tab.component user={user} organization={organization} />
                ) : (
                  <Loading />
                )}
              </ModalProvider>
            </ToastProvider>
          </main>
        </div>
      </MouseTooltip>
    </UserContextProvider>
  );
}

export default App;
