export const WinkingComputer = () => {
  return (
    <svg
      width="108"
      height="109"
      viewBox="0 0 108 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="108" height="108" rx="54" fill="#F6F8FA" />
      <path
        d="M78.3563 29.3041V62.8506H17.4629V29.3041C17.4629 26.5078 19.7284 24.2422 22.5248 24.2422H73.2945C76.0909 24.2422 78.3563 26.5078 78.3563 29.3041Z"
        fill="white"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.6783 29.3041V67.0782C84.6783 69.8745 82.4128 72.1399 79.6165 72.1399H73.2939C76.0903 72.1399 78.3557 69.8745 78.3557 67.0782V29.3041C78.3557 26.5078 76.0903 24.2422 73.2939 24.2422H79.6165C82.4128 24.2422 84.6783 26.5078 84.6783 29.3041Z"
        fill="#CDD0D5"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.3538 62.8477V67.0777C78.3538 69.8775 76.094 72.1378 73.2937 72.1378H22.524C19.724 72.1378 17.4639 69.8775 17.4639 67.0777V62.8477H78.3538Z"
        fill="#E2E4E9"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32.7734 83.7578H35.9134H39.3034" fill="white" />
      <path
        d="M32.7734 83.7578H35.9134H39.3034"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.3437 83.7566H39.3037C42.3935 83.7566 44.5635 80.7066 43.5537 77.7866L41.6135 72.1367H58.6535L60.5937 77.7866C61.6035 80.7065 59.4335 83.7566 56.3437 83.7566Z"
        fill="white"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.3041 83.7578H35.9141"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.0987 78.5586H71.7656C64.8344 78.5586 59.2071 72.9313 59.2071 66.0001V59.5801H84.6572V66.0001C84.6572 72.9313 79.0299 78.5586 72.0987 78.5586Z"
        fill="white"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.5352 73.3483H85.4182C88.2453 73.3483 90.537 71.0565 90.537 68.2295C90.537 65.9173 89.0039 63.9631 86.8988 63.3281"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.9947 83.7578H58.8686C57.0579 83.7578 55.5879 82.2878 55.5879 80.4771H88.2754C88.2754 82.2878 86.8054 83.7578 84.9947 83.7578Z"
        fill="#CDD0D5"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7778 46.0742H24.833C24.2807 46.0742 23.833 46.5219 23.833 47.0742V53.019C23.833 53.5713 24.2807 54.019 24.833 54.019H30.7778C31.3301 54.019 31.7778 53.5713 31.7778 53.019V47.0742C31.7778 46.5219 31.3301 46.0742 30.7778 46.0742Z"
        fill="#CDD0D5"
      />
      <path
        d="M52.4253 31.2695H46.4805C45.9282 31.2695 45.4805 31.7172 45.4805 32.2695V38.2143C45.4805 38.7666 45.9282 39.2143 46.4805 39.2143H52.4253C52.9776 39.2143 53.4253 38.7666 53.4253 38.2143V32.2695C53.4253 31.7172 52.9776 31.2695 52.4253 31.2695Z"
        fill="#E2E4E9"
      />
      <path
        d="M72.1089 47.6641H38.3633C37.811 47.6641 37.3633 48.1118 37.3633 48.6641V50.5122C37.3633 51.0644 37.811 51.5122 38.3633 51.5122H72.1089C72.6612 51.5122 73.1089 51.0644 73.1089 50.5122V48.6641C73.1089 48.1118 72.6612 47.6641 72.1089 47.6641Z"
        fill="#CDD0D5"
      />
      <path
        d="M72.6084 33.4922H60.0098C59.4575 33.4922 59.0098 33.9399 59.0098 34.4922V36.3403C59.0098 36.8926 59.4575 37.3403 60.0098 37.3403H72.6084C73.1607 37.3403 73.6084 36.8926 73.6084 36.3403V34.4922C73.6084 33.9399 73.1607 33.4922 72.6084 33.4922Z"
        fill="#CDD0D5"
      />
      <path
        d="M24.1309 35.2394C24.1309 35.2394 27.3352 39.0374 30.9076 39.0374C34.48 39.0374 37.5111 35.2394 37.5111 35.2394C37.5111 35.2394 34.48 31.4414 30.9076 31.4414C27.3352 31.4414 24.1309 35.2394 24.1309 35.2394Z"
        fill="white"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8601 37.8804C32.2952 37.8804 33.4585 36.717 33.4585 35.282C33.4585 33.8469 32.2952 32.6836 30.8601 32.6836C29.4251 32.6836 28.2617 33.8469 28.2617 35.282C28.2617 36.717 29.4251 37.8804 30.8601 37.8804Z"
        fill="#868C98"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6025 2.18757C32.6025 2.18757 28.6823 6.78658 33.2522 10.709C28.6822 6.7865 24.7309 11.3587 24.7309 11.3587C24.7309 11.3587 28.6511 6.75967 24.0811 2.83722C28.6511 6.75967 32.6025 2.18757 32.6025 2.18757Z"
        fill="white"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.2942 101.16C84.2942 101.16 78.2702 101.64 78.7281 107.645C78.2702 101.64 72.2432 102.079 72.2432 102.079C72.2432 102.079 78.2672 101.599 77.8093 95.5938C78.2672 101.599 84.2942 101.16 84.2942 101.16Z"
        fill="white"
        stroke="#868C98"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
