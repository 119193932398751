export function SectionCard({
  children,
  containerStyle,
  id,
}: {
  children: React.ReactNode;
  containerStyle?: string;
  id?: string;
}) {
  return (
    <div className={`mx-auto py-8 px-3 sm:px-6 lg:px-8`} id={id}>
      <div
        className={`relative isolate overflow-hidden text-center rounded-3xl sm:px-12 py-12 ${containerStyle ?? ''}`}
      >
        {children}
      </div>
    </div>
  );
}

export function SectionText({
  superText,
  superTextStyle,
  title,
  titleStyle,
  subtitle,
  subtitleStyle,
}: {
  superText: string;
  superTextStyle?: string;
  title: string;
  titleStyle?: string;
  subtitle: string;
  subtitleStyle?: string;
}) {
  return (
    <div className="px-6 pb-12 lg:px-8">
      <div className="mx-auto text-center">
        <p
          className={`text-base font-semibold leading-7 text-fuchsia-900 ${superTextStyle ?? ''}`}
        >
          {superText}
        </p>
        <h2
          className={`mt-2 text-4xl font-semibold tracking-tight text-gray-900 ${titleStyle ?? ''}`}
        >
          {title}
        </h2>
        <p
          className={`mt-6 mx-auto max-w-[780px] text-lg sm:text-xl leading-8  ${subtitleStyle ?? 'text-slate-600'}`}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
}

export interface ProductFeature {
  id: number;
  name: string;
  nameStyle?: string;
  description: string;
  descriptionStyle?: string;
  imageSrc: string;
  imageAlt: string;
  imageContainerStyle?: string;
  containerStyle?: string;
  textContainerStyle?: string;
}

export function ProductFeatureCards({
  features,
}: {
  features: ProductFeature[];
}) {
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl px-3 sm:px-0">
      <div className="grid grid-cols-1 gap-y-4 lg:grid-cols-3 lg:gap-x-6">
        {features.map((feature) => (
          <div
            key={feature.name}
            className={`relative flex flex-col overflow-hidden rounded-2xl border border-gray-200 ${feature.containerStyle ?? ''}`}
          >
            <div
              className={`aspect-h-4 aspect-w-[6.5] bg-gray-200 ${feature.imageContainerStyle ?? ''}`}
            >
              <img
                src={feature.imageSrc}
                alt={feature.imageAlt}
                className={`h-full w-full object-cover object-top`}
              />
            </div>
            <div
              className={`flex flex-1 flex-col space-y-1 p-5 ${feature.textContainerStyle ?? 'border-t'}`}
            >
              <h3
                className={`text-left text-base font-medium tracking-tight ${feature.nameStyle ?? 'text-slate-800 '}`}
              >
                <span aria-hidden="true" className="absolute inset-0" />
                {feature.name}
              </h3>
              <p
                className={`text-left text-sm tracking-tight ${feature.descriptionStyle ?? 'text-slate-600 '}`}
              >
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
