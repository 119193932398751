import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Sidebar } from '../Penguin/Components/TieredDetails';
import HamsterOpportunitySidebar from './HamsterOpportunitySidebar';

export default function HamsterOpportunityInfoButton() {
  const [showOpportunityInfo, setShowOpportunityInfo] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => {
          setShowOpportunityInfo(true);
        }}
        className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
      >
        <InformationCircleIcon
          className="-ml-0.5 mr-2 h-5 w-5"
          aria-hidden="true"
        />
        Opp Info
      </button>
      <Sidebar
        isOpen={showOpportunityInfo}
        onClose={() => setShowOpportunityInfo(false)}
        title={''}
      >
        <HamsterOpportunitySidebar />
      </Sidebar>
    </>
  );
}
