import { useState } from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { Currency } from '../PricingFlow/types_common/price';
import { PricingData, PricingGuidanceData } from './ProductASPsDashboard';

export const CPQvsDealopsPlot = ({
  data,
  currency,
}: {
  data: {
    dealops: { x: number; y: number }[];
    cpq: { x: number; y: number }[];
    guidance: { x: number; y: number }[];
  };
  currency: Currency;
}) => {
  const [showDealops, setShowDealops] = useState(true);
  const [showCPQ, setShowCPQ] = useState(true);
  const [showGuidance, setShowGuidance] = useState(true);

  const handleClick = (source: string) => {
    if (source === 'Dealops') {
      setShowDealops(!showDealops);
    } else if (source === 'CPQ') {
      setShowCPQ(!showCPQ);
    } else if (source === 'Guidance') {
      setShowGuidance(!showGuidance);
    }
  };

  return (
    <ResponsiveContainer width="100%">
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <Legend
          verticalAlign="top"
          align="right"
          height={36}
          iconSize={10}
          formatter={(value) => (
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'regular',
                color: '#475467',
              }}
              onClick={() => handleClick(value)}
            >
              {value}
            </span>
          )}
        />
        <XAxis
          type="number"
          dataKey="x"
          name="Monthly Minimum"
          label={{
            value: 'Monthly Minimum',
            position: 'insideBottom',
            offset: -10,
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Price"
          label={{
            value: 'Price',
            angle: -90,
            offset: -5,
            position: 'insideLeft',
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <ZAxis type="category" dataKey="z" name="Source" />
        <RechartTooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(value: any) =>
            typeof value === 'number'
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency,
                }).format(value)
              : value
          }
        />
        {/* <RechartTooltip
          content={<AnalyticsTooltip currency="USD" />}
          cursor={{ fill: 'transparent' }}
        /> */}
        <Scatter
          name="Guidance"
          data={
            showGuidance
              ? data.guidance.map((point) => {
                  return { ...point, z: `Guidance` };
                })
              : []
          }
          fill="#16B364"
        />
        <Scatter
          name="CPQ"
          data={
            showCPQ
              ? data.cpq.map((point) => {
                  return { ...point, z: 'CPQ' };
                })
              : []
          }
          fill="#0BA5EC"
        />
        <Scatter
          name="Dealops"
          data={
            showDealops
              ? data.dealops.map((point) => {
                  return { ...point, z: 'Dealops' };
                })
              : []
          }
          fill="#981781"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export const CPQvsDealopsPerProductPlot = ({
  pricingData,
  pricingGuidanceData,
  currency,
}: {
  pricingData: PricingData[];
  pricingGuidanceData: PricingGuidanceData[];
  currency: Currency;
}) => {
  const [showDealops, setShowDealops] = useState(true);
  const [showCPQ, setShowCPQ] = useState(true);
  const [showGuidance, setShowGuidance] = useState(true);

  const handleLegendClick = (source: string) => {
    if (source === 'Dealops') {
      setShowDealops(!showDealops);
    } else if (source === 'CPQ') {
      setShowCPQ(!showCPQ);
    } else if (source === 'Guidance') {
      setShowGuidance(!showGuidance);
    }
  };

  let cpqData = pricingData
    .filter((product) => product.metadata.source === 'CPQ')
    .map((product) => {
      return { ...product.point, ...product };
    });
  let dealopsData = pricingData
    .filter((product) => product.metadata.source === 'Dealops')
    .map((product) => {
      return { ...product.point, ...product };
    });

  // need to filter monthly minimums to only include those
  // that are less than or equal to the max monthly minimum
  let suggestionData = pricingGuidanceData
    .map((product) => {
      return { ...product.point, ...product };
    })
    .filter(
      (point) =>
        point.x <=
        Math.max(
          ...cpqData.map((point) => point.x),
          ...dealopsData.map((point) => point.x),
        ),
    );

  return (
    <ResponsiveContainer width="100%">
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <Legend
          verticalAlign="top"
          align="right"
          height={36}
          iconSize={10}
          formatter={(value) => (
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'regular',
                color: '#475467',
              }}
              onClick={() => handleLegendClick(value)}
            >
              {value}
            </span>
          )}
        />
        <XAxis
          type="number"
          dataKey="x"
          name="Monthly Minimum"
          label={{
            value: `Monthly Minimum`,
            position: 'insideBottom',
            offset: -10,
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Price"
          label={{
            value: 'Price',
            angle: -90,
            offset: -5,
            position: 'insideLeft',
            className: 'font-medium text-xs text-gray-900',
          }}
          tickFormatter={(value) =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(value)
          }
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={{ stroke: '#475467' }}
        />
        <ZAxis type="category" dataKey="z" name="Source" />
        <ZAxis type="category" dataKey="metadata" name="Metadata" />
        <RechartTooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(value: string | number) =>
            typeof value === 'number'
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency,
                }).format(value)
              : value
          }
        />
        {/* <RechartTooltip
          content={<AnalyticsTooltip currency="USD" />}
          cursor={{ fill: 'transparent' }}
        /> */}
        <Scatter
          name="Guidance"
          data={
            showGuidance
              ? suggestionData.map((point) => {
                  return {
                    ...point,
                    z: `Guidance data, Pricebook: ${point.metadata.pricebook}`,
                  };
                })
              : []
          }
          fill="#16B364"
        />
        <Scatter
          name="CPQ"
          data={
            showCPQ
              ? cpqData.map((point) => {
                  return {
                    ...point,
                    z: `CPQ data, Pricebook: ${point.metadata.pricebook}`,
                  };
                })
              : []
          }
          fill="#0BA5EC"
        />
        <Scatter
          name="Dealops"
          data={
            showDealops
              ? dealopsData.map((point) => {
                  return {
                    ...point,
                    z: `Dealops data, Pricebook: ${point.metadata.pricebook}`,
                  };
                })
              : []
          }
          fill="#981781"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

// interface AnalyticsTooltipProps extends TooltipProps<number, string> {
//   currency: string;
// }

// const AnalyticsTooltip: React.FC<AnalyticsTooltipProps> = ({
//   active,
//   payload,
//   label,
// }) => {
//   if (active && payload && payload.length) {
//     console.log('payload: ', payload);
//     console.log('label: ', label);
//     let color = '#9ca3af';
//     return (
//       <div className="bg-white border border-slate-200 rounded-md py-2 px-4 text-slate-700 flex flex-col gap-2 min-w-[180px]">
//         <div className="flex flex-col gap-1">
//           <span style={{ color }}>
//             Monthly Minimum:{' '}
//             {/* {formatCurrencyValue(entry.payload.tooltipData['Gross profit'])} */}
//             {payload.find((entry) => entry.name === 'Monthly Minimum')?.value}
//           </span>
//           <span style={{ color }}>
//             Price:{' '}
//             {/* {formatCurrencyValue(entry.payload.tooltipData['Take rate'])} */}
//             {payload.find((entry) => entry.name === 'Price')?.value}
//           </span>
//           <span style={{ color }}>
//             Source:{' '}
//             {/* {formatCurrencyValue(entry.payload.tooltipData['Take rate'])} */}
//             {payload.find((entry) => entry.name === 'Source')?.value}
//           </span>
//         </div>
//         {/* {payload.map((entry, index) => {
//           if (entry.dataKey !== 'value') return null;
//           let color = '#9ca3af';

//           return (
//             <div key={index} className="flex flex-col gap-1">
//               <span style={{ color }}>
//                 Monthly Minimum:{' '}
//                 {formatCurrencyValue(entry.payload.tooltipData['Gross profit'])}
//               </span>
//               <span key={index} style={{ color }}>
//                 Price:{' '}
//                 {formatCurrencyValue(entry.payload.tooltipData['Take rate'])}
//               </span>
//               <span key={index} style={{ color }}>
//                 Source:{' '}
//                 {formatCurrencyValue(entry.payload.tooltipData['Take rate'])}
//               </span>
//             </div>
//           );
//         })} */}
//       </div>
//     );
//   }

//   return null;
// };
