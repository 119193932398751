import { Route, Routes } from 'react-router-dom';
import { Organization, User } from '../../types';
import PricingCurvesCreate from './PricingCurvesCreate';
import PricingCurvesPage from './PricingCurvesPage';

type PricingCurvesRouterProps = {
  user: User;
  organization: Organization;
};
const PricingCurvesRouter = (props: PricingCurvesRouterProps) => {
  const { user, organization } = props;
  return (
    <>
      {/* Route Configuration */}
      <Routes>
        <Route
          path="/"
          element={
            <PricingCurvesPage user={user} organization={organization} />
          }
        />
        <Route
          path="/new"
          element={
            <PricingCurvesCreate user={user} organization={organization} />
          }
        />
      </Routes>
    </>
  );
};

export default PricingCurvesRouter;
