import { datadogRum } from '@datadog/browser-rum';
import {
  AdjustmentsHorizontalIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import api from 'src/api';
import { useModal } from 'src/components/Modal';
import { useToast } from 'src/components/Toast';
import { usePricingFlowContext } from '../../PricingFlow';
import ShareButton from '../../ShareButton';
import BottomBar from '../Components/BottomBar';
import PenguinFinalizeQuoteModal from '../Components/PenguinFinalizeQuoteModal';
import PenguinQuoteTable, {
  getMonthlyMinimumRampValues,
} from '../Components/PenguinQuoteTable';
import PricingTiersSettings from '../Components/PricingTiersSettings';
import { PenguinPricingFlow } from '../penguin_types';

export default function Step4PricingCalculator(props: {
  previousStage: () => void;
}) {
  const { previousStage } = props;
  const { pricingFlow, restartInteractionTracking, editMode } =
    usePricingFlowContext<PenguinPricingFlow>();
  const { showToast } = useToast();
  const { showModal, hideModal } = useModal();

  const handleShowFinalizeQuoteModal = async () => {
    if (!editMode) {
      showToast({
        title: 'You are in view-only mode, you cannot generate a quote',
        subtitle: '',
        type: 'error',
        autoDismiss: false,
      });
      return 'error';
    }
    restartInteractionTracking('modifiedPricingFlow');
    console.log('show finalize quote modal');

    const isValid = ((): boolean => {
      const monthlyMinimumRampValues = getMonthlyMinimumRampValues({
        pricingFlow,
      });
      if (monthlyMinimumRampValues != null) {
        const isIncreasing = monthlyMinimumRampValues.every(
          (value, i) =>
            i === 0 || value.value >= monthlyMinimumRampValues[i - 1].value,
        );
        if (!isIncreasing) {
          showToast({
            title: 'Monthly minimum ramp must be increasing',
            subtitle:
              'Please check the monthly minimum ramp ups and make sure each month is the same or increasing.',
            type: 'error',
            autoDismiss: false,
          });
          return false;
        }
      }
      return true;
    })();

    if (!isValid) {
      return 'idle';
    }
    console.log('call POST quotes?pricingFlowId={}');
    const eventualResponse = api.post(
      'quotes?pricingFlowId=' + pricingFlow.id,
      { currentPricingCurves: pricingFlow.currentPricingCurves },
    );
    showModal({
      title: 'Make sure to recalculate approvals',
      children: (
        <PenguinFinalizeQuoteModal
          close={hideModal}
          eventualResponse={eventualResponse}
        />
      ),
    });
    try {
      await eventualResponse;

      return 'success';
    } catch (error) {
      datadogRum.addError(error);
      return 'error';
    }
  };
  const [open, setOpen] = useState(false);

  const showPricingTiersSettings = () => {
    setOpen(true);
  };

  return (
    <div>
      <PricingTiersSettings open={open} setOpen={setOpen} />
      {/* Header */}
      <div className="flex flex-col gap-4 border-b border-gray-200 px-6 py-4 xl:flex-row xl:justify-between">
        <div className="flex flex-row items-center gap-4">
          {/* Currency icon */}
          <div className="h-14 w-14">
            <div className="absolute flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <CurrencyDollarIcon className="h-7 w-7" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <h1 className="text-lg font-semibold">
              Pricing for {pricingFlow.opportunity.sfdcOpportunityName}
            </h1>
            <p className="text-base text-gray-700">
              Adjust the quote for this opportunity based on your company
              guidance.
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            type="button"
            onClick={() => {
              showPricingTiersSettings();
            }}
            className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
          >
            <AdjustmentsHorizontalIcon
              className="-ml-0.5 mr-2 h-5 w-5"
              aria-hidden="true"
            />
            Tiering and ramping settings
          </button>
          <ShareButton
            textToCopy={window.location.href}
            pricingFlow={pricingFlow}
          />
        </div>
      </div>
      <div className="px-8 py-4">
        <div className="pt-2">
          <PenguinQuoteTable />
        </div>
        <div className="h-64" />
      </div>
      <BottomBar
        primaryButtonProps={{
          label: 'Generate quote in SFDC',
          successLabel: 'Success!',
          errorLabel: 'Error',
          onClick: handleShowFinalizeQuoteModal,
        }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
      />
    </div>
  );
}
