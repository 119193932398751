import { CurrencyDollarIcon, LifebuoyIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import CollapsibleSection from 'src/components/CollapsibleSection';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useModal } from 'src/components/Modal';
import { Organization, User } from 'src/types';
import BottomBar from '../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../PricingFlow';
import HamsterAnnualRevenueTable from './HamsterAnnualRevenueTable';
import HamsterAskForApprovalModal from './HamsterApprovalModal';
import HamsterExitModal from './HamsterExitModal';
import HamsterOpportunityInfoButton from './HamsterOpportunityInfoButton';
import HamsterQuoteTable from './HamsterQuoteTable';
import HamsterTermsSection from './HamsterTermsSection';
import { HamsterPricingFlow } from './hamster_types';

interface HamsterPricingFlowPageProps {
  user: User;
  organization: Organization;
}

export default function HamsterPricingFlowPage(
  props: HamsterPricingFlowPageProps,
) {
  const { showModal, hideModal } = useModal();
  const { pricingFlow, editMode } = usePricingFlowContext<HamsterPricingFlow>();
  const showApprovalModal = () => {
    showModal({
      title: 'Ask for approval',
      children: (
        <HamsterAskForApprovalModal
          hideModal={hideModal}
          pricingFlow={pricingFlow}
        />
      ),
      className: 'sm:max-w-5xl max-w-5xl h-[90vh] overflow-y-auto',
    });
  };
  const showExitModal = () => {
    showModal({
      title: 'What do you want to do next?',
      children: (
        <HamsterExitModal
          hideModal={hideModal}
          pricingFlow={pricingFlow}
          showApprovalModal={showApprovalModal}
        />
      ),
    });
  };
  const navigate = useNavigate();
  return (
    <div>
      <HeaderBreadcrumbs
        steps={[
          {
            label: 'Opportunities',
            onClick: () => {
              navigate('/app/opportunity');
            },
          },
          {
            label: `Opp-${pricingFlow.opportunity.sfdcOpportunityId}`,
            onClick: () => {
              navigate(
                `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}`,
              );
            },
          },
          {
            label: `Create a pricing option`,
            onClick: () => {},
          },
        ]}
      />
      {/* Header */}
      <div className="flex flex-col gap-4 border-b border-t mt-4 border-gray-200 px-8 py-4 xl:flex-row xl:justify-between">
        <div className="flex flex-row items-center gap-4">
          {/* Currency icon */}
          <div className="h-14 w-14">
            <div className="absolute flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <CurrencyDollarIcon className="h-7 w-7" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <h1 className="text-lg font-semibold">Configure quote</h1>
            <p className="text-base text-gray-700">
              Configure terms and products
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <button className="inline-flex justify-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <LifebuoyIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
            Help
          </button>
          <HamsterOpportunityInfoButton />
        </div>
      </div>
      {/* Page contents */}
      <div className="px-8 py-4">
        <CollapsibleSection title="TERMS">
          <HamsterTermsSection />
        </CollapsibleSection>
        <CollapsibleSection title="PRICING">
          <HamsterQuoteTable />
        </CollapsibleSection>
        <div className="flex justify-end mt-6">
          <HamsterAnnualRevenueTable />
        </div>
        <div className="h-64" />
      </div>
      <BottomBar
        primaryButtonProps={{
          label: 'Finish',
          successLabel: 'Finish',
          errorLabel: 'Error',
          onClick: async () => {
            showExitModal();
            return 'idle';
          },
        }}
      />
    </div>
  );
}
