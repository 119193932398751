import { Crepe } from '@milkdown/crepe';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import './HamsterApprovalRequestForm.css';

import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { replaceAll } from '@milkdown/kit/utils';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { Spinner } from 'src/components/Loading';
import { classNames } from 'src/dashboard/App';
import { HamsterPricingFlow } from './hamster_types';

interface ApprovalRequestFormProps {
  message: string | null;
  setMessage: React.Dispatch<React.SetStateAction<string | null>>;
  crepe: Crepe | null;
  setCrepe: React.Dispatch<React.SetStateAction<Crepe | null>>;
  pricingFlow: HamsterPricingFlow;
}
function ApprovalRequestForm(props: ApprovalRequestFormProps) {
  const { crepe, setCrepe } = props;

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const newCrepe = new Crepe({
      root: '#crepeeditor',
      defaultValue: 'Generating message...',
    });
    newCrepe.setReadonly(true);
    setCrepe(newCrepe);

    newCrepe.create().then(() => {
      console.log('Crepe editor created');
    });

    return () => {
      // Destroy the editor when unmounting component
      newCrepe.destroy();
      console.log('Crepe editor destroyed');
    };
  }, []);

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute w-full h-full flex justify-center items-center opacity-60">
          <Spinner width={40} height={40} />
        </div>
      )}
      <div
        id="crepeeditor"
        className={classNames(
          'border border-gray-300 py-2 px-4 mx-4 my-6 rounded-xl h-full min-h-48',
          isLoading && 'opacity-50 bg-opacity-10 bg-slate-500',
        )}
      />
      {isNil(crepe) ? (
        'Loading editor...'
      ) : (
        <CrepeController
          crepe={crepe}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          message={props.message}
          setMessage={props.setMessage}
          pricingFlow={props.pricingFlow}
        />
      )}
    </div>
  );
}
export default ApprovalRequestForm;

function CrepeController(props: {
  crepe: Crepe;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  message: string | null;
  setMessage: React.Dispatch<React.SetStateAction<string | null>>;
  pricingFlow: HamsterPricingFlow;
}) {
  const { crepe, isLoading, setIsLoading, message, setMessage, pricingFlow } =
    props;

  useEffect(() => {
    crepe.setReadonly(isLoading);
    console.log('Setting crepe readonly to: ', isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (message == null) {
      generateInitialMessage();
    }
  }, []);

  async function generateInitialMessage() {
    setIsLoading(true);
    console.log('Generating initial message');
    const response = await api.post('approval/generateInitialMessage', {
      pricingFlowId: pricingFlow.id,
    });
    if (response.status !== 200) {
      console.error('Failed to generate initial message');
      setIsLoading(false);
      return;
    } else {
      console.log('Got initial message');
      setMessage(response.data);
      setIsLoading(false);
      replaceMessage(response.data);
    }
  }

  async function proofreadMessage() {
    setIsLoading(true);
    const markdown = crepe.getMarkdown();
    console.log('Got markdown');
    console.log(markdown);
    const response = await api.post('approval/proofreadMessage', {
      markdown,
      pricingFlowId: pricingFlow.id,
    });
    if (response.status !== 200) {
      console.error('Failed to proofread message');
      setIsLoading(false);
      return;
    } else {
      console.log('Got proofread message');
      console.log(response.data);
      setMessage(response.data);
      setIsLoading(false);
      replaceMessage(response.data);
    }
  }

  function replaceMessage(message: string) {
    console.log('Replacing message with: ');
    console.log(message);
    setIsLoading(false);
    crepe.editor.action(replaceAll(message));
  }

  return (
    <div>
      <button
        className={classNames(
          'border-gray-200 flex items-center justify-center gap-1 whitespace-nowrap rounded-lg border py-1 px-2 font-medium text-sm text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 absolute top-4 right-8',
          isLoading && 'bg-gray-100',
          !isLoading &&
            ' bg-white hover:bg-gray-200 focus-visible:outline-fuchsia-900',
        )}
        onClick={proofreadMessage}
        disabled={isLoading}
      >
        <DocumentMagnifyingGlassIcon className="h-4 w-4" />
        <span>Proofread</span>
      </button>
    </div>
  );
}
