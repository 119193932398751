import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { classNames } from 'src/dashboard/App';

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
}
export default function CollapsibleSection(props: CollapsibleSectionProps) {
  const { title, children } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  return (
    <>
      <div
        className={classNames(
          'bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500 w-full',
          !isExpanded && 'border-b border-b-slate-200',
        )}
        onClick={() =>
          setIsExpanded((prevIsExpanded) => {
            return !prevIsExpanded;
          })
        }
      >
        {title}
      </div>
      <Transition
        as={'div'}
        show={isExpanded}
        className="w-full"
        enter="transition-all duration-300"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-[48rem] opacity-100"
        leave="transition-all duration-300"
        leaveFrom="max-h-[48rem] opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        {children}
      </Transition>
    </>
  );
}
