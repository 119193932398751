import { ChartBarIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { ReactNode } from 'react';
import { useTooltip } from '../MouseTooltip';
import GraphContainer, { RenderChildWithSize } from './GraphContainer';
import { Legend } from './helpers';

export type Bar = {
  label: string;
  value: number;
  color: string;
};

export function DistributionGraph<BarType extends Bar>({
  width,
  bars,
  formatTooltip,
}: {
  width: number;
  bars: BarType[];
  formatTooltip?: (arg0: BarType) => ReactNode;
}) {
  const { setTooltipText } = useTooltip();
  const valueSum = _.sum(bars.map((b) => b.value));
  return (
    <div className="flex">
      {bars.map((b) => {
        return (
          <div
            className={`mx-[2px] h-2 rounded-sm border-black first:ml-0 last:mr-0 ${formatTooltip && 'hover:ring-2'}`}
            style={{
              backgroundColor: b.color,
              width: `${(100 * b.value) / valueSum}%`,
              // @ts-ignore
              '--tw-ring-color': b.color,
            }}
            onMouseEnter={() => {
              if (formatTooltip !== undefined) {
                setTooltipText(formatTooltip(b));
              }
            }}
            onMouseLeave={() => {
              setTooltipText(null);
            }}
          ></div>
        );
      })}
    </div>
  );
}

export function DistributionBar<BarType extends Bar>({
  bars,
  formatTooltip,
}: {
  bars: BarType[];
  formatTooltip?: (arg0: BarType) => ReactNode;
}) {
  return (
    <div className="mt-6 w-full">
      <RenderChildWithSize
        className="w-full"
        childFunction={(width, height) => {
          return (
            <DistributionGraph
              width={width}
              bars={bars}
              formatTooltip={formatTooltip}
            />
          );
        }}
      />
      <Legend className="ml-1 mt-4" mappings={bars} />
    </div>
  );
}

export const DISTRIBUTION_BAR_EXAMPLE_ARGS = {
  bars: [
    { label: 'Dealops', value: 10, color: '#981781', user: 'tyler' },
    { label: 'CPQ', value: 20, color: '#35B9E9', user: 'sage' },
  ],
};

export function DistributionBarExample({
  bars,
}: {
  bars: (Bar & { user: string })[];
}) {
  return (
    <GraphContainer
      header={
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center text-gray-900">
            <div className="mr-1 w-6 stroke-gray-600">
              <ChartBarIcon className="text-gray-800" />
            </div>{' '}
            <div className="font-semibold text-gray-900">
              Total Quote creation distribution
            </div>
          </div>
          <div className="whitespace-nowrap text-sm text-gray-700">
            2/15-3/15
          </div>
        </div>
      }
    >
      <div className="mb-4 flex w-full items-center justify-between">
        <div className="text-lg font-normal text-gray-500">
          <span className="font-bold text-black">38</span> in Dealops{' '}
          <span className="font-bold text-black">23</span> in the CPQ
        </div>
      </div>
      <DistributionBar
        bars={bars}
        formatTooltip={(bar) => {
          return (
            <div>
              <p>{bar.user}</p>
              <p>{bar.value}</p>
            </div>
          );
        }}
      />
    </GraphContainer>
  );
}
