import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import { InlineSpinner } from 'src/components/Loading';
import { HamsterPricingFlow } from './hamster_types';

interface HamsterExitModalProps {
  hideModal: () => void;
  showApprovalModal: () => void;
  pricingFlow: HamsterPricingFlow;
}
export default function HamsterExitModal(props: HamsterExitModalProps) {
  const { hideModal, pricingFlow, showApprovalModal } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-2 pt-4 border-t border-gray-200">
      <button
        type="button"
        className="inline-flex w-full justify-center rounded-md bg-white py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const newPricingFlow = (
            await api.post('pricingflow', {
              sfdcOpportunityId: pricingFlow.opportunity.sfdcOpportunityId,
            })
          ).data;
          hideModal();
          navigate(
            `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/pricingflow/${newPricingFlow.id}`,
          );
        }}
      >
        {isLoading ? <InlineSpinner /> : 'Create another quote'}
      </button>
      <button
        type="button"
        className="inline-flex w-full justify-center rounded-md bg-white py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={async () => {
          showApprovalModal();
        }}
      >
        Submit for approval
      </button>
      <button
        type="submit"
        className="whitespace-nowrap inline-flex w-full items-center justify-center rounded-md bg-fuchsia-900 text-sm py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
        onClick={async () => {
          hideModal();
          navigate(
            `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}`,
          );
        }}
      >
        Save and quit
      </button>
    </div>
  );
}
