let CheckIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      x="0px"
      y="0px"
      width="14"
      height="14"
      viewBox="0 0 24 24"
    >
      <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z"></path>
    </svg>
  );
};

export default function CustomCheckbox({ isChecked }: { isChecked: boolean }) {
  return (
    <label className="flex items-center">
      <input
        readOnly
        type="checkbox"
        checked={isChecked}
        className="sr-only [&:checked+span>svg]:block [&:checked+span]:bg-pink-200"
      />
      <span className="block h-4 w-4 cursor-pointer rounded border border-pink-800">
        {isChecked && <CheckIcon className="stroke-1 stroke-pink-800" />}
      </span>
    </label>
  );
}
