import {
  PricingCurve,
  PricingFlowCommon,
  PricingFlowType,
  ProductCommon,
} from '../types';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValueTiered,
} from '../types_common/price';

type BillingFrequency = 'annual_upfront' | 'other';
type PaymentTerms = 'net_30' | 'net_60' | 'net_90' | 'other';

export interface HamsterOpportunityData {
  Owner: string;
  Segment: string;
  Country: string;
  Number_of_lawyers: number;
  Estimated_revenue: number;
}

export interface HamsterPricingInformation {
  // TODO(georgehamster)
  listPrice: CurrencyValueTiered<CurrencyValueFlat, Count> | null;
  headOfMidmarketSales?: CurrencyValueTiered<CurrencyValueFlat, Count>;
  vpOfSales?: CurrencyValueTiered<CurrencyValueFlat, Count>;
  dealDesk?: CurrencyValueTiered<CurrencyValueFlat, Count>;
  // if "true", approval is always required if this product is added
  legal?: true;
}
export type HamsterPricingCurve = PricingCurve & {
  pricingInformation: HamsterPricingInformation;
};
export interface HamsterAdditionalData {
  startDate: string | null; // YYYY-MM-DD
  subscriptionTerms: number;
  billingFrequency: BillingFrequency;
  // if billingFrequency === 'other', the rep can enter a free form description
  // of what they're doing
  billingFrequency_Other: string;
  paymentTerms: PaymentTerms;
  // if paymentTerms === 'other', the rep can enter a free form description
  // of what they're doing
  paymentTerms_Other: string;
  nonCommercialTerms: string;
  priceProtection: boolean;
  priceProtectionCap: number;
}
export type HamsterCV = CurrencyValueFlat;
export type HamsterQuotePrice =
  | HamsterCV
  | CurrencyValueTiered<HamsterCV, Count>;
export interface HamsterProduct extends ProductCommon {
  quotePrice: HamsterQuotePrice | null;
  // Note that:
  // - the volume numbers here are increases to the minimum seat count, not the
  //   raw number of seats provisioned at that month. To get that number, add the
  //   product.volume, which is the base seat count
  // - These are not volume forcasts, these are the actual number of seats
  //   they're selling!
  rampedVolumeIncremental: (Count | null)[];
  customName?: string;
}

export const HAMSTER_CATEGORY_NAME = [
  'New customers',
  'Existing customers',
] as const;
export type HamsterCategoryName = (typeof HAMSTER_CATEGORY_NAME)[number];
export const HAMSTER_SUBCATEGORY_NAME = ['Core', 'Add-ons'] as const;
export type HamsterSubcategoryName = (typeof HAMSTER_SUBCATEGORY_NAME)[number];
export interface HamsterProductPrice {
  id: string;
  name: string;
  // only one product in an exclusive group can be selected at once
  exclusiveGroup: string | null;
  // number is the fixed volume value, null means the rep can edit it
  fixedVolume: number | null;
  isFreeformProduct: boolean;
  currentPricingCurve: HamsterPricingCurve;
  subcategoryMemberships: {
    subcategory: {
      name: HamsterSubcategoryName;
      category: { name: HamsterCategoryName };
    };
  }[];
  displayOrder: number;
}
export interface HamsterProductPrices {
  [productId: string]: HamsterProductPrice | undefined;
}
export interface HamsterPricingSheet {
  productInfo: HamsterProductPrices;
}

export interface HamsterPricingFlow extends PricingFlowCommon {
  type: PricingFlowType.HAMSTER;
  opportunity: PricingFlowCommon['opportunity'] & {
    opportunityData: HamsterOpportunityData;
  };
  additionalData: HamsterAdditionalData;
  products: HamsterProduct[];
  pricingSheetData: HamsterPricingSheet;
}
