import { InformationCircleIcon } from '@heroicons/react/24/outline';
import BottomBar from '../../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../../PricingFlow';
import AlpacaQuoteTable_NEW from '../AlpacaQuoteTable_NEW';
import {
  AlpacaCollectionsProduct,
  AlpacaCollectionsProductPrice,
  AlpacaGlobalAccountsProductPrice,
  AlpacaPricingFlow,
  AlpacaProductPrice,
} from '../alpaca_types';
import AlpacaHeader from '../Components/AlpacaHeader';
import AlpacaHelpButton from '../Components/AlpacaHelpButton';
import AlpacaOpportunityInfoButton from '../Components/AlpacaOpportunityInfoButton';

export function getAllCollectionsProductInfos(pricingFlow: AlpacaPricingFlow) {
  const allProductPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  );
  return allProductPrices.filter(
    (pp): pp is AlpacaCollectionsProductPrice => pp.skuGroup === 'Collections',
  );
}

export function getAllGlobalAccountsProductInfos(
  pricingFlow: AlpacaPricingFlow,
) {
  const allProductInfos =
    pricingFlow.pricingSheetData?.countryPricingSheets['us']?.productInfo;
  const globalAccountsProductInfos = Object.values(allProductInfos).filter(
    (productInfo): productInfo is AlpacaGlobalAccountsProductPrice =>
      productInfo.skuGroup === 'Global Accounts',
  );

  return globalAccountsProductInfos;
}

function getSelectedGlobalAccountsProductInfos(pricingFlow: AlpacaPricingFlow) {
  const globalAccountsProductInfos =
    getAllGlobalAccountsProductInfos(pricingFlow);

  const selectedGlobalAccountsProductInfos = globalAccountsProductInfos.filter(
    ({ id: globalAccountProductInfoId }) =>
      pricingFlow?.products
        ?.map(({ id }) => id)
        .includes(globalAccountProductInfoId),
  );

  return selectedGlobalAccountsProductInfos;
}

export function getProductsWithUpdatedCollections(params: {
  oldPricingFlow: AlpacaPricingFlow;
  newPricingFlow: AlpacaPricingFlow;
}) {
  const { oldPricingFlow, newPricingFlow } = params;
  const products = newPricingFlow?.products ?? [];

  // Compare newPricingFlow to pricingFlow to see which Global Accounts products have been added or removed
  const allGlobalAccountProductInfos =
    getAllGlobalAccountsProductInfos(newPricingFlow);
  const allCollectionsProductInfos =
    getAllCollectionsProductInfos(newPricingFlow);

  // If you add a Global Account, also add all the associated Collections products
  const addedGlobalAccounts = newPricingFlow?.products?.filter(
    (p) =>
      p.categoryName === 'Global Accounts' &&
      !oldPricingFlow?.products?.includes(p),
  );
  const addedGlobalAccountProductInfos = allGlobalAccountProductInfos.filter(
    (p) => addedGlobalAccounts?.map((x) => x.id).includes(p.id),
  );
  const addedCollectionsProductInfos = allCollectionsProductInfos.filter(
    (productInfo) =>
      addedGlobalAccountProductInfos
        .map((x) => x.country)
        .includes(productInfo.country),
  );
  const addedCollectionsProductsToAdd: AlpacaCollectionsProduct[] =
    addedCollectionsProductInfos.map((productInfo) => {
      return {
        categoryName: 'Collections',
        quotePrice: null,
        volume: 0,
        name: productInfo.name,
        id: productInfo.id,
      };
    });
  // If you remove a Global Account, also remove all the associated Collections products
  const removedGlobalAccounts = oldPricingFlow?.products?.filter(
    (p) =>
      p.categoryName === 'Global Accounts' &&
      !newPricingFlow?.products?.includes(p),
  );
  const removedGlobalAccountsProductInfos = allGlobalAccountProductInfos.filter(
    (p) => removedGlobalAccounts?.map((x) => x.id).includes(p.id),
  );
  const removedCollectionsProductInfos = allCollectionsProductInfos.filter(
    (productInfo) =>
      removedGlobalAccountsProductInfos
        .map((x) => x.country)
        .includes(productInfo.country),
  );

  const newProductsWithRemovedCollections = products.filter(
    (p) => !removedCollectionsProductInfos.map((x) => x.id).includes(p.id),
  );
  return newProductsWithRemovedCollections.concat(
    addedCollectionsProductsToAdd,
  );
}

export default function Collections(props: {
  nextStep: () => void;
  previousStep: () => void;
}) {
  const { updateFlow, pricingFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const { nextStep, previousStep } = props;

  return (
    <div id="collections" className="">
      {/* Header */}
      <AlpacaHeader
        title="Collections"
        rightChildren={[
          <AlpacaOpportunityInfoButton />,
          <AlpacaHelpButton url="https://airwallex.atlassian.net/wiki/spaces/SBAH/pages/2583330888/Sales+Play+Global+Accounts+Wallets" />,
        ]}
      />

      <div className="px-8 py-4">
        <div className="pt-2">
          <AlpacaQuoteTable_NEW
            pricingFlow={pricingFlow as AlpacaPricingFlow}
            skuFilter={(sku: AlpacaProductPrice) => {
              return (
                sku &&
                sku.skuGroup === 'Collections' &&
                // filter out skus whose country doesn't match
                // selected global accounts
                getSelectedGlobalAccountsProductInfos(pricingFlow)
                  .map((x) => x.country)
                  .includes(sku.country)
              );
            }}
            productSort={(a, b) => {
              // The first two characters of the name are the country code
              return a.name.localeCompare(b.name);
            }}
            productPriceSort={(a, b) => {
              return a.name.localeCompare(b.name);
            }}
            updateFlow={(newFlow: AlpacaPricingFlow): void => {
              updateFlow(newFlow, false);
            }}
            title={'Products'}
            categoryName={'Collections'}
            selectorHelpText={
              <span className="text-gray-500 font-medium">
                <InformationCircleIcon className="size-5 inline mr-2 mb-1" />
                Products are filtered based on Global Accounts chosen.
              </span>
            }
          />
        </div>
        <div className="h-16" />
        <BottomBar
          primaryButtonProps={{
            label: 'Next',
            onClick: async () => nextStep(),
          }}
          secondaryButtonProps={{
            label: 'Back',
            onClick: async () => previousStep(),
          }}
        />
      </div>
    </div>
  );
}
