const Fallback = () => {
  return (
    <div className="mx-auto my-20 max-w-md rounded-lg border border-red-500 bg-white p-4 shadow-lg">
      <h2 className="text-lg font-semibold text-red-600">
        Oops! Something went wrong.
      </h2>
      <p className="mt-2 text-sm text-gray-600">
        Sorry, there was an error in the application. Please reach out to our
        support team for help.
      </p>
      <a
        href="mailto:support@dealops.com"
        className="mt-4 inline-block text-blue-600 hover:text-blue-800"
      >
        support@dealops.com
      </a>
    </div>
  );
};

export default Fallback;
