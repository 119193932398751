import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { classNames } from 'src/dashboard/App';

export type Option = {
  name: string;
  subOptions: Option[];
  [key: string]: any;
};

type MultiLevelDropdownProps = {
  label?: string;
  inputName: string;
  options: Option[];
  width?: string; // e.g. "w-60"
  defaultValue?: string[];
  value?: string[];
  showValidation?: boolean;
  handleInputChange?: (value: string[]) => void;
};

// Idea for future development
// - Support showing the path to the selected option if there is one
// - Fix "selected" state of leaf nodes
// - Fix margin right of branch nodes

export default function MultiLevelDropdown(props: MultiLevelDropdownProps) {
  const {
    label,
    options,
    inputName,
    width,
    defaultValue,
    value,
    handleInputChange,
  } = props;
  const [selectedPath, setSelectedPath] = useState<string[]>(
    value ?? defaultValue ?? [],
  );

  // If there are no options, disable the dropdown
  const disabled = options.length === 0;

  let showValidation = props.showValidation ?? false;

  const validButtonClassNames = classNames(
    width ?? 'w-60',
    disabled
      ? 'relative rounded-md bg-gray-200 py-1.5 pl-3 pr-10 text-left text-gray-400 cursor-not-allowed sm:text-sm sm:leading-6'
      : 'shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-fuchsia-900',
    'relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6',
  );
  const invalidButtonClassNames = classNames(
    width ?? 'w-60',
    disabled
      ? 'relative rounded-md bg-gray-200 py-1.5 pl-3 pr-10 text-left text-gray-400 cursor-not-allowed sm:text-sm sm:leading-6'
      : 'shadow-sm ring-1 ring-inset ring-red-300 focus:ring-2 focus:ring-red-600',
    'relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6',
  );

  // If the value is invalid (empty list) and we are showing validation, add the invalid class.
  const buttonClassNames =
    selectedPath.length === 0 && showValidation
      ? invalidButtonClassNames
      : validButtonClassNames;

  return (
    <>
      <input type="hidden" name={inputName} value={selectedPath ?? []} />
      <Listbox
        disabled={disabled}
        value={selectedPath}
        onChange={(value) => {
          console.log('value: ');
          console.log(value);
          setSelectedPath(value);
          handleInputChange && handleInputChange(value);
        }}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                {label}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button className={buttonClassNames}>
                {selectedPath.length > 0 ? (
                  <span className="block truncate">
                    {selectedPath[selectedPath.length - 1]}
                  </span>
                ) : (
                  <span className="block truncate text-gray-500">Select</span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => {
                    if (option.subOptions.length === 0) {
                      return (
                        <LeafNode key={option.name} option={option} path={[]} />
                      );
                    } else {
                      return (
                        <BranchNode
                          key={option.name}
                          option={option}
                          path={[]}
                        />
                      );
                    }
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}

function BranchNode(props: { option: Option; path: string[] }) {
  const { option, path } = props;
  const [hoveredOptionName, setHoveredOptionName] = useState<string | null>(
    null,
  );

  return (
    <div
      onMouseEnter={() => setHoveredOptionName(option.name)}
      onMouseLeave={() => setHoveredOptionName(null)}
      className="-mr-1"
    >
      <div
        className={classNames(
          hoveredOptionName === option.name ? 'bg-gray-100' : '',
          'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-800',
        )}
      >
        <div>
          <span
            className={classNames(
              hoveredOptionName === option.name
                ? 'font-semibold'
                : 'font-normal',
              'block truncate',
            )}
          >
            {option.name}
          </span>

          <span
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400',
            )}
          >
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        </div>

        {hoveredOptionName === option.name && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute left-full top-[0] z-20 mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {option.subOptions.map((subOption) => {
                if (subOption.subOptions.length === 0) {
                  return (
                    <LeafNode
                      key={subOption.name}
                      option={subOption}
                      path={path.concat([option.name])}
                    />
                  );
                } else {
                  return (
                    <BranchNode
                      key={subOption.name}
                      option={subOption}
                      path={path.concat([option.name])}
                    />
                  );
                }
              })}
            </Listbox.Options>
          </Transition>
        )}
      </div>
    </div>
  );
}

function LeafNode(props: { option: Option; path: string[] }) {
  const { option, path } = props;
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(
          active ? 'bg-gray-100' : '',
          'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-800',
        )
      }
      value={path.concat([option.name])}
      onClick={(e) => e.stopPropagation()}
    >
      {({ selected, active }) => {
        // @TODO(fay) selected is never true, this is not a problem for now
        return (
          <>
            <span
              className={classNames(
                selected ? 'font-semibold' : 'font-normal',
                'block truncate',
              )}
            >
              {option.name}
            </span>

            {selected && (
              <span
                className={classNames(
                  active ? 'text-white' : 'text-fuchsia-900',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                )}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )}
          </>
        );
      }}
    </Listbox.Option>
  );
}
