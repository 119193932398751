import { ChartBarIcon, FaceSmileIcon } from '@heroicons/react/24/outline';
import GraphContainer, { RenderChildWithSize } from './GraphContainer';

const WIDTH_TO_BARS_RATIO = 13;

export function ProgressBar({
  width,
  progress,
}: {
  width: number;
  progress: number;
}) {
  const totalElements = Math.round(width / WIDTH_TO_BARS_RATIO);
  let greenElements = Math.ceil(progress * totalElements);

  // don't show entirely completed unless 100%
  if (greenElements === totalElements && progress !== 1) {
    greenElements = totalElements - 1;
  }

  const grayElements = totalElements - greenElements;

  return (
    <div className="flex w-full justify-between" style={{ width: width }}>
      {Array.from({ length: greenElements }).map(() => {
        return <div className="h-6 w-[7px] bg-[#38C793]"></div>;
      })}
      {Array.from({ length: grayElements }).map(() => {
        return <div className="h-6 w-[7px] bg-[#E2E4E9]"></div>;
      })}
    </div>
  );
}

export const GOAL_TRACKER_EXAMPLE_ARGS = {
  target: 0.75,
  progress: 0.55,
};
export function GoalTrackerExample({
  target,
  progress,
}: {
  target: number;
  progress: number;
}) {
  return (
    <GraphContainer
      header={
        <div className="flex items-center">
          <ChartBarIcon className="mr-1 w-6 stroke-gray-600 text-gray-800" />
          <div className="font-semibold text-gray-900">Goal Tracking</div>
        </div>
      }
    >
      <div className="mb-4 flex w-full items-center justify-between">
        <div>
          <div className="text-xl font-normal text-gray-500">
            Improvement in <span className="font-bold text-black">ASPs</span> is{' '}
            <span className="font-bold text-black">
              {(progress * 100).toFixed(0)}%
            </span>
          </div>
          <div className="text-md text-gray-500">
            Your target is {(target * 100).toFixed(0)}%
          </div>
        </div>
        <FaceSmileIcon className="w-12" />
      </div>
      <RenderChildWithSize
        className="w-full"
        childFunction={(width, height) => {
          return <ProgressBar width={width} progress={progress} />;
        }}
      />
    </GraphContainer>
  );
}
