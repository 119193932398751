function findIndexOrNull<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
): number | null {
  // eslint-disable-next-line rulesdir/no-findindex
  const index = array.findIndex(predicate);
  return index === -1 ? null : index;
}

const list = {
  findIndexOrNull,
};

export default list;
