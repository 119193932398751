import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/react/20/solid';
import Badge from 'src/components/Badge';
import { classNames } from 'src/dashboard/App';
import { formatCurrency } from '../../../utils/formatters';
import { RelatedDeal } from './types';

export default function RelatedDealTable(props: { deal: RelatedDeal | null }) {
  const dealProducts = [
    { name: 'Auth', volume: 200, quotePrice: 400 },
    { name: 'Transactions', volume: 3000, quotePrice: 800 },
  ];
  const products = [
    { name: 'Balance', volume: 800, quotePrice: 10 },
    { name: 'Auth', volume: 100, quotePrice: 500 },
    { name: 'Transactions', volume: 3000, quotePrice: 500 },
    { name: 'Platform fee', volume: 1, quotePrice: 9 },
  ];
  // Filter products that exist in dealProducts based on the "name" field
  const matchingProducts = products.filter((product) =>
    dealProducts.some((dealProduct) => dealProduct.name === product.name),
  );

  // Filter products that do not exist in dealProducts based on the "name" field
  const nonMatchingProducts = products.filter(
    (product) =>
      !dealProducts.some((dealProduct) => dealProduct.name === product.name),
  );

  return (
    <div className="mt-2 w-full">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="min-w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <th
                scope="col"
                className="sticky top-0 z-10 w-full rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                Product
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Monthly Est. Volume
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                How your deal compares
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Quote price
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                How your deal compares
              </th>
            </tr>
          </thead>
          <tbody>
            {matchingProducts.length > 0
              ? matchingProducts.map((product, idx) => {
                  const compareProduct = dealProducts.find(
                    (p) => p.name === product.name,
                  );
                  return (
                    <RelatedDealTableRow
                      key={idx}
                      product={product}
                      compareProduct={compareProduct}
                    />
                  );
                })
              : null}
            {nonMatchingProducts.length > 0
              ? nonMatchingProducts.map((product, idx) => {
                  const compareProduct = dealProducts.find(
                    (p) => p.name === product.name,
                  );
                  return (
                    <RelatedDealTableRow
                      key={idx}
                      product={product}
                      compareProduct={compareProduct}
                    />
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function RelatedDealTableRow(props: { product: any; compareProduct: any }) {
  const { product, compareProduct } = props;

  let badgeQuotePrice;
  let badgeVolume;
  if (compareProduct) {
    // Calculate the percentage difference of quotePrice
    const percentageDifferenceQuotePrice = Math.round(
      (compareProduct.quotePrice / product.quotePrice - 1) * 100,
    );
    console.log(
      'DEBUG!',
      percentageDifferenceQuotePrice,
      compareProduct.quotePrice,
      product.quotePrice,
    );

    // Determine whether it's higher, lower, or equal
    const tooltipQuotePrice = (
      <span className="tooltip -ml-40 -mt-20 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
        Your deal priced this product at{' '}
        {formatCurrency({
          amount: compareProduct.quotePrice,
          currency: 'USD',
          rounding: false,
        })}
      </span>
    );
    if (percentageDifferenceQuotePrice > 0) {
      badgeQuotePrice = (
        <Badge color="green" className="has-tooltip items-center">
          <ArrowSmallUpIcon className="mr-0.5 h-4 w-4" aria-hidden="true" />
          {Math.abs(percentageDifferenceQuotePrice)}% higher
          {tooltipQuotePrice}
        </Badge>
      );
    } else if (percentageDifferenceQuotePrice < 0) {
      badgeQuotePrice = (
        <Badge color="red" className="has-tooltip items-center">
          <ArrowSmallDownIcon className="mr-0.5 h-4 w-4" aria-hidden="true" />
          {Math.abs(percentageDifferenceQuotePrice)}% lower
          {tooltipQuotePrice}
        </Badge>
      );
    } else {
      badgeQuotePrice = (
        <Badge color="green" className="has-tooltip items-center">
          – Same
          {tooltipQuotePrice}
        </Badge>
      );
    }

    // Calculate the percentage difference of volume
    const tooltipQuoteVolume = (
      <span className="tooltip -ml-20 -mt-20 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
        Your deal set this volume at {compareProduct.volume}
      </span>
    );
    const percentageDifferenceVolume = Math.round(
      (compareProduct.volume / product.volume - 1) * 100,
    );

    // Determine whether it's higher, lower, or equal
    if (percentageDifferenceVolume > 0) {
      badgeVolume = (
        <Badge color="green" className="has-tooltip items-center">
          <ArrowSmallUpIcon className="mr-0.5 h-4 w-4" aria-hidden="true" />
          {Math.abs(percentageDifferenceQuotePrice)}% higher
          {tooltipQuoteVolume}
        </Badge>
      );
    } else if (percentageDifferenceVolume < 0) {
      badgeVolume = (
        <Badge color="red" className="has-tooltip items-center">
          <ArrowSmallDownIcon className="mr-0.5 h-4 w-4" aria-hidden="true" />
          {Math.abs(percentageDifferenceQuotePrice)}% lower
          {tooltipQuoteVolume}
        </Badge>
      );
    } else {
      badgeVolume = (
        <Badge color="green" className="has-tooltip items-center">
          – Same
          {tooltipQuoteVolume}
        </Badge>
      );
    }
  }

  return (
    <tr className={classNames(!compareProduct ? 'bg-gray-50 opacity-60' : '')}>
      <td className="w-full whitespace-nowrap border-b px-6 py-3.5 text-left text-sm font-medium text-gray-900">
        <div className="items-left flex flex-col  gap-2 lg:flex-row lg:items-center">
          {product.name}
          {compareProduct && (
            <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
              Also in your deal
            </span>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap border-b px-6 py-3.5 text-left text-sm text-gray-500 ">
        {product.volume}
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3.5 text-left text-sm font-medium">
        {badgeVolume}
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3.5 text-left text-sm font-medium">
        {formatCurrency({
          amount: product.quotePrice,
          currency: 'USD',
          rounding: false,
        })}
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3.5 text-left text-sm font-medium">
        {badgeQuotePrice}
      </td>
    </tr>
  );
}
