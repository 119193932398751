import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { Organization, User } from '../../types';

import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import { InlineSpinner } from 'src/components/Loading';
import { getOpportunityIdFromIdOrUrl } from '../../utils/formatters';
import { classNames } from '../App';

interface OpportunitySearchPageProps {
  user: User;
  organization: Organization;
}

export default function OpportunitySearchPage(
  props: OpportunitySearchPageProps,
) {
  const navigate = useNavigate();
  const createAnalyticsEvent = useAnalyticsContext();
  useEffect(() => {
    createAnalyticsEvent({
      name: 'opportunity_search_page__loaded',
      eventData: {},
    });
  }, []);

  return (
    <>
      <div
        className="relative flex flex-col items-center justify-center bg-repeat-x"
        style={{ backgroundImage: `url(${backgroundLines})` }}
      >
        {/* Dealops target logo */}
        <div className="mt-36 h-24 w-24">
          <img
            className="absolute h-24 w-24"
            src={fadedCircleBg}
            alt="faded circle"
          />
          <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
            <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
          </div>
        </div>

        <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
          Hi {props.user.name?.split(' ')[0]}, let's work on pricing!
        </h1>
        <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
          Enter a Salesforce opportunity ID to get started.
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();

            const id = getOpportunityIdFromIdOrUrl(
              e.currentTarget.opportunity.value,
            );
            navigate(id, { relative: 'path' });
          }}
          className="mx-auto mt-8 block sm:mx-auto sm:w-full sm:max-w-sm"
        >
          <div className="mb-4">
            <input
              type="text"
              name="opportunity"
              required
              placeholder="Enter Opportunity ID or URL"
              className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800 sm:text-sm"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fuchsia-950"
            >
              Find Opportunity
            </button>
          </div>
        </form>
        <RecentOpportunities
          setId={(id) => {
            navigate(`/app/opportunity/${id}`);
          }}
          user={props.user}
        />
      </div>
    </>
  );
}

interface Opportunity {
  Id: string;
  Name: string;
  StageName: string;
  CloseDate: string;
  Amount: number;
  Account: {
    Name: string;
  };
  Owner: {
    Name: string;
  };
}
export function RecentOpportunities(props: {
  setId: (id: string) => void;
  user: User;
}) {
  // null means loading
  const [opportunities, setOpportunities] = useState<Opportunity[] | null>(
    null,
  );
  const [startIndex, setStartIndex] = useState(0);
  useEffect(() => {
    if (props.user.salesforceUserId == null) {
      console.log('No salesforce user ID, will not fetch opportunities');
      return;
    }
    const fetchOpportunities = async () => {
      try {
        const response = await api.get('opportunities');
        setOpportunities(response.data);
      } catch (err) {
        datadogRum.addError(err);
        console.error(err);
      }
    };

    fetchOpportunities();
  }, []);

  if (isNil(opportunities)) {
    const spinnerSize = 30;
    return (
      <div className="my-16 w-7/12">
        <InlineSpinner height={spinnerSize} width={spinnerSize} />;
      </div>
    );
  }
  if (opportunities.length === 0) {
    return (
      <div className="my-8 w-7/12 flex justify-center font-md text-gray-600">
        <span>No recent opportunities found.</span>
      </div>
    );
  }

  return (
    <div className="my-8 w-7/12">
      <div className="font-md text-gray-900 pb-4">Recent opportunities</div>
      <ul role="list" className="divide-y divide-gray-100">
        {opportunities.slice(startIndex, startIndex + 5).map((opportunity) => (
          <li
            key={opportunity.Id}
            className="flex items-center justify-between gap-x-6 py-2"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {opportunity.Name}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  Stage: {opportunity.StageName}
                </p>
              </div>
            </div>
            <button
              className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
              onClick={() => props.setId(opportunity.Id)}
            >
              Price this opportunity &rarr;
            </button>
          </li>
        ))}
      </ul>
      <div className="flex gap-x-4 mt-4">
        <button
          className={classNames(
            'flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0',
            startIndex === 0 ? 'bg-gray-200 ' : 'bg-white hover:bg-gray-50 ',
          )}
          onClick={() => setStartIndex((startIndex) => startIndex - 5)}
          disabled={startIndex === 0}
        >
          Back
        </button>
        <button
          className={classNames(
            'flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0',
            startIndex + 5 >= opportunities.length
              ? 'bg-gray-200 '
              : 'bg-white hover:bg-gray-50 ',
          )}
          onClick={() => setStartIndex((startIndex) => startIndex + 5)}
          disabled={startIndex + 5 >= opportunities.length}
        >
          View next 5 opportunities
        </button>
      </div>
    </div>
  );
}
