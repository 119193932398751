import { useState } from 'react';
import StackedCardRadioButtonGroup from 'src/components/StackedCardRadioButtonGroup';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { Organization, User } from 'src/types';
import BottomBar from '../PricingFlow/Penguin/Components/BottomBar';
import FlowProgressBar from './FlowProgressBar';
import { CurvesChart, OVERVIEW_CURVE_DATA } from './PricingCurvesPage';
import PricingCurvesRuleBuilder from './PricingCurvesRuleBuilder';

export const TYPE = 'Type';
export const BUILDER = 'Builder';
export const REVIEW = 'Review';

export const PricingCurveCreateSteps = [TYPE, BUILDER, REVIEW];

interface PricingCurvesCreateProps {
  user: User;
  organization: Organization;
}

export default function PricingCurvesCreate(props: PricingCurvesCreateProps) {
  const { user, organization } = props;
  const [stage, setStage] = useState<string>(TYPE);

  return (
    <div>
      {/* // @TODO(fay) make this stick to the top */}
      <FlowProgressBar
        stage={stage}
        setStage={(stage) => setStage(stage)}
        customSteps={PricingCurveCreateSteps}
        align="left"
      />

      {stage === TYPE && <Step1 next={() => setStage(BUILDER)} />}
      {stage === BUILDER && (
        <Step2
          user={user}
          organization={organization}
          next={() => setStage(REVIEW)}
          previous={() => setStage(TYPE)}
        />
      )}
      {stage === REVIEW && <Step3 previous={() => setStage(BUILDER)} />}
    </div>
  );
}

const Step1OptionsSection1 = [
  {
    name: 'Import from a spreadsheet',
    description: 'csv, .xls',
    icon: () => <span>Upload a file</span>,
  },
  {
    name: 'Manual',
    description: 'Use our rule based engine and the base curve as base.',
    icon: () => <span>Create a curve</span>,
  },
];
const Step1OptionsSection2 = [
  {
    name: 'CRM Data based',
    description: 'Based on historical Salesforce + Gong + 6sense data',
    icon: () => <span>Create a curve</span>,
  },
  {
    name: 'Usage Data based',
    description: 'Based on usage data',
    icon: () => <span>Create a curve</span>,
  },
  {
    name: 'Behavior based',
    description: 'Cloning the pricing behavior of specific reps',
    icon: () => <span>Create a curve</span>,
  },
];

function Step1(props: { next: () => void }) {
  const [selected, setSelected] = useState<string | undefined>(undefined);

  return (
    <div className="relative flex flex-col items-center justify-center">
      {/* Dealops target logo */}
      <div className="mt-8 h-24 w-24">
        <img
          className="absolute h-24 w-24"
          src={fadedCircleBg}
          alt="faded circle"
        />
        <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
          <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
        </div>
      </div>

      {/** Heading */}
      <h1 className="mx-auto max-w-7xl px-4 pt-4 text-center text-2xl font-semibold sm:px-6 lg:px-8">
        Create a curve
      </h1>
      <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
        How do you want to create your curve?
      </p>

      <div className="mt-8 mb-28">
        <StackedCardRadioButtonGroup
          options={Step1OptionsSection1}
          selected={selected}
          onSelect={(option) => setSelected(option)}
        />

        <div className="rounded-md bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500 my-4">
          DEALOPS RECOMMENDED CURVES
        </div>

        <StackedCardRadioButtonGroup
          options={Step1OptionsSection2}
          selected={selected}
          onSelect={(option) => setSelected(option)}
        />
      </div>

      <BottomBar
        primaryButtonProps={{
          label: 'Next',
          successLabel: 'Next',
          errorLabel: 'Error',
          onClick: function (): Promise<
            void | ('idle' | 'loading' | 'success' | 'error')
          > {
            return new Promise((resolve) => {
              props.next();
              resolve('success');
            });
          },
        }}
        secondaryButtonProps={{
          label: 'Cancel',
          successLabel: 'Cancel',
          errorLabel: 'Error',
          onClick: function (): Promise<
            void | ('idle' | 'loading' | 'success' | 'error')
          > {
            return new Promise((resolve) => {
              // Redirect to the pricing curves overview page
              window.location.href = '/app/pricingcurves';
              resolve('success');
            });
          },
        }}
      />
    </div>
  );
}

function Step2(props: {
  user: User;
  organization: Organization;
  next: () => void;
  previous: () => void;
}) {
  return (
    <div>
      <PricingCurvesRuleBuilder />

      <BottomBar
        primaryButtonProps={{
          label: 'Next',
          successLabel: 'Next',
          errorLabel: 'Error',
          onClick: function (): Promise<
            void | ('idle' | 'loading' | 'success' | 'error')
          > {
            return new Promise((resolve) => {
              props.next();
              resolve('success');
            });
          },
        }}
        secondaryButtonProps={{
          label: 'Back',
          successLabel: 'Back',
          errorLabel: 'Error',
          onClick: function (): Promise<
            void | ('idle' | 'loading' | 'success' | 'error')
          > {
            return new Promise((resolve) => {
              props.previous();
              resolve('success');
            });
          },
        }}
      />
    </div>
  );
}

function Step3(props: { previous: () => void }) {
  return (
    <div>
      <div className="p-6">
        {/* Header */}
        <div className="text-2xl font-semibold">Review your curve</div>
        <div className="text-gray-500">
          This curve will add a 7% discount on top of the Base curve pricing if
          the competitor is Braintree and the industry is E-commerce and the
          geography is not EMEA.
        </div>

        <div className="h-[300px] w-full mt-6">
          <CurvesChart chartName="Preview" chartData={OVERVIEW_CURVE_DATA} />
        </div>
      </div>

      <BottomBar
        primaryButtonProps={{
          label: 'Save',
          successLabel: 'Success',
          errorLabel: 'Error',
          onClick: function (): Promise<
            void | ('idle' | 'loading' | 'success' | 'error')
          > {
            return new Promise((resolve) => {
              // Redirect to the pricing curves overview page
              window.location.href = '/app/pricingcurves';
              resolve('success');
            });
          },
        }}
        secondaryButtonProps={{
          label: 'Back',
          successLabel: 'Back',
          errorLabel: 'Error',
          onClick: function (): Promise<
            void | ('idle' | 'loading' | 'success' | 'error')
          > {
            return new Promise((resolve) => {
              props.previous();
              resolve('success');
            });
          },
        }}
      />
    </div>
  );
}
