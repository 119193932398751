import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { PricingFlowCommon, PricingFlowStage } from './types';

const stages = {
  [PricingFlowStage.NOT_STARTED]: 0, // This stage doesn't show up in the progress bar
  [PricingFlowStage.ADD_PRODUCTS]: 1,
  [PricingFlowStage.CALCULATE_PRICE]: 2,
};

export default function FlowProgressBar(props: {
  stage: PricingFlowCommon['stage'];
  setStage: (stage: PricingFlowCommon['stage']) => void;
}) {
  const { stage, setStage } = props;

  function icon(stepToDisplay: PricingFlowCommon['stage']) {
    if (stages[stage] < stages[stepToDisplay]) {
      return (
        <div
          className={
            'flex h-6 w-6 items-center justify-center rounded-full border border-gray-400 text-sm'
          }
        >
          {stages[stepToDisplay]}
        </div>
      );
    } else if (stages[stage] === stages[stepToDisplay]) {
      return (
        <div
          className={
            'flex h-6 w-6 items-center justify-center rounded-full border border-none border-gray-400 bg-fuchsia-50 text-sm text-fuchsia-900'
          }
        >
          {stages[stepToDisplay]}
        </div>
      );
    } else if (stages[stage] > stages[stepToDisplay]) {
      return (
        <CheckCircleIcon
          className="-m-1 h-7 w-7 text-green-500"
          aria-hidden="true"
        />
      );
    }
  }
  return (
    <div className="flex flex-row items-center justify-center gap-3 border-b bg-white px-4 py-6 text-gray-600">
      <button
        className="flex flex-row items-center gap-2"
        onClick={() => setStage(PricingFlowStage.ADD_PRODUCTS)}
      >
        {icon(PricingFlowStage.ADD_PRODUCTS)}
        Products
      </button>
      <ChevronRightIcon
        className="h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <button
        className="flex flex-row items-center gap-2"
        onClick={() => setStage(PricingFlowStage.CALCULATE_PRICE)}
        disabled // @TODO this is not optimal but without rethinking more, let's just disable this in case products are not set
      >
        {icon(PricingFlowStage.CALCULATE_PRICE)}
        Pricing
      </button>
    </div>
  );
}
