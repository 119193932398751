import {
  ChevronRightIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import Badge from 'src/components/Badge';
import { useModal } from 'src/components/Modal';
import { Organization, User } from '../types';
import EditableIndicator from './PricingFlow/Penguin/Components/EditableIndicator';

type Product = {
  quantity: number;
  standardPrice: number;
  proposedPrice: number;
  discount: number;
};
type AdditionalData = {
  subscriptionTerms: number;
  billingFrequency: string;
  paymentTerms: string;
  priceProtection: boolean;
  nonCommercialTerms: string;
};
type DemoPricingFlow = {
  dealopsUrl: string;
  additionalData: AdditionalData;
  products: Record<string, Product>;
};

interface ProofreadDemoProps {
  user: User;
  organization: Organization;
}
export default function ProofreadDemo(props: ProofreadDemoProps) {
  const { showModal, hideModal } = useModal();
  const showApprovalModal = () => {
    showModal({
      title: 'Ask for approval',
      children: <AskForApprovalModal />,
    });
  };
  useEffect(() => {
    showApprovalModal();
  }, []);
  const [pricingFlow, setPricingFlow] = useState<DemoPricingFlow>({
    dealopsUrl: 'https://dealops.com/app/proofread-demo',
    additionalData: {
      subscriptionTerms: 12,
      billingFrequency: 'Annual upfront',
      paymentTerms: 'Net 60',
      priceProtection: true,
      nonCommercialTerms: 'Here is freetext',
    },
    products: {
      'Base Platform + Vault Bundle': {
        quantity: 500,
        standardPrice: 275,
        proposedPrice: 261.25,
        discount: 0.1,
      },
    },
  });
  return (
    <div>
      {/* Header */}
      <div className="flex flex-col gap-4 border-b border-gray-200 px-6 py-4 xl:flex-row xl:justify-between">
        <div className="flex flex-row items-center gap-4">
          {/* Currency icon */}
          <div className="h-14 w-14">
            <div className="absolute flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <CurrencyDollarIcon className="h-7 w-7" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <h1 className="text-lg font-semibold">Configure quote</h1>
            <p className="text-base text-gray-700">
              Configure terms and products
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            className="border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-2 py-1 font-medium text-sm shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={showApprovalModal}
          >
            Submit for approval
          </button>
        </div>
      </div>
      <div className="px-8 py-4">
        <div className="pt-2">
          <QuoteTable pricingFlow={pricingFlow} />
        </div>
        <div className="h-64" />
      </div>
    </div>
  );
}

interface QuoteTableRowProps {
  product: Product;
  productName: string;
}
function QuoteTableRow(props: QuoteTableRowProps) {
  const { product, productName } = props;
  return (
    <tr>
      {/* Product */}
      <td className="flex min-w-[172px] items-center gap-4 border-b border-gray-200 px-6 py-4">
        <div className="h-5 w-5 flex-shrink-0">
          {' '}
          <button
            className="absolute flex h-5 w-5 items-center justify-center rounded-full border border-fuchsia-900 bg-fuchsia-50"
            onClick={() => {}}
          >
            <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
          </button>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-gray-900">
            {productName}
          </span>
        </div>
      </td>
      {/* Quantity */}
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm text-gray-500">
        <div className="flex flex-col">
          <span className="">{product.quantity.toLocaleString()}</span>
          <span className="">custom 12 Mo</span>
        </div>
      </td>
      {/* List price */}
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-purple-700">
        <button
          className=""
          title="Set quote price to rack rate"
          onClick={() => {}}
        >
          <Badge color="purple">
            ${product.standardPrice.toLocaleString()}
          </Badge>
        </button>
      </td>
      {/* Proposed price */}
      <td
        className="overflow-show h-full w-full p-0 align-top border-b border-gray-200 min-w-[120px]"
        onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {}}
      >
        <EditableIndicator className="py-4 pl-4 text-sm">
          Tiered
        </EditableIndicator>
      </td>
      {/* Approval level */}
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-green-700">
        <Badge color={'orange'}>VP of Sales</Badge>
      </td>
      {/* Est. Monthly revenue */}
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500">
        ${(product.quantity * product.proposedPrice).toLocaleString()}
      </td>
    </tr>
  );
}

interface QuoteTableProps {
  pricingFlow: DemoPricingFlow;
}
function QuoteTable(props: QuoteTableProps) {
  const { pricingFlow } = props;
  return (
    <div className="my-2 w-full self-stretch">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full min-w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <th
                scope="col"
                className="sticky top-0 z-10 w-full rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                Products
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                List price
              </th>
              <th
                scope="col"
                className="has-tooltip sticky top-0 z-20 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Proposed price
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Approval level
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden rounded-tr-xl  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Est. monthly revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(pricingFlow.products).map(
              ([productName, product]) => {
                return (
                  <QuoteTableRow product={product} productName={productName} />
                );
              },
            )}
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="col"
                colSpan={6}
                className="bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              ></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

interface AskForApprovalModalProps {}
function AskForApprovalModal(props: AskForApprovalModalProps) {
  return (
    <div className="w-full">
      <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500 w-full">
        APPROVER
      </div>
      <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500 w-full">
        SLACK MESSAGE
      </div>
    </div>
  );
}
