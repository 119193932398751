import { datadogRum } from '@datadog/browser-rum';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowDownRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { produce } from 'immer';
import _, { isNil } from 'lodash';
import { Fragment, useRef, useState } from 'react';
import Badge from 'src/components/Badge';
import { FormattedNumberField } from 'src/components/Fields';
import { unreachable } from 'src/typeUtils';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { formatCurrencyValue } from '../Alpaca/alpaca_utils';
import { QuotePriceEditable } from '../Alpaca/Components/AlpacaQuotePriceEditable';
import { QuoteTableBodyEmptyState } from '../Alpaca/Components/AlpacaQuoteTableEmptyState';
import EditableIndicator from '../Penguin/Components/EditableIndicator';
import { usePricingFlowContext } from '../PricingFlow';
import {
  CurrencyValueFlat,
  CurrencyValueType,
  Tier,
  ZERO_FLAT,
} from '../types_common/price';
import HamsterProductSelectionModal from './HamsterProductSelectionModal';
import {
  HamsterCV,
  HamsterPricingFlow,
  HamsterProduct,
  HamsterProductPrice,
  HamsterQuotePrice,
} from './hamster_types';

export function estimatedRevenue(
  volume: number,
  quotePrice: HamsterQuotePrice | null,
  listPrice: CurrencyValueFlat | null,
) {
  const quotePriceValue = (() => {
    if (isNil(quotePrice)) {
      return listPrice?.value ?? 0;
    }
    switch (quotePrice.type) {
      case CurrencyValueType.FLAT:
        return quotePrice.value;
      case 'tiered':
        // TODO(georgehamster) is this waterfalled?
        const cv = _.last(quotePrice.tiers)?.currencyValue;
        switch (cv?.type) {
          case CurrencyValueType.FLAT:
            return cv.value;
          default:
            datadogRum.addError('Unexpected quote price type');
            return 0;
        }
      default:
        unreachable(quotePrice);
    }
  })();
  return volume * quotePriceValue;
}

function _maxVolumeForProduct(product: HamsterProduct) {
  return (
    product.volume +
    (_.max(product.rampedVolumeIncremental.map((v) => v?.value ?? 0)) ?? 0)
  );
}

enum ApprovalLevel {
  None = 0,
  HeadOfMidmarket = 1,
  VPOfSales = 2,
  DealDesk = 3,
  Legal = 4,
}
function _getApprovalLevelForPrice(
  price: HamsterCV | null,
  volume: number,
  product: HamsterProduct,
  productInfo: HamsterProductPrice,
) {
  const pricingInfo = productInfo.currentPricingCurve.pricingInformation;
  let approvalLevel = ApprovalLevel.None;
  const requiresApproval = (key: keyof typeof pricingInfo) => {
    const approvalTiers = pricingInfo[key];
    if (isNil(approvalLevel)) {
      return false;
    }
    if (approvalTiers === true) {
      return true;
    }
    const approvalTier = tierForValue(approvalTiers?.tiers, volume);
    if (isNil(approvalTier)) {
      return false;
    }
    return (
      approvalTier.currencyValue.value >
      (price?.value ?? getHamsterListPrice(product, productInfo)?.value ?? 0)
    );
  };
  if (requiresApproval('headOfMidmarketSales')) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.HeadOfMidmarket);
  }
  if (requiresApproval('vpOfSales')) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.VPOfSales);
  }
  if (requiresApproval('dealDesk')) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.DealDesk);
  }
  if (requiresApproval('legal')) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.Legal);
  }
  return approvalLevel;
}
function _getApprovalLevelForProduct(
  product: HamsterProduct,
  productInfo: HamsterProductPrice,
) {
  const quotePrice = product.quotePrice;
  const listPrice = getHamsterListPrice(product, productInfo);
  switch (quotePrice?.type) {
    case CurrencyValueType.FLAT:
      return _getApprovalLevelForPrice(
        quotePrice,
        _maxVolumeForProduct(product),
        product,
        productInfo,
      );
    case 'tiered':
      const highestApproval = _.max(
        quotePrice.tiers.map((t) =>
          _getApprovalLevelForPrice(
            t.currencyValue,
            _maxVolumeForProduct(product),
            product,
            productInfo,
          ),
        ),
      );
      if (highestApproval) {
        return highestApproval;
      } else {
        datadogRum.addError(
          `computing approval for empty tiers, falling back to no approval`,
        );
        return ApprovalLevel.None;
      }
    case undefined:
      return _getApprovalLevelForPrice(
        listPrice,
        _maxVolumeForProduct(product),
        product,
        productInfo,
      );
    default:
      unreachable(quotePrice);
  }
}

interface ApprovalBadgeProps {
  approvalLevel: ApprovalLevel;
}
function ApprovalBadge(props: ApprovalBadgeProps) {
  switch (props.approvalLevel) {
    case ApprovalLevel.None:
      return <Badge color={'green'}>None</Badge>;
    case ApprovalLevel.HeadOfMidmarket:
      return <Badge color={'yellow'}>Head of Midmarket Sales</Badge>;
    case ApprovalLevel.VPOfSales:
      return <Badge color={'orange'}>VP of Sales</Badge>;
    case ApprovalLevel.DealDesk:
      return <Badge color={'red'}>Deal Desk</Badge>;
    case ApprovalLevel.Legal:
      return <Badge color={'pink'}>Legal</Badge>;
    default:
      unreachable(props.approvalLevel);
  }
}

function tierForValue<T extends Tier>(
  tiers: T[] | null | undefined,
  quantity: number,
): T | null {
  if (isNil(tiers)) {
    return null;
  }
  return tiers
    .filter((t) => t.minimum.value <= quantity)
    .sort((a, b) => b.minimum.value - a.minimum.value)[0];
}

export function getHamsterListPrice(
  product: HamsterProduct,
  productInfo: HamsterProductPrice | null | undefined,
) {
  if (isNil(productInfo)) {
    return null;
  }
  const maxVolume = _maxVolumeForProduct(product);
  return (
    tierForValue(
      productInfo.currentPricingCurve.pricingInformation.listPrice?.tiers,
      maxVolume,
    )?.currencyValue ?? null
  );
}

interface QuoteTableRowProps {
  product: HamsterProduct;
}
function QuoteTableRow(props: QuoteTableRowProps) {
  const volumeInputRef = useRef<HTMLInputElement>(null);
  const { product } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
  const [showRampUp, setShowRampUp] = useState(false);
  if (isNil(productInfo)) {
    return null;
  }
  const listPrice = getHamsterListPrice(product, productInfo);
  const estMonthlyRevenue = estimatedRevenue(
    product.volume,
    product.quotePrice,
    listPrice,
  );
  const months = Array.from(
    { length: pricingFlow.additionalData.subscriptionTerms },
    (_, i) => i + 1,
  );
  return (
    <>
      {/* Main product row */}
      <tr>
        {/* Product */}
        <td className="flex min-w-[172px] items-center gap-4 border-b border-gray-200 px-6 py-4 h-full">
          <div className="flex flex-row items-center">
            {isNil(productInfo.fixedVolume) && (
              <button
                className="mr-3 flex h-5 w-5 items-center justify-center rounded-full border border-gray-200 bg-white"
                onClick={() => {
                  setShowRampUp((prevShowRampUp) => !prevShowRampUp);
                }}
              >
                {showRampUp ? (
                  <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
                )}
              </button>
            )}
            {productInfo.isFreeformProduct ? (
              <input
                type="text"
                data-1p-ignore
                className="text-sm font-medium text-gray-900 rounded-sm outline-1 outline-gray-300 outline-none border-none focus-within:outline focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent p-1"
                value={product.customName ?? productInfo.name}
                onChange={(e) => {
                  updateFlow(
                    produce(pricingFlow, (draftFlow) => {
                      const productToRename = draftFlow.products.find(
                        (p) => p.id === product.id,
                      );
                      if (productToRename)
                        productToRename.customName = e.currentTarget.value;
                    }),
                    false,
                  );
                }}
              />
            ) : (
              <span className="text-sm font-medium text-gray-900">
                {product.customName ?? productInfo.name}
              </span>
            )}
          </div>
        </td>
        {/* Quantity */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 text-sm text-gray-500">
          {!isNil(productInfo.fixedVolume) ? (
            <div className="w-28 h-full">
              <FormattedNumberField
                type="text"
                value={productInfo.fixedVolume}
                required={true}
                numberDecimals={0}
                className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
                updateValue={() => {}}
                disabled={true}
              />
            </div>
          ) : (
            <EditableIndicator>
              <FormattedNumberField
                type="text"
                value={product.volume}
                required={true}
                numberDecimals={0}
                className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
                data-volume-editable
                ref={volumeInputRef}
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  volumeInputRef,
                  'data-volume-editable',
                )}
                updateValue={(value: number) => {
                  updateFlow(
                    produce(pricingFlow, (draft) => {
                      const productToUpdate = draft.products.find(
                        (p) => p.id === product.id,
                      );
                      if (productToUpdate) {
                        productToUpdate.volume = value;
                      }
                    }),
                    false,
                  );
                }}
                disabled={!editMode}
              />
            </EditableIndicator>
          )}
        </td>
        {/* List price */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-purple-700">
          <button
            className=""
            title="Set quote price to rack rate"
            onClick={() => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  const productToUpdate = draft.products.find(
                    (p) => p.id === product.id,
                  );
                  if (productToUpdate) {
                    productToUpdate.quotePrice = listPrice;
                  }
                }),
                false,
              );
            }}
          >
            <Badge color="purple">{formatCurrencyValue(listPrice, 2)}</Badge>
          </button>
        </td>
        {/* Proposed price */}
        <td
          className="overflow-show h-full w-full p-0 align-top border-b border-gray-200 min-w-[120px]"
          onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {}}
        >
          {/* // TODO(georgehamster) obviously lots of these props are wrong, pending pricing curve modelling */}
          <QuotePriceEditable
            quotePrice={product.quotePrice ?? listPrice ?? ZERO_FLAT('USD')}
            updateQuotePrice={(newQuotePrice) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  const productToUpdate = draft.products.find(
                    (p) => p.id === product.id,
                  );
                  if (productToUpdate) {
                    productToUpdate.quotePrice =
                      (newQuotePrice as HamsterQuotePrice) ?? ZERO_FLAT('USD');
                  }
                }),
                false,
              );
            }}
            validPriceTypes={[CurrencyValueType.FLAT]}
            validTierMinimumTypes={['count']}
            quoteCurrency="USD"
            stickerPrice={null}
            cost={null}
            productName={productInfo.name}
            tierConfig={{
              showStickerPrice: false,
              showCost: false,
              showApprovalLevel: true,
              getApprovalBadge: (tierMin, price) => {
                return (
                  <ApprovalBadge
                    approvalLevel={_getApprovalLevelForPrice(
                      price as CurrencyValueFlat,
                      tierMin.value,
                      product,
                      productInfo,
                    )}
                  />
                );
              },
              countMinimumHeaderOverride: 'Quantity greater than',
              countMinimumSuffixOverride: '',
              additionalPrices: [
                {
                  label: 'List price',
                  badgeColor: 'purple',
                  value: (tierMin, _ignored) => {
                    return (
                      tierForValue(
                        productInfo.currentPricingCurve.pricingInformation
                          .listPrice?.tiers,
                        tierMin.value,
                      )?.currencyValue ?? null
                    );
                  },
                },
              ],
            }}
          />
        </td>
        {/* Approval level */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-green-700">
          <ApprovalBadge
            approvalLevel={_getApprovalLevelForProduct(product, productInfo)}
          />
        </td>
        {/* Est. Monthly revenue */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500">
          ${estMonthlyRevenue}
        </td>
      </tr>
      {/* Optionally, ramp up rows */}
      {showRampUp &&
        months.map((monthNumber) => {
          return (
            <Transition
              key={monthNumber}
              as="tr"
              className="overflow-hidden"
              show={showRampUp}
              enter="transition-all duration-300"
              enterFrom="max-h-0 opacity-0"
              enterTo="max-h-[48rem] opacity-100"
              leave="transition-all duration-300"
              leaveFrom="max-h-[48rem] opacity-100"
              leaveTo="max-h-0 opacity-0"
            >
              <ProductRampUpRow
                monthIdx={monthNumber - 1}
                monthNumber={monthNumber}
                product={product}
              />
            </Transition>
          );
        })}
    </>
  );
}

function ProductRampUpRow(props: {
  monthIdx: number;
  monthNumber: number;
  product: HamsterProduct;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const { monthNumber, monthIdx, product } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const incrementalVolumeAtMonth = product.rampedVolumeIncremental[monthIdx];
  const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
  const listPrice = getHamsterListPrice(product, productInfo);
  const estMonthlyRevenue = estimatedRevenue(
    product.volume + (incrementalVolumeAtMonth?.value ?? 0),
    product.quotePrice,
    listPrice,
  );

  return (
    <>
      <td className="h-full flex flex-row items-center gap-2 bg-gray-100 px-6 py-4 pr-24 text-sm text-gray-500">
        <ArrowDownRightIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
        Month {monthNumber}
      </td>
      {/* Quantity */}
      <td className="whitespace-nowrap bg-gray-100 px-6 text-sm text-gray-500">
        <EditableIndicator>
          <FormattedNumberField
            type="text"
            value={incrementalVolumeAtMonth?.value ?? 0}
            required={true}
            numberDecimals={0}
            className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
            data-volume-editable
            ref={inputRef}
            onKeyDown={getHandleKeyDownForEnterNextRowHandling(
              inputRef,
              'data-volume-editable',
            )}
            updateValue={(value: number) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  const productToUpdate = draft.products.find(
                    (p) => p.id === product.id,
                  );
                  if (productToUpdate) {
                    productToUpdate.rampedVolumeIncremental[monthIdx] = {
                      type: 'count',
                      value,
                    };
                  }
                }),
                false,
              );
            }}
            disabled={!editMode}
          />
        </EditableIndicator>
      </td>
      {/* List price */}
      <td className="bg-gray-100"></td>
      {/* Proposed price */}
      <td className="bg-gray-100"></td>
      {/* Approval level */}
      <td className="bg-gray-100"></td>
      {/* Est. Monthly revenue */}
      <td className="whitespace-nowrap bg-gray-100 px-6 py-4 text-sm font-medium text-gray-500">
        ${estMonthlyRevenue}
      </td>
    </>
  );
}

export default function HamsterQuoteTable() {
  const { pricingFlow, editMode } = usePricingFlowContext<HamsterPricingFlow>();
  const [showProductSelectionModal, setShowProductSelectionModal] =
    useState(false);
  return (
    <div className="my-2 w-full self-stretch">
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full min-w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <th
                scope="col"
                className="sticky top-0 z-10 w-full rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                Products
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                List Price
              </th>
              <th
                scope="col"
                className="has-tooltip sticky top-0 z-20 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Proposed Price
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Approval Level
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 hidden rounded-tr-xl  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
              >
                Est. Monthly Revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {pricingFlow.products.length > 0 ? (
              pricingFlow.products
                .sort((a, b) => {
                  return (
                    (pricingFlow.pricingSheetData.productInfo[a.id]
                      ?.displayOrder ?? 0) -
                    (pricingFlow.pricingSheetData.productInfo[b.id]
                      ?.displayOrder ?? 0)
                  );
                })
                .map((product) => {
                  return <QuoteTableRow product={product} />;
                })
            ) : (
              <QuoteTableBodyEmptyState colSpan={6} />
            )}
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="col"
                colSpan={5}
                className="bg-slate-50 px-6 py-3.5 text-left text-sm
                font-semibold text-gray-700 backdrop-blur backdrop-filter
                sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
              >
                <button
                  type="button"
                  className="mt-3 inline-flex items-center w-full justify-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-slate-900  ring-1 ring-inset ring-gray-200 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => {
                    setShowProductSelectionModal(true);
                  }}
                  disabled={!editMode}
                >
                  <PlusIcon
                    className="-ml-0.5 mr-1 h-4 w-4"
                    aria-hidden="true"
                  />
                  Add / Edit Products
                </button>
              </th>
              <th
                scope="col"
                colSpan={5}
                className="bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
              ></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <Transition appear show={showProductSelectionModal} as={Fragment}>
        <Dialog
          as="div"
          className="absolute z-50 items-center justify-center overflow-y-auto min-w-full"
          onClose={() => {
            setShowProductSelectionModal(false);
          }}
        >
          {/* This transitions the background to a dark shade */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto lg:px-20">
            <div className="min-h-full flex items-center justify-center px-8 py-4 text-center">
              <Transition.Child
                className="w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <HamsterProductSelectionModal
                  closeModal={() => {
                    setShowProductSelectionModal(false);
                  }}
                />
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
