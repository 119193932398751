import { ArrowPathIcon } from '@heroicons/react/20/solid';
import {
  ArrowTopRightOnSquareIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { produce, setAutoFreeze } from 'immer';
import { round } from 'lodash';
import { useState } from 'react';
import api from 'src/api';
import CollapsibleSection from 'src/components/CollapsibleSection';
import { InlineSpinner } from 'src/components/Loading';
import { classNames } from 'src/dashboard/App';
import { formatCurrency } from 'src/utils/formatters';
import { getOpportunitySFDCUrl } from '../Penguin/Components/OpportunityOverviewBar';
import { usePricingFlowContext } from '../PricingFlow';
import QuoteOptionsSection from '../QuoteOptionsSection';
import { HamsterOpportunityData, HamsterPricingFlow } from './hamster_types';

function getOpportunityOverviewFields(
  hamsterOpportunityData: HamsterOpportunityData,
): Array<{
  icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  name: string;
  value: React.ReactNode;
}> {
  return [
    {
      icon: UserIcon,
      name: 'Owner',
      value: hamsterOpportunityData.Owner ?? '–',
    },
    {
      icon: TagIcon,
      name: 'Segment',
      value: hamsterOpportunityData.Segment ?? '–',
    },
    {
      icon: GlobeAltIcon,
      name: 'Country',
      value: hamsterOpportunityData.Country ?? '–',
    },
    {
      icon: UserIcon,
      name: 'Number of lawyers',
      value: hamsterOpportunityData.Number_of_lawyers ?? '–',
    },

    {
      icon: CurrencyDollarIcon,
      name: 'Est. revenue',
      value: formatCurrency({
        amount: round(hamsterOpportunityData.Estimated_revenue ?? 0, 2),
        currency: 'USD', // Hardcoded to USD because that's what the SFDC reporting currency is
      }),
    },
  ];
}

export default function AlpacaOpportunitySidebar() {
  const { pricingFlow } = usePricingFlowContext<HamsterPricingFlow>();

  const fields = getOpportunityOverviewFields(
    pricingFlow.opportunity.opportunityData as HamsterOpportunityData,
  );

  return (
    <div className="z-40 w-72 border-r border-gray-200 bg-white text-xs font-medium h-full overflow-y-scroll">
      {/* header */}
      <div className="py-4 line-clamp-5 px-4 text-lg hover:line-clamp-none sticky top-0 bg-white border-b ">
        {pricingFlow.opportunity.sfdcOpportunityName}
      </div>
      {/* scrollable content */}
      <div className="flex-1">
        <CollapsibleSection title="DETAILS">
          <div className="flex flex-wrap items-center divide-y">
            {fields.map(
              (field: { icon: any; name: string; value: any }, i: number) => {
                return (
                  <div
                    key={i}
                    className="mx-2 flex w-full items-center gap-2 py-3 pr-4"
                  >
                    <div className="flex h-9 w-9 justify-center">
                      <field.icon
                        className="m-2 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="uppercase text-gray-400">
                        {field.name}
                      </div>
                      <div
                        className={classNames(
                          'opacity-100',
                          'text-md text-gray-600 transition-opacity duration-500 ease-in-out',
                        )}
                      >
                        {field.value}
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
          <div className="w-full flex justify-center my-6">
            <RefreshDataButton />
          </div>
        </CollapsibleSection>
        <QuoteOptionsSection
          currentPricingFlowId={pricingFlow.id}
          sfdcOpportunityId={pricingFlow.opportunity.sfdcOpportunityId}
          pricingFlows={pricingFlow.opportunity.pricingFlows}
        />
      </div>
      {/* footer */}
      <div className="sticky inset-x-0 bottom-0 flex h-16 flex-row items-center justify-center border-t bg-white">
        <a
          href={getOpportunitySFDCUrl(pricingFlow)}
          target="_blank"
          rel="noopener noreferrer"
          className="col-span-full inline-flex"
        >
          <button className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900">
            Open in SFDC
            <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </a>
      </div>
    </div>
  );
}

function RefreshDataButton() {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  async function refreshSalesforceData(): Promise<void> {
    setIsRefreshing(true);
    const newPricingFlow = await api.post(
      'refresh-salesforce-data/' + pricingFlow.id,
      {},
    );

    if (!editMode) {
      // This is a little annoying, but I imagine people are not going to do this very often
      // If you don't have edit access, I think it's okay for you to refresh the SFDC data, but
      // the page will refresh because I don't want anything else to update
      window.location.reload();
      return;
    }
    const opportunityData = newPricingFlow.data.opportunity
      .opportunityData as HamsterOpportunityData;
    setAutoFreeze(false);
    updateFlow(
      produce(pricingFlow, (draftPricingFlow) => {
        draftPricingFlow.opportunity.opportunityData = opportunityData;
      }),
      false,
    );
    setIsRefreshing(false);
  }
  const [isRefreshing, setIsRefreshing] = useState(false);
  return (
    <button
      type="button"
      className={classNames(
        'flex flex-row gap-1 items-center rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        isRefreshing ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={refreshSalesforceData}
      disabled={isRefreshing}
    >
      {isRefreshing ? (
        <div className="h-4 w-4 flex items-center">
          <InlineSpinner height={12} width={12} />
        </div>
      ) : (
        <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
      )}
      Refresh data
    </button>
  );
}
