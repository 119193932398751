import { Combobox, Popover, Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { ReactNode, useState } from 'react';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import { AlpacaTFxPairProduct, AlpacaTFxProductPrice } from '../alpaca_types';

type Option = {
  id: string;
  name: ReactNode;
  buyCurrency: AlpacaTFxProductPrice;
  sellCurrency: AlpacaTFxProductPrice;
};

const MAX_SHOW_OPTIONS = 40;

type AlpacaAddCurrencyPairButtonProps = {
  currencies: AlpacaTFxProductPrice[];
  onSelectCurrencyPair: (
    sellCurrency: AlpacaTFxProductPrice,
    buyCurrency: AlpacaTFxProductPrice,
  ) => void;
  products: AlpacaTFxPairProduct[];
};
export default function AlpacaAddCurrencyPairButton(
  props: AlpacaAddCurrencyPairButtonProps,
) {
  const { currencies, onSelectCurrencyPair, products } = props;
  const [query, setQuery] = useState('');
  const { editMode } = usePricingFlowContext();

  const options = currencies
    .flatMap((sellCurrency) => {
      return currencies.map((buyCurrency) => {
        if (sellCurrency.name !== buyCurrency.name) {
          return {
            id: `${sellCurrency.id}_${buyCurrency.id}`,
            name: (
              <span className="flex flex-row gap-2 items-center">
                {sellCurrency.name}
                <ArrowRightIcon className="h-4 w-4" />
                {buyCurrency.name}
              </span>
            ),
            buyCurrency,
            sellCurrency,
          };
        }
        return null;
      });
    })
    .filter(
      (option): option is Exclude<typeof option, undefined | null> =>
        !isNil(option),
    );

  const filteredOptions = options
    .filter((option) => {
      if (
        // already selected
        products.some((p) => {
          return (
            p.buyCurrencyId === option.buyCurrency.id &&
            p.sellCurrencyId === option.sellCurrency.id
          );
        })
      ) {
        return false;
      }
      return query
        .replace(/\s*[->]+\s*/g, ' ')
        .split(' ')
        .every((substring) => {
          const lsubstring = substring.toLowerCase();
          return (
            option.buyCurrency.name.toLowerCase().includes(lsubstring) ||
            option.sellCurrency.name.toLowerCase().includes(lsubstring)
          );
        });
    })
    .slice(0, MAX_SHOW_OPTIONS);

  return (
    <Popover className="relative">
      <Popover.Button
        className={classNames(
          'flex items-center block rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800',
          !editMode && 'cursor-not-allowed opacity-50',
        )}
        disabled={!editMode}
      >
        <PlusIcon className="h-4 w-4 mr-2" />
        <span>Add a currency pair</span>
      </Popover.Button>

      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 "
        enterTo="opacity-100 "
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 "
        leaveTo="opacity-0 "
        afterLeave={() => setQuery('')}
      >
        <Popover.Panel className="absolute bottom-full bg-white ring-1 ring-gray-900/5 drop-shadow-xl rounded-md z-30 ">
          <Combobox
            onChange={(option: Option) => {
              onSelectCurrencyPair(option.sellCurrency, option.buyCurrency);
            }}
          >
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                autoFocus
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder:text-gray-400 focus:ring-0 text-sm font-medium"
                placeholder="Search a pair..."
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setQuery(event.target.value)
                }
                displayValue={() => query}
              />
            </div>

            {query === '' && (
              <p className="p-4 text-sm text-gray-500 text-wrap w-48 text-left">
                Search to see results, e.g. "CAD GBP"
              </p>
            )}
            {query !== '' && filteredOptions.length > 0 && (
              <Combobox.Options
                static
                className="max-h-80 overflow-y-auto text-sm text-gray-800"
              >
                {filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'cursor-default select-none px-4 py-2 border-t text-sm text-gray-800 font-medium text-left',
                        active && 'bg-fuchsia-900 text-white',
                      )
                    }
                  >
                    {option.name}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}

            {query !== '' && filteredOptions.length === 0 && (
              <p className="p-4 text-sm text-gray-500">No options found</p>
            )}
          </Combobox>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
