import { LifebuoyIcon } from '@heroicons/react/24/outline';
import { useModal } from 'src/components/Modal';

export default function AlpacaHelpButton(props: {
  url?: string; // Provide a single URL if you want the button to just open this URL
  urls?: { name: string; url: string }[]; // Provide multiple URLs if you want the button to open a modal with a list of URLs
  // If you provide both we'll just use the single url :p
}) {
  const { url, urls } = props;
  const { showModal } = useModal();

  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="col-span-full inline-flex"
      >
        <div className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700">
          <LifebuoyIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
          Help with Pricing
        </div>
      </a>
    );
  } else if (urls) {
    return (
      <button
        type="button"
        onClick={() => {
          showModal({
            title: 'Help links',
            children: (
              <div className="flex flex-col gap-2 mt-4">
                {urls.map((u) => (
                  <a
                    key={u.name}
                    href={u.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                  >
                    {u.name}
                  </a>
                ))}
              </div>
            ),
          });
        }}
        className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
      >
        <LifebuoyIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
        Help with Pricing
      </button>
    );
  } else return <></>;
}
