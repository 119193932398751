import { classNames } from 'src/dashboard/App';

export function Legend({
  mappings,
  className,
}: {
  mappings: {
    color: string;
    label: string;
  }[];
  className?: string;
}) {
  return (
    <div className={classNames('flex gap-x-5', className)}>
      {' '}
      {mappings.map(({ color, label }) => {
        return (
          <div
            className="flex items-center gap-x-2 whitespace-nowrap text-slate-600 text-xs"
            key={`${color}-${label}`}
          >
            <div
              className="h-[.7rem] w-[.7rem] rounded-full"
              style={{ background: color }}
            ></div>
            <span>{label}</span>
          </div>
        );
      })}
    </div>
  );
}
