import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  ArrowDownCircleIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DocumentChartBarIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { DistributionBar } from 'src/components/graphs/DistributionComparison';
import { GaugeChart } from 'src/components/graphs/GaugeChart';
import GraphContainer, {
  RenderChildWithSize,
} from 'src/components/graphs/GraphContainer';
import LinePlot from 'src/components/graphs/LinePlot';

const Heading = (): JSX.Element => (
  // TODO(seb): Reconcile with Fay's Heading component in Pricing Curves
  <div className="lg:flex lg:items-center lg:justify-between">
    <div className="min-w-0 flex-1">
      <h2 className="text-2xl font-medium leading-7 text-gray-900 sm:truncate sm:text-3xl">
        Analytics Dashboard
      </h2>
      <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 text-gray-500">
        This dashboard only includes Dealops generated quotes
      </div>
    </div>
    <div className="mt-5 flex lg:ml-4 lg:mt-0">
      <span className="hidden sm:block">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <CalendarIcon
            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Last 7 Days
          <ChevronDownIcon
            className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </span>

      <span className="ml-3 hidden sm:block">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <ArrowDownCircleIcon
            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Export
        </button>
      </span>
    </div>
  </div>
);

const TableData = [
  {
    product: 'ACH - Standard',
    avgQuotePrice: 1240,
    suggestedPrice: 1240,
    deltaWithSuggested: '19%',
    l1Price: 890,
    deltaWithL1: '8%',
  },
  {
    product: 'Recurring Transactions',
    avgQuotePrice: 1240,
    suggestedPrice: 1240,
    deltaWithSuggested: '17%',
    l1Price: 890,
    deltaWithL1: '7%',
  },
  {
    product: 'Income - Document Parsing (bank statements)',
    avgQuotePrice: 1240,
    suggestedPrice: 1240,
    deltaWithSuggested: '16%',
    l1Price: 890,
    deltaWithL1: '6%',
  },
  {
    product: 'CRA Income Insights',
    avgQuotePrice: 1240,
    suggestedPrice: 1240,
    deltaWithSuggested: '12%',
    l1Price: 890,
    deltaWithL1: '2%',
  },
];

const TableRows = () => {
  return (
    <tbody className="divide-y divide-gray-200">
      {TableData.map((item, index) => (
        <tr key={index}>
          <th className="px-6 py-4 font-medium text-gray-900">
            {item.product}
          </th>
          <td className="px-6 py-4">${item.avgQuotePrice}</td>
          <td className="px-6 py-4">
            <PillBadge color={PillColor.Green}>
              ${item.suggestedPrice}
            </PillBadge>
          </td>
          <td className="px-6 py-4">
            <PillBadge color={PillColor.Red}>
              {item.deltaWithSuggested}
            </PillBadge>
          </td>
          <td className="px-6 py-4">
            <PillBadge color={PillColor.Orange}>${item.l1Price}</PillBadge>
          </td>

          <td className="px-6 py-4">
            <PillBadge color={PillColor.Red}>{item.deltaWithL1}</PillBadge>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

enum PillColor {
  Blue = 'bg-blue-100 text-blue-800',
  Gray = 'bg-gray-100 text-gray-800',
  Red = 'bg-red-100 text-red-800',
  Green = 'bg-green-100 text-green-800',
  Yellow = 'bg-yellow-100 text-yellow-800',
  Orange = 'bg-orange-100 text-orange-800',
  Indigo = 'bg-indigo-100 text-indigo-800',
  Purple = 'bg-purple-100 text-purple-800',
  Pink = 'bg-pink-100 text-pink-800',
}

const PillBadge: React.FC<{ color: PillColor; children: React.ReactNode }> = ({
  color,
  children,
}) => (
  <span
    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}
  >
    {children}
  </span>
);

const ApprovalLevelWidget = () => {
  const location = useLocation();
  const approvalsUrlPath = `${location.pathname}/approvals`;
  return (
    <GraphContainer
      className="h-full"
      header={
        <div className="flex w-full items-center justify-between text-gray-900">
          <div className="flex items-center">
            <div className="mr-1 w-6 stroke-gray-600">
              <UserPlusIcon className="text-gray-800" />
            </div>
            <div className="font-semibold">Approval Level</div>
          </div>
          <Link
            to={approvalsUrlPath}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Full Reports
          </Link>
        </div>
      }
    >
      <GaugeChart
        data={[
          {
            label: 'Manager',
            value: 11,
            color: '#73E2A3',
            textColor: '#099250',
          },
          {
            label: 'Head',
            value: 38,
            color: '#8098F9',
            textColor: '#444CE7',
          },
          {
            label: 'Deal desk',
            value: 17,
            color: '#FF9C66',
            textColor: '#E62E05',
          },
          {
            label: 'Finance',
            value: 25,
            color: '#FDA29B',
            textColor: '#B42318',
          },
        ]}
        title={
          <div className="flex flex-col items-center">
            <div className="text-xl text-gray-500">AVERAGE</div>
            <div className="text-center text-3xl">
              <p>Deal </p>Desk
            </div>
            <div></div>
          </div>
        }
      />
    </GraphContainer>
  );
};

const MonthlyMinimumWidget = () => {
  const location = useLocation();
  const monthlyMinimumsUrl = `${location.pathname}/monthly-minimums`;
  return (
    <GraphContainer
      className="h-full w-full"
      header={
        <div className="flex w-full items-center justify-between text-gray-900">
          <div className="flex items-center">
            <div className="mr-1 w-6 stroke-gray-600">
              <CurrencyDollarIcon className="text-gray-800" />
            </div>
            <div className="font-semibold">
              Monthly minimum as % of usage based revenue
            </div>
          </div>
          <Link
            to={monthlyMinimumsUrl}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Full Report
          </Link>
        </div>
      }
    >
      <div className="flex h-full w-full flex-col">
        <RenderChildWithSize
          className="h-full w-full overflow-hidden"
          childFunction={(width, height) => {
            return (
              <LinePlot
                width={width}
                height={height}
                start={new Date('2007-04-22').getTime()}
                end={new Date('2007-05-15').getTime()}
                lines={[
                  {
                    color: '#981781',
                    points: [
                      {
                        xLocation: new Date('2007-04-23').getTime(),
                        value: 0.55,
                        info: { user: 'tyler' },
                      },
                      {
                        xLocation: new Date('2007-04-25').getTime(),
                        value: 0.55,
                        info: { user: 'taylor' },
                      },
                      {
                        xLocation: new Date('2007-04-26').getTime(),
                        value: 0.75,
                        info: { user: 'sage' },
                      },
                      {
                        xLocation: new Date('2007-04-29').getTime(),
                        value: 0.75,
                        info: { user: 'taylor' },
                      },
                      {
                        xLocation: new Date('2007-05-01').getTime(),
                        value: 0.35,
                        info: { user: 'tyler' },
                      },
                      {
                        xLocation: new Date('2007-05-03').getTime(),
                        value: 0.32,
                        info: { user: 'sage' },
                      },
                      {
                        xLocation: new Date('2007-05-04').getTime(),
                        value: 0.32,
                        info: { user: 'tyler' },
                      },
                      {
                        xLocation: new Date('2007-05-08').getTime(),
                        value: 0.25,
                        info: { user: 'taylor' },
                      },
                      {
                        xLocation: new Date('2007-05-02').getTime(),
                        value: 0.35,
                        info: { user: 'sage' },
                      },
                      {
                        xLocation: new Date('2007-05-09').getTime(),
                        value: 0.25,
                        info: { user: 'taylor' },
                      },
                      {
                        xLocation: new Date('2007-05-10').getTime(),
                        value: 0.4,
                        info: { user: 'tyler' },
                      },
                      {
                        xLocation: new Date('2007-05-11').getTime(),
                        value: 0.4,
                        info: { user: 'sage' },
                      },
                      {
                        xLocation: new Date('2007-05-12').getTime(),
                        value: 0.57,
                        info: { user: 'tyler' },
                      },
                      {
                        xLocation: new Date('2007-05-13').getTime(),
                        value: 0.57,
                        info: { user: 'sage' },
                      },
                      {
                        xLocation: new Date('2007-05-14').getTime(),
                        value: 0.45,
                        info: { user: 'tyler' },
                      },
                      {
                        xLocation: new Date('2007-05-15').getTime(),
                        value: 0.45,
                        info: { user: 'taylor' },
                      },
                    ],
                    info: {
                      total: 1000,
                    },
                  },
                ]}
                formatYAxis={(y) => `${(y * 100).toFixed(0)}%`}
                formatXAxis={(x: number) => {
                  const date = new Date(x);
                  const month = date.getMonth() + 1;
                  const day = date.getDate();
                  return `${day}/${month}`;
                }}
                formatTooltip={(linesAndPoints) => {
                  return (
                    <div>
                      {linesAndPoints.map(({ line, point }) => {
                        return (
                          <div>
                            <p style={{ color: line.color }}>
                              {point.value} {point.info.user}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            );
          }}
        />
      </div>
    </GraphContainer>
  );
};

const PriceDifferentialTable = (): JSX.Element => {
  return (
    <GraphContainer
      header={
        <div className="flex w-full items-center justify-between text-gray-900">
          <div className="flex items-center">
            <div className="mr-1 w-6 stroke-gray-600">
              <CurrencyDollarIcon className="text-gray-800" />
            </div>
            <div className="font-semibold">Price Differential Analysis</div>
          </div>
          <Link
            to={`/app/analytics/differentials`}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Full Report
          </Link>
        </div>
      }
    >
      <table className="w-full rounded-lg border-collapse bg-white text-left text-sm text-gray-500">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900 rounded-tl-lg w-2/5"
            >
              Product
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Avg Quote Price
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Suggested Price
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Delta w/ Suggested
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              L1 Price
            </th>
            <th scope="col" className="px-6 py-4 font-medium text-gray-900">
              Delta w/ L1
            </th>
          </tr>
        </thead>
        <TableRows />
      </table>
    </GraphContainer>
  );
};

const Body = (): JSX.Element => (
  <div className="flex flex-col">
    <div className="grid grid-flow-row grid-cols-4 space-x-4 py-4">
      <div className="h-[400px] space-y-4">
        <GraphContainer
          className="max-h-[192px] h-full"
          header={
            <div className="flex w-full items-center text-gray-900">
              <div className="mr-1 w-6 stroke-gray-600">
                <ClockIcon className="text-gray-800" />
              </div>
              <div className="font-semibold text-gray-900">
                Quote Creation Time
              </div>
            </div>
          }
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-baseline space-x-2">
                <span className="text-lg font-semibold">4 minutes</span>
              </div>
              <div className="text-sm text-gray-500">
                AVG quote creation time
              </div>
            </div>
            <div>
              <span
                className="text-3xl p-2 rounded-full bg-orange-100 flex items-center justify-center"
                style={{ width: '3.5rem', height: '3.5rem' }}
              >
                😎
              </span>
            </div>
          </div>
          <div className="mt-4 flex items-center space-x-4">
            <div className="flex -space-x-2">
              <img
                className="w-8 h-8 rounded-full border-2 border-white"
                src="https://randomuser.me/api/portraits/men/1.jpg"
                alt="avatar1"
              />
              <img
                className="w-8 h-8 rounded-full border-2 border-white"
                src="https://randomuser.me/api/portraits/women/2.jpg"
                alt="avatar2"
              />
              <img
                className="w-8 h-8 rounded-full border-2 border-white"
                src="https://randomuser.me/api/portraits/men/3.jpg"
                alt="avatar3"
              />
            </div>
            <div className="flex items-center space-x-1">
              <span className="text-gray-700 text-sm">87</span>
              <span className="text-gray-500 text-sm">reps</span>
            </div>
            <div className="flex items-center space-x-1">
              <DocumentChartBarIcon className="w-5 h-5 text-gray-700" />
              <span className="text-gray-700 text-sm">168</span>
              <span className="text-gray-500 text-sm">quotes</span>
            </div>
          </div>
        </GraphContainer>
        <GraphContainer
          className="max-h-[192px] h-full"
          header={
            <div className="flex items-center justify-between">
              <div className="flex w-full items-center text-gray-900">
                <div className="mr-1 w-6 stroke-gray-600">
                  <DocumentChartBarIcon className="text-gray-800" />
                </div>
                <div className="font-semibold text-gray-900">
                  Quote creation distribution
                </div>
              </div>
              <div className="whitespace-nowrap text-sm text-gray-700">
                2/15-3/15
              </div>
            </div>
          }
        >
          <div className="mb-4 flex w-full items-center justify-between">
            <div className="text-lg font-normal text-gray-500">
              <span className="font-bold text-black">38</span> in Dealops{' '}
              <span className="font-bold text-black">23</span> in the CPQ
            </div>
          </div>
          <DistributionBar
            bars={[
              { label: 'Dealops', value: 10, color: '#981781' },
              { label: 'CPQ', value: 20, color: '#35B9E9' },
            ]}
            formatTooltip={(bar) => {
              return (
                <div>
                  <p>{bar.label}</p>
                  <p>{bar.value}</p>
                </div>
              );
            }}
          />
        </GraphContainer>
      </div>
      <div className="h-[400px] col-span-2">
        <MonthlyMinimumWidget />
      </div>
      <div className="h-[400px]">
        <ApprovalLevelWidget />
      </div>
    </div>
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <PriceDifferentialTable />
    </div>
  </div>
);

const Home = () => {
  return (
    <div className="mx-auto sm:p-6 lg:p-8">
      <Heading />
      <Body />
    </div>
  );
};

export default Home;
