import { Popover, Transition } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  AlpacaPricingFlow,
  AlpacaTFxBucketProduct,
  isAlpacaTFXProduct,
  isAlpacaTFXProductPrice,
} from '../alpaca_types';

type AlpacaAddCurrencyToBucketButtonProps = {
  product: AlpacaTFxBucketProduct;
};
export default function AlpacaAddCurrencyToBucketButton(
  props: AlpacaAddCurrencyToBucketButtonProps,
) {
  const { product } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const products = pricingFlow.products ?? [];

  const productInfos =
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo;
  const setIncludedCurrencies = (newIds: string[]) => {
    newIds.sort();
    const oldProduct = products.find((p) => p.id === product.id);
    if (
      !isNil(oldProduct) &&
      isAlpacaTFXProduct(oldProduct) &&
      oldProduct.subCategory === 'bucket'
    ) {
      oldProduct.currencyIds = newIds;
    }
    updateFlow(
      {
        ...pricingFlow,
      },
      false,
    );
  };
  const existingSingleBuyCurrencyIds = products
    .filter((p) => isAlpacaTFXProduct(p) && p.subCategory === 'single_buy')
    .map((c) => c.id);

  const allCurrencies = Object.values(productInfos)
    .filter((currency) => {
      return (
        isAlpacaTFXProductPrice(currency) &&
        currency.skuSubgroup === product.bucket
      );
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  const includedCurrencyIds = product.currencyIds;
  const [selectedCurrencyIds, setSelectedCurrencyIds] =
    useState<string[]>(includedCurrencyIds);

  useEffect(() => {
    setSelectedCurrencyIds(includedCurrencyIds);
  }, [includedCurrencyIds]);

  return (
    <Popover className="relative">
      <Popover.Button
        className={classNames(
          'relative inline-flex items-center rounded-md bg-white px-2 py-1 text-xs text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 gap-1',
          !editMode && 'cursor-not-allowed opacity-50',
        )}
        disabled={!editMode}
      >
        <PencilIcon className="h-3 w-3 text-gray-500" />
        Edit
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setIncludedCurrencies(selectedCurrencyIds)}
      >
        <Popover.Panel className="absolute left-full z-30">
          <div className="shrink rounded-lg bg-white text-sm pr-16 py-2 font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5 justify-start flex flex-col max-h-[400px] overflow-scroll">
            {allCurrencies.map((c) => (
              <fieldset key={c.name}>
                <div className="flex flex-row gap-3 items-center px-4 py-2">
                  <div className="flex items-center">
                    <input
                      id={c.name}
                      name={c.name}
                      type="checkbox"
                      className={classNames(
                        'h-4 w-4 rounded border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900',
                        existingSingleBuyCurrencyIds.includes(c.id)
                          ? 'bg-gray-100'
                          : '',
                      )}
                      checked={selectedCurrencyIds.includes(c.id)}
                      // #TfxNoSingleBuyCurrenciesInBuckets
                      disabled={existingSingleBuyCurrencyIds.includes(c.id)}
                      onChange={() => {
                        if (selectedCurrencyIds.includes(c.id)) {
                          setSelectedCurrencyIds(
                            selectedCurrencyIds.filter((id) => id !== c.id),
                          );
                        } else {
                          setSelectedCurrencyIds([
                            ...selectedCurrencyIds,
                            c.id,
                          ]);
                        }
                      }}
                    />
                  </div>
                  <div className="text-sm">
                    <label
                      htmlFor={c.name}
                      className="font-medium text-gray-900"
                    >
                      {c.name}
                    </label>
                  </div>
                </div>
              </fieldset>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
