import { datadogRum } from '@datadog/browser-rum';
import { DiscoveredOrganization } from '@stytch/vanilla-js';
import { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import Loading from 'src/components/Loading';
import { unreachable } from 'src/typeUtils';
import utils from 'src/utils';
import dealopsLogo from '../images/logos/dealops.svg';
import { LAST_LOCATION_LOCAL_STORAGE_KEY } from './constants';

/**
 * This page is responsible for converting some kind of token into a valid
 * stytch_session cookie, which is used to verify users' identities throughout
 * the rest of the app.
 *
 * If signing in with SSO, the token can be directly converted into a
 * stytch_session, and the user is redirected to the /app homepage without
 * interacting with anything on this page.
 *
 * If signing in with OAuth, the token is first exchanged for an intermediate
 * session token (IST), which provides a list of discovered organizations the
 * user can potentially access. Once they select an org, the IST is exchanged
 * for a real stytch_session token
 */

type OrganizationListProps = {
  organizations: DiscoveredOrganization[];
  handleOrgSelection: (org: DiscoveredOrganization) => void;
};

const OrganizationList = ({
  organizations,
  handleOrgSelection,
}: OrganizationListProps) => {
  return (
    <div className="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="my-10 flex items-center gap-3 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          <img className="h-10 w-auto" src={dealopsLogo} alt="Dealops" />
          Organizations
        </h2>
        {organizations.length === 0 ? (
          <div className="text-s mt-5 text-center leading-9 tracking-tight text-gray-900">
            No organizations found
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {organizations.map((org) => (
              <button
                key={org.organization.organization_id}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-fuchsia-800 focus-within:ring-offset-2 hover:border-gray-400"
                onClick={async () => await handleOrgSelection(org)}
              >
                <div className="min-w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    {org.organization.organization_name}
                  </p>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const Authenticate = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState<
    DiscoveredOrganization[] | null
  >(null);
  const navigateToLastLocationOrHome = () => {
    const lastLocation = localStorage.getItem(LAST_LOCATION_LOCAL_STORAGE_KEY);
    localStorage.removeItem(LAST_LOCATION_LOCAL_STORAGE_KEY);
    navigate(lastLocation ?? '/app');
  };

  const handleOrgSelection = async (org: DiscoveredOrganization) => {
    try {
      await api.post('exchange_intermediate_session', {
        orgId: org.organization.organization_id,
      });
      navigateToLastLocationOrHome();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        return navigate('/login');
      }
      datadogRum.addError(error);
    }
  };

  useEffect(() => {
    const doEffect = async () => {
      const resSession = await api.get('is_existing_session_valid');
      if (resSession.data.isSessionValid) {
        return navigateToLastLocationOrHome();
      } else if (isNil(organizations)) {
        try {
          const stytch_token_type = utils.retrieveParamFromURL(
            'stytch_token_type',
          ) as 'discovery_oauth' | 'sso';
          switch (stytch_token_type) {
            case 'sso': {
              const pkceCodeVerifierKey = `stytch_sdk_state_${process.env.REACT_APP_STYTCH_PUBLIC_KEY}::PKCE_VERIFIER:sso`;
              const pkce_code_verifier = JSON.parse(
                localStorage.getItem(pkceCodeVerifierKey) ?? '{}',
              ).code_verifier;

              const resAuthenticate = await api.get('authenticate', {
                token: utils.retrieveParamFromURL('token'),
                stytch_token_type,
                pkce_code_verifier,
              });
              if (resAuthenticate.status === 200) navigate('/app');
              return;
            }
            case 'discovery_oauth': {
              const pkceCodeVerifierKey = `stytch_sdk_state_${process.env.REACT_APP_STYTCH_PUBLIC_KEY}::PKCE_VERIFIER:oauth`;
              const pkce_code_verifier = JSON.parse(
                localStorage.getItem(pkceCodeVerifierKey) ?? '{}',
              ).code_verifier;

              const resAuthenticate = await api.get('authenticate', {
                token: utils.retrieveParamFromURL('token'),
                stytch_token_type,
                pkce_code_verifier,
              });
              const discoveredOrgs: DiscoveredOrganization[] =
                resAuthenticate.data.discoveredOrgs;
              const authenticatedOrganizations = discoveredOrgs.filter(
                (org) => org.member_authenticated,
              );

              if (authenticatedOrganizations.length === 1) {
                return await handleOrgSelection(authenticatedOrganizations[0]);
              } else {
                return setOrganizations(
                  authenticatedOrganizations.sort((a, b) =>
                    a.organization.organization_name.localeCompare(
                      b.organization.organization_name,
                    ),
                  ),
                );
              }
            }
            default:
              unreachable(stytch_token_type);
          }
        } catch (error) {
          // Note: on localhost, you'll always hit an error here while logging
          // in. This is because React runs this hook twice, but the token can
          // only be used by the 'authenticate' endpoint once.
          datadogRum.addError(error);
        }
      }
    };
    doEffect();
  }, [navigate]);

  if (organizations) {
    return (
      <OrganizationList
        organizations={organizations}
        handleOrgSelection={handleOrgSelection}
      />
    );
  } else {
    return <Loading />;
  }
};
