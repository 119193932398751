import { TrashIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import CollapsibleCard from 'src/components/CollapsibleCard';
import { TextField } from 'src/components/Fields';
import Loading from 'src/components/Loading';
import MultiLevelDropdown, { Option } from 'src/components/MultiLevelDropdown';
import list from 'src/list';
import {
  AndComplexCondition,
  Condition,
  ConditionValueA,
  FunctionCall,
  OrComplexCondition,
  Rule,
} from 'src/types';

const DEFAULT_CONDITION: Condition = {
  kind: 'Condition',
  operator: null,
  valueA: null,
  valueB: null,
};

const DEFAULT_OR_COMPLEX_CONDITION: OrComplexCondition = {
  kind: 'ComplexCondition',
  operator: 'or',
  conditions: [DEFAULT_CONDITION],
};

const DEFAULT_RULE: Rule = {
  id: 'default-rule',
  organizationId: 'org1',
  groupName: 'group1',
  userId: 'user1',
  createdAt: new Date(),
  updatedAt: new Date(),
  name: '',
  description: '',
  ruleJson: {
    kind: 'Rule',
    condition: {
      kind: 'ComplexCondition',
      operator: 'and',
      conditions: [DEFAULT_OR_COMPLEX_CONDITION],
    },
    actions: [
      {
        kind: 'Action',
        func: {
          name: 'incrementCounter',
          arguments: [],
        },
      },
    ],
    state: 'active',
    trigger: {
      kind: 'OpportunityTrigger',
      event: 'OpportunityEvent.CREATED',
    },
  },
};

function Badge(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <span className="inline-flex items-center rounded-full bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
      {children}
    </span>
  );
}

export default function RuleBuilder() {
  const [rule, setRule] = useState<Rule>(DEFAULT_RULE);

  return (
    <div className="flex flex-col max-w-screen-2xl">
      <div className="flex flex-row gap-x-4 px-4 sm:px-6 lg:px-8 pt-6 text-sm">
        <div className="flex flex-col gap-y-2 w-[25%]">
          <TextField label={'Name'} name="name" type="text" />
        </div>

        <div className="flex flex-col gap-y-2 w-[75%]">
          <TextField label={'Description'} name="Description" type="text" />
        </div>
      </div>

      <div className="flex w-full">
        <div className="w-[60%] px-4 pt-6 sm:px-6 lg:px-8">
          <AndComplexConditionBuilder
            andComplexCondition={rule.ruleJson.condition}
            setAndComplexCondition={(andComplexCondition) =>
              setRule({
                ...rule,
                ruleJson: { ...rule.ruleJson, condition: andComplexCondition },
              })
            }
            showValidation={false}
          />
        </div>

        <div className="w-[40%] pr-4 pt-6 sm:pr-6 lg:pr-8">
          <Output />
        </div>
      </div>
    </div>
  );
}

function Output() {
  return (
    <CollapsibleCard title="Output">
      <div className="flex flex-col gap-y-4 text-sm pb-3">
        <div className="flex flex-col gap-y-2">
          Method
          <MultiLevelDropdown
            width={'w-full'}
            inputName={'Method'}
            options={[
              {
                name: 'Add a discount on top of an existing curve',
                subOptions: [],
              },
              { name: 'Replace an existing curve', subOptions: [] },
              { name: 'Create a new curve', subOptions: [] },
            ]}
          />
        </div>

        <div className="flex flex-col gap-y-2">
          Curve
          <MultiLevelDropdown
            width={'w-full'}
            inputName={'Curve'}
            options={[
              { name: 'Base curve', subOptions: [] },
              { name: 'Custom curve', subOptions: [] },
              { name: 'Custom curve with base curve', subOptions: [] },
            ]}
          />
        </div>

        <TextField label={'Discount'} name="discount" type="text" />
      </div>
    </CollapsibleCard>
  );
}

/**
 *
 * AndComplexCondition Builder
 */
function AndComplexConditionBuilder(props: {
  andComplexCondition: AndComplexCondition;
  setAndComplexCondition: (andComplexCondition: AndComplexCondition) => void;
  showValidation: boolean;
}) {
  const { andComplexCondition, setAndComplexCondition, showValidation } = props;

  const orComplexConditions = andComplexCondition.conditions;

  function setOrComplexCondition(
    orComplexCondition: OrComplexCondition,
    idx: number,
  ) {
    orComplexConditions[idx] = orComplexCondition;
    setAndComplexCondition({
      ...andComplexCondition,
      conditions: orComplexConditions,
    });
  }

  function handleAddOrComplexCondition() {
    setAndComplexCondition({
      ...andComplexCondition,
      conditions: [
        ...andComplexCondition.conditions,
        DEFAULT_OR_COMPLEX_CONDITION,
      ],
    });
  }

  function handleDeleteOrComplexCondition(idx: number) {
    return () => {
      setAndComplexCondition({
        ...andComplexCondition,
        conditions: [
          ...orComplexConditions.slice(0, idx),
          ...orComplexConditions.slice(idx + 1),
        ],
      });
    };
  }

  return (
    <CollapsibleCard title="Conditions">
      <div className="py-2">
        {orComplexConditions.map(
          (orComplexCondition: OrComplexCondition, idx: number) => {
            return (
              <Fragment key={idx}>
                {/* AND badge if it's not the first one */}
                {idx !== 0 && (
                  <div className="flex gap-x-3">
                    <div className="flex flex-col items-start relative">
                      <Badge>AND</Badge>
                      <div className="absolute left-2 top-0 bottom-0 bg-purple-100 w-px"></div>
                    </div>
                    <div className="h-14"></div>
                  </div>
                )}

                <div className="flex gap-x-3" key={idx}>
                  <div className="flex flex-col items-start relative">
                    <Badge>If</Badge>
                    <div className="absolute left-2 top-0 bottom-0 bg-purple-100 w-px"></div>
                  </div>
                  <OrComplexConditionBuilder
                    orComplexCondition={orComplexCondition}
                    setOrComplexCondition={(orComplexCondition) =>
                      setOrComplexCondition(orComplexCondition, idx)
                    }
                    handleDeleteOrComplexCondition={handleDeleteOrComplexCondition(
                      idx,
                    )}
                    showValidation={showValidation}
                  />
                </div>
              </Fragment>
            );
          },
        )}

        <button
          type="button"
          className="inline-flex mr-3 items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={handleAddOrComplexCondition}
        >
          Add condition
        </button>
      </div>
    </CollapsibleCard>
  );
}

function OrComplexConditionBuilder(props: {
  orComplexCondition: OrComplexCondition;
  setOrComplexCondition: (orComplexCondition: OrComplexCondition) => void;
  handleDeleteOrComplexCondition: () => void;
  showValidation: boolean;
}) {
  const {
    orComplexCondition,
    setOrComplexCondition,
    handleDeleteOrComplexCondition,
    showValidation,
  } = props;
  const conditions = orComplexCondition.conditions.map((condition, idx) => ({
    ...condition,
    localId: idx,
  }));
  const [valueAOptionsRaw, setValueAOptionsRaw] = useState<ConditionValueA[]>(
    [],
  );

  async function getValueAOptions() {
    setValueAOptionsRaw([
      {
        fieldName: 'Name',
        fieldType: 'string',
        functionCall: { name: 'Name', arguments: [] },
        objectName: 'Opportunity',
        possibleValues: ['Braintree', 'Stripe', 'Adyen'],
      },
      {
        fieldName: 'Segment',
        fieldType: 'picklist',
        functionCall: { name: 'Segment', arguments: [] },
        objectName: 'Opportunity',
        possibleValues: ['SMB', 'Mid-market', 'Enterprise'],
      },
      {
        fieldName: 'Competitor',
        fieldType: 'picklist',
        functionCall: { name: 'Competitor', arguments: [] },
        objectName: 'Opportunity',
        possibleValues: ['Braintree', 'Stripe', 'Adyen'],
      },
      {
        fieldName: 'Industry',
        fieldType: 'picklist',
        functionCall: { name: 'Industry', arguments: [] },
        objectName: 'Opportunity',
        possibleValues: ['Payments', 'Identity', 'Fraud'],
      },
      {
        fieldName: 'Geography',
        fieldType: 'picklist',
        functionCall: { name: 'Geography', arguments: [] },
        objectName: 'Opportunity',
        possibleValues: ['US/CA', 'EMEA', 'APAC', 'LATAM'],
      },
    ]);
  }

  useEffect(() => {
    if (valueAOptionsRaw.length === 0) {
      getValueAOptions();
    }
  }, [valueAOptionsRaw]);

  function handleDeleteCondition(localIdToDelete: number) {
    return () => {
      // Filter out the condition with the specified localId
      const newConditions = conditions.filter(
        (condition) => condition.localId !== localIdToDelete,
      );

      if (newConditions.length === 0) {
        // If this is the last one, delete the whole OrComplexCondition
        handleDeleteOrComplexCondition();
      } else {
        setOrComplexCondition({
          ...orComplexCondition,
          conditions: newConditions,
        });
      }
    };
  }

  function handleAddCondition() {
    console.log('handleAddCondition');
    console.log({
      ...orComplexCondition,
      conditions: [...orComplexCondition.conditions, DEFAULT_CONDITION],
    });
    setOrComplexCondition({
      ...orComplexCondition,
      conditions: [...orComplexCondition.conditions, DEFAULT_CONDITION],
    });
  }

  return (
    <OutlinedSection>
      {conditions.map((condition: ConditionWithLocalId, idx: number) => {
        return (
          <Fragment key={condition.localId}>
            {valueAOptionsRaw.length > 0 ? (
              <ConditionBuilder
                condition={condition}
                setCondition={(condition: ConditionWithLocalId) =>
                  setOrComplexCondition({
                    ...orComplexCondition,
                    conditions: [
                      ...conditions.slice(0, idx),
                      condition,
                      ...conditions.slice(idx + 1),
                    ],
                  })
                }
                handleDeleteCondition={handleDeleteCondition(condition.localId)}
                valueAOptionsRaw={valueAOptionsRaw}
                showValidation={showValidation}
              />
            ) : (
              <Loading />
            )}

            <OrDivider />
          </Fragment>
        );
      })}

      <div className="flex items-center justify-center space-x-4 py-4">
        <button
          type="button"
          className="text-fuchsia-900 hover:text-fuchsia-950"
          onClick={handleAddCondition}
        >
          + Add condition
        </button>
      </div>
    </OutlinedSection>
  );
}

type ConditionWithLocalId = Condition & { localId: number };

function transformConditionValueAToMultileveloptions(
  conditionValueA: ConditionValueA[],
): Option[] {
  const result: Option[] = [];
  // 1. Get all the unique first level names
  // 2. For each unique name, get all the unique second level names

  conditionValueA.forEach((conditionValueA) => {
    const firstLevelName = conditionValueA.objectName;
    const secondLevelName = conditionValueA.fieldName;

    const firstLevelOption = result.find(
      (option) => option.name === firstLevelName,
    );
    if (!firstLevelOption) {
      result.push({ name: firstLevelName, subOptions: [] });
    }

    const firstLevelOptionIndex = list.findIndexOrNull(
      result,
      (option: { name: string }) => option.name === firstLevelName,
    );
    if (firstLevelOptionIndex === null) {
      // I'm not sure if this is the right thing to do
      throw new Error(
        'Something went wrong: Could not find firstLevelOptionIndex',
      );
    }

    const secondLevelOption = result[firstLevelOptionIndex].subOptions.find(
      (option) => option.name === secondLevelName,
    );
    if (!secondLevelOption) {
      result[firstLevelOptionIndex].subOptions.push({
        name: secondLevelName,
        subOptions: [],
        ...conditionValueA,
      });
    }
  });

  return result;
}

// @TODO(fay) add validation for no nulls
// @TODO(fay) should we have a save as draft???
function ConditionBuilder(props: {
  condition: ConditionWithLocalId;
  setCondition: (condition: ConditionWithLocalId) => void;
  handleDeleteCondition: () => void;
  valueAOptionsRaw: ConditionValueA[];
  showValidation: boolean;
}) {
  const {
    condition,
    setCondition,
    handleDeleteCondition,
    valueAOptionsRaw,
    showValidation,
  } = props;

  const valueAOptions =
    transformConditionValueAToMultileveloptions(valueAOptionsRaw);
  const operatorOptions = condition.valueA
    ? [
        { name: '=', subOptions: [] },
        { name: '!=', subOptions: [] },
      ]
    : []; // @TODO(jacob) make this dynamic
  const valueBType = condition.valueA
    ? valueAOptionsRaw.find(
        (conditionValueA) =>
          conditionValueA.functionCall.name === condition.valueA?.name,
      )?.fieldType
    : undefined;
  const valueBOptions = condition.valueA
    ? getValueBOptionsForFuncCall(condition.valueA)
    : [];
  console.log('valueBType: ', valueBType);
  console.log('valueBOptions: ', valueBOptions);

  function handleSetValueA(valueA: string[]) {
    const conditionValueA = findConditionFunction(valueAOptionsRaw, valueA);
    if (!conditionValueA) {
      throw new Error(
        'Something went wrong: could not find conditionValueA in handleSetValueA',
      );
    }

    setCondition({
      ...condition,
      valueA: conditionValueA?.functionCall ?? null,
    });
  }

  function handleSetOperator(operator: string[]) {
    setCondition({ ...condition, operator: operator[operator.length - 1] });
  }

  function handleSetValueB(valueB: string[]) {
    setCondition({ ...condition, valueB: valueB[valueB.length - 1] });
  }

  function findPathFromFunctionCall(functionCall: FunctionCall): string[] {
    // Search through conditionValueA array
    // Find the conditionValueA which has the functionCall
    // Return the [objectName, fieldName] of that conditionValueA

    const conditionValueAWithFunctionCall = valueAOptionsRaw.find(
      (conditionValueA) =>
        conditionValueA.functionCall.name === functionCall.name,
    );
    if (!conditionValueAWithFunctionCall) {
      throw new Error(
        'Something went wrong: Could not find conditionValueA with functionCall',
      );
    }
    return [
      conditionValueAWithFunctionCall?.objectName || '',
      conditionValueAWithFunctionCall?.fieldName || '',
    ];
  }

  function findConditionFunction(
    conditionValueA: ConditionValueA[],
    value: string[],
  ): ConditionValueA {
    // Using a value that is like ['Account', 'Account Name']
    const objectName = value[0];
    const fieldName = value[1];
    // Find ConditionValueA with objectName and fieldName
    const conditionFunction = conditionValueA.find(
      (conditionValueA) =>
        conditionValueA.objectName === objectName &&
        conditionValueA.fieldName === fieldName,
    );
    if (!conditionFunction) {
      throw new Error('Something went wrong: Could not find conditionFunction');
    }
    return conditionFunction;
  }

  function getValueBOptionsForFuncCall(funcCall: FunctionCall): Option[] {
    // Using a funcCall value
    // Find the ConditionValueA with the same funcCall
    // Return the possibleValues of that ConditionValueA
    const conditionValueA = valueAOptionsRaw.find(
      (conditionValueA) => conditionValueA.functionCall.name === funcCall.name,
    );
    if (!conditionValueA) {
      throw new Error(
        'Something went wrong: Could not find conditionValueA in getValueBOptionsForFuncCall',
      );
    }
    return conditionValueA.possibleValues.map((possibleValue) => {
      return { name: possibleValue, subOptions: [] };
    });
  }

  // const handleSetValueBDebounced = useCallback(debounce((value) => {
  //     handleSetValueB(value);
  // }, 300), []); // 300ms delay

  return (
    <div className="flex px-3 py-4 gap-x-4">
      <GrayBgSection>
        <MultiLevelDropdown
          value={
            condition.valueA ? findPathFromFunctionCall(condition.valueA) : []
          }
          handleInputChange={handleSetValueA}
          inputName={'conditionA'}
          showValidation={showValidation}
          options={valueAOptions}
        />

        {operatorOptions.length > 0 && (
          <MultiLevelDropdown
            value={condition.operator ? [condition.operator] : []}
            handleInputChange={handleSetOperator}
            width="w-24"
            inputName={'comparator'}
            showValidation={showValidation}
            options={operatorOptions}
          />
        )}

        {/* 	
            If valueBType is 'picklist', show a MultiLevelDropdown with valueBOptions	
            If valueBType is 'string', show a TextField	
            If valueBType is 'number', show a TextField with type='number'	
            */}
        {valueBType === 'picklist' && (
          <MultiLevelDropdown
            value={condition.valueB ? [condition.valueB] : []}
            handleInputChange={handleSetValueB}
            inputName={'conditionB'}
            showValidation={showValidation}
            options={valueBOptions}
          />
        )}
        {(valueBType === 'string' || valueBType === '') && (
          <TextField
            label={undefined}
            name={'conditionB'}
            value={condition.valueB ?? ''}
            onChange={(e: { target: { value: string } }) =>
              handleSetValueB([e.target.value])
            }
            showValidation={showValidation}
            required={true}
            type="text"
          />
        )}
        {valueBType === 'number' && (
          <TextField
            label={undefined}
            name={'conditionB'}
            value={condition.valueB ?? ''}
            onChange={(e: { target: { value: string } }) =>
              handleSetValueB([e.target.value])
            }
            showValidation={showValidation}
            required={true}
            type="number"
          />
        )}
      </GrayBgSection>

      <button
        type="button"
        className="text-gray-400 hover:text-gray-500"
        onClick={handleDeleteCondition}
      >
        <TrashIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
      </button>
    </div>
  );
}

function OrDivider() {
  return (
    <div className="flex items-center justify-center space-x-4">
      <div className="flex-1 bg-gray-200 h-px"></div>
      <span>OR</span>
      <div className="flex-1 bg-gray-200 h-px"></div>
    </div>
  );
}

function OutlinedSection(props: { children: React.ReactNode }) {
  return (
    <div className="flex-wrap items-center gap-x-4 rounded-md border border-gray-200 bg-white text-gray-900 mb-8">
      {props.children}
    </div>
  );
}

function GrayBgSection(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <div className="inline-flex flex-wrap items-center gap-x-4 rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900">
      {children}
    </div>
  );
}
