import { PricingFlowCommon, PricingFlowType } from '../types';

const pricingCurveRegistry: Record<
  string,
  (pricingFlow: PricingFlowCommon) => boolean
> = {
  default: () => {
    return true;
  },
  testCurve_20240901: (pricingFlow) => {
    // corresponds to opp id 006UV000003tGwtYAE in penguin prod
    return pricingFlow.id === '06212f70-6239-4c2f-b5a1-f0a6d0a62f4c';
  },
  balance_and_transactions_experiment_202409: (pricingFlow) => {
    return pricingFlow.type === PricingFlowType.PENGUIN;
  },
};

export default pricingCurveRegistry;
