import { datadogRum } from '@datadog/browser-rum';
import { useStytchB2BClient } from '@stytch/react/b2b';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import api from 'src/api';
import SignInWithGoogleButton from 'src/components/SignInWithGoogleButton';
import dealopsLogo from '../images/logos/dealops.svg';

function Login() {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const stytchClient = useStytchB2BClient();
  const navigate = useNavigate();
  useEffect(() => {
    const doEffect = async () => {
      const res = await api.get('is_existing_session_valid');
      if (res.data.isSessionValid === true) {
        navigate('/authenticate');
      }
    };
    doEffect();
  }, []);

  async function startSSO({ connectionId }: { connectionId: string }) {
    await api.post('logout', {});
    stytchClient.sso.start({
      connection_id: connectionId,
      login_redirect_url: `${process.env.REACT_APP_CLIENT_BASE_URL}/authenticate`,
      signup_redirect_url: `${process.env.REACT_APP_CLIENT_BASE_URL}/authenticate`,
    });
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setError(null);
  }

  async function onClickSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');

    // Call API fetchSSOInfo(email) which returns {ssoEnabled: boolean, connectionId: "saml-connection-test-..."}
    const fetchUsers = async () => {
      try {
        const response = await api.get(`sso_enabled?email=${email}`);
        if (response?.data.sso_enabled) {
          return response;
        } else {
          setError(
            `⚠️ SSO is not enabled for this domain. Please contact support@dealops.com.`,
          );
          return null;
        }
        // eslint-disable-next-line rulesdir/require-datadog-rum-error
      } catch (error: any) {
        setError('⚠️ Something went wrong. Please contact support@dealops.com');
        datadogRum.addError(error, {
          message: 'Failed to get an SSO response',
        });
      }
    };
    const response = await fetchUsers();
    if (response) {
      const { sso_enabled, stytch_connection_id } = response.data;
      if (sso_enabled) {
        startSSO({ connectionId: stytch_connection_id });
      } else {
        setError('⚠️ SSO is not enabled for this organization.');
        datadogRum.addError(error, {
          message: 'SSO is not enabled for this organization',
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <NavLink to="/">
            <img
              className="mx-auto h-10 w-auto"
              src={dealopsLogo}
              alt="Dealops"
            />
          </NavLink>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={onClickSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fuchsia-900 sm:text-sm sm:leading-6"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {error && <span className="text-red-500">{error}</span>}
            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-fuchsia-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
              >
                Sign in with SSO
              </button>
            </div>
          </form>

          <div className="my-2.5 flex justify-center text-gray-500">or</div>

          <div className="my-2.5">
            <SignInWithGoogleButton />
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a
              href="/contact"
              className="font-semibold leading-6 text-fuchsia-900 hover:text-fuchsia-800"
            >
              Contact sales
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Login;
