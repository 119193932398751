import _ from 'lodash';
import { usePricingFlowContext } from '../PricingFlow';
import { estimatedRevenue, getHamsterListPrice } from './HamsterQuoteTable';
import { HamsterPricingFlow } from './hamster_types';

export default function HamsterAnnualRevenueTable() {
  const { pricingFlow } = usePricingFlowContext<HamsterPricingFlow>();
  const year1Revenue = (() => {
    return pricingFlow.products.reduce((acc, product) => {
      const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
      const months = Array.from(
        { length: Math.min(pricingFlow.additionalData.subscriptionTerms, 12) },
        (_, i) => i,
      );
      return (
        acc +
        _.sum(
          months.map((monthIdx) => {
            const rampVolume =
              product.rampedVolumeIncremental.length > monthIdx
                ? (product.rampedVolumeIncremental[monthIdx]?.value ?? 0)
                : 0;
            return estimatedRevenue(
              product.volume + rampVolume,
              product.quotePrice,
              getHamsterListPrice(product, productInfo),
            );
          }),
        )
      );
    }, 0);
  })();
  const arrAtScale =
    (() => {
      return pricingFlow.products.reduce((acc, product) => {
        const listPrice = getHamsterListPrice(
          product,
          pricingFlow.pricingSheetData.productInfo[product.id],
        );
        const maxVolume =
          product.volume +
          (_.max(product.rampedVolumeIncremental.map((v) => v?.value ?? 0)) ??
            0);
        return acc + estimatedRevenue(maxVolume, product.quotePrice, listPrice);
      }, 0);
    })() * 12;
  return (
    <div className="rounded-xl border border-gray-200 bg-white">
      <table className="border-separate border-spacing-0">
        <thead>
          <tr className="bg-gray-50">
            <th className="rounded-tl-xl border-b border-gray-200"></th>
            <th className="font-medium text-xs rounded-tr-xl px-6 py-3.5 text-gray-700 border-b border-gray-200">
              Total Revenue
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="font-medium text-xs text-gray-700 px-6 py-3.5 border-b border-gray-200">
              YEAR 1
            </td>
            <td className="font-medium text-xl text-gray-700 px-6 py-3.5 border-b border-gray-200">
              ${year1Revenue.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td className="font-medium text-xs text-gray-700 px-6 py-3.5">
              ARR AT SCALE
            </td>
            <td className="font-medium text-xl text-gray-700 px-6 py-3.5">
              ${arrAtScale.toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
