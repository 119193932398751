import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { useRef, useState } from 'react';
import { classNames } from 'src/dashboard/App';

type CollapsibleCardProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};

export default function CollapsibleCard(props: CollapsibleCardProps) {
  const { title, subtitle, children } = props;
  const [collapsed, setCollapsed] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  function toggleCollapse(): void {
    setCollapsed(!collapsed);
  }

  return (
    <div className="w-full">
      <div className="">
        <div
          className={classNames(
            'divide-y divide-gray-200 rounded-lg bg-white shadow',
            collapsed ? 'overflow-hidden' : '',
          )}
        >
          <button type="button" className="w-full" onClick={toggleCollapse}>
            <div className="flex flex-wrap items-center justify-between pb-4 sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </h3>
                {subtitle && (
                  <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                )}
              </div>
              <div className="relative ml-4 mt-4 inline-flex flex-shrink-0 items-center px-3 py-2">
                {collapsed ? (
                  <ChevronUpIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </button>
          <div
            ref={contentRef}
            className={classNames(
              'px-4 transition-all duration-300 ease-out',
              collapsed ? 'overflow-hidden' : 'py-4',
            )}
            style={collapsed ? { height: '0px' } : {}}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
