export function setupDeveloperMode() {
  const keyword = 'enableDevMode';

  window.activateDeveloperMode = function (input) {
    if (input === keyword) {
      console.log('Developer Mode Activated!');
      window.isDeveloperModeEnabled = true;
    } else {
      console.log('Incorrect keyword. Developer mode not activated.');
    }
  };

  console.log(
    "Type activateDeveloperMode('your_keyword') to enable developer mode.",
  );
}
