import React from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

export type Datum = {
  name: string;
  topBar: number;
  middleTransparentBar: number;
  bottomBar: number;
  tooltipData: {};
};

function formatNumber(value: number, currency: string) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

type TooltipComponentProps = {
  label: any;
  payload: Payload<number, string>[];
};
// Later we can add configuration for if the y-axis is not a currency
export default function StackedBarChart(props: {
  data: Datum[];
  currency: string;
  TooltipComponent: React.FC<TooltipComponentProps>;
}) {
  const { data, currency, TooltipComponent } = props;

  const max = data.reduce(
    (acc, curr) =>
      Math.max(acc, curr.topBar, curr.middleTransparentBar, curr.bottomBar),
    0,
  );
  const formatted = formatNumber(max, currency);
  // calculate how much padding the left side needs, add 8 pixels per character then add some extra 20px buffer
  const leftPadding = 8 * formatted.length + 20;

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        width={650}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 0,
          bottom: 20,
          left: 0,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          label={{
            value: '',
            position: 'insideBottom',
            offset: -10,
            className: 'font-medium text-xs text-gray-900',
          }}
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          label={{
            value: '',
            angle: -90,
            offset: -36,
            position: 'insideLeft',
            className: 'font-medium text-xs text-gray-900',
          }}
          width={leftPadding}
          tickFormatter={(value) => formatNumber(value, currency)}
          tick={{ fontSize: 12, fill: '#475467' }}
          axisLine={true}
          tickLine={true}
        />
        <RechartTooltip
          content={
            <CustomTooltip currency={currency} Tooltip={TooltipComponent} />
          }
          cursor={{ fill: 'transparent' }}
        />
        <Bar
          dataKey="bottomBar"
          stackId="a"
          fill="#3B82F6"
          radius={[10, 10, 10, 10]}
          label={{
            fontSize: 12,
            fill: 'white',
            position: 'insideTop',
            formatter: (value: any) =>
              value > 1
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currency,
                  }).format(value)
                : '',
          }}
        />
        <Bar
          dataKey="middleTransparentBar"
          stackId="a"
          fill="transparent"
          radius={[10, 10, 10, 10]}
        />
        <Bar
          dataKey="topBar"
          stackId="a"
          fill="#22C55E"
          radius={[10, 10, 10, 10]}
        >
          <LabelList
            valueAccessor={(value: any) =>
              value.topBar + value.middleTransparentBar
            }
            fontSize={12}
            fill={'white'}
            position={'insideTop'}
            formatter={(value: any) =>
              value > 1
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currency,
                  }).format(value)
                : ''
            }
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  currency: string;
  Tooltip: React.FC<TooltipComponentProps>;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  Tooltip,
}) => {
  if (active && payload && payload.length) {
    return <Tooltip label={label} payload={payload} />;
  }

  return null;
};
