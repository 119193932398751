export function Logo(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151"
      height="35"
      fill="none"
      className={props.className}
    >
      <path
        d="M56.903 20.682c0-.596-.056-1.164-.168-1.704a3.9 3.9 0 0 0-.589-1.453c-.262-.428-.607-.773-1.037-1.033s-.972-.391-1.626-.391-1.205.13-1.654.391a3.22 3.22 0 0 0-1.093 1.034c-.262.428-.458.922-.589 1.481-.112.54-.168 1.108-.168 1.704a7.33 7.33 0 0 0 .196 1.676c.131.559.336 1.061.617 1.508.299.428.663.782 1.093 1.061.449.261.981.391 1.598.391.654 0 1.196-.13 1.626-.391.449-.261.803-.605 1.065-1.033a4.85 4.85 0 0 0 .561-1.508c.112-.559.168-1.136.168-1.732zm.056 5.419h-.056c-.467.782-1.084 1.35-1.85 1.704-.747.335-1.598.503-2.551.503-1.084 0-2.037-.205-2.859-.615a6.07 6.07 0 0 1-2.046-1.704c-.523-.726-.925-1.555-1.205-2.486A10.69 10.69 0 0 1 46 20.598c0-.968.131-1.899.392-2.793.28-.913.682-1.713 1.205-2.402a6 6 0 0 1 2.018-1.648c.803-.428 1.738-.643 2.803-.643.86 0 1.672.186 2.439.559.785.354 1.401.884 1.85 1.592h.056V8h3.98v19.944h-3.784v-1.843z"
        fill="url(#A)"
      />
      <path
        d="M73.645 19.173c-.187-1.006-.523-1.769-1.009-2.291-.467-.521-1.187-.782-2.158-.782-.635 0-1.168.112-1.598.335-.411.205-.748.465-1.009.782-.243.317-.421.652-.533 1.006-.093.354-.15.67-.168.95h6.475zm-6.475 2.514c.056 1.285.383 2.216.981 2.793s1.458.866 2.579.866c.803 0 1.495-.196 2.074-.587.579-.41.934-.838 1.065-1.285h3.503c-.561 1.732-1.42 2.97-2.579 3.715s-2.56 1.117-4.204 1.117c-1.14 0-2.168-.177-3.083-.531-.916-.372-1.691-.894-2.326-1.564s-1.13-1.471-1.485-2.402c-.336-.931-.505-1.955-.505-3.073 0-1.08.178-2.086.532-3.017s.859-1.732 1.514-2.402a7.06 7.06 0 0 1 2.326-1.62c.916-.391 1.925-.587 3.027-.587 1.233 0 2.308.242 3.223.726.916.466 1.663 1.099 2.242 1.899.598.801 1.028 1.713 1.289 2.738a10.16 10.16 0 0 1 .28 3.212H67.17z"
        fill="url(#A)"
      />
      <path
        d="M79.957 17.944c.056-.931.29-1.704.701-2.318s.934-1.108 1.57-1.48 1.345-.633 2.13-.782c.803-.168 1.607-.251 2.41-.251.729 0 1.467.056 2.214.168.747.093 1.429.289 2.046.587s1.121.717 1.514 1.257c.392.521.588 1.22.588 2.095v7.514a15.03 15.03 0 0 0 .112 1.872c.075.596.206 1.043.392 1.341h-4.036l-.196-.67-.084-.726a5.19 5.19 0 0 1-2.242 1.369 9.03 9.03 0 0 1-2.635.391 7.14 7.14 0 0 1-1.934-.251c-.598-.168-1.121-.428-1.57-.782s-.803-.801-1.065-1.341c-.243-.54-.364-1.183-.364-1.927 0-.819.14-1.49.42-2.011.299-.54.673-.968 1.121-1.285.467-.317.99-.549 1.57-.698a15.45 15.45 0 0 1 1.794-.391l1.766-.224c.579-.056 1.093-.14 1.541-.251s.803-.27 1.065-.475c.262-.224.383-.54.364-.95 0-.428-.075-.764-.224-1.006-.131-.261-.318-.456-.561-.587-.224-.149-.495-.242-.813-.279-.299-.056-.626-.084-.981-.084-.785 0-1.402.168-1.85.503s-.71.894-.785 1.676h-3.98zm9.193 2.933a1.94 1.94 0 0 1-.645.363 7.46 7.46 0 0 1-.813.196l-.897.14-.953.14a7.88 7.88 0 0 0-.897.223 2.65 2.65 0 0 0-.757.391 1.72 1.72 0 0 0-.505.587c-.131.242-.196.549-.196.922 0 .354.065.652.196.894a1.61 1.61 0 0 0 .532.587c.224.13.486.223.785.279a5.03 5.03 0 0 0 .925.084c.785 0 1.392-.13 1.822-.391s.748-.568.953-.922c.206-.372.327-.745.364-1.117.056-.372.084-.67.084-.894v-1.48z"
        fill="url(#A)"
      />
      <path d="M96.472 8h3.98v19.944h-3.98V8z" fill="url(#A)" />
      <path
        d="M107.258 20.737c0 .577.056 1.145.168 1.704.112.54.299 1.034.56 1.481.281.428.645.773 1.094 1.034s1.009.391 1.681.391 1.233-.13 1.682-.391a2.92 2.92 0 0 0 1.093-1.033c.28-.447.476-.94.589-1.481.112-.559.168-1.127.168-1.704s-.056-1.145-.168-1.704-.309-1.052-.589-1.48a2.92 2.92 0 0 0-1.093-1.033c-.449-.279-1.009-.419-1.682-.419s-1.233.14-1.681.419c-.449.261-.813.605-1.094 1.034-.261.428-.448.922-.56 1.48s-.168 1.127-.168 1.704zm-3.98 0c0-1.155.177-2.197.532-3.129.355-.95.86-1.75 1.514-2.402.654-.67 1.439-1.182 2.354-1.536.916-.372 1.943-.559 3.083-.559s2.168.186 3.083.559c.935.354 1.729.866 2.383 1.536.654.652 1.158 1.452 1.513 2.402.355.931.533 1.974.533 3.129s-.178 2.197-.533 3.128-.859 1.732-1.513 2.402c-.654.652-1.448 1.154-2.383 1.508-.915.354-1.943.531-3.083.531s-2.167-.177-3.083-.531-1.7-.857-2.354-1.508c-.654-.67-1.159-1.471-1.514-2.402s-.532-1.974-.532-3.128z"
        fill="url(#A)"
      />
      <path
        d="M128.316 25.346c.654 0 1.196-.13 1.626-.391.448-.261.803-.596 1.065-1.006.28-.428.476-.922.588-1.48a8.65 8.65 0 0 0 .168-1.704 7.44 7.44 0 0 0-.196-1.704c-.112-.559-.308-1.052-.588-1.48-.281-.447-.645-.801-1.093-1.061-.43-.279-.963-.419-1.598-.419-.654 0-1.205.14-1.654.419a3.33 3.33 0 0 0-1.065 1.034c-.261.428-.448.922-.56 1.48a8.79 8.79 0 0 0-.168 1.732 8.65 8.65 0 0 0 .168 1.704c.131.559.327 1.052.588 1.48.281.41.645.745 1.093 1.006s.991.391 1.626.391zm-7.315-11.844h3.783v1.844h.057c.485-.782 1.102-1.35 1.849-1.704s1.57-.531 2.467-.531c1.14 0 2.121.214 2.943.643s1.504.996 2.046 1.704.944 1.536 1.205 2.486c.262.931.392 1.909.392 2.933a9.88 9.88 0 0 1-.392 2.793c-.261.894-.663 1.685-1.205 2.374-.523.689-1.187 1.238-1.99 1.648-.785.41-1.71.615-2.775.615-.897 0-1.728-.177-2.494-.531-.748-.372-1.364-.912-1.85-1.62h-.056V33h-3.98V13.503z"
        fill="url(#A)"
      />
      <path
        d="M141.358 23.251c0 .428.084.801.253 1.117a2.69 2.69 0 0 0 .7.754 3.16 3.16 0 0 0 .953.419 4.74 4.74 0 0 0 1.149.14 4.73 4.73 0 0 0 .869-.084c.318-.075.598-.177.841-.307a2.08 2.08 0 0 0 .645-.559c.168-.242.252-.54.252-.894 0-.596-.402-1.043-1.205-1.341-.785-.298-1.887-.596-3.308-.894l-1.709-.447c-.542-.186-1.028-.419-1.458-.698-.429-.298-.775-.661-1.037-1.089-.261-.447-.392-.987-.392-1.62 0-.931.177-1.695.532-2.29.374-.596.86-1.062 1.458-1.397.598-.354 1.27-.596 2.018-.726a11.72 11.72 0 0 1 2.298-.223c.785 0 1.542.074 2.27.223.748.149 1.411.4 1.99.754s1.056.829 1.43 1.425c.392.577.626 1.313.701 2.207h-3.784c-.056-.764-.346-1.276-.869-1.536-.523-.279-1.14-.419-1.85-.419a5.21 5.21 0 0 0-.729.056c-.261.019-.504.075-.728.168a1.44 1.44 0 0 0-.533.419c-.149.168-.224.4-.224.698 0 .354.131.642.392.866s.598.41 1.009.559l1.458.363 1.653.363a15.89 15.89 0 0 1 1.682.475c.561.186 1.056.438 1.486.754a3.41 3.41 0 0 1 1.037 1.145c.261.447.392 1.006.392 1.676 0 .95-.196 1.75-.589 2.402-.373.633-.868 1.145-1.485 1.536s-1.327.661-2.13.81a11.51 11.51 0 0 1-2.411.251c-.84 0-1.663-.084-2.466-.251s-1.523-.447-2.158-.838c-.617-.391-1.131-.903-1.542-1.536-.392-.652-.607-1.462-.644-2.43h3.783z"
        fill="url(#A)"
      />
      <path
        d="M0 2a2 2 0 0 1 2-2h31a2 2 0 0 1 2 2v31a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
        fill="url(#B)"
      />
      <path
        d="M30 17.5C30 24.404 24.404 30 17.5 30S5 24.404 5 17.5 10.596 5 17.5 5 30 10.596 30 17.5z"
        fill="#fff"
      />
      <path
        d="M28 17.5C28 23.299 23.299 28 17.5 28S7 23.299 7 17.5 11.701 7 17.5 7 28 11.701 28 17.5z"
        fill="url(#B)"
      />
      <path d="M26 17.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 1 1 17 0z" fill="#fff" />
      <path
        d="M24 17.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 1 1 13 0z"
        fill="url(#B)"
      />
      <path d="M22 17.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 1 1 9 0z" fill="#fff" />
      <path d="M20 17.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 1 1 5 0z" fill="url(#B)" />
      <path
        d="M23 9.75c-.5 1-6.25 6.25-6.25 6.25s-1.25 1.5-.25 2.5 2.25-.25 2.25-.25l6-5.75c.75-.5 1.25.5 2 0s5-4.75 5-4.75l-4.5-4.25-4.75 4.75c-.5.5 1 .5.5 1.5z"
        fill="url(#C)"
      />
      <path
        d="M23.447 8.5l.25 2-6.5 6.25c-.083.083-.399.6.001 1s.916-.083.999-.25l6.25-6.25 2 .25 3.5-3.75h-2.75L26.947 5l-3.5 3.5z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="A"
          x1="97.958"
          y1="14.227"
          x2="98.759"
          y2="27.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".359" stopColor="#2c0c62" />
          <stop offset="1" stopColor="#a31784" />
        </linearGradient>
        <linearGradient
          id="B"
          x1="17.5"
          y1="0"
          x2="17.5"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".349" stopColor="#2c0c62" />
          <stop offset="1" stopColor="#a31784" />
        </linearGradient>
        <linearGradient
          id="C"
          x1="17.934"
          y1="0"
          x2="17.934"
          y2="34.118"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".349" stopColor="#2c0c62" />
          <stop offset="1" stopColor="#a31784" />
        </linearGradient>
      </defs>
    </svg>
  );
}
