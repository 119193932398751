import { RadioGroup } from '@headlessui/react';

type StackedCardRadioButtonOption = {
  name: string;
  description: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

type StackedCardRadioButtonGroupProps = {
  options: StackedCardRadioButtonOption[];
  selected: string | undefined;
  onSelect: (name: string) => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function StackedCardRadioButtonGroup(
  props: StackedCardRadioButtonGroupProps,
) {
  const { options, selected, onSelect } = props;

  return (
    <RadioGroup value={selected} onChange={onSelect}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="space-y-4">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option.name} // Ensure that the value is option.name
            className={({ active }) =>
              classNames(
                active
                  ? 'border-fuchsia-900 ring-2 ring-fuchsia-900'
                  : 'border-gray-300',
                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label
                      as="span"
                      className="font-medium text-gray-900"
                    >
                      {option.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      {option.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-fuchsia-900' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
