import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import { useRef } from 'react';
import { FormattedNumberField } from 'src/components/Fields';
import Tooltip from 'src/components/Tooltip';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import EditableIndicator from '../../Penguin/Components/EditableIndicator';
import { usePricingFlowContext } from '../../PricingFlow';
import { CurrencyValueType, QuotePrice } from '../../types_common/price';
import {
  AlpacaSupportedCurrency,
  ALPACA_CURRENCY_SYMBOLS,
} from '../alpaca_types';

type InputTypes = 'count' | 'volume' | 'count_and_volume';
function getReason(
  inputTypes: InputTypes,
  quotePriceType: CurrencyValueType | null,
  costType: CurrencyValueType | null,
) {
  switch (inputTypes) {
    case 'count':
      if (quotePriceType === CurrencyValueType.FLAT) {
        if (costType === CurrencyValueType.FLAT) {
          return 'Txn count required because quote price and cost are flat fees.';
        } else if (costType === CurrencyValueType.FLAT_AND_PERCENT) {
          return 'Txn count required because quote price and cost have flat fee components.';
        } else {
          return 'Txn count required because quote price is a flat fee.';
        }
      } else {
        if (costType === CurrencyValueType.FLAT) {
          return 'Txn count required because cost is a flat fee.';
        } else if (costType === CurrencyValueType.FLAT_AND_PERCENT) {
          return 'Txn count required because cost has a flat fee component.';
        } else {
          datadogRum.addError(
            `Should not be displaying a txn count input with ${quotePriceType} ${costType}`,
          );
          return '';
        }
      }
    case 'volume':
      if (quotePriceType === CurrencyValueType.PERCENT) {
        if (costType === CurrencyValueType.PERCENT) {
          return 'Volume required because quote price and cost are % fees.';
        } else if (costType === CurrencyValueType.FLAT_AND_PERCENT) {
          return 'Volume required because quote price and cost have % fee components.';
        } else {
          return 'Volume required because quote price is a % fee.';
        }
      } else {
        if (costType === CurrencyValueType.PERCENT) {
          return 'Volume required because cost is a % fee.';
        } else if (costType === CurrencyValueType.FLAT_AND_PERCENT) {
          return 'Volume required because cost has a % fee component.';
        } else {
          datadogRum.addError(
            `Should not be displaying a volume input with ${quotePriceType} ${costType}`,
          );
          return '';
        }
      }
    case 'count_and_volume':
      switch (quotePriceType) {
        case CurrencyValueType.FLAT:
          switch (costType) {
            case CurrencyValueType.FLAT_AND_PERCENT:
              return 'Volume and txn count required because cost has flat and % fee components.';
            case CurrencyValueType.PERCENT:
              return 'Volume and txn count required because quote price is a flat fee and cost is a % fee.';
            default:
              datadogRum.addError(
                `Should not be displaying count and volume input with ${quotePriceType} ${costType}`,
              );
              return '';
          }
        case CurrencyValueType.PERCENT:
          switch (costType) {
            case CurrencyValueType.FLAT_AND_PERCENT:
              return 'Volume and txn count required because cost has flat and % fee components.';
            case CurrencyValueType.FLAT:
              return 'Volume and txn count required because cost is a flat fee and quote price is a % fee.';
            default:
              datadogRum.addError(
                `Should not be displaying count and volume input with ${quotePriceType} ${costType}`,
              );
              return '';
          }
        case CurrencyValueType.FLAT_AND_PERCENT:
          return 'Volume and txn count required because quote price has flat and % fee components.';
      }
  }
}
function getQuotePriceType(
  quotePrice: QuotePrice | null,
): CurrencyValueType | null {
  if (isNil(quotePrice)) {
    return null;
  }
  if (quotePrice.type === 'tiered') {
    // ##AlpacaTierAggregationCalculations
    // Depending on what info is in the tiers (minimum type and types of tiers),
    // we need the rep to input transaction count, dollar volumes, or both in
    // order to measure the expected revenue from the deal.
    // The CurrencyValueType on the quotePrice determines which of these inputs
    // are shown to the rep.
    if (
      quotePrice.tiers.some(
        (tier) =>
          tier.currencyValue.type === CurrencyValueType.FLAT_AND_PERCENT,
      )
    ) {
      return CurrencyValueType.FLAT_AND_PERCENT;
    }

    // Track conditions for needing transaction count and volume
    let tracker = {
      needTxnCount: false,
      needVolume: false,
    };
    if (
      quotePrice.minimumType === 'count' ||
      quotePrice.tiers.some(
        (tier) => tier.currencyValue.type === CurrencyValueType.FLAT,
      )
    ) {
      tracker.needTxnCount = true;
    }
    if (
      quotePrice.minimumType === CurrencyValueType.FLAT ||
      quotePrice.tiers.some(
        (tier) => tier.currencyValue.type === CurrencyValueType.PERCENT,
      )
    ) {
      tracker.needVolume = true;
    }

    // Return the correct type based on the tracker
    if (tracker.needTxnCount && tracker.needVolume) {
      return CurrencyValueType.FLAT_AND_PERCENT;
    } else if (tracker.needTxnCount) {
      return CurrencyValueType.FLAT;
    } else if (tracker.needVolume) {
      return CurrencyValueType.PERCENT;
    }
    return CurrencyValueType.FLAT_AND_PERCENT;
  }
  if (quotePrice.type === 'ramped') {
    if (
      quotePrice.rampValues.some(
        (value) => value.type === CurrencyValueType.FLAT_AND_PERCENT,
      )
    ) {
      return CurrencyValueType.FLAT_AND_PERCENT;
    }

    // Track conditions for needing transaction count and volume
    let tracker = {
      needTxnCount: false,
      needVolume: false,
    };
    if (
      quotePrice.rampValues.some(
        (value) => value.type === CurrencyValueType.FLAT,
      )
    ) {
      tracker.needTxnCount = true;
    }
    if (
      quotePrice.rampValues.some(
        (value) => value.type === CurrencyValueType.PERCENT,
      )
    ) {
      tracker.needVolume = true;
    }

    // Return the correct type based on the tracker
    if (tracker.needTxnCount && tracker.needVolume) {
      return CurrencyValueType.FLAT_AND_PERCENT;
    } else if (tracker.needTxnCount) {
      return CurrencyValueType.FLAT;
    } else if (tracker.needVolume) {
      return CurrencyValueType.PERCENT;
    }
    return CurrencyValueType.FLAT_AND_PERCENT;
  }
  return quotePrice.type;
}
export const VolumeEditable = (props: {
  volume: number;
  updateVolume: (newVolume: number) => void;
  transactionCount?: number;
  updateTransactionCount: (newTransactionCount: number | undefined) => void;
  customCountSuffix?: string | null;
  quotePrice: QuotePrice | null;
  quoteCurrency: AlpacaSupportedCurrency;
  cost: QuotePrice | null;
  // Normally, we infer whether the input is in currency, counts, or both by
  // looking at the quote price and cost, and determining what inputs are
  // necessary for gross revenue/cost calculations. However, in some cases (e.g.
  // tier minimums), we don't care about that and want to override this with a
  // particular input type
  inputTypesOverride?: InputTypes;
}) => {
  const {
    volume,
    updateVolume,
    transactionCount,
    updateTransactionCount,
    customCountSuffix,
    quotePrice,
    quoteCurrency,
    cost,
    inputTypesOverride,
  } = props;

  const { editMode } = usePricingFlowContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const quotePriceType = getQuotePriceType(quotePrice);
  const costType = getQuotePriceType(cost);
  const inputTypes: InputTypes | null =
    inputTypesOverride ??
    (() => {
      function cvHasFlat(currencyValueType: CurrencyValueType | null) {
        return (
          !isNil(currencyValueType) &&
          (currencyValueType === CurrencyValueType.FLAT ||
            currencyValueType === CurrencyValueType.FLAT_AND_PERCENT)
        );
      }
      function cvHasPercent(currencyValueType: CurrencyValueType | null) {
        return (
          !isNil(currencyValueType) &&
          (currencyValueType === CurrencyValueType.PERCENT ||
            currencyValueType === CurrencyValueType.FLAT_AND_PERCENT)
        );
      }
      const requiresCount = cvHasFlat(quotePriceType) || cvHasFlat(costType);
      const requiresVolume =
        cvHasPercent(quotePriceType) || cvHasPercent(costType);
      if (requiresCount && requiresVolume) {
        return 'count_and_volume';
      } else if (requiresCount) {
        return 'count';
      } else if (requiresVolume) {
        return 'volume';
      } else {
        // if cost and quote price are null you can get into this case
        return null;
      }
    })();

  switch (inputTypes) {
    case 'volume': {
      // Volume only
      const editableInput = (
        <EditableIndicator>
          <FormattedNumberField
            type="text"
            value={volume}
            numberDecimals={2}
            className="w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent  pl-4 py-4"
            updateValue={(value: number) => {
              updateVolume(value);
            }}
            prefix={ALPACA_CURRENCY_SYMBOLS[quoteCurrency]}
            ref={inputRef}
            data-volume-editable
            onKeyDown={getHandleKeyDownForEnterNextRowHandling(
              inputRef,
              'data-volume-editable',
            )}
            disabled={!editMode}
          />
        </EditableIndicator>
      );
      return (
        <Tooltip
          as={'div'}
          text={getReason(inputTypes, quotePriceType, costType)}
          location={'TOP'}
          // If the user has entered a volume, they know what they're doing and
          // don't need the tooltip
          // If we've passed in input type overrides, the tooltip will not
          // accurately reflect why we're asking for volume or txn count inputs
          disableTooltip={volume !== 0 || !isNil(inputTypesOverride)}
        >
          {editableInput}
        </Tooltip>
      );
    }
    case 'count': {
      // Transaction count only
      const editableInput = (
        <EditableIndicator>
          <FormattedNumberField
            type="text"
            value={transactionCount ?? 0}
            numberDecimals={0}
            className="w-0 grow cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent  pl-4 py-4"
            updateValue={(value: number) => {
              updateTransactionCount(value);
            }}
            suffix={` ${customCountSuffix ?? 'txns'}`}
            ref={inputRef}
            data-volume-editable
            onKeyDown={getHandleKeyDownForEnterNextRowHandling(
              inputRef,
              'data-volume-editable',
            )}
            disabled={!editMode}
          />
        </EditableIndicator>
      );
      return (
        <Tooltip
          as={'div'}
          // If the user has entered a transaction count, they know what they're doing and
          // don't need the tooltip
          // If we've passed in input type overrides, the tooltip will not
          // accurately reflect why we're asking for volume or txn count inputs
          text={getReason(inputTypes, quotePriceType, costType)}
          disableTooltip={transactionCount !== 0 || !isNil(inputTypesOverride)}
          location={'TOP'}
        >
          {editableInput}
        </Tooltip>
      );
    }
    case 'count_and_volume': {
      // Transaction count and volume
      const editableInput = (
        <EditableIndicator>
          <div className="flex flex-col items-left">
            <FormattedNumberField
              type="text"
              value={volume}
              numberDecimals={2}
              className="h-0 grow cursor-pointer border-none bg-transparent px-0 text-xs text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent  pl-4 pt-4"
              updateValue={(value: number) => {
                updateVolume(value);
              }}
              prefix={ALPACA_CURRENCY_SYMBOLS[quoteCurrency]}
              ref={inputRef}
              data-volume-editable
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                inputRef,
                'data-volume-editable',
              )}
              disabled={!editMode}
            />
            <FormattedNumberField
              type="text"
              value={transactionCount ?? 0}
              numberDecimals={0}
              className="h-0 grow cursor-pointer border-none bg-transparent px-0 text-xs text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent pl-4 pb-4"
              updateValue={(value: number) => {
                updateTransactionCount(value);
              }}
              suffix=" txns"
              ref={inputRef}
              data-volume-editable
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                inputRef,
                'data-volume-editable',
              )}
              disabled={!editMode}
            />
          </div>
        </EditableIndicator>
      );
      return (
        <Tooltip
          as={'div'}
          text={getReason(inputTypes, quotePriceType, costType)}
          disableTooltip={
            // If the user has entered volume values, they know what they're
            // doing and don't need the tooltip
            (volume !== 0 && transactionCount !== 0) ||
            // If we've passed in input type overrides, the tooltip will not
            // accurately reflect why we're asking for volume or txn count
            // inputs
            !isNil(inputTypesOverride)
          }
          location={'TOP'}
        >
          {editableInput}
        </Tooltip>
      );
    }
    case null: {
      return (
        <div className="flex py-4 pl-4 text-sm font-medium text-gray-500">
          N/A
        </div>
      );
    }
    default:
      const typecheck: never = inputTypes;
  }
};
